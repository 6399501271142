import { PAGInit } from 'libpag';
import { blobToBase64 } from './strings';
import saveAs from 'file-saver';

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isOKApp = /OKApp/i.test(navigator.userAgent);
export const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);
export const isAndroid = /android/i.test(navigator.userAgent);
export const isBrowser = typeof window !== 'undefined';
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isChrome = /chrome/i.test(navigator.userAgent);
export const isWeChat = /micromessenger/i.test(navigator.userAgent);

export const isSupportWebp = document.createElement('canvas').toDataURL('image/webp').startsWith('data:image/webp');

export const pag = PAGInit({ locateFile: () => '/libpag.wasm' });

export const openTab = (url: string) => window.open(url, '_blank');

const flutter_inappwebview = window.flutter_inappwebview as { callHandler: any } | undefined;

export const native = flutter_inappwebview && {
  quitGame() {
    flutter_inappwebview.callHandler('Back', { data: 'OK' });
    return true;
  },
  share(data: string) {
    flutter_inappwebview.callHandler('Share', { data });
    return true;
  },
  login() {
    flutter_inappwebview.callHandler('Login');
    return true;
  },
  openUser() {
    flutter_inappwebview.callHandler('navProfile');
    return true;
  },
  updateLang(lang: string) {
    flutter_inappwebview.callHandler('updateLang', { lang });
    return true;
  },
  openPage(name: string, props?: any) {
    flutter_inappwebview.callHandler('openPage', { name, ...props });
    return true;
  },
};

export const shareBlob = async (title: string, blob: Blob) => {
  if (native) native.share(await blobToBase64(blob));
  else saveAs(blob, title);
};
