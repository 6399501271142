<template>
  <div class="py-4">
    <div class="grid grid-cols-4 gap-3" un-children="rd-lg bg-gray-900 p-4" portrait="grid-cols-2">
      <div v-for="(clan, i) in mine" :key="i" @click="openClan(clan)">
        <div class="flex justify-between text-xl">
          <img class="rd size-16" :src="clan.logoUrl" alt="" />
          <div
            :class="{ 'c-yellow': clan.isStar }"
            class="i-local-star-fill c-gray-600 cursor-pointer"
            @click.stop="markStar(clan)"
          />
        </div>
        <div class="flex items-center mt-2">
          <h4 class="truncate">{{ clan.name }}</h4>
          <img v-if="clan.clanRole === 2" class="w-5 ml-1" src="@/assets/img/clan-owner-tag.png" alt="" />
        </div>
        <div class="flex space-x-6 c-gray-200">
          <div><span class="c-gray">ID:</span>{{ clan.clanId }}</div>
          <div><span class="c-gray inline-block align-middle i-local-user" /> {{ clan.memberCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from '@/router';
import type { Clan } from '@/types';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';

const mine = ref<Clan[]>([]);

const updateList = () => {
  authAPI.get('v1/clan/listJoined').then((res) => (mine.value = res.map((v: any) => ({ ...v.clan, ...v }))));
};
updateList();

const openClan = async (v: Clan) => {
  router.push(`/cl/${v.clanId}`);
};
const markStar = async (v: Clan) => {
  if (v.isStar) return;
  await authAPI.post('v1/clan/user/star', { clanId: v.clanId });
  updateList();
};
</script>
