const parent = window.parent.window;

export const loading = (value: any) => parent.postMessage({ playdeck: { method: 'loading', value: value } }, '*');

export const getPlaydeckState = () => parent.postMessage({ playdeck: { method: 'getPlaydeckState' } }, '*');

export const getScore = () => parent.postMessage({ playdeck: { method: 'getScore' } }, '*');

export const getGlobalScore = (value: any) =>
  parent.postMessage({ playdeck: { method: 'getGlobalScore', top: value } }, '*');

export const setScore = (value: any, isForce = false) =>
  parent.postMessage({ playdeck: { method: 'setScore', value: value, isForce: isForce } }, '*');

export const getData = (key: any) => parent.postMessage({ playdeck: { method: 'getData', key: key } }, '*');
export const setData = (key: any, value: any) =>
  parent.postMessage({ playdeck: { method: 'setData', key: key, value: value } }, '*');
//We have limit for data = 10Kb and key length <= 50 symbols

export const getUserLocale = () => parent.postMessage({ playdeck: { method: 'getUserLocale' } }, '*');
export const getUser = () => parent.postMessage({ playdeck: { method: 'getUser' } }, '*');

export const gameEnd = () => parent.postMessage({ playdeck: { method: 'gameEnd' } }, '*');

export const openTelegramLink = (url: any) =>
  parent.postMessage({ playdeck: { method: 'openTelegramLink', value: url } }, '*');

export const getUserProfile = () => parent.postMessage({ playdeck: { method: 'getUserProfile' } }, '*');

window.addEventListener('message', ({ data }) => {
  const pdData = data?.playdeck;
  if (!pdData) return;

  console.log('[game 🎾 :]', pdData.method, pdData.value);

  if (pdData.method === 'isOpen') {
    // window.playdeckIsOpen = pdData.value;
  }

  if (pdData.method === 'getUser') {
    try {
      // loginSocial(pdData.value['username']!, pdData.value['token']!);
      console.log('success');
    } catch (e) {
      alert(e);
    }

    // window.playdeckUser = pdData.value;
  }

  if (pdData.method === 'getUserLocale') {
    // window.playdeckUserLocale = pdData.value;
  }

  if (pdData.method === 'getScore') {
    // window.playdeckScore = pdData.value;
  }
  if (pdData.method === 'getData') {
    // window.playdeckData = pdData.value;
  }

  if (pdData.method === 'play') {
    // if (runner.crashed && runner.gameOverPanel) {
    //   runner.restart();
    // } else {
    //   const e = new KeyboardEvent('keydown', { keyCode: 32, which: 32 });
    //   document.dispatchEvent(e);
    // }
  }

  if (pdData.method === 'pause') {
    // runner.stop();
  }
  if (pdData.method === 'loaded') {
    loading(100);
  }
});

// const params = new URLSearchParams(window.location.search);

// // const token = params.get('token');
// const telegramEnv = params.get('telegram');

// if (telegramEnv) {
//   console.log('telegramEnv=' + telegramEnv);
//   getUser();

//   // loading();
//   setTimeout(() => {
//     loading(100);
//   }, 1000);
// }
