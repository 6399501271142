import AccountPage from './AccountPage.vue';
import ClanPage from './ClanPage.vue';
import EditPage from './EditPage.vue';
import GameSettingPage from './GameSettingPage.vue';
import RecordPage from './RecordPage.vue';
import ReferralPage from './ReferralPage.vue';
import FeedbackPage from './FeedbackPage.vue';

import TaskPage from '@/views/task/MainPage.vue';
import GoldPage from '@/views/task/GoldPage.vue';
import DailyPage from '@/views/task/DailyPage.vue';
import ExperiencePage from '@/views/task/ExperiencePage.vue';
import DiamondPage from '@/views/task/DiamondPage.vue';

export default [
  { path: 'profile/account', name: 'account', component: AccountPage, meta: { title: 'ACCOUNT' } },
  { path: 'profile/records', name: 'records', component: RecordPage, meta: { title: 'HAND_REVIEW' } },
  { path: 'profile/settings', name: 'settings', component: GameSettingPage, meta: { title: 'GAME_SETTINGS' } },
  { path: 'profile/referral', name: 'referral', component: ReferralPage, meta: { title: 'REFERRAL' } },
  { path: 'profile/clan', name: 'profile-clan', component: ClanPage, meta: { title: 'MY_CLAN' } },
  { path: 'profile/edit', name: 'profile-edit', component: EditPage, meta: { title: 'PROFILE' } },
  {
    path: 'profile/task',
    name: 'profile-task',
    component: TaskPage,
    meta: { title: 'TASK_CENTER', showAssets: true },
    children: [
      {
        path: 'daily',
        name: 'daily',
        component: DailyPage,
      },
      {
        path: 'experience',
        name: 'experience',
        component: ExperiencePage,
      },
      {
        path: 'diamond',
        name: 'diamond',
        component: DiamondPage,
      },
      {
        path: 'gold',
        name: 'gold',
        component: GoldPage,
      },
    ],
  },
  { path: 'profile/feedback', name: 'feedback', component: FeedbackPage, meta: { title: 'FEEDBACK' } },
];
