<template>
  <div class="py-4 space-y-3">
    <teleport to="#m-nav-add">
      <div class="i-local-menu size-7 cursor-pointer" @click="showSections = true" />
    </teleport>
    <div v-for="(v, i) in data" :key="i" class="rd-lg bg-gray-900 p-4" @click="cur = i">
      <h4 class="flex items-center" :class="{ 'c-green': cur === i }">
        {{ v.question }}<span class="i-local-arrow-d c-gray ml-a" />
      </h4>
      <template v-if="cur === i">
        <div class="my-3 h-1px bg-gray-700" />
        <div class="ws-pre-wrap">{{ v.answer }}</div>
      </template>
    </div>
    <ADrawer v-model="showSections" class="w-110" :position="1">
      <div class="space-y-6 pt-16" un-children="pl-4 b-l-solid b-.3rem cursor-pointer">
        <h5
          :class="section === 'holdem-tables' ? 'c-green' : 'b-transparent'"
          @click="$router.replace('/faq/holdem-tables')"
        >
          Holdem-Tables
        </h5>
        <h5 :class="section === 'ladder' ? 'c-green' : 'b-transparent'" @click="$router.replace('/faq/ladder')">
          {{ $t('home.NAV_3') }}
        </h5>
        <h5 :class="section === 'clan' ? 'c-green' : 'b-transparent'" @click="$router.replace('/faq/clan')">
          {{ $t('CLAN') }}
        </h5>
      </div>
    </ADrawer>
  </div>
</template>
<script lang="ts" setup>
import { cmsLabelMapping } from '@/utils/language';
import { blogFetch } from '@/utils/network';
import qs from 'qs';
import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const cur = ref(0);
const props = defineProps<{ section?: string }>();
const data = ref<{ question: string; answer: string }[]>([]);

const showSections = ref(false);
const { locale } = useI18n();

watchEffect(async () => {
  const query = qs.stringify({
    fields: ['question', 'answer'],
    sort: 'id:asc',
    locale: cmsLabelMapping[locale.value] || 'en',
    filters: { faq_label: { label: { $eq: props.section || 'holdem-tables' } } },
  });
  const result = await blogFetch.get(`faqs?${query}`).json<{ data: any[] }>();
  cur.value = 0;
  data.value = result.data.map((v) => v.attributes);
});
</script>
