<template>
  <div class="py-4 text-lg">
    <h3>{{ $t('mtt.TOURNAMENT_SETTINGS') }}</h3>
    <div class="c-gray-400 mb-4">{{ $t('mtt.CHANGE_SETTINGS_BEFORE_PRETABLE') }}</div>
    <DPMttForm v-if="form" :form="form" edit @submit="update" @cancel="cancelMtt" :mttID="props.id" />
  </div>
</template>
<script setup lang="ts">
import { useDialog } from '@/components/ui/APopPad.vue';
import router from '@/router';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps<{ id: string }>();

const form = ref();
authAPI.get<any>('v1/mtt/show_details', { mttID: props.id }).then(async (v) => {
  const blind = await authAPI.get<{ smallBlind: number; bigBlind: number; ante: number }[]>('v1/mtt/blinds_structure', {
    mttID: props.id,
  });
  form.value = { ...v, blindStructureJson: blind };
});

const update = (form: any) => {
  const postData = { ...form };
  authAPI.post('v1/mtt/modify_settings?mttID=' + props.id, postData).then(() => router.back());
};
const dialog = useDialog();
const cancelMtt = () => {
  dialog.confirm({
    title: t('game.CONFIRM'),
    content: t('ARE_YOU_SURE_TO_DELETE THIS TOURNAMENT'),
    action: () => authAPI.post('v1/mtt/cancel?mttID=' + props.id).then(() => router.back()),
  });
};
</script>
