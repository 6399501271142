import HomeLayout from './HomeLayout.vue';
import ClanPage from './ClanPage.vue';
import PrivatePage from './PrivatePage.vue';
import LobbyPage from './LobbyPage.vue';
import MttPage from './MttPage.vue';
import ProfilePage from './ProfilePage.vue';
import { refreshToken } from '@/utils/network';

export default {
  path: '',
  component: HomeLayout,
  redirect: refreshToken.value ? '/lobby' : '/home',
  children: [
    {
      path: 'cl',
      name: 'clan',
      component: ClanPage,
    },
    {
      path: 'private',
      name: 'private',
      component: PrivatePage,
    },
    {
      path: 'lobby',
      name: 'lobby',
      component: LobbyPage,
      meta: { showAssets: true },
    },
    {
      path: 'm/tab/:tab?/:subTab?',
      name: 'tournament',
      component: MttPage,
      alias: ['m'],
      props: true,
    },
    {
      path: 'profile',
      name: 'profile',
      component: ProfilePage,
      meta: { showAssets: true },
    },
  ],
};
