import Layout from './layout.vue';
import MainPage from './main.vue';
import RankPage from './rank.vue';
import RankDPage from './rank-d.vue';
import RankMPage from './rank-m.vue';
import HistoryPage from './history.vue';

export default {
  name: 'sng',
  path: '/s',
  component: Layout,
  meta: { title: 'home.NAV_3' },
  children: [
    {
      name: 'sng-main',
      path: '',
      component: MainPage,
    },
    {
      name: 'sng-rank',
      path: 'rank',
      component: RankPage,
      children: [
        {
          name: 'sng-rank-d',
          path: '',
          component: RankDPage,
        },
        {
          name: 'sng-rank-m',
          path: 'm',
          component: RankMPage,
        },
      ],
    },
    {
      name: 'sng-history',
      path: 'history',
      component: HistoryPage,
    },
  ],
};
