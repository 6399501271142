<script setup lang="ts">
import { blogFetch } from '@/utils/network';
import qs from 'qs';
import { ref } from 'vue';

const props = defineProps<{ id: number }>();
const sponsor = ref<any>({});

const getData = async () => {
  const query = qs.stringify(
    {
      fields: [
        'sponsor_name',
        'sponsor_level',
        'website',
        'x',
        'discord',
        'telegram',
        'debox',
        'description',
        'content',
      ],
      populate: {
        logo: {
          fields: ['url'],
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  );
  const result = blogFetch.get(`sponsors/${props.id}?${query}`);
  const data: any = await result.json();
  sponsor.value = {
    ...data.data.attributes,
    logo: data.data.attributes.logo.data.attributes.url,
  };
};
getData();

import { socialImgs } from '@/views/mtt/socialIcons';
import level1 from '@/assets/img/sponsor_v1.png';
import level2 from '@/assets/img/sponsor_v2.png';
import level3 from '@/assets/img/sponsor_v4.png';
import level4 from '@/assets/img/sponsor_v1.png';
const levelImages = ['', level1, level2, level3, level4];
</script>

<template>
  <div class="py-10 of-hidden">
    <div>
      <div class="rd-lg bg-gray-900">
        <div
          class="bg-gradient-to-b from-[#08BB7A] to-[#197374] rd-md flex items-center justify-between py-4 pr-10 pl-2"
        >
          <div class="flex items-center justify-between">
            <img :src="sponsor.logo" class="h-20 mr-5" alt="" />
            <div class="text-lg fw-500 flex items-center space-x-2">
              <span>{{ sponsor.sponsor_name }}</span>
              <img :src="levelImages[sponsor.sponsor_level]" class="w-16" alt="" />
            </div>
          </div>
          <div>
            <img src="../../assets/img/sponsor_icon.png" class="w-12" alt="" />
          </div>
        </div>
        <div class="rd-tr-lg flex items-stretch h-16">
          <div class="flex-1 px-10 flex items-center space-x-4">
            <a :href="sponsor.telegram" target="_blank" v-if="sponsor.telegram">
              <img :src="socialImgs.telegram" class="w-7" alt="telegram" />
            </a>
            <a :href="sponsor.x" target="_blank" v-if="sponsor.x">
              <img :src="socialImgs.x" class="w-7" alt="x" />
            </a>
            <a :href="sponsor.discord" target="_blank" v-if="sponsor.discord">
              <img :src="socialImgs.discord" class="w-7" alt="discord" />
            </a>
            <a :href="sponsor.debox" target="_blank" v-if="sponsor.debox">
              <img :src="socialImgs.debox" class="w-7" alt="debox" />
            </a>
          </div>
        </div>
        <div class="px-10 pt-5 pb-10" v-html="sponsor.content" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
