<template>
  <div class="w-screen item-center justify-center" :class="{ 'pr-30 pl-30': landscape }">
    <div v-if="diamondData" class="space-y-2.5 p-2">
      <span class="text-xl">{{ $t('TASK_LIST') }}</span>
      <DPGoldPlatformTaskItem :data="diamondData" @callback="claimDiamondCallback" />
    </div>
    <ADialog class="w-100" :unclosable="true" :transparent="true" v-model="showClaimNotify">
      <TaskAnim :diamond="claimData?.diamond" />
      <div class="flex justify-center">
        <AButton class="w-30" @click="showClaimNotify = false">{{ $t('game.CONFIRM') }}</AButton>
      </div>
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import { toast } from '@/components/ui/AToast.vue';
import { discordUser, tgUser, landscape, taskRedDot, showLoading, xUser, user } from '@/stores';
import type { PlatformTask, PlatformTaskGroup, TaskClaimPrize } from '@/types';
import { checkDiscordOfficialServer } from '@/utils/discord';
import { authAPI } from '@/utils/network';
import { checkConnectTaskFinish } from '@/utils/task';
import { checkJoinOfficialGroup, checkSubBot } from '@/utils/telegram';
import { onMounted, ref } from 'vue';

const diamondData = ref<PlatformTask[]>();
const showClaimNotify = ref(false);
const claimData = ref<TaskClaimPrize>();
showLoading.value = true;

//以防死循环
let reloadTime = 0;

const getDiamondTaskList = () => {
  authAPI
    .get<PlatformTaskGroup[]>('v1/task/list', { taskTime: 'diamond' })
    .then((v) => {
      let reload = false;
      v.forEach((group) => {
        group.list.forEach((task) => {
          if (task.taskKey === 'CONNECT_TG' && tgUser.id && !task.isFinish) {
            checkConnectTaskFinish('TG', tgUser.id + '');
            reload = true;
          } else if (task.taskKey === 'CONNECT_DISCORD' && discordUser.id && !task.isFinish) {
            checkConnectTaskFinish('DISCORD', discordUser.id);
            reload = true;
          } else if (task.taskKey === 'CONNECT_X' && xUser.id && !task.isFinish) {
            checkConnectTaskFinish('X', xUser.id);
            reload = true;
          } else if (task.taskKey === 'START_TG_BOT' && !task.isFinish) {
            if (tgUser.id) {
              checkSubBot().then((v) => {
                if (v) {
                  checkConnectTaskFinish('START_TG_BOT', tgUser.id + '');
                  reload = true;
                }
              });
            } else {
              task.taskUrl = 'CONNECT_TG';
            }
          } else if (task.taskKey === 'JOIN_TG_GROUP' && !task.isFinish) {
            if (tgUser.id) {
              checkJoinOfficialGroup().then((v) => {
                if (v) {
                  checkConnectTaskFinish('JOIN_TG_GROUP', tgUser.id + '');
                  reload = true;
                }
              });
            } else {
              task.taskUrl = 'CONNECT_TG';
            }
          } else if (task.taskKey === 'JOIN_DISCORD_SERVER' && !task.isFinish) {
            if (discordUser.id) {
              checkDiscordOfficialServer().then((v) => {
                if (v) {
                  checkConnectTaskFinish('JOIN_DISCORD_SERVER', tgUser.id + '');
                  reload = true;
                }
              });
            } else {
              task.taskUrl = 'CONNECT_DISCORD';
            }
          } else if (task.taskKey === 'EDIT_AVATAR' && user.value?.avatar && !task.isFinish) {
            checkConnectTaskFinish('EDIT_AVATAR', user.value.id);
            reload = true;
          }
        });
        diamondData.value = group.list;
      });
      if (reload && reloadTime < 3) {
        reloadTime++;
        getDiamondTaskList();
      }
      showLoading.value = false;
    })
    .catch((e: any) => {
      toast.error(e.msg);
      showLoading.value = false;
    });
};

const claimDiamondCallback = async (v: any) => {
  claimData.value = v;
  showClaimNotify.value = true;
  taskRedDot.value.diamondTask = taskRedDot.value.diamondTask - 1;
  getDiamondTaskList();
};

onMounted(() => {
  getDiamondTaskList();
});
</script>
