<template>
  <div class="pt-4">
    <div class="flex">
      <div v-if="assets" class="h-7 flex items-center m-a rd-full bg-gray-700 px-2">
        <img class="w-4 mr-1" src="@/assets/img/coin.png" alt="" />
        <span class="min-w-13">{{ assets.goldCoin }}</span>
        <img class="w-4 mx-1" src="@/assets/img/diamond.png" alt="" />
        <span class="min-w-13">{{ assets.diamond }}</span>
      </div>
    </div>
    <div class="flex my-4" un-children="text-center flex-1 p-2 b-b-solid cursor-pointer">
      <h5
        :class="$route.path === '/mall/coin' ? 'b-green bg-green/20' : 'b-transparent'"
        @click="$router.replace('coin')"
      >
        {{ $t('COIN') }}
      </h5>
      <h5
        :class="$route.path === '/mall/diamond' ? 'b-green bg-green/20' : 'b-transparent'"
        @click="$router.replace('diamond')"
      >
        {{ $t('DIAMOND') }}
      </h5>
      <h5
        :class="$route.path === '/mall/record' ? 'b-green bg-green/20' : 'b-transparent'"
        @click="$router.replace('record')"
      >
        {{ $t('BUY_RECORD') }}
      </h5>
    </div>
    <RouterView :key="index" />
  </div>
</template>
<script lang="tsx" setup>
import { assets } from '@/stores/user';
import { useTopic } from '@/utils/stomp';

import DPPopItem from '@/components/DPPopItem.vue';
import { useDialog } from '@/components/ui/APopPad.vue';
import { useI18n } from 'vue-i18n';
import { onUnmounted, ref } from 'vue';
import { showTaskEntry } from '@/stores';

const dialog = useDialog();
const { t } = useI18n();
const index = ref(0);
showTaskEntry.value = true;

useTopic('/user/paymentNotification', ({ num, imageUrl, productName }) => {
  index.value += 1;
  dialog.alert({
    content: (
      <div class="text-center mb-8 w-110">
        <h1>{t('YOURE_ALL_SET')}</h1>
        <DPPopItem class="-z-1 w-90 -my-4 m-auto">
          <img class="h-50 w-90 object-contain" src={imageUrl} alt="" />
        </DPPopItem>
        <h3>{t('BUY_SUCCESS_TIPS', [t(productName, [num])])}</h3>
      </div>
    ),
    transparent: true,
  });
});

onUnmounted(() => {
  showTaskEntry.value = false;
});
</script>
