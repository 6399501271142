import { createApp, watch } from 'vue';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import Tooltip from 'primevue/tooltip';

import router from './router';
import myPlugin from './utils/plugin';
import { Geetest } from 'vue3-geetest';

import '@/assets/theme.css';
import '@/assets/main.css';
import 'uno.css';

import i18n from '@/stores/lang';
import { authAPI, refreshToken, token } from './utils/network';
import { initUser, refCode, userPromise } from './stores/user';
import { useIntervalFn } from '@vueuse/core';
import { gifts } from './stores';
import { giftParams } from './utils/constant';

import * as Sentry from '@sentry/vue';

// if (import.meta.env.MODE === 'dev') import('vconsole').then(({ default: v }) => new v());

const app = createApp(App);
// app.config.throwUnhandledErrorInProduction = true

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://f1f03869c450a904c2fad0f1b5450777@o4508220540715008.ingest.us.sentry.io/4508220585672704',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_ENV,
  });
}

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

app.use(i18n);
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(myPlugin);
app.use(Geetest, { captchaId: 'cb7474eaab5ec45a81db37a8ee53b8d8' });

// import NavigationBar from '@/react_app/index';
// import { applyReactInVue } from 'veaury';

// app.component('ReactTest', applyReactInVue(NavigationBar));

// OPTIMIZE: some init process, need to be Refactored
watch(refreshToken, () => initUser(), { immediate: true });
useIntervalFn(() => authAPI.get('v1/user/monitorActive', undefined, undefined, true), 3e5, { immediateCallback: true });
authAPI.get('v1/gift/getGiftDashboard').then((v) => (gifts.value = v.filter((g: any) => giftParams[g.giftId])));

app.mount('#app');
