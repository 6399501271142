import { Howl, Howler } from 'howler';
import { onUnmounted, watchEffect } from 'vue';
import { volume, userGameSetting } from '@/utils/value';

Howler.autoSuspend = false;
let unlocked = false;

new Howl({
  src: [''],
  onunlock: () => (unlocked = true),
});

export const useSound = (file: string) => {
  const sound = new Howl({ src: [file], html5: true });
  onUnmounted(() => sound.unload());
  return sound;
};

export const useGameSound = () => {
  const soundMap = Object.fromEntries(
    Object.entries(import.meta.glob<string>('@/assets/sound/*', { eager: true, query: '?url', import: 'default' })).map(
      ([k, v]) => [k.slice(18, -4), useSound(v)],
    ),
  );
  const voiceMap = Object.fromEntries(
    Object.entries(import.meta.glob<string>('@/assets/voice/*', { eager: true, query: '?url', import: 'default' })).map(
      ([k, v]) => [k.slice(18, -4), useSound(v)],
    ),
  );

  watchEffect(() => Object.values(soundMap).forEach((v) => v.volume(volume.value)));

  return {
    play: (name: string) => {
      if (unlocked && volume.value > 0) soundMap[name]?.play();
    },
    playVoice: (name: string) => {
      if (unlocked && userGameSetting.value.voiceEffect) voiceMap[name]?.play();
    },
    playAwait: (name: string) => {
      return new Promise<void>((resolve) => {
        const sound = voiceMap[name];
        if (sound) {
          sound.on('end', () => {
            sound.off('end'); // Remove the listener to avoid memory leaks
            resolve();
          });
          sound.play();
        } else {
          resolve();
        }
      });
    },
  };
};
