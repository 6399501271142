<template>
  <DPHomeBanner class="mb-4" tag="Tournament" />
  <div
    class="grid grid-cols-2 gap-3 mb-8"
    un-children="p-4 h-22 rd-lg bg-gray-800 pr-20 cursor-pointer bg-no-repeat bg-contain bg-right"
  >
    <h4 class="bgi-mtt-create-bg" @click="openMttEdit">{{ $t('CREATE_TOURNAMENT') }}</h4>
    <h4 class="bgi-mtt-search-bg" @click="showSearch = true">{{ $t('SEARCH_TOURNAMENT') }}</h4>
  </div>
  <div class="flex justify-around mb-4">
    <h5
      v-for="(t, i) in tabs"
      :key="i"
      class="relative flex justify-center items-center flex-1 py-2 px-1 b-2 b-b-solid cursor-pointer"
      :class="tabIdx === i ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
      @click="$router.replace('/m/tab/' + t.tabName)"
    >
      {{ $t(t.tabName) }}
      <div v-if="t.gameCount" class="absolute top-0 right-0 text-xs bg-red-400 rd pr-1">
        <img src="@/assets/img/trophy.png" alt="" class="drop-shadow w-4 h-4 float-left -ml-2" />{{ t.gameCount }}
      </div>
    </h5>
  </div>
  <div v-if="tabs[tabIdx]?.subTabList.length > 1" class="flex mb-4 space-x-2">
    <p
      v-for="(t, i) in tabs[tabIdx]?.subTabList"
      :key="i"
      class="min-w-20 p-2 text-center rd-lg b-solid b-gray-700 cursor-pointer"
      :class="subIdx === i ? 'b-green' : 'bg-gray-900'"
      @click="$router.replace(`/m/tab/${tabs[tabIdx].tabName}/${t.name}`)"
    >
      {{ $t(t.name) }}
    </p>
  </div>
  <div class="space-y-4 pb-4 flex-1">
    <div v-if="!data" class="m-a my-20 i-local-loading text-4xl c-green" />
    <div v-else-if="!data.length">
      <div
        v-if="tabs[tabIdx].subTabList[subIdx].name === 'EXCLUSIVE'"
        class="flex flex-col justify-center items-center"
      >
        <img src="@/assets/img/ticket-back.png" alt="" class="w-72 h-64" />
        <div class="-mt-15 fw-600 text-lg">{{ $t('mtt.REDEEM_TICKETS') }}</div>
        <span class="op-60">{{ $t('mtt.REDEEM_TICKETS_DESC') }}</span>
        <Button class="mt-10 px-15" outlined @click="showTicketRedeem = true">
          {{ $t('mtt.REDEEM') }}
        </Button>
      </div>
      <div
        v-else-if="tabs[tabIdx].tabName === 'MY_TOURNAMENT' && !user"
        class="op-60 text-center pt-20 flex flex-col justify-center items-center"
      >
        <img class="h-40 mb-4" src="@/assets/img/user-line.png" alt="" />
        {{ $t('NO_LOGIN_TIPS') }}
        <AButton class="min-w-30 mt-4" outlined @click="showLogin = true">{{ $t('LOGIN') }}</AButton>
      </div>
      <div v-else class="op-60 text-center pt-20 flex flex-col justify-center items-center">
        <div class="i-local-history h-32 w-32 mb-4" />
        {{ $t('mtt.NO_MTT_REGISTERED') }}
      </div>
    </div>
    <div
      v-for="(v, i) in data"
      :key="i"
      class="relative rd-lg bg-gray-900"
      :class="{ 'b-solid b-green bg-gradient-to-r from-[#08BB7A40] via-[#08BB7A00] to-[#0870BB50]': v.pin }"
      @click="$router.push(`/m/${v.mttID}`)"
    >
      <img v-if="v.pin" class="absolute -top-2 -left-2 z-2 w-8" src="@/assets/img/icon-hot.png" />
      <div class="flex w-full items-center justify-between p-4 of-hidden">
        <h6
          v-if="v.isRegistered"
          class="absolute flex items-center c-green top-0 left-0 pl-1 pr-3 bg-dark-200 rd-br-4 rd-tl-2 z-1"
        >
          <i class="i-local-check mr-1" /> {{ $t('mtt.REGISTERED') }}
        </h6>
        <DPAvatar class="text-5xl flex-shrink-0" :avatar="v.tableLogoUrl" />
        <div class="info flex-1 ml-4 relative of-hidden">
          <h5 class="lh-5 fw-700 mr-2 truncate pr-8">{{ v.mttName }}</h5>
          <h6 class="absolute right-0 top-0 bg-dark-900 px-1 italic c-yellow fw-extrabold rd-1">
            {{ v.gameType }}
          </h6>
          <div class="flex items-center justify-between my-3">
            <div class="flex items-center">
              <div
                class="px-1 fw-500 text-xs rd"
                :class="{
                  'bg-yellow': v.status === 1,
                  'bg-green': v.status === 2,
                  'bg-red': v.status === 3,
                  'bg-blue': v.status === 4,
                  'bg-gray-500': v.status > 4,
                  'c-gray-300': v.status === 7,
                }"
              >
                {{ $t(MttStatus[v.status]) }}
              </div>
              <p v-if="v.status === 1" class="c-yellow ml-1.5">
                {{ $t('mtt.REG_OPEN') }} {{ dt(v.registerTime * 1e3).strDateTimeShort }}
              </p>
              <p v-if="v.status === 2" class="c-green ml-1.5">
                {{ $t('mtt.START_IN') }} {{ dur((v.startTime - v.currentTime) * 1e3) }}
              </p>
              <p v-if="v.status === 3" class="c-red ml-1.5">
                {{ $t('mtt.CLOSE_IN') }} {{ dur((v.lateRegTime + v.startTime - v.currentTime) * 1e3) }}
              </p>
            </div>
            <div class="flex items-center space-x-1">
              <div class="b-solid b-1 b-white/20 p-0.3 rd-1.5" v-if="v.autoRegApproval === 2">
                <div class="i-local-ticket c-green w-4 h-4" />
              </div>
              <DPTaskList :taskList="v.taskTypeList" />
            </div>
          </div>
          <div class="text-sm flex items-center op-70">
            <div class="i-local-user mr-1" />
            {{ v.totalEntries }} / {{ v.maxPlayers }}
            <div class="i-local-clock mr-1 ml-5" />
            <span class="flex-1">{{ dt(v.startTime * 1e3).strDatetime }}</span>
            <img alt="" v-if="v.status < 5" class="w-9 h-3 justify-end" src="@/assets/img/arrow.png" />
          </div>
        </div>
      </div>
      <p v-if="v.prizeHighLight || v.reEntryConfig?.length" class="bg-gray-700 px-3 py-2 rd-b-lg flex items-center">
        {{ v.prizeHighLight }}
        <template v-if="v.reEntryConfig?.length">
          {{ $t('REBUY') }}:
          <template v-for="(t, i) in v.reEntryConfig" :key="i">
            <img v-if="t.regType === 2" class="h-4 mx-1" src="@/assets/img/ticket.png" alt="" />
            <img v-else-if="t.regType === 3" class="h-4 mx-1" src="@/assets/img/diamond.png" alt="" />
            <img v-else-if="t.regType === 4" class="h-4 mx-1" src="@/assets/img/coin.png" alt="" />
            <span v-if="i < v.reEntryConfig.length - 1">/</span>
          </template>
        </template>
      </p>
    </div>
  </div>
  <div
    v-if="props.subTab === 'EXCLUSIVE' && data?.length"
    class="sticky bottom-0 box-content px-4 flex justify-center bg-gray-900 cursor-pointer"
    @click="showTicketRedeem = true"
  >
    <img src="@/assets/img/ticket-back.png" alt="" class="w-48 h-20 object-cover -ml-12" />
    <div class="-ml-10 pt-3">
      <h3 class="c-green flex items-center">
        {{ $t('mtt.REDEEM_TICKETS') }}
        <div class="i-local-go-union ml-2" />
      </h3>
      <div class="c-gray-300 my-1">{{ $t('mtt.REDEEM_TICKETS_DESC') }}</div>
    </div>
  </div>
  <ADialog class="w-100" v-model="showCreatNotice" :title="$t('NOTICE')">
    <div class="space-y-4">
      <p class="text-center op-70">
        {{ $t('TOURNAMENT_CREATION_IS_PRIVATE') }}
      </p>
      <DPSocailBtns />
    </div>
  </ADialog>
  <ADialog class="w-100" v-model="showCreator" :title="$t('mtt.NEW_TOURNAMENT')">
    <div class="text-center c-gray mb-4">{{ $t('CREATE_MTT_TIPS') }}</div>
    <AInput class="text-lg" :limit="100" v-model="createName">{{ $t('mtt.TOURNAMENT_NAME') }}</AInput>
    <template #footer>
      <AButton class="text-lg" :disabled="!createName" :action="createMtt">
        {{ $t('CREATE_TOURNAMENT') }}
      </AButton>
    </template>
  </ADialog>
  <MTTTicketRedeem v-model="showTicketRedeem" @refresh="updataData" />
  <ADialog v-model="showSearch" class="w-100" :title="$t('JOIN_THE_TOURNAMENT')">
    <div class="text-center c-gray">{{ $t('COPY_INVITATION_LINK_TO_JOIN') }}</div>
    <AInput class="text-xl mt-4" v-model="mttUrl" placeholder="Paste invitation link here" />
    <template #footer>
      <AButton class="text-lg" :action="openMTT">{{ $t('JOIN') }}</AButton>
    </template>
  </ADialog>
</template>
<script setup lang="tsx">
import { toast } from '@/components/ui/AToast.vue';
import { user, platform, showLogin } from '@/stores';
import { MttStatus } from '@/types';
import { dt, dur } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { useIntervalFn } from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import { computed, ref, triggerRef, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();

const urlTab = useRouteQuery<string | null>('tab');

if (urlTab.value) {
  const [t, st] = urlTab.value.split('-');
  router.replace(`/m/tab/${t}${st ? '/' + st : ''}`);
}

type Mtt = {
  mttID: string;
  status: number;
  mttName: string;
  gameType: string;
  tableLogoUrl: string;
  registerTime: number;
  startTime: number;
  totalEntries: number;
  lateRegTime: number;
  isRegistered: boolean;
  currentTime: number;
  maxPlayers: number;
  prizeHighLight: string;
  autoRegApproval: number;
  taskCount: number;
  taskTypeList: string[];
  pin: number;
  reEntryConfig: { regType: 2 | 3 | 4 }[];
};

const showSearch = ref(false);
const mttUrl = ref('');
const openMTT = () => {
  try {
    const path = new URL(mttUrl.value).pathname;
    if (!/^\/m\/[0-9a-zA-z]{7}$/.test(path)) throw 'error';
    router.push(new URL(mttUrl.value).pathname);
  } catch {
    toast.error('Invalid invitation link');
  }
};

const props = defineProps<{ tab?: string; subTab?: string }>();

const data = ref<Mtt[]>();
const updataData = () => {
  triggerRef(tabs);
};
const tabs = ref<{ subTabList: { tag: string; name: string }[]; tabName: string; gameCount: number }[]>([]);

authAPI.get('v1/mtt/tab').then((v) => {
  tabs.value = [
    ...v,
    {
      subTabList: [
        { tag: '', name: 'MY_PARTICIPATION' },
        { tag: '', name: 'MY_MTT' },
        { tag: '', name: 'EXCLUSIVE' },
      ],
      tabName: 'MY_TOURNAMENT',
      gameCount: 0,
    },
  ];
});

const tabIdx = computed(() => {
  const i = tabs.value.findIndex((v) => v.tabName === props.tab);
  return i < 0 ? 0 : i;
});

const subIdx = computed(() => {
  const i = tabs.value[tabIdx.value]?.subTabList.findIndex((v) => v.name === props.subTab);
  return i < 0 ? 0 : i;
});

watchEffect(async () => {
  if (!tabs.value.length) return;
  data.value = undefined;
  try {
    if (!props.tab || props.tab === 'COMMUNITY')
      data.value = await authAPI.get('v1/mtt/community_list', {
        excluedType: platform.value,
        tag: tabs.value[tabIdx.value].subTabList[subIdx.value].tag,
      });
    else if (props.tab === 'OFFICIAL')
      data.value = await authAPI.get('v1/mtt/official_list', {
        excluedType: platform.value,
        tag: tabs.value[tabIdx.value].subTabList[subIdx.value].tag,
      });
    else if (props.tab === 'MY_TOURNAMENT') {
      if (!props.subTab || props.subTab === 'MY_PARTICIPATION')
        data.value = await authAPI.get('v1/mtt/player/mtt_participation_list', undefined, undefined, true);
      else if (props.subTab === 'MY_MTT')
        data.value = await authAPI.get('v1/mtt/player/mtt_organization_list', undefined, undefined, true);
      else if (props.subTab === 'EXCLUSIVE')
        data.value = await authAPI.get('v1/mtt/exclusive_list', undefined, undefined, true);
    }
  } catch {
    data.value = [];
  }
});

let ts = Date.now();

useIntervalFn(() => {
  const now = Date.now();
  const update = (v: Mtt, delta: number) => {
    v.currentTime += delta;
    if (v.status > 3) return;
    if (v.currentTime < v.registerTime) v.status = 1;
    else if (v.currentTime < v.startTime) v.status = 2;
    else if (v.currentTime < v.lateRegTime + v.startTime) v.status = 3;
    else v.status = 4;
  };
  data.value?.forEach((v) => update(v, (now - ts) / 1e3));
  ts = now;
}, 500);

const showCreatNotice = ref(false);
const showCreator = ref(false);
const createName = ref('');
const openMttEdit = () => {
  if (!user.value) showLogin.value = true;
  else if (user.value.permissions.includes('create_mtt')) showCreator.value = true;
  else showCreatNotice.value = true;
};
const createMtt = async () => {
  const res = await authAPI.get<boolean>('v1/mtt/check_name', { mttName: createName.value });
  if (res) return toast.error(t('THE_MTT_NAME_IS_DUPLICATED'));
  router.push('/m/new?name=' + createName.value);
};

const showTicketRedeem = ref(false);
</script>
