<template>
  <label :="$attrs" @click="check">
    <input type="checkbox" v-model="inputValue" class="hidden" />
    <div class="size-1.2em rd b-current b-solid bg-dark-900 b-1.5">
      <div :class="{ 'op-0': !inputValue }" class="i-local-check"></div>
    </div>
    <slot></slot>
  </label>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{ value?: any; radio?: boolean; limit?: number; trust?: boolean }>();
const model = defineModel<any>();
const inputValue = computed({
  get: () => {
    if (Array.isArray(model.value)) return model.value.includes(props.value);
    return model.value === (props.value || true);
  },
  set: (v) => {
    if (props.radio) {
      if (v) model.value = props.value || v;
    } else if (Array.isArray(model.value)) {
      if (v) {
        model.value.push(props.value);
        while (props.limit && model.value.length > props.limit) model.value.pop();
      } else {
        const idx = model.value.indexOf(props.value);
        model.value.splice(idx, 1);
      }
    } else {
      model.value = props.value ? (v ? props.value : undefined) : v;
    }
  },
});

const check = (e: Event) => {
  if (props.trust && !e.isTrusted) e.preventDefault();
};
</script>
