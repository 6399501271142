import { loginSocialV2, checkAccountStatus, socialLink } from '@/utils/user';
import {
  showBindAccount,
  socialToken,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
  showLoading,
  tonUser,
} from '@/stores';
import { botAPI } from '@/utils/network';
import { isIOS, isAndroid, isMobile } from '@/utils/device';

import TonConnect, {
  TonConnectError,
  isWalletInfoCurrentlyEmbedded,
  isWalletInfoCurrentlyInjected,
  toUserFriendlyAddress,
  // isWalletInfoRemote,
} from '@tonconnect/sdk';

// export interface TonCallBack {
//   onError?: (msg: string) => void;
// }

let init = false;
let bt = '';
// const callback: TonCallBack = undefined;

const onStatusCallBack = async (walletInfo: any) => {
  if (!walletInfo) {
    return;
  }
  const proof = walletInfo.connectItems?.tonProof;
  const account = walletInfo.account;

  if (!proof || !account) return;
  const address = toUserFriendlyAddress(account.address);
  const walletType = 'WALLET_TON#TON';

  const { token } = await botAPI.post<{ token: string }>('wallet/ton/token', {
    proof: proof,
    walletInfo: account,
    address: address,
  });
  socialToken.value = token;

  if (address) {
    if (bt == 'link') {
      showLoading.value = false;
      try {
        await socialLink(address, address, '', walletType);
        tonUser.address = address;
        // location.reload();
      } catch (e: any) {
        // console.log(e);
        alert(e);
        // callback.onError(e);
      }
    } else {
      if (await checkAccountStatus(socialToken.value)) {
        await loginSocialV2(socialToken.value);
      } else {
        if (bt == 'bind') {
          showBindAccount.value = 3;
        } else {
          linkType.value = walletType;
          linkUserId.value = address;
          linkUserName.value = address;
          linkAvatar.value = '';
          showBindAccount.value = 1;
        }
      }
      showLoading.value = false;
    }
  }
};

const errorHanlder = (err: TonConnectError) => {
  if (!err.message.indexOf('Pop-up closed')) {
    console.info(err);
  }
  showLoading.value = false;
};

export const tonLogin = async (bindType = 'login') => {
  showLoading.value = true;
  bt = bindType;
  const date = new Date();
  const msg = 'Welcome to LePoker.io. Please sign this message to login.\n\nTimestamp:' + date.getTime();
  const connector = new TonConnect();

  if (!init) {
    connector.onStatusChange(onStatusCallBack, errorHanlder);
    init = true;
  }
  if (isIOS || isAndroid || isMobile) {
    const walletConnectionSource = {
      universalLink: 'https://app.tonkeeper.com/ton-connect',
      bridgeUrl: 'https://bridge.tonapi.io/bridge',
    };

    const universalLink = connector.connect(walletConnectionSource, { tonProof: 'LePoker' });
    // location.href = universalLink;
    window.open(universalLink, '_target');
  } else {
    const walletsList = await connector.getWallets();
    const walletInfo = walletsList.find((v) => v.name === 'Tonkeeper');
    if (!walletInfo) throw 'no wallet';
    console.info(walletInfo);
    if (isWalletInfoCurrentlyEmbedded(walletInfo) || isWalletInfoCurrentlyInjected(walletInfo)) {
      return connector.connect({ jsBridgeKey: walletInfo.jsBridgeKey }, { tonProof: msg });
    } else {
      window.open('https://chromewebstore.google.com/detail/omaabbefbmiijedngplfjmnooppbclkk', '_target');
      showLoading.value = false;
      throw 'no injected';
    }
  }
};
