<template>
  <header class="bg-cover bg-right p-4 rd-lg bg-no-repeat" :style="{ backgroundImage: `url(${banner})` }">
    <h5>{{ seasons?.[cur]?.seasonName || '-' }}</h5>
    <h2 class="flex items-center my-1">
      {{ seasons?.[cur]?.dropName || '-' }}
      <APopButton v-model="showPad" align="end">
        <AButton class="text-xs ml-2 !p-1" type="secondary" icon="i-local-arrow-d" />
        <template #pad>
          <div class="bg-gray-700 rd p-4 w-40 max-h-50 of-auto space-y-2" un-children="cursor-pointer">
            <p v-for="(s, i) in seasons" :key="i" :class="{ 'c-green': cur === i }" @click="setSeason(i)">
              {{ s.dropName }}
            </p>
          </div>
        </template>
      </APopButton>
    </h2>
    <h5 v-if="cutDown" class="px-1 rd bg-black/30 w-max">{{ dur(cutDown * 1e3) }}</h5>
  </header>

  <div class="space-y-2 flex-1 of-auto mt-4">
    <div
      v-for="(item, i) of rank"
      :key="i"
      class="bg-contain bg-no-repeat rd-lg bg-gray-900 flex items-center px-4 py-3"
      :class="{
        'bgi-ranking-1-bg': i === 0,
        'bgi-ranking-2-bg': i === 1,
        'bgi-ranking-3-bg': i === 2,
        'bgi-ranking-general-bg': i > 2,
      }"
    >
      <h2 class="w-16 pl-4 italic c-gray-200">{{ item.rank }}</h2>
      <div class="flex flex-row flex-1 mx-3 items-center of-hidden">
        <DPAvatar class="text-1.7rem flex items-center of-hidden" :name="item.userName" :avatar="item.avatarUrl">
          <template #default="{ user }">
            <h3 class="ml-2 truncate">{{ user.name }}</h3>
          </template>
        </DPAvatar>
      </div>
      <div class="text-right shrink-0">
        <div>
          {{ $t('NET_POWER') }}: <b class="c-green">{{ item.netPoints }}</b>
        </div>
        <div class="c-gray-400">
          {{ $t('X_TOURNAMENTS', { v: item.seq }) }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="mine" class="flex items-center px-4 py-3 b-t-solid b-green bg-gradient-to-b from-green-900">
    <h2 class="w-16 pl-4 italic c-gray-200">{{ mine.rank || '-' }}</h2>
    <div class="flex flex-row flex-1 mx-3 items-center of-hidden">
      <DPAvatar class="text-1.7rem flex items-center of-hidden" :name="mine.userName" :avatar="mine.avatarUrl">
        <template #default="{ user }">
          <h3 class="ml-2 truncate">{{ user.name }}</h3>
        </template>
      </DPAvatar>
    </div>
    <div class="text-right shrink-0">
      <div>
        {{ $t('NET_POWER') }}: <b class="c-green">{{ mine.netPoints }}</b>
      </div>
      <div class="c-gray-400">
        {{ $t('X_TOURNAMENTS', { v: mine.seq }) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { dur } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { useTimer } from '@/utils/timer';
import { ref, watch } from 'vue';

const rank = ref<{ userName: string; avatarUrl: string; rank: number; seq: number; netPoints: number }[]>([]);
const seasons =
  ref<{ seasonName: string; seasonFlag: number; startDate: string; endDate: string; dropName: string }[]>();
const mine = ref();
const cur = ref(0);
const showPad = ref(false);

authAPI.get('v1/sng/getLeaderBoardList', { type: 1 }).then((v) => (seasons.value = v));
const setSeason = (i: number) => {
  cur.value = i;
  showPad.value = false;
};
const total = ref('-');
const banner = ref();
const cutDown = ref(0);
useTimer({ t: cutDown });

const loadRank = () => {
  if (!seasons.value && cur.value) return;
  authAPI
    .get('v1/sng/get_power_leaderBoard', {
      type: 1,
      seasonFlag: seasons.value?.[cur.value].seasonFlag,
      startDate: seasons.value?.[cur.value].startDate,
    })
    .then((r) => {
      rank.value = r.seasonPowerLeaderBoards;
      mine.value = r.userLeaderBoard;
      total.value = r.total;
      banner.value = r.bannerUrl;
      cutDown.value = r.countDownTime;
    });
};

watch(cur, () => loadRank(), { immediate: true });
</script>
