import { loginSocialV2, checkAccountStatus, socialLink } from '@/utils/user';
import {
  showBindAccount,
  socialToken,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
  user,
  ethUser,
  bnbUser,
  zksyncUser,
  lineaUser,
} from '@/stores';
import { botAPI } from '@/utils/network';
import { isIOS, isAndroid, isMobile } from '@/utils/device';
import Web3 from 'web3';
import { MetaMaskSDK } from '@metamask/sdk';
import MetaMaskOnboarding from '@metamask/onboarding';
import { checkNetwork } from './claimOnChainReward';
import { networkConfigs, setUserAddress } from '@/utils/wallet';

// const ethereum = (window as any).ethereum;

const metamask = new MetaMaskSDK({
  checkInstallationOnAllCalls: true,
  dappMetadata: {
    name: 'LePoker',
    url: window.location.href,
  },
  // infuraAPIKey: '9321e08afdc04e2c81cabc499dc5d569',
  // enableAnalytics: true,
  // logging: {
  //   developerMode: true,
  //   sdk: true,
  // },
  i18nOptions: {
    enabled: true,
  },
  // useDeeplink: true,
  // openDeeplink: (link: string) => {
  //   window.location.href = link;
  // },
  extensionOnly: true,
});

export const getMetaMaskEVMToken = async (chain: string) => {
  await metamask.connect();
  const provider = metamask.getProvider();
  const date = new Date();
  const msg = 'Welcome to LePoker.io. Please sign this message to login.\n\nTimestamp:' + date.getTime();
  let userId = '';
  if (user.value) userId = user.value.id;
  if (isIOS || isAndroid || isMobile) {
    let address = '';
    let sign = '';
    try {
      if (provider?.isConnected()) {
        address = provider?.getSelectedAddress()!;
      } else {
        const account = await provider!.request<string[]>({ method: 'eth_requestAccounts', params: [] });
        address = account![0]!;
      }
    } catch (err) {
      console.info(err);
    }
    try {
      const { token } = await botAPI.post<{ token: string }>('wallet/metamask/checkAddress', {
        address: address,
        lepokerUserId: userId,
        type: chain,
      });
      socialToken.value = token;
      return address || '';
    } catch (err) {
      console.info(err);
    }
    const web3 = new Web3(provider);
    sign = await web3.eth.personal.sign(msg, address, '123456');
    const { token } = await botAPI.post<{ token: string }>('wallet/metamask/token', {
      address: address,
      signature: sign,
      lepokerUserId: userId,
      message: msg,
      type: chain,
    });
    socialToken.value = token;

    return address || '';
  } else {
    try {
      provider?.isConnected();
    } catch (err) {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
      return '';
    }
    try {
      const account = await provider!.request<[]>({ method: 'eth_requestAccounts', params: [] });
      const web3 = new Web3(provider);
      const signature = await web3.eth.personal.sign(msg, account!.at(0)!, '123456');
      const { token } = await botAPI.post<{ token: string }>('wallet/metamask/token', {
        address: account!.at(0)!,
        signature,
        message: msg,
        type: chain,
      });
      socialToken.value = token;
      return account!.at(0)!;
    } catch (err) {
      console.warn(`failed to connect..`, err);
      throw err;
    }
  }
};

export const makeMetaMaskEVMTransaction = async (chainId: string) => {
  await metamask.connect();
  const provider = metamask.getProvider();

  if (isIOS || isAndroid || isMobile) {
    let address = '';
    try {
      if (provider?.isConnected()) {
        address = provider?.getSelectedAddress()!;
      } else {
        const account = await provider!.request<string[]>({ method: 'eth_requestAccounts', params: [] });
        address = account![0]!;
      }
    } catch (err) {
      console.error(err);
    }

    const pluginChainId = await provider!.request<string>({ method: 'eth_chainId' });
    if (pluginChainId !== chainId) {
      try {
        await provider!.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainId }],
        });
      } catch (switchError: any) {
        if (switchError.code === 4902) {
          await provider!.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfigs[chainId]],
          });
        } else {
          throw switchError;
        }
      }
    }
    const result = await provider!.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: address,
          to: address,
          value: '0x0',
          chainId: chainId,
        },
      ],
    });
    console.log(result);
  } else {
    try {
      provider?.isConnected();
    } catch (err) {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
      return '';
    }
    try {
      const account = await provider!.request<[]>({ method: 'eth_requestAccounts', params: [] });

      const pluginChainId = await provider!.request<string>({ method: 'eth_chainId' });
      if (pluginChainId !== chainId) {
        try {
          await provider!.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
          });
        } catch (switchError: any) {
          if (switchError.code === 4902 || switchError.code === -32603) {
            await provider!.request({
              method: 'wallet_addEthereumChain',
              params: [networkConfigs[chainId]],
            });
          } else {
            throw switchError;
          }
        }
      }

      const result = await provider!.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: account!.at(0)!,
            to: account!.at(0)!,
            value: '0x0',
            chainId: chainId,
          },
        ],
      });
      console.log(result);
    } catch (err) {
      console.warn(`failed to connect..`, err);
      throw err;
    }
  }
};

export const metamaskLogin = async (chain: string, bindType = 'login') => {
  let address = '';
  let walletType = '';
  if (chain == 'eth') {
    address = await getMetaMaskEVMToken('eth');
    walletType = 'WALLET_EVM#ETH';
  } else if (chain == 'bsc') {
    address = await getMetaMaskEVMToken('bsc');
    walletType = 'WALLET_EVM#BSC';
  } else if (chain == 'zksync') {
    address = await getMetaMaskEVMToken('zksync');
    walletType = 'WALLET_EVM#ZKS';
  } else if (chain == 'linea') {
    address = await getMetaMaskEVMToken('linea');
    walletType = 'WALLET_EVM#LINEA';
  }
  if (address) {
    if (bindType == 'link') {
      await socialLink(address, address, '', walletType);
      setUserAddress(chain, address);
      return true;
    } else {
      if (await checkAccountStatus(socialToken.value)) {
        await loginSocialV2(socialToken.value);
        return true;
      } else {
        if (bindType == 'bind') {
          showBindAccount.value = 3;
          return false;
        } else {
          linkType.value = walletType;
          linkUserId.value = address;
          linkUserName.value = address;
          linkAvatar.value = '';
          showBindAccount.value = 1;
          return true;
        }
      }
    }
  } else {
    return false;
  }
};
