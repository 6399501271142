<template>
  <Teleport to="body">
    <TransitionGroup name="toast" tag="div" class="z-10000 op-90 fixed top-6 right-6">
      <div
        class="p-2 w-100 b-l-solid mb-2 b-.5rem rd cursor-pointer"
        :class="{
          'c-green bg-green-100': item.type === 'success',
          'c-blue bg-blue-100': item.type === 'info',
          'c-yellow bg-yellow-100': item.type === 'warn',
          'c-red bg-red-100': item.type === 'error',
        }"
        v-for="item in toasts"
        :key="item.key"
        @click="popToast(item.key)"
      >
        <!-- <span class="fw-bold mr-2">Error:</span> -->
        <h5>{{ item.msg }}</h5>
      </div>
    </TransitionGroup>
  </Teleport>
</template>
<script lang="ts">
import { BzError } from '@/utils/network';
import { ref } from 'vue';

import i18n from '@/stores/lang';

type Toast = {
  type: 'success' | 'info' | 'warn' | 'error';
  msg?: string;
  life?: number;
  key: number;
};

const toasts = ref<Toast[]>([]);

function popToast(v: number) {
  toasts.value = toasts.value.filter((i) => i.key !== v);
}

let key = 1;
const addToast = (cfg: Partial<Toast>) => {
  const v: Toast = { type: 'info', life: 3000, key: key++, ...cfg };
  toasts.value.push(v);
  if (v.life) setTimeout(() => popToast(v.key), v.life);
};

export const toast = {
  add: addToast,
  warn: (msg: string) => addToast({ type: 'warn', msg }),
  error: (msg: string) => addToast({ type: 'error', msg }),
  info: (msg: string) => addToast({ type: 'info', msg }),
  success: (msg: string) => addToast({ type: 'success', msg }),
};

window.addEventListener('unhandledrejection', (e) => {
  if (e.reason instanceof BzError) {
    toast.error(i18n.global.t(`ERROR_${e.reason.code}`));
    e.preventDefault();
  }
});

export default {
  data() {
    return { toasts, popToast };
  },
};
</script>
