<template>
  <slot v-if="visible" />
  <slot v-else name="fallback" />
</template>
<script lang="ts" setup>
import { user } from '@/stores';
import { computed } from 'vue';

const props = withDefaults(defineProps<{ value: string; or?: boolean; and?: boolean }>(), { and: true });
const visible = computed(() => (user.value?.permissions.includes(props.value) || props.or) && props.and);
</script>
