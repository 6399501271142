<template>
  <div>
    <div class="relative" :class="{ 'h-full w-max': vertical }" ref="frame">
      <slot>
        <div class="bg-gray-700 rd-full" :class="vertical ? 'w-2 h-full' : 'h-2'" />
      </slot>
      <div
        class="absolute"
        :class="[
          vertical
            ? 'w-full bottom-0 left-0 bg-bottom bg-[size:100%_auto]'
            : 'h-full left-0 top-0 bg-left bg-[size:auto_100%]',
          bar ? bar : 'rd-full bg-green',
        ]"
        :style="{
          [vertical ? 'height' : 'width']: `${percent * 100}%`,
        }"
      />
      <div
        v-if="dragging || max > min"
        class="absolute w-0 h-0 cursor-pointer"
        un-children="-translate-x-50% -translate-y-50%"
        :class="vertical ? 'left-50%' : 'top-50%'"
        :style="{ [vertical ? 'bottom' : 'left']: `${percent * 100}%` }"
        @touchstart.passive="onDragStart()"
        @touchmove.passive="onDrag($event)"
        @touchend="onDragEnd($event)"
        @mousedown="onMouseDown()"
      >
        <slot name="cursor">
          <div class="rd-full size-5 bg-white"></div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{ min?: number; max?: number; vertical?: boolean; step?: number; bar?: string }>(),
  {
    min: 0,
    max: 1,
  },
);
const value = defineModel({ default: 0 });
const dragging = ref(false);
const percent = ref(0);

const frame = ref<HTMLElement>();
let pageMax = 1;
let pageStart = 0;

const setValue = (e: TouchEvent | MouseEvent) => {
  const p = (e instanceof MouseEvent ? e : e.touches[0])?.[props.vertical ? 'pageY' : 'pageX'];
  if (!p) return;
  percent.value = Math.min(Math.max((props.vertical ? pageStart - p : p - pageStart) / pageMax, 0), 1);
  if (props.max < props.min) return (value.value = props.min);
  let res = percent.value * (props.max - props.min) + props.min;
  if (props.step) res = Math.min(Math.ceil((res - props.min) / props.step) * props.step + props.min, props.max);
  value.value = +res.toFixed(4);
};

watch(
  () => [value.value, props.min, props.max],
  ([v, min, max]) => {
    if (dragging.value) return;
    percent.value = Math.max(Math.min((v - min) / (max - min), 1), 0);
    value.value = Math.max(Math.min(v, max), min);
  },
  { immediate: true },
);

const onDragStart = () => {
  dragging.value = true;
  const rect = frame.value!.getBoundingClientRect();
  pageMax = props.vertical ? rect.height : rect.width;
  pageStart = props.vertical ? rect.bottom : rect.left;
};
const onDrag = (e: any) => {
  if (!dragging.value) return;
  setValue(e);
};
const onDragEnd = (e: any) => {
  if (!dragging.value) return;
  setValue(e);
  dragging.value = false;
  window.removeEventListener('mousemove', onDrag);
};

const onMouseDown = () => {
  onDragStart();
  window.addEventListener('mousemove', onDrag);
  window.addEventListener('mouseup', onDragEnd, { once: true });
};
</script>
