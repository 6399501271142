<template>
  <div class="py-4">
    <div class="flex-1 of-auto">
      <div v-for="(item, i) in list" :key="i" class="flex items-start space-x-2 pb-2">
        <DPAvatar class="text-4xl" :name="item.userName" :avatar="item.userAvatarUrl" />
        <div class="space-y-1 flex-1">
          <h5>{{ item.userName }}</h5>
          <p v-if="item.detail.content">{{ $t('TEXT_SUBMISSION') }}: {{ item.detail.content }}</p>
          <p>{{ dt(item.createTime).strDatetime }}</p>
          <div class="flex space-x-2">
            <AButton class="min-w-30" type="secondary" @click="reject(item.id)">{{ $t('mtt.REJECT') }}</AButton>
            <AButton class="min-w-30" @click="approve(item.id)">{{ $t('mtt.APPROVE') }}</AButton>
          </div>
        </div>
        <div v-if="item.detail.imgUrls?.length" class="relative rd bg-white" @click="imgs = item.detail.imgUrls">
          <img class="w-20 h-20 block object-contain" :src="item.detail.imgUrls[0]" alt="" />
          <div v-if="item.detail.imgUrls.length > 1" class="absolute text-sm bottom-1 right-1 px-2 rd bg-black/60">
            +{{ item.detail.imgUrls.length - 1 }}
          </div>
        </div>
      </div>
      <ALoader class="mb-4" :loader="loadData" />
    </div>
    <div class="flex space-x-2">
      <AButton class="flex-1" type="secondary" @click="rejectAll">{{ $t('REJECT_ALL') }}</AButton>
      <AButton class="flex-1" @click="approveAll">{{ $t('APPROVE_ALL') }}</AButton>
    </div>
    <AImageViewer v-model="imgs" />
  </div>
</template>
<script lang="ts" setup>
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';

const props = defineProps<{ id: string }>();
const list = ref<any[]>([]);

const imgs = ref<string[]>();

const loadData = async (index?: string) => {
  const res = await authAPI.get('v1/clan/joinRequest/listByClan', {
    clanId: props.id,
    pageSize: 10,
    index,
  });
  list.value.push(...res.records);
  if (res.records.length < 10) return 'end';
  return res.index as string;
};

const reject = (id: string) => {
  authAPI.post('v1/clan/joinRequest/reject', { joinRequestId: id }).then(() => {
    list.value = list.value!.filter((item) => item.id !== id);
  });
};
const approve = (id: string) => {
  authAPI.post('v1/clan/joinRequest/approve', { joinRequestId: id }).then(() => {
    list.value = list.value!.filter((item) => item.id !== id);
  });
};

const rejectAll = () => {
  authAPI.post('v1/clan/joinRequest/batchReject', { clanId: props.id }).then(() => {
    list.value = [];
  });
};

const approveAll = () => {
  authAPI.post('v1/clan/joinRequest/batchApprove', { clanId: props.id }).then(() => {
    list.value = [];
    loadData();
  });
};
</script>
