export default [
  {
    path: '/poker-to-mint-last',
    name: 'activity-mint-last',
    component: () => import('./SeasonLast.vue'),
    meta: { title: 'EVENT_TITLE_2' },
  },
  {
    path: '/poker-to-mint',
    name: 'activity-mint',
    component: () => import('./SeasonNew.vue'),
    meta: { title: 'EVENT_TITLE_2' },
  },
];
