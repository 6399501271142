import ky, { type Options, type ResponsePromise, type SearchParamsOption } from 'ky';
import { useLocalStorage, type RemovableRef } from '@vueuse/core';
import log from './log';

export const APIBase = `https://${import.meta.env.VITE_HOST}/`;
export const BotBase = `https://${import.meta.env.VITE_BOT_HOST}/`;
export const AuthBase = `https://${import.meta.env.VITE_AUTH_HOST}/`;

export const token: RemovableRef<string> = useLocalStorage('token', '');
export const refreshToken = useLocalStorage('refreshToken', '');

export const session: RemovableRef<string> = useLocalStorage('session', '');

// twitter auth
export const xRedirect: RemovableRef<string> = useLocalStorage('x_redirect', '');
export const request_token: RemovableRef<string> = useLocalStorage('request_token', '');
export const request_secret: RemovableRef<string> = useLocalStorage('request_secret', '');

const baseFetch = ky.create({
  retry: 0,
  timeout: 5000,
  hooks: {
    beforeRequest: [
      (request) => {
        if (token.value) request.headers.set('Authorization', `Bearer ${token.value}`);
        if (session.value) request.headers.set('sessionID', session.value);
      },
    ],
  },
});

export class BzError extends Error {
  code: number;
  msg: string;
  constructor(data: { code: number; msg: string }) {
    super(data.msg);
    this.code = data.code;
    this.msg = data.msg;
  }
}

const withTokenRefresh = async <T>(fetch: () => ResponsePromise, needToken = false, unwrap: boolean, url: string) => {
  if (needToken) {
    if (!token.value && !refreshToken.value) throw 'need token';
    if (!token.value) await updateToken();
  }
  let v = await fetch()
    .json<any>()
    .catch((e) => {
      if (e.name === 'TimeoutError') log.error('http-timeout', { url: e.request.url });
      else if (e.name === 'HTTPError') log.error('http-error', { url: e.request.url, data: e.response.status });
      else log.error('http-fail', { url: url, data: e.name });
      throw e;
    });
  if ([402, 403].includes(v?.code)) {
    await updateToken().catch(() => {
      token.value = '';
      refreshToken.value = '';
      throw v;
    });
    v = await fetch().json<T & { code: number }>();
  }
  if (v.code) throw new BzError(v);
  return (unwrap ? v.data : v) as T;
};
type UrlQuery = SearchParamsOption | Record<string, number | string | boolean | undefined | null>;
const formatSearch = (v?: UrlQuery) => {
  if (!v || typeof v === 'string' || Array.isArray(v) || v instanceof URLSearchParams) return v;
  Object.keys(v).forEach((k) => {
    if (v[k] === undefined || v[k] === null) delete v[k];
  });
  return v as Record<string, string | number | boolean>;
};
const createHttp = (prefixUrl: string, unwrap = true) => ({
  get: async <T = any>(url: string, search?: UrlQuery, json?: any, needToken = false, args?: Partial<Options>) =>
    withTokenRefresh<T>(
      () => baseFetch.get(url, { prefixUrl, searchParams: formatSearch(search), json, ...args }),
      needToken,
      unwrap,
      url,
    ),
  post: <T = any>(url: string, json?: any, search?: UrlQuery, needToken = false, args?: Partial<Options>) =>
    withTokenRefresh<T>(
      () => baseFetch.post(url, { prefixUrl, searchParams: formatSearch(search), json, ...args }),
      needToken,
      unwrap,
      url,
    ),
  blob: (url: string, searchParams?: SearchParamsOption, json?: any) =>
    baseFetch
      .get(url, { prefixUrl, searchParams, json })
      .then(async (v) => ({ data: await v.blob(), suffix: v.headers.get('Content-Disposition')?.split('.')[1] })),
});

export const gameAPI = createHttp(APIBase, false);
export const authAPI = createHttp(AuthBase);
export const botAPI = createHttp(BotBase);

export const blogFetch = ky.create({
  retry: 0,
  timeout: 10000,
  prefixUrl: `https://${import.meta.env.VITE_BLOG_HOST}/api`,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('Authorization', `Bearer ${import.meta.env.VITE_BLOG_TOKEN}`);
      },
    ],
  },
});

let updatePromise: Promise<any> | undefined;

const fetchToken = async () => {
  token.value = '';
  if (!refreshToken.value) throw 'refresh token is null';
  const data = await authAPI.post('refresh_token', { refreshToken: refreshToken.value });
  if (!data.isSuccessful) throw 'refresh token fail';
  token.value = data.authenticationResult.accessToken;
  refreshToken.value = data.authenticationResult.refreshToken || refreshToken.value;
};
export const updateToken = () => {
  if (!updatePromise) updatePromise = fetchToken().finally(() => (updatePromise = undefined));
  return updatePromise;
};

// export const userPromise = loadUser().catch(() => undefined);
