<script setup lang="ts">
import { computed } from 'vue';
import { emitter } from '@/stores/user';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
  }>(),
  { modelValue: false },
);

const emit = defineEmits(['update:modelValue', 'refresh']);

const modelVisible = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const changeVisible = (visible: boolean) => {
  modelVisible.value = visible;
};

emitter.on('customerHelp', changeVisible);
</script>

<template>
  <Teleport to="body">
    <div
      class="fixed top-0 left-0 w-full max-w-full h-full py-5 bg-black/70 z-10 flex flex-col inset-0"
      v-show="modelVisible"
    >
      <div class="absolute z-1 top-3 left-3 c-gray i-local-close size-6 cursor-pointer" @click="modelVisible = false" />
      <div class="w-full flex-1 pt-3">
        <iframe
          ref="myIframe"
          src="https://app.wonderchat.io/chatbot/cm2juunag0dzi9ndbgfdj5yoo"
          style="border-width: 0"
          allow="clipboard-read; clipboard-write"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  </Teleport>
</template>

<style scoped></style>
