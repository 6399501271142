<script setup lang="ts">
import tableBG from '@/assets/img/table-bg.png';
import mTableBG from '@/assets/img/m-table-bg.png';
import previewH from '@/assets/img/preview_pc.png';
import previewV from '@/assets/img/preview_m.png';
import carpet from '@/assets/img/canvas-bg.png';
import { landscape } from '@/stores';
import { computed, ref } from 'vue';

const props = defineProps<{ themeConfig?: any; chooseLandscape: boolean }>();

const themeConfig = computed(() => {
  return {
    tableBackgroundUrl: props.themeConfig?.tableBackgroundUrl || tableBG,
    verticalTableBackgroundUrl: props.themeConfig?.verticalTableBackgroundUrl || mTableBG,
    carpetUrl: props.themeConfig?.carpetUrl || carpet,
    verticalCarpetUrl: props.themeConfig?.verticalCarpetUrl || carpet,
  };
});

const visible = ref(false);

const preType = computed(() => {
  if (props.chooseLandscape && landscape.value) return 0; // 横屏展示横屏
  if (!props.chooseLandscape && landscape.value) return 1; // 横屏展示竖屏
  if (props.chooseLandscape && !landscape.value) return 2; // 竖屏展示横屏
  return 3; // 竖屏展示竖屏
});
</script>

<template>
  <div class="flex items-center" @click="visible = true">
    <div class="i-local-preview mr-1.5 c-gray" />
    {{ $t('PREVIEW') }}
  </div>
  <div
    class="w-full h-full fixed z-100 top-0 left-0 bg-black/70 flex items-center justify-center"
    :class="{ 'animate-fade-in animate-duration-150': visible }"
    v-show="visible"
  >
    <div
      class="relative flex flex-col w-95% h-95% justify-center items-center canvas"
      :style="{ '--bg': `url(${themeConfig.carpetUrl})` }"
      :class="{ 'w-45%!': preType === 1, 'h-45%!': preType === 2 }"
    >
      <div
        class="relative mt-20"
        :class="{
          'w-210 h-120': chooseLandscape,
          'w-110 h-180': !chooseLandscape,
        }"
      >
        <div class="flex items-center justify-center relative z-0">
          <div
            class="relative"
            :class="{
              'table-landscape': props.chooseLandscape,
              'table-portrait': !props.chooseLandscape,
              'w-full h-157': preType === 3,
              'h-104 w-205': preType === 0,
              'h-52 w-103': preType === 2,
              'h-140 w-100': preType === 1,
            }"
          >
            <div
              class="custom-bg absolute inset-0"
              :style="{
                backgroundImage: `url(${props.chooseLandscape ? themeConfig.tableBackgroundUrl : themeConfig.verticalTableBackgroundUrl})`,
              }"
            />
            <div
              class="inset-1/2 absolute w-full h-full flex justify-between"
              :class="{
                'top--22% left--7%': props.chooseLandscape,
                'top--5% left-0': !props.chooseLandscape,
              }"
            >
              <img
                alt=""
                :src="props.chooseLandscape ? previewH : previewV"
                :class="{
                  'w-140% h-140%': props.chooseLandscape,
                  'w-110% h-110%': !props.chooseLandscape,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="inset-1 absolute flex flex-col justify-between py-5">
        <div class="text-center font-bold text-2xl">{{ $t('PREVIEW') }}</div>
        <Button class="mx-5" label="OK" @click="visible = false" />
      </div>
    </div>
  </div>
</template>
