import { authAPI } from './network';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';

export const mapPositionColor = (position: string | undefined) => {
  if (!position) return;
  switch (position.toLowerCase()) {
    case 'sb':
    case 'bb':
      return 'fw-400 c-green';
    default:
      return 'fw-400 c-yellow';
  }
};

export const upload = async ({ files }: any, bucket = 'lepoker-img-upload') => {
  const token = await authAPI.post<{ accessKey: string; secretKey: string; sessionToken: string }>('v1/s3/request_sts');
  const client = new S3Client({
    region: 'ap-southeast-1',
    credentials: { secretAccessKey: token.secretKey, accessKeyId: token.accessKey, sessionToken: token.sessionToken },
  });
  let suffix = files[0].name.split('.').at(-1);
  if (suffix) suffix = `.${suffix}`;
  const key = URL.createObjectURL(new Blob()).split('/').at(-1) + suffix;
  const command = new PutObjectCommand({ Bucket: bucket, Key: key, Body: files[0], CacheControl: 'max-age=36000000' });

  try {
    await client.send(command);
    client.destroy();
    return `https://${bucket}.s3.ap-southeast-1.amazonaws.com/${key}`;
  } catch (err) {
    client.destroy();
    throw err;
  }
};
