<script setup lang="ts">
import { platform } from '@/stores';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

const router = useRouter();

async function initAuth() {
  platform.value = 'vpnLanding';
}

async function gotoSite(gt: string) {
  if (gt == 'private') {
    router.push('/g/p');
  } else if (gt == 'mtt') {
    router.push('/m');
  } else if (gt == 'sng') {
    router.push('/s');
  }
}

onMounted(() => {
  initAuth();
});
</script>
<template>
  <header>
    <img src="/static/logo.png" alt="" />
    <h2>社区免费在线扑克</h2>
  </header>
  <main>
    <div style="display: flex; align-items: center; justify-content: center">
      <a @click="gotoSite('sng')">
        <img src="/static/vpn-btn-sng.png" style="width: 91vw; height: 24vw" />
      </a>
    </div>
    <div style="display: flex; margin-top: 3vw; align-items: center; justify-content: center">
      <a @click="gotoSite('mtt')" style="width: 44vw; height: 21.5vw">
        <img src="/static/vpn-btn-mtt.png" style="width: 44vw; height: 21.5vw" alt="" />
      </a>
      <a @click="gotoSite('private')" style="width: 44vw; height: 21.5vw; margin-left: 3vw">
        <img src="/static/vpn-btn-private.png" style="width: 44vw; height: 21.5vw" alt="" />
      </a>
    </div>
  </main>
</template>
<style scoped>
header {
  background: url(/static/table.png) no-repeat top center/cover;
  padding: 20vw 7vw 50vw;
  text-align: center;
}
header img {
  height: 9vw;
}
header h2 {
  font-size: 7vw;
  padding: 2vw;
  color: white;
}
header p {
  font-size: 4vw;
  color: rgb(211, 211, 211);
}
main {
  padding: 0 7vw;
}
main h3 {
  font-size: 4vw;
  margin-bottom: 2vw;
}
main p {
  font-size: 3vw;
}
main img {
  background: center;
  height: 26vw;
}
</style>
