<script setup lang="ts">
const props = defineProps<{
  mttId: string;
  poolId: number;
  rank: number;
  chainName: string;
  transactionHash?: string;
}>();

import {
  connectAndFetchAddress,
  requestConnect,
  signMessage,
  callClaim,
  checkNetwork,
  generateExplorerUrl,
  getNativeBalance,
  watchTransaction,
} from '@/utils/claimOnChainReward';
import { computed, onMounted, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { authAPI } from '@/utils/network';

const loading = ref<boolean>(false);
const balanceLoading = ref<boolean>(false);
const txLoading = ref<boolean>(false);
const address = ref<string>('');
const messagePrefix = 'Welcome to LePoker.io. Please sign this message to verify claim address.\n\nTimestamp: ';
const tx = ref<string>('');
const txSuccess = ref<number>(2); // 2 - none, 1 - success, 0 - failed
const balance = ref<number>(0);

onMounted(async () => {
  if (!props.transactionHash) {
    address.value = await connectAndFetchAddress();
  }
});

watch(
  () => address.value,
  async (v) => {
    if (v) {
      balanceLoading.value = true;
      try {
        balance.value = Number(await getNativeBalance(v, props.chainName));
      } finally {
        balanceLoading.value = false;
      }
    }
  },
);

const hasHash = computed(() => {
  return !!(props.transactionHash || tx.value);
});

const toast = useToast();
const { t } = useI18n();

const claim = async () => {
  loading.value = true;
  try {
    // 检查钱包连接
    if (!address.value) {
      address.value = await requestConnect();
    }

    // 检查 gas fee
    balance.value = Number(await getNativeBalance(address.value, props.chainName));
    if (balance.value < 0.001) {
      toast.add({ severity: 'error', summary: t('ERROR_MESSAGE'), detail: t('reward.GAS_INSUFFICIENT'), life: 3000 });
      return;
    }

    // 检查网络
    await checkNetwork(props.chainName);

    // 签名
    const timestamp = Math.ceil(Date.now() / 1000);
    const signature = await signMessage(address.value, messagePrefix + timestamp);
    const result = await authAPI.post('v1/mtt/saveUserEncryptInfo', {
      userSign: signature,
      signTime: timestamp,
      mttId: props.mttId,
      rank: props.rank,
      claimer: address.value,
    });

    // 调用智能合约
    tx.value = await callClaim(props.poolId, props.rank, result.signature, address.value, result.contractAddress);
    toast.add({ severity: 'success', summary: t('SUCCESS'), life: 3000 });
  } catch (error: any) {
    toast.add({ severity: 'error', summary: t('ERROR_MESSAGE'), detail: error.message, life: 3000 });
  } finally {
    loading.value = false;
  }
};

watch(
  () => tx.value,
  async (v) => {
    if (v) {
      txLoading.value = true;
      try {
        txSuccess.value = (await watchTransaction(v, props.chainName)) ? 1 : 0;
      } catch (error: any) {
        toast.add({ severity: 'error', summary: t('ERROR_MESSAGE'), detail: error.message, life: 3000 });
      } finally {
        txLoading.value = false;
      }
    }
  },
);

const buttonLabel = computed(() => {
  if (!address.value) {
    return t('CONNECT');
  }
  return t('mtt.CLAIM_REWARD');
});
</script>

<template>
  <div class="flex bg-dark-700 rd p-4 space-x-3 items-start">
    <div class="flex items-center space-x-3" portrait="flex-col-reverse">
      <div v-if="!address && loading" class="i-local-loading c-white/50" />
      <div v-else class="i-local-completed c-white/50" :class="{ 'c-green!': address || hasHash }" />
      <div portrait="mb-2" class="fw-bold c-white/50">{{ $t('STEP', { v: 1 }) }}</div>
    </div>
    <div class="flex-1">{{ $t('reward.STEP_1_DESC') }}</div>
  </div>
  <div class="flex bg-dark-700 rd p-4 space-x-3 items-start mt-2">
    <div class="flex items-center space-x-3" portrait="flex-col-reverse">
      <div v-if="balanceLoading" class="i-local-loading c-white/50" />
      <div v-else class="i-local-completed c-white/50" :class="{ 'c-green!': balance >= 0.001 || hasHash }" />
      <div portrait="mb-2" class="fw-bold c-white/50">{{ $t('STEP', { v: 2 }) }}</div>
    </div>
    <div class="flex-1">
      {{ $t('reward.STEP_2_DESC') }}
    </div>
  </div>
  <div class="flex bg-dark-700 rd p-4 space-x-3 items-start mt-2">
    <div class="flex items-center space-x-3" portrait="flex-col-reverse">
      <div v-if="address && loading" class="i-local-loading c-white/50" />
      <div v-else class="i-local-completed c-white/50" :class="{ 'c-green!': hasHash && txSuccess }" />
      <div portrait="mb-2" class="fw-bold c-white/50">{{ $t('STEP', { v: 3 }) }}</div>
    </div>
    <div class="flex-1">
      {{ $t('reward.STEP_3_DESC') }}
    </div>
  </div>
  <div class="flex bg-dark-700 rd p-4 space-x-3 items-start mt-2">
    <div class="flex items-center space-x-3" portrait="flex-col-reverse">
      <div v-if="txLoading" class="i-local-loading c-white/50" />
      <div v-else class="i-local-completed c-white/50" :class="{ 'c-green!': hasHash && txSuccess }" />
      <div portrait="mb-2" class="fw-bold c-white/50">{{ $t('STEP', { v: 4 }) }}</div>
    </div>
    <div class="flex-1">
      <div>
        {{ $t('reward.STEP_4_DESC') }}
      </div>
      <a
        v-if="hasHash && !!txSuccess"
        class="underline break-all"
        :class="{ 'c-red': !txSuccess }"
        :href="generateExplorerUrl(props.transactionHash || tx, props.chainName)"
        target="_blank"
        >{{ !txSuccess ? $t('reward.TX_FAILED') : '' }}{{ props.transactionHash || tx }}</a
      >
    </div>
  </div>

  <Button
    v-if="!props.transactionHash"
    class="w-full mt-4"
    :loading="loading"
    @click="claim"
    :label="buttonLabel"
    :disabled="!!(tx && txSuccess)"
  />
</template>
