export const parseStrEmpty = (str: string) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  return str;
};

export const escapeHTML = (str: string): string => {
  if (!str) return '';
  return str
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const convertToLangeLabel = (str: string | undefined) =>
  str
    ?.toUpperCase()
    .replace('.', '')
    .split(' ')
    .filter((v) => !!v)
    .join('_') || '';

export const encodeText = (str?: string) => {
  if (!str) return '';

  return str
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/@\(([^,\s]+),?\s*(\S*)\)/g, (_, p1, p2) => `<a href="${p1}" target="_blank">${p2 || p1}</a>`);
};
export const formatNumber = (v: any, simple = false, float = 2): string => {
  if (typeof v !== 'number' || isNaN(v)) return '-';
  if (v < 0) return '-' + formatNumber(-v, simple);
  if (v < 1000 || !simple) return (+v.toFixed(float)).toLocaleString();
  const units = ['K', 'M', 'B', 'T'];
  for (let i = 0; i < units.length; i++) {
    v = Math.round(v / 10) / 100;
    if (v < 1000) return v + units[i];
  }
  return `${formatNumber(v, false)}T`;
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
