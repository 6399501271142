import socialX from '@/assets/img/icon-x-white.png';
import socialDiscord from '@/assets/img/icon-discord.png';
import socialTelegram from '@/assets/img/icon-telegram.png';
import socialDebox from '@/assets/img/icon-debox.png';
export const socialImgs: any = {
  x: socialX,
  discord: socialDiscord,
  telegram: socialTelegram,
  debox: socialDebox,
};
export const socialList = ['x', 'discord', 'telegram', 'debox'];
export const socialNames: any = {
  x: 'X (Twitter)',
  discord: 'Discord',
  telegram: 'Telegram',
  debox: 'Debox',
};
