<template>
  <div class="relative of-hidden">
    <slot name="background">
      <img class="w-full block" src="@/assets/img/draw-pad.png" alt="" />
    </slot>
    <div
      class="absolute inset-5%"
      :class="{ 'animate-draw': state }"
      :style="{ '--dest': 1080 + ((count - gift) * 360) / count + 'deg' }"
    >
      <slot />
    </div>
    <div class="absolute top-4.5 w-full h-0 items-start flex justify-center" @click="draw">
      <slot name="btn">
        <img class="cursor-pointer w-full" src="@/assets/img/draw-spin.png" alt="" />
      </slot>
    </div>
    <div class="absolute -inset-1px" v-if="$slots.disable">
      <div class="rd-full h-full w-full bg-black/70 flex items-center justify-center">
        <slot name="disable" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
  count: number;
  getResult: () => Promise<number | undefined>;
  disable?: boolean;
}>();
const emits = defineEmits<{ finish: [number] }>();

const state = ref(false);
const gift = ref(0);

let drawing = false;

const draw = async () => {
  if (props.disable || drawing) return;
  drawing = true;
  state.value = false;
  await new Promise((r) => setTimeout(r, 50));
  const res = await props.getResult().catch(() => {
    drawing = false;
  });
  if (res === undefined) return (drawing = false);
  gift.value = res;
  state.value = true;
  setTimeout(() => {
    emits('finish', gift.value);
    drawing = false;
  }, 4500);
};
</script>
<style>
.animate-draw {
  animation: 4s my-spin ease-out forwards;
}
@keyframes my-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(var(--dest, 0deg));
  }
}
</style>
