<script setup lang="ts">
import { computed } from 'vue';
import openImg from '@/assets/img/rabbit-open.png';
import bgImg from '@/assets/img/rabbit-bg.png';
import { userGameSetting } from '@/utils/value';

const props = defineProps<{
  name?: string;
  disabled?: boolean;
  hl?: boolean;
  rabbit?: boolean;
  mini?: boolean;
  simple?: boolean;
  type?: 1 | 2 | 3;
}>();

const suit = computed(() => {
  if (!props.name) return '';
  const key = props.name[1] || props.name[0];
  return { s: 'spade', c: 'club', h: 'heart', d: 'diamond' }[key];
});

const value = computed(() => {
  if (!props.name) return undefined;
  if (props.name[1] || !suit.value) return props.name[0].toLowerCase();
  return undefined;
});

const cardType = computed(() => props.type || userGameSetting.value.cardType);

const color = computed(() => {
  if (!suit.value) return 'black';
  if (cardType.value === 2)
    return { heart: '#e20913', spade: 'black', club: '#04925f', diamond: '#1496DD' }[suit.value];
  if (cardType.value === 3)
    return { heart: '#e20913', spade: '#333333', club: '#04925f', diamond: '#1496DD' }[suit.value];
  return { heart: '#e20913', spade: 'black', club: 'black', diamond: '#e20913' }[suit.value];
});
</script>

<template>
  <div
    class="w-2.8em h-3.9em rd-.2em shadow-[0_.2rem_1rem_0_rgba(0,0,0,0.2)] preserve-3d"
    :class="{ 'card-hl': hl }"
    :style="{ color: cardType === 3 ? 'white' : color }"
  >
    <div
      class="absolute bg-white rd-.2em p-.2em inset-0 -translate-z-.1px"
      :class="{ 'brightness-50': disabled }"
      :data-ignore="value"
    >
      <div class="bg-1 rd-.1em h-full" />
    </div>
    <div
      v-if="name"
      class="flex flex-col h-full rd-.2em p-.25em justify-center"
      :class="{ 'brightness-50': disabled }"
      :style="{ backgroundColor: cardType === 3 ? color : 'white' }"
    >
      <div
        v-if="value"
        class="lh-none mb-.1em"
        :class="[`i-local-card-${value}`, { 'text-1.6em': mini, 'text-2em self-center': !suit }]"
      />
      <div v-if="!mini && value && suit" class="size-.8em ml-.13em" :class="`i-local-${suit}`" />
      <div
        v-if="suit"
        class="text-1.6em self-end -mt-.06em"
        :class="[`i-local-${suit}`, { 'text-2em self-center': !value }]"
      />
    </div>
    <div v-show="rabbit" class="absolute inset-0 translate-z-.2px z-1">
      <img v-if="simple" class="size-full" src="@/assets/img/rabbit-end.png" />
      <img v-else class="size-full" :class="{ 'cursor-pointer': !name }" :src="name ? openImg : bgImg" />
    </div>
  </div>
</template>
<style>
.bg-1 {
  background:
    url('../assets/img/card-bg.png') top/ 100% 100%,
    #fa8787;
}
.card-hl {
  transform: translateY(-0.3em);
  box-shadow:
    0 0 0.3em #ff8a00,
    inset 0 0 0.2em #ffd600,
    -0.1em 0.7em 0.7em #00000044 !important;
}
</style>
