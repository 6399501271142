<template>
  <ATitle>
    {{ $t('TABLE_LEFT') }}: <b class="c-green ml-1">{{ tables.length }}</b>
  </ATitle>
  <div v-if="!tables.length" class="text-center p-8">
    <img class="w-46" src="@/assets/img/no-data-1.png" alt="" />
    <div class="c-gray">{{ $t('THERE_IS_NO_INFO_THAT_CAN_BE_DISPLAYED') }}</div>
  </div>
  <div class="grid grid-cols-2 gap-y-4" landscape="grid-cols-4">
    <div
      v-for="(t, i) in tables"
      :key="i"
      class="flex flex-col items-center bgi-mini-table bg-contain bg-center bg-no-repeat p-2"
      @click="openTable(t)"
    >
      <div class="fw-extrabold c-yellow mb-2">NO.{{ t.data.seq }}</div>
      <p class="flex items-center c-gray-200 truncate"><span class="i-local-buyin" />{{ $t('mtt.S_B_STACK') }}</p>
      <p>{{ t.data.min }} / {{ t.data.max }}</p>
      <p class="flex items-center px-2 rd-full bg-black/30 mt-2 mb-6"><span class="i-local-user" />{{ t.data.len }}</p>
    </div>
    <ADialog class="text-center" v-if="cur" v-model="showDetail">
      <h5>{{ $t('mtt.TABLE') }}: NO.{{ cur.data.seq }}</h5>
      <p>
        <span class="c-gray-200">{{ $t('mtt.S_B_STACK') }}:</span> {{ cur.data.min }}/{{ cur.data.max }}
      </p>
      <p>
        <span class="c-gray-200">{{ $t('mtt.PLAYERS') }}:</span> {{ cur.data.len }}
      </p>
      <div class="rd-lg b-solid b-gray-700 of-hidden mt-4">
        <table class="w-full border-collapse">
          <tr class="bg-gray-700" un-children="py-2">
            <th>{{ $t('mtt.RANK') }}</th>
            <th>{{ $t('mtt.PLAYER') }}</th>
            <th>{{ $t('mtt.CHIPS') }}</th>
          </tr>
          <tr v-for="(p, k) in cur.list" :key="k" un-children="py-2" :class="{ 'bg-dark-900': k % 2 }">
            <td>{{ k + 1 }}</td>
            <td>{{ p.playerName }}</td>
            <td>{{ p.chip }}</td>
          </tr>
        </table>
      </div>
      <AButton class="w-full mt-4" v-if="observe" @click="$router.push(`/g/m-l/${cur.data.id}`)">{{
        $t('mtt.OPEN_TABLE')
      }}</AButton>
    </ADialog>
  </div>
</template>
<script lang="ts" setup>
import { authAPI } from '@/utils/network';
import { useIntervalFn } from '@vueuse/core';
import { computed, ref } from 'vue';

const props = defineProps<{ id: string; observe?: boolean; opened?: boolean }>();

const rank = ref<any[]>([]);

const loadRank = async () => {
  if (!props.opened) return;
  rank.value = await authAPI.get('v1/mtt/getMTTRanking', { mttID: props.id });
};

useIntervalFn(loadRank, 3e4, { immediateCallback: true });

const tables = computed(() => {
  if (!rank.value) return [];
  const tables: Record<string, any[]> = {};
  rank.value
    .filter((v) => v.chip)
    .forEach((v) => {
      if (!v.tableID) return;
      if (!tables[v.tableID]) tables[v.tableID] = [];
      tables[v.tableID].push(v);
    });
  return Object.entries(tables)
    .map(([k, v]) => ({
      data: {
        id: k,
        seq: v[0].tableNo,
        len: v.length,
        max: Math.max(...v.map((p) => p.chip)),
        min: Math.min(...v.map((p) => p.chip)),
      },
      list: v,
    }))
    .sort((a, b) => a.data.seq - b.data.seq);
});
const showDetail = ref(false);
const cur = ref<(typeof tables.value)[number]>();
function openTable(v: (typeof tables.value)[number]) {
  cur.value = v;
  showDetail.value = true;
}
</script>
