<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    leftTicket: number;
    disabled?: boolean;
    red?: boolean;
  }>(),
  { leftTicket: 0, disabled: false, red: false },
);
</script>

<template>
  <div class="relative">
    <div
      class="text-center absolute top--6 b-green b-solid b-1 b-b-0 w-full rd-t-md pb-4"
      :class="{ 'op-60': disabled, 'b-red b-2': red }"
      v-if="!!props.leftTicket"
    >
      <div class="bg-black rd-t-md">
        {{ props.leftTicket }} {{ props.leftTicket > 1 ? $t('mtt.TICKETS_LEFT') : $t('mtt.TICKET_LEFT') }}
      </div>
    </div>
    <slot />
  </div>
</template>
