<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { loginSocialV2, checkAccountStatus, uploadUserAvatar, updateUser } from '@/utils/user';
import {
  user,
  googleUser,
  showBindAccount,
  socialToken,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
} from '@/stores';
import { botAPI } from '@/utils/network';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
import { okxLogin } from '@/utils/okx';
import { tonLogin } from '@/utils/ton';
import { dt } from '@/utils/datetime';
import { connectX } from '@/utils/twitter';
import { connectDiscord } from '@/utils/discord';
import { connectGoogle } from '@/utils/google';
import { connectDebox } from '@/utils/debox';
import { metamaskLogin } from '@/utils/metamask';
import { updateResolve } from '@/stores/user';

const i18n = useI18n();

const showBind1 = ref(false);
const showBind2 = ref(false);
const showBind3 = ref(false);
const showBindEmail = ref(false);
const showUpdateProfile = ref(false);
const showWeb3Wallet = ref(false);
const data = ref({ name: '', avatar: '', country: '' });

const { t } = useI18n();

if (showBindAccount.value == 1) {
  showBind1.value = true;
} else if (showBindAccount.value == 3) {
  showBind3.value = true;
  showWeb3Wallet.value = false;
} else if (showBindAccount.value == 4) {
  showUpdateProfile.value = true;
}

const toast = useToast();

onMounted(() => {
  if (user.value) {
    if (user.value.name.length > 15) {
      data.value.name = 'user' + user.value.name.substring(0, 15);
    } else {
      data.value.name = user.value.name;
    }
    data.value.avatar = user.value.avatar;
    data.value.country = user.value.country;
  } else if (linkType.value) {
    if (linkUserName.value.length > 15) {
      data.value.name = 'user' + linkUserName.value.substring(0, 15);
    } else {
      data.value.name = linkUserName.value;
    }
    data.value.avatar = linkAvatar.value;
  }
});

const botName = import.meta.env.VITE_TG_BOT;

const loginWithTelegram = async (userLoginUser: any) => {
  let userName = '';
  userName = userLoginUser['username'];
  const firstName = userLoginUser['first_name'];
  const lastName = userLoginUser['last_name'];
  if (!userName && firstName) {
    userName = firstName + userLoginUser['id'];
  } else if (!userName && lastName) {
    userName = lastName + userLoginUser['id'];
  }
  userLoginUser['authType'] = 'login';
  data.value.name = userName;
  try {
    const { token } = await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
    if (await checkAccountStatus(token)) {
      showBind2.value = false;
      await loginSocialV2(token);
    } else {
      showBind2.value = false;
      showBind3.value = true;
    }
  } catch (e: any) {
    console.info(e);
    // showLogin.value = false;
  }
};

const loginDiscord = async () => {
  await connectDiscord('---bindDiscord');
};

const loginDebox = async () => {
  await connectDebox('---bindDebox');
};

let clickLoginX = false;
const loginX = async () => {
  if (!clickLoginX) {
    clickLoginX = true;
    await connectX('---bindX');
  }
};

let clickLoginGoogle = false;
const loginGoogle = async () => {
  if (!clickLoginGoogle) {
    clickLoginGoogle = true;
    await connectGoogle('---bindGoogle');
  }
};

const newAccount = async () => {
  linkType.value = '';
  linkUserId.value = '';
  linkUserName.value = '';
  linkAvatar.value = '';
  const result = await loginSocialV2(socialToken.value, googleUser.email);
  if (result) {
    // location.reload();
    if (user.value) {
      if (user.value.name.length > 15) {
        data.value.name = 'user' + user.value!.name.substring(0, 15);
      } else {
        data.value.name = user.value!.name;
      }
      data.value.avatar = user.value!.avatar;
      data.value.country = user.value!.country;
    }

    showUpdateProfile.value = true;
  }
};

const uploadAvatar = async (v: any) => {
  data.value!.avatar = await uploadUserAvatar(v);
};

const finishNewAccount = async () => {
  if (!data.value.name) {
    toast.add({ severity: 'error', summary: i18n.t('FAIL'), detail: 'Please set username', life: 3000 });
  } else {
    if (data.value.name.length > 40) {
      toast.add({ severity: 'error', summary: i18n.t('FAIL'), detail: 'Please set username', life: 3000 });
      return;
    }
    try {
      await updateUser(data.value.name, data.value.avatar, data.value.country, true);
      showBindAccount.value = 0;
      updateResolve?.();
    } catch (e: any) {
      if (parseInt(e.msg)) {
        const msg = i18n.t('NEED_TO_WAIT_30_DAYS_LIMIT', { v: dt(e.msg * 1e3).strDatetime });
        toast.add({ severity: 'error', summary: i18n.t('FAIL'), detail: msg, life: 3000 });
      } else {
        toast.add({
          severity: 'error',
          summary: i18n.t('FAIL'),
          detail: i18n.t(convertToLangeLabel(e.msg)),
          life: 3000,
        });
      }
    }
  }
};

const web3Steps = [
  {
    step: 'chooseNetwork',
    header: t('CHOOSE_NETWORK'),
  },
  {
    step: 'chooseWallet',
    header: t('CHOOSE_WALLET'),
  },
];
const currentWeb3Step = ref('');
const currentWeb3StepHeader = ref('');
const chain = ref('');
const chooseChain = (index: number, network = '') => {
  if (index == -1) {
    showBind2.value = false;
    showBind1.value = true;
    currentWeb3Step.value = '';
    currentWeb3StepHeader.value = '';
    chain.value = '';
  } else {
    showBind2.value = false;
    showWeb3Wallet.value = true;
    currentWeb3Step.value = web3Steps[index].step;
    currentWeb3StepHeader.value = web3Steps[index].header;
    chain.value = network;
  }
};

const loginWeb3 = async (wallet: string) => {
  try {
    showBindAccount.value = 0;
    if (wallet == 'okx') {
      await okxLogin(chain.value, 'bind');
    } else if (wallet === 'ton-keeper') {
      await tonLogin('bind');
    } else if (wallet === 'metamask') {
      await metamaskLogin(chain.value, 'bind');
    }
  } catch (msg: any) {
    toast.add({
      severity: 'error',
      summary: t('FAIL'),
      detail: t(convertToLangeLabel(msg)),
      life: 3000,
    });
  }
};
</script>
<template>
  <ADialog class="w-100" v-model="showBindEmail" :title="$t('CONNECT_WITH_EMAIL')">
    <DPBindEmail connectType="bind" @callback="showBindEmail = false" />
  </ADialog>
  <Dialog
    class="w-100"
    v-model:visible="showBind1"
    header=""
    :pt="{ content: { class: 'mt--8' } }"
    :draggable="false"
    :closable="false"
    modal
  >
    <div class="text-center space-y-4">
      <h5>{{ $t('BIND_ACCOUNT_TITLE') }}</h5>
      <p class="px-4 text-center">{{ $t('BIND_ACCOUNT_DESC') }}</p>
      <Button
        :label="t('CONNECT_TO_AN_EXISTING_ACCOUNT')"
        @click="
          showBind2 = true;
          showBind1 = false;
        "
        class="w-full justify-center"
      />
      <Button class="w-full justify-center bg-gray-700" :label="t('CREATE_A_NEW_ACCOUNT')" @click="newAccount" />
    </div>
  </Dialog>
  <Dialog
    class="w-100"
    v-model:visible="showBind2"
    header=" "
    :pt="{ content: { class: 'mt--8' } }"
    :draggable="false"
    :closable="false"
    modal
  >
    <div class="text-center space-y-4">
      <h5>{{ t('CONNECT_TO_AN_EXISTING_ACCOUNT') }}</h5>
      <Button
        @click="
          showBind2 = false;
          showBindEmail = true;
        "
        class="w-full bg-gray-700"
      >
        <img class="w-10 h-10" src="@/assets/img/icon-email.png" alt="" />
        <span class="text-sm p-l-2">Email</span>
      </Button>
      <div v-if="linkType != 'TG'" class="w-full">
        <ButtonLogin
          class="w-full"
          :botUsername="botName"
          mode="callback"
          requestAccess="write"
          @callback="loginWithTelegram"
        >
          <Button class="w-full bg-gray-700">
            <img class="w-10 h-10" src="@/assets/img/icon-telegram.png" alt="" />
            <span class="text-sm p-l-2">Telegram</span>
          </Button>
        </ButtonLogin>
      </div>
      <Button v-if="linkType != 'DISCORD'" @click="loginDiscord" class="w-full bg-gray-700">
        <img class="w-10 h-10" src="@/assets/img/icon-discord.png" alt="" />
        <span class="text-sm p-l-2">Discord</span>
      </Button>
      <Button v-if="linkType != 'X'" @click="loginX" class="w-full bg-white">
        <img class="w-10 h-10" src="@/assets/img/icon-x.png" alt="" />
        <span class="text-sm p-l-2">X(Twitter)</span>
      </Button>
      <!-- <Button v-if="linkType != 'GOOGLE'" @click="loginGoogle" class="w-full bg-gray-700">
        <div class="i-local-google w-10 h-10" />
        <span class="text-sm p-l-2">Google</span>
      </Button> -->
      <Button v-if="linkType != 'DEBOX'" @click="loginDebox" class="w-full bg-gray-700">
        <div class="p-2 bg-green rd-full"><div class="i-local-debox size-8" /></div>
        <span class="text-sm pl-2">Debox</span>
      </Button>
      <Button @click="chooseChain(0)" class="w-full bg-gray-700">
        <img class="w-10 h-10" src="@/assets/img/icon-wallet.png" alt="" />
        <span class="text-sm p-l-2">Wallet</span>
      </Button>
    </div>
  </Dialog>
  <Dialog
    class="w-90"
    v-model:visible="showBind3"
    header=""
    :pt="{ content: { class: 'mt--8' } }"
    :draggable="false"
    :closable="false"
    modal
  >
    <div class="text-center space-y-4">
      <h5>{{ $t('NOTICE') }}</h5>
      <p class="px-4 text-center">{{ $t('ACCOUNT_NOT_FOUND') }}</p>
      <Button
        :label="t('BACK')"
        @click="
          showBind2 = false;
          showBind3 = false;
          showWeb3Wallet = false;
          showBind1 = true;
        "
        class="w-full justify-center"
      />
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showUpdateProfile"
    :header="$t('UPDATE_YOUR_PROFILE')"
    :draggable="false"
    :closable="false"
    modal
  >
    <div class="p-1 flex flex-col min-w-100">
      <p class="px-4 text-center c-gray-400 mb-8">
        {{ $t('QUICK_UPDATE_YOUR_AVATAR_AND_USERNAME') }} <br />
        {{ $t('YOU_CAN_ALWAYS_CHANGE_IT_LATER') }}
      </p>
      <div class="flex flex-col justify-center items-center space-y-5 pb-6">
        <div class="text-5xl">
          <DPAvatar class="cursor-pointer text-1em" :name="data.name" :avatar="data.avatar" />
        </div>
        <div class="mt-6">
          <div class="flex space-x-4">
            <FileUpload
              class="p-2"
              mode="basic"
              :choose-label="$t('UPLOAD')"
              accept="image/*"
              :maxFileSize="1000000"
              :auto="true"
              customUpload
              @uploader="uploadAvatar"
            />
            <Button severity="secondary" icon="i-local-delete" @click="data.avatar = ''" />
          </div>
          <p class="text-center op-70 pt-4">{{ $t('IMAGE_SIZE') }}</p>
        </div>
      </div>
      <p class="flex mb-1 mt-4">
        <span class="flex-1">{{ $t('USERNAME') }}</span>
        <span class="text-sm c-white">{{ data.name.length || 0 }}</span>
        <span class="text-sm c-gray-400">/40</span>
      </p>
      <InputText class="w-full" maxlength="40" v-model="data.name" />
      <Button :label="$t('game.CONFIRM')" class="w-full justify-center mt-2" @click="finishNewAccount" />
    </div>
  </Dialog>
  <Dialog v-model:visible="showWeb3Wallet" :header="currentWeb3StepHeader" :draggable="false" :closable="false" modal>
    <div v-if="currentWeb3Step === 'chooseNetwork'" class="p-1 flex flex-col min-w-100">
      <div class="text-sm px-3 !rd-3 c-gray-1000 pb-4 cursor-pointer" @click="chooseChain(-1)">
        <i class="inline-block align-middle i-local-arrow-d rotate-90" />{{ $t('BACK') }}
      </div>
      <Button
        v-if="linkType.indexOf('WALLET_TON') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'ton')"
        ><img class="w-9 h-9 mr-4" src="@/assets/img/ton.svg" /> TON</Button
      >
      <Button
        v-if="linkType.indexOf('WALLET_EVM') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'eth')"
        ><div class="i-local-eth text-4xl mr-4" />
        ETH</Button
      >
      <Button
        v-if="linkType.indexOf('WALLET_EVM') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'bsc')"
      >
        <img class="w-9 h-9 mr-4" src="@/assets/img/bnb.svg" />
        BNB Chain
      </Button>
      <Button
        v-if="linkType.indexOf('WALLET_SOL') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'sol')"
        ><div class="i-local-sol text-4xl mr-4" />
        Solana</Button
      >
      <Button
        v-if="linkType.indexOf('WALLET_EVM') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'zksync')"
        ><div class="i-local-zks text-4xl mr-4" />
        ZkSync</Button
      >
      <Button
        v-if="linkType.indexOf('WALLET_EVM') == -1"
        severity="secondary"
        class="!w-full fw-semibold !pl-3 mb-3 !justify-start"
        @click="chooseChain(1, 'linea')"
        ><div class="i-local-linea text-4xl mr-4" />
        Linea</Button
      >
      <Button severity="secondary" disabled class="!w-full fw-semibold !pl-3 !justify-start"
        ><div class="i-local-web3chain text-4xl mr-4" />
        {{ $t('COMING_SOON') }}...</Button
      >
    </div>
    <div v-if="currentWeb3Step === 'chooseWallet'" class="p-1 flex flex-col min-w-100 relative">
      <div class="text-sm px-3 !rd-3 c-gray-1000 cursor-pointer" @click="chooseChain(0)">
        <i class="inline-block align-middle i-local-arrow-d rotate-90" />{{ $t('BACK') }}
      </div>
      <div class="flex flex-row justify-between mt-4">
        <div
          v-if="chain != 'ton'"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="loginWeb3('okx')"
        >
          <div class="mr-2 mb-2 p-2 rd-lg bg-black"><div class="i-local-okx-wallet size-8" /></div>
          {{ $t('X_WALLET', { v: 'OKX' }) }}
        </div>
        <div
          v-if="chain != 'ton' && chain != 'solana'"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="loginWeb3('metamask')"
        >
          <div class="i-local-metamask text-4xl mr-2 mb-2 h-12 w-12" />
          {{ $t('X_WALLET', { v: 'MetaMask' }) }}
        </div>
        <div
          v-if="chain == 'ton'"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="loginWeb3('ton-keeper')"
        >
          <div class="i-local-tonkeeper text-4xl mr-2 mb-2 h-12 w-12" />
          {{ $t('X_WALLET', { v: 'TON keeper' }) }}
        </div>
        <div
          v-if="chain == 'ton'"
          class="w-full flex flex-1 rd-lg h-40 w-32 p-2 ml-2 flex-col justify-center items-center bg-gray-700 op-30"
        >
          <i class="mr-2 mb-2 i-local-wallet text-3.2rem" /> {{ $t('COMING_SOON') }}...
        </div>
      </div>
    </div>
  </Dialog>
</template>
<style>
.p-divider-content {
  background: rgb(37, 38, 45) !important;
  color: gray;
}
</style>
