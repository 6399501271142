import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

class DateTime {
  protected value!: Dayjs;

  constructor(v: Parameters<typeof dayjs>[0]) {
    this.value = dayjs(v);
  }

  get date() {
    return this.value.toDate();
  }
  get strDateTimeShort() {
    return this.value.format('MM-DD HH:mm');
  }
  get strDatetime() {
    return this.value.format('YYYY-MM-DD HH:mm:ss');
  }
  get strDate() {
    return this.value.format('YYYY-MM-DD');
  }
  get strTime() {
    return this.value.format('HH:mm:ss');
  }
  get strYearMonth() {
    return this.value.format('YYYY.MM');
  }
  get getWeekOfMonth() {
    // const offset = 1; //周一为第一周
    const offset = 0; //周日为第一周
    const firstDay = this.value.date(1).day();
    const actualOffset = (firstDay === 0 ? firstDay : 7 - firstDay) + offset;
    let week = 0;
    let date = this.value.date();
    for (;;) {
      if (date - actualOffset <= 0) {
        return week;
      } else {
        date = date - 7;
        week = week + 1;
      }
    }
    // const date = this.value.date();
    // const firstDayOfMonth = this.value.startOf('month').day();
    // const weekOfMonth = Math.ceil((date + firstDayOfMonth - 1) / 7);
    // return weekOfMonth;
  }
  diff() {
    return this.value.fromNow();
  }
}

export function dt(v?: string | number | Date | null) {
  if (typeof v === 'number' && v < 1e10) v *= 1e3;
  return new DateTime(v);
}

export function dur(v: number) {
  let res = '';
  let data = Math.floor(v / 1e3);
  res = ((data % 60) + '').padStart(2, '0');
  data = Math.floor(data / 60);
  res = ((data % 60) + '').padStart(2, '0') + ':' + res;
  if (data < 60) return res;
  data = Math.floor(data / 60);
  res = ((data % 24) + '').padStart(2, '0') + ':' + res;
  if (data < 24) return res;
  data = Math.floor(data / 24);
  res = data + ':' + res;
  return res;
}
