export const LANGS = [
  { val: 'en', label: 'English' },
  { val: 'cn', label: '中文(简体)' },
  { val: 'tc', label: '中文(繁體)' },
  { val: 'ru', label: 'Pyссkий' },
  { val: 'jp', label: '日本語' },
  { val: 'kr', label: '한국어' },
  { val: 'vn', label: 'Tiếng Việt' },
];

export const cmsLabelMapping: { [key: string]: string } = {
  cn: 'zh',
  // jp: 'ja',
  // kr: 'ko',
  // ru: 'ru',
  // vn: 'vi',
  tc: 'zh-Hant-TW',
};

export const selectLangLabel = (val: string) => (LANGS.find((lang) => lang.val === val) || {}).label;
