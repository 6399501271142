<template>
  <!-- <ReactTest /> -->
  <RouterView :key="routerIdx" />
  <Toast position="top-right" />
  <ConfirmDialog :draggable="false" />
  <DPGuide v-if="beginnerGuide != undefined" :value="beginnerGuide" />
  <ADialog class="w-110 p-6" v-if="!token" v-model="showLogin" ignoreMask :title="$t('LOGIN_REGISTER') + ' LePoker'">
    <DPLogin @success="showLogin = false" />
  </ADialog>
  <DPBindAccount v-if="showBindAccount > 0" class="w-100" />
  <Dialog class="w-100" modal :closable="false" :visible="showLoading" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
    </div>
  </Dialog>
  <DPLanguage />
  <APopPad />
  <AToast />
  <div v-if="user?.id && showTaskEntry" class="fixed bottom-28 right-4 z-5 cursor-pointer" @click="openTask()">
    <img class="size-16" src="@/assets/img/task-center-entry.png" />
    <div
      v-if="
        taskRedDot.dailyTask ||
        taskRedDot.diamondTask ||
        taskRedDot.experienceTask ||
        taskRedDot.levelTask ||
        taskRedDot.signTask
      "
      class="absolute top-1 right-0 rd-full size-3 bg-red"
    />
  </div>
</template>
<script setup lang="ts">
// i-local-club i-local-spade i-local-diamond i-local-heart
// i-local-card-a i-local-card-2 i-local-card-3 i-local-card-4 i-local-card-5 i-local-card-6 i-local-card-7 i-local-card-8 i-local-card-9 i-local-card-t i-local-card-j i-local-card-q i-local-card-k
import { onMounted, onUnmounted, watch, defineAsyncComponent } from 'vue';
import { height, width, landscape, showLogin, showBindAccount, showLoading, taskRedDot } from './stores';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

import router, { routerIdx } from './router';
import { token } from './utils/network';
import { useI18n } from 'vue-i18n';

import { useDialog } from '@/components/ui/APopPad.vue';
import { beginnerGuide } from './stores/user';
import { user, showTaskEntry } from '@/stores';

const DPGuide = defineAsyncComponent(() => import('@/components/DPGuide.vue'));

const { t } = useI18n();

watch(
  () => [width.value, height.value, router.currentRoute.value.name] as [number, number, string?],
  ([width, height, r]) => {
    let fs = 0;
    if (width > height) fs = Math.min(height, (width * 2) / 3) / 52;
    else fs = Math.max(Math.min(width, height / 2), width / 1.3) / 31.25;
    if (r?.startsWith('game')) document.documentElement.style.fontSize = fs + 'px';
    else if (landscape.value) document.documentElement.style.fontSize = '16.8px';
    else document.documentElement.style.fontSize = (width / 375) * 12 + 'px';
  },
  { immediate: true },
);

let lastTouchEnd = 0;
const doubleFinger = (event: TouchEvent) => {
  if (event.touches.length > 1) {
    event.preventDefault();
  }
};

const doubleClickZoom = (event: TouchEvent) => {
  let now = new Date().getTime();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
};

onMounted(() => {
  /* 对于Safari浏览器来说，无法通过meta属性限制其缩放，可以在App.vue中添加如下代码，实现缩放禁止，主要原理是：禁止两指以上的触摸事件（因为缩放需要两指操作），禁止手势事件的识别 */
  window.onload = function () {
    // 禁止双指缩放
    window.addEventListener('touchstart', doubleFinger);
    // 禁止ios 双击缩放
    window.addEventListener('touchend', doubleClickZoom, false);
  };
});

onUnmounted(() => {
  window.removeEventListener('touchstart', doubleFinger);
  window.removeEventListener('touchend', doubleClickZoom, false);
});

const popup = useDialog();
let lastUpdate = Infinity;
const askReload = () =>
  popup.alert({
    title: t('NEW_VERSION_CONFIRM_TITLE'),
    content: t('NEW_VERSION_CONFIRM_CONTENT'),
    btn: t('RELOAD_AND_UPDATE'),
    action: () => location.reload(),
    unclosable: true,
  });
router.beforeEach(() => {
  fetch('/', { method: 'HEAD' }).then((res) => {
    const date = new Date(res.headers.get('last-modified') || 0).getTime();
    if (date > lastUpdate) askReload();
    lastUpdate = date;
  });
});

window.addEventListener('vite:preloadError', askReload);

//geetest用到
declare global {
  interface Window {
    [propName: string]: never;
  }
}

const openTask = () => {
  router.push('/profile/task/daily');
};
</script>
