<template>
  <slot>
    <Button :label="$t(id ? 'VIEW' : 'SET')" size="small" @click="showBlinds = true" />
  </slot>
  <Dialog
    v-model:visible="showBlinds"
    maximizable
    modal
    :header="$t('mtt.BLIND_STRUCTURE')"
    :style="{ width: landscape ? '50rem' : '30rem' }"
  >
    <DPBlindTable :duration="duration" v-model="data" :id="id" />
  </Dialog>
</template>
<script setup lang="ts">
import { landscape } from '@/stores';
import type { BlindStructure } from '@/types';
import { ref } from 'vue';
defineProps<{ duration: number; id?: string }>();
const data = defineModel<BlindStructure[]>();
const showBlinds = ref(false);
</script>
