<template>
  <div class="flex c-black fw-bold">
    <DPCountdownNumber class="mr-.7em" v-if="data.day" :value="data.day" />
    <template v-if="data.day || data.hour">
      <DPCountdownNumber :value="data.hour" />
      <div class="colon" />
    </template>
    <DPCountdownNumber :value="data.min" />
    <div class="colon" />
    <DPCountdownNumber :value="data.sec" />
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{ value: number }>();

const data = computed(() => {
  const v = props.value > 0 ? Math.round(props.value) : 0;
  return {
    sec: v % 60,
    min: Math.floor((v / 60) % 60),
    hour: Math.floor((v / 3600) % 24),
    day: Math.floor(v / 3600 / 24),
  };
});
</script>
<style>
.colon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5em 0.2em;
}
.colon::after,
.colon::before {
  content: '';
  display: block;
  border-radius: 100%;
  width: 0.2em;
  height: 0.2em;
  background: var(--gray-200);
}
</style>
