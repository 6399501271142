<script setup lang="ts">
import { authAPI } from '@/utils/network';
import { ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { convertToLangeLabel } from '@/utils/strings';

type User = {
  userID: string;
  userAvatarUrl: string;
  userName: string;
};

const i18n = useI18n();
const toast = useToast();

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    mttId: string;
    mttName: string;
  }>(),
  { modelValue: false },
);

const emit = defineEmits(['update:modelValue', 'refresh']);

const modelVisible = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const transferUser = ref<User | undefined>(undefined);
const username = ref('');
const ticketRedeemStatus = ref(0); // 0 -- normal, 1 -- success
const errorText = ref('');
const userList = ref<User[]>([]);

const searchUser = async () => {
  if (!username.value) return;
  const result = await authAPI.get('v1/user/query_user_by_name', { userName: username.value });
  userList.value = result;
  if (!result.length) errorText.value = i18n.t('NO_USER_FOUND');
};

const ok = () => {
  if (!transferUser.value) return;
  authAPI
    .post('v1/mtt/transfer_mtt_owner', { mttId: props.mttId, transferUserId: transferUser.value.userID })
    .then(() => {
      toast.add({
        severity: 'success',
        summary: i18n.t('SUCCESS'),
        detail: i18n.t('mtt.OWNER_TRANSFER_SUCCESS', {
          mttName: props.mttName,
          username: transferUser?.value?.userName,
        }),
        life: 3000,
      });

      username.value = '';
      userList.value = [];
      transferUser.value = undefined;
      modelVisible.value = false;
      emit('refresh');
    })
    .catch((result) => {
      toast.add({
        severity: 'error',
        summary: i18n.t('FAIL'),
        detail: i18n.t(convertToLangeLabel(result.msg)),
        life: 3000,
      });
    })
    .finally(() => {});
};

watch(username, () => {
  errorText.value = '';
});
</script>
<template>
  <Dialog :draggable="false" class="w-100" modal v-model:visible="modelVisible" :header="$t('mtt.TRANSFER_OWNER')">
    <div class="px-4 pb-4 space-y-4">
      <div class="text-center op-70">{{ $t('mtt.TRANSFER_OWNER_INFO') }}</div>
      <div class="c-white">{{ $t('mtt.TRANSFER_OWNER_TO') }}</div>
      <div class="relative" v-if="!transferUser">
        <AInput class="w-full" :placeholder="$t('SEARCH_BY_USERNAME')" v-model="username" @change="searchUser" />
        <div class="c-red" v-show="!!errorText">
          {{ errorText }}
        </div>
        <div class="absolute z-10 bg-gray-700 w-full rd-md">
          <div
            class="p-2 items-center flex"
            v-for="item in userList"
            @click="transferUser = item"
            v-bind:key="item.userID"
          >
            <DPAvatar class="text-xl mr-2" :name="item.userName" :avatar="item.userAvatarUrl" />
            {{ item.userName }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="bg-gray-700 p-2 items-center flex justify-between rd-md animate-slide-in-up animate-duration-75"
      >
        <div class="items-center flex">
          <DPAvatar class="text-xl mr-2" :name="transferUser.userName" :avatar="transferUser.userAvatarUrl" />
          {{ transferUser.userName }}
        </div>
        <div class="i-local-close text-xl c-gray" @click="transferUser = undefined" />
      </div>

      <div class="flex justify-between pt-2 space-x-4">
        <Button class="flex-1" severity="secondary" @click="ticketRedeemStatus = 0" :label="$t('CANCEL')" />
        <Button class="flex-1" @click="ok" :label="$t('TRANSFER')" :disabled="!transferUser" />
      </div>
    </div>
  </Dialog>
</template>
