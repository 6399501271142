import MainPage from './MainPage.vue';
import MallPage from './MallPage.vue';
import MallRecordPage from './MallRecordPage.vue';
import MallProductPage from './MallProductPage.vue';
import TablesPage from './TablesPage.vue';
import RankPage from './RankPage.vue';

export default [
  {
    path: 'rg',
    component: MainPage,
    meta: { showAssets: true, title: 'RING_GAMES' },
    children: [
      {
        path: '',
        name: 'ring-tables',
        component: TablesPage,
      },
      {
        path: 'rank',
        name: 'ring-rank',
        component: RankPage,
      },
    ],
  },
  {
    path: 'mall',
    component: MallPage,
    meta: { title: 'MALL' },
    children: [
      {
        path: ':type',
        name: 'mall-list',
        component: MallProductPage,
        props: true,
      },
      {
        path: 'record',
        name: 'mall-record',
        component: MallRecordPage,
      },
    ],
  },
];
