<template>
  <div class="of-auto pb-4">
    <div class="flex justify-around mb-4" un-children="text-center flex-1 p-2 b-2 b-b-solid cursor-pointer">
      <h5
        :class="
          $route.name === 'sng-main' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'
        "
        @click="$router.replace('/s')"
      >
        {{ $t('TOURNAMENT') }}
      </h5>
      <h5
        :class="
          $route.matched.some((v) => v.name === 'sng-rank')
            ? 'b-green bg-gradient-to-t from-green-900 c-white'
            : 'c-gray-300 b-transparent'
        "
        @click="$router.replace('/s/rank')"
      >
        {{ $t('mtt.RANKING') }}
      </h5>
      <h5
        :class="
          $route.name === 'sng-history' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'
        "
        @click="$router.replace('/s/history')"
      >
        {{ $t('GAME_HISTORY') }}
      </h5>
    </div>
    <RouterView />
  </div>
</template>
<script lang="ts" setup>
import type { SNG } from '@/types';
import { useTopic } from '@/utils/stomp';
import { provide, ref } from 'vue';

const detail = ref<SNG | null>();

useTopic('/user/sng', (data, client) => {
  if (data.action === 'refresh') client.publish({ destination: '/sng/currentSNGInfo', body: '' });
  else detail.value = data.payload;
});

provide('sng', detail);
</script>
