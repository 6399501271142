import Web3 from 'web3';
import { MetaMaskSDK, SDKProvider } from '@metamask/sdk';

// ------------------ constant config ------------------
type NetworkConfig = {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
};

type NetworkConfigs = {
  [key: string]: NetworkConfig;
};

const networkConfigs: NetworkConfigs = {
  '0x61': {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  '0x38': {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://bsc-rpc.publicnode.com'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  '0x310c5': {
    chainId: '0x310c5',
    chainName: 'Bitlayer',
    nativeCurrency: {
      name: 'Bitlayer',
      symbol: 'BTC', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://rpc.bitlayer.org'],
    blockExplorerUrls: ['https://www.btrscan.com'],
  },
};

const rewardAbi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_poolId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_rank',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '_signature',
        type: 'bytes',
      },
    ],
    name: 'claimReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const netMap = JSON.parse(import.meta.env.VITE_NETWORK_MAP || '');

// ------------------ static utils ------------------
export const generateExplorerUrl = (transactionHash: string, chainName: string) => {
  const chainId = netMap[chainName] ? '0x' + parseInt(netMap[chainName]).toString(16) : '';
  if (!chainId || !networkConfigs[chainId]) {
    throw new Error('network not found');
  }
  const blockExplorerUrl = networkConfigs[chainId].blockExplorerUrls[0];

  return `${blockExplorerUrl}/tx/${transactionHash}`;
};

export const getTokenName = (chainName: string) => {
  const chainId = netMap[chainName] ? '0x' + parseInt(netMap[chainName]).toString(16) : '';
  if (!chainId || !networkConfigs[chainId]) {
    throw new Error('network not found');
  }

  return networkConfigs[chainId].nativeCurrency.symbol;
};

const getRpcUrl = (chainName: string): string => {
  const chainId = netMap[chainName] ? '0x' + parseInt(netMap[chainName]).toString(16) : '';
  if (!chainId || !networkConfigs[chainId]) {
    throw new Error('network not found');
  }
  return networkConfigs[chainId].rpcUrls[0];
};

// ------------------ init ethereum provider ------------------
let ethereum: SDKProvider | undefined;

const metamask = new MetaMaskSDK({
  checkInstallationOnAllCalls: true,
  dappMetadata: {
    name: 'LePoker',
    url: window.location.href,
  },
  i18nOptions: {
    enabled: true,
  },
  useDeeplink: true,
  openDeeplink: (link: string) => {
    window.location.href = link;
  },
  extensionOnly: true,
});

const initProvider = async () => {
  if (!ethereum) {
    await metamask.init();
    ethereum = metamask.getProvider();
  }

  if (!ethereum) throw new Error('Ethereum provider is not initialized');
};

// ------------------ on chain data ------------------
export const getNativeBalance = async (address: string, chainName: string) => {
  const rpcUrl = getRpcUrl(chainName);
  const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

  try {
    // Fetch the balance in Wei
    const balanceWei = await web3.eth.getBalance(address);
    // Convert the balance from Wei to Ether
    return web3.utils.fromWei(balanceWei, 'ether');
  } catch (error: any) {
    throw new Error(`Failed to fetch balance: ${error.message}`);
  }
};

export const watchTransaction = async (transactionHash: string, chainName: string): Promise<boolean> => {
  const rpcUrl = getRpcUrl(chainName);
  const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));

  return new Promise((resolve, reject) => {
    const interval = setTimeout(async () => {
      try {
        const receipt = await web3.eth.getTransactionReceipt(transactionHash);
        if (receipt) {
          clearInterval(interval);
          if (receipt.status) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    }, 1000); // Poll every second
  });
};

// ------------------ plugin functions ------------------
export const connectAndFetchAddress = async (): Promise<string> => {
  await initProvider();
  try {
    // Check if MetaMask has already been authorized
    const accounts = await ethereum!.request<string[]>({ method: 'eth_accounts' });
    if (accounts && accounts.length > 0) {
      return accounts[0] || '';
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};

export const requestConnect = async (): Promise<string> => {
  try {
    await metamask.connect();
    await initProvider();
  } catch (error: any) {
    throw new Error('Please install metamask');
  }

  const accounts = await ethereum!.request<string[]>({ method: 'eth_requestAccounts', params: [] });
  if (accounts && accounts.length > 0 && accounts[0]) {
    return accounts[0];
  } else {
    throw new Error('Cannot connect to metamask');
  }
};

export const signMessage = async (address: string, message: string): Promise<string> => {
  await initProvider();

  const signature = await ethereum!.request<string>({
    method: 'personal_sign',
    params: [message, address],
  });
  if (signature) return signature;
  else throw new Error('Failed to sign message');
};

export const callClaim = async (
  poolId: number,
  rank: number,
  signature: string,
  address: string,
  contractAddress: string,
) => {
  await initProvider();

  // Initialize Web3 with the MetaMask provider
  const web3 = new Web3(ethereum);
  const contract = new web3.eth.Contract(rewardAbi, contractAddress);
  const result = await contract.methods.claimReward(poolId, rank, signature).send({ from: address });
  return result.transactionHash;
};

export const checkNetwork = async (network: string) => {
  await initProvider();

  const chainId = netMap[network] ? '0x' + parseInt(netMap[network]).toString(16) : '';
  if (!chainId || !networkConfigs[chainId]) {
    throw new Error('network not found');
  }

  const pluginChainId = await ethereum!.request<string>({ method: 'eth_chainId' });
  if (pluginChainId !== chainId) {
    try {
      await window.ethereum!.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902 || switchError.code === -32603) {
        try {
          // Here, you would specify the network's parameters, like chainName, rpcUrls, and blockExplorerUrls.
          // This is an example for adding the Binance Smart Chain.
          await ethereum!.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfigs[chainId]],
          });
          console.log(`Added and switched to network ${chainId}`);
        } catch (addError: any) {
          throw new Error('Failed to add the network:' + addError.message);
        }
      } else {
        throw new Error('Failed to switch the network:' + switchError.message);
      }
    }
  }
};
