import { authAPI } from '@/utils/network';

type Account = {
  userAvatarUrl: string;
  countryCode: string;
  userName: string;
  userTitle: string;
  seed?: string;
};

export const users: Record<string, Promise<Account | undefined>> = {};

export const loadUser = (id: string) => {
  if (!users[id]) {
    users[id] = authAPI.get<Account>('v1/user/account', { userID: id }).catch(() => {
      delete users[id];
      return undefined;
    });
  }
  return users[id];
};
