import { loginSocialV2, checkAccountStatus, socialLink } from '@/utils/user';
import { showBindAccount, socialToken, linkType, linkUserId, linkUserName, linkAvatar } from '@/stores';
import { botAPI } from '@/utils/network';
import { isIOS, isAndroid, isMobile, isOKApp } from '@/utils/device';
import Web3 from 'web3';
import bs58 from 'bs58';
import { checkNetwork } from './claimOnChainReward';
import { networkConfigs, setUserAddress } from './wallet';

const okx = (window as any).okxwallet;

export const okxLogin = async (chain: string, bindType = 'login') => {
  let address = '';
  let walletType = '';

  if (chain == 'eth') {
    address = await getOKXEVMToken('eth');
    walletType = 'WALLET_EVM#ETH';
  } else if (chain == 'bsc') {
    address = await getOKXEVMToken('bsc');
    walletType = 'WALLET_EVM#BSC';
  } else if (chain == 'zksync') {
    address = await getOKXEVMToken('zksync');
    walletType = 'WALLET_EVM#ZKS';
  } else if (chain == 'sol') {
    address = await getOKXSOLToken();
    walletType = 'WALLET_SOL#SOL';
  } else if (chain == 'linea') {
    address = await getOKXEVMToken('linea');
    walletType = 'WALLET_EVM#LINEA';
  }
  if (address) {
    if (bindType == 'link') {
      await socialLink(address, address, '', walletType);
      setUserAddress(chain, address);
      return true;
    } else {
      if (await checkAccountStatus(socialToken.value)) {
        await loginSocialV2(socialToken.value);
        return true;
      } else {
        if (bindType == 'bind') {
          showBindAccount.value = 3;
          return false;
        } else {
          linkType.value = walletType;
          linkUserId.value = address;
          linkUserName.value = address;
          linkAvatar.value = '';
          showBindAccount.value = 1;
          return true;
        }
      }
    }
  } else {
    return false;
  }
};

export const makeOKXEVMTransaction = async (chainId: string) => {
  if ((isIOS || isAndroid || isMobile) && !isOKApp) {
    const encodedUrl =
      'https://www.okx.com/download?deeplink=' +
      encodeURIComponent('okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href));
    window.location.href = encodedUrl;
  } else if (okx) {
    try {
      await okx.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await okx.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfigs[chainId]],
          });
        } catch (addError) {
          console.info(addError);
          throw addError;
        }
      } else {
        throw switchError;
      }
    }
    try {
      const accounts = await okx.request({ method: 'eth_requestAccounts' });
      const result = await okx.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: accounts[0],
            to: accounts[0],
            value: '0x0',
            chainId: chainId,
          },
        ],
      });
      console.log(result);
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  } else {
    window.open('https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge', '_target');
  }
  return false;
};

export const getOKXEVMToken = async (chain: string) => {
  if ((isIOS || isAndroid || isMobile) && !isOKApp) {
    const encodedUrl =
      'https://www.okx.com/download?deeplink=' +
      encodeURIComponent('okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href));
    window.location.href = encodedUrl;
  } else if (okx) {
    const date = new Date();
    const msg = 'Welcome to LePoker.io. Please sign this message to login.\n\nTimestamp:' + date.getTime();
    const accounts = await okx.request({ method: 'eth_requestAccounts' });
    const okxWallet = new Web3(okx);
    const signature = await okxWallet.eth.personal.sign(msg, accounts[0], '123456');
    const { token } = await botAPI.post<{ token: string }>('wallet/okx/token', {
      address: accounts[0],
      signature,
      message: msg,
      type: chain,
    });
    socialToken.value = token;
    return accounts[0];
  } else {
    window.open('https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge', '_target');
  }
};

export const getOKXSOLToken = async () => {
  if ((isIOS || isAndroid || isMobile) && !isOKApp) {
    const encodedUrl =
      'https://www.okx.com/download?deeplink=' +
      encodeURIComponent('okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href));
    window.location.href = encodedUrl;
  } else if (okx) {
    const date = new Date();
    const msg = 'Welcome to LePoker.io. Please sign this message to login.\n\nTimestamp:' + date.getTime();
    const provider = okx.solana;
    const resp = await provider.connect();
    const publicKey = resp.publicKey.toString();
    // 26qv4GCcx98RihuK3c4T6ozB3J7L6VwCuFVc7Ta2A3Uo
    // { address: string, publicKey: string }
    const encodedMessage = new TextEncoder().encode(msg);
    const signedMessage = await provider.signMessage(encodedMessage, 'utf8');
    const { token } = await botAPI.post<{ token: string }>('wallet/okx/token', {
      address: publicKey,
      signature: bs58.encode(signedMessage.signature),
      message: msg,
      type: 'sol',
    });
    socialToken.value = token;
    return publicKey;
  } else {
    window.open('https://chromewebstore.google.com/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge', '_target');
  }
};
