<template>
  <ATitle class="fw-bold text-2xl mb-4">{{ $t('GAMES') }}</ATitle>
  <div class="flex mb-4" portrait="flex-col">
    <div class="flex-1 mb-4" un-children="bg-gray-900 rd-lg b-solid b-gray-700">
      <div class="rd-lg bg-gray-800 p-2.5 flex items-center b-solid b-gray-700 mb-4">
        <div class="i-local-flash c-green size-8 mr-2"></div>
        <div class="mr-a">{{ $t('QUICKLY_JOIN_A_RING_GAME') }}</div>
        <AButton class="lh-none" @click="quickGame()">{{ $t('QUICK_PLAY') }}</AButton>
      </div>
      <div
        class="rd-lg h-44 col-span-2 p-4 bgi-lobby-ring-bg bg-no-repeat bg-right-bottom bg-contain cursor-pointer"
        @click="emits('jump', '/rg')"
      >
        <div class="flex items-center b-solid b-gray-700 rd-lg bg-dark-900 w-max c-gray-200 px-2 text-base">
          <span class="size-2 rd-full bg-green" />
          <span class="c-green fw-bold mx-1">{{ formatNumber(gameInfo.ring) }}</span>
          {{ $t('PLAYING') }}
        </div>
        <h2 class="text-3xl my-1">{{ $t('RING_GAMES') }}</h2>
        <div class="c-gray text-base">{{ $t('COINS_FOR_POKER_GAMES') }}</div>
      </div>
    </div>
    <div
      class="flex"
      landscape="flex-col w-94 ml-4"
      un-children="flex-1 bg-gray-900 rd-lg  pt-4 pl-4 mb-4 b-solid bg-no-repeat bg-right-bottom bg-contain b-gray-700 cursor-pointer landscape:bg-[size:11rem]"
    >
      <div class="bgi-lobby-mtt-bg" portrait="h-58" @click="emits('jump', '/m', true)">
        <div class="flex items-center b-solid b-gray-700 rd-lg bg-dark-900 w-max c-gray-200 px-2 text-base">
          <span class="size-2 rd-full bg-green" />
          <span class="c-green fw-bold mx-1">{{ formatNumber(gameInfo.mtt) }}</span>
          {{ $t('PLAYING') }}
        </div>
        <h2 class="text-2xl">{{ $t('TOURNAMENT') }}</h2>
        <div class="c-gray text-base">{{ $t('WIN_GREAT_PRIZE') }}</div>
      </div>
      <div class="bgi-lobby-sng-bg" portrait="h-58 ml-4" @click="emits('jump', '/s')">
        <div class="flex items-center b-solid b-gray-700 rd-lg bg-dark-900 w-max c-gray-200 px-2 text-base">
          <span class="size-2 rd-full bg-green" />
          <span class="c-green fw-bold mx-1">{{ formatNumber(gameInfo.sng.play) }}</span>
          {{ $t('PLAYING') }}
        </div>
        <h2 class="text-2xl flex items-center">
          {{ $t('home.NAV_3') }}
          <span class="c-green b-solid rd-lg ml-2 px-1 text-lg fw-extrabold bg-black">{{ gameInfo.sng.season }}</span>
        </h2>
        <div class="c-gray text-base">{{ gameInfo.sng.dur }}</div>
      </div>
    </div>
  </div>
  <ATitle class="fw-bold text-2xl mb-4">{{ $t('MY_GAMES_AND_TOURNAMENTS') }}</ATitle>
  <div v-if="!rooms.length" class="text-center pt-8">
    <img class="w-46" src="@/assets/img/no-data-0.png" alt="" />
    <div class="c-gray">{{ $t('NO_GAME_IN_PROGRESS') }}</div>
  </div>
  <div class="grid gap-4 grid-cols-1" landscape="grid-cols-2">
    <div v-for="(room, i) in rooms" :key="i" class="flex rd-lg bg-gray-900 p-4">
      <div
        class="rd-lg p-3 size-40 bg-dark flex flex-col items-center bg-cover"
        :class="room.gameType === 'mtt' ? 'bgi-mtt-card-bg' : 'bgi-table-card-bg'"
      >
        <template v-if="room.gameType === 'golden'">
          <h5 class="rd-lg bg-black/70 px-2 c-yellow italic">HOLD'EM</h5>
          <p>{{ $t('mtt.BLINDS') }}</p>
          <h5>{{ room.blindStructure.smallBlind }}/{{ room.blindStructure.bigBlind }}</h5>
          <div class="flex items-center mt-a c-green">
            <span class="size-2 rd-full bg-current mr-1" />{{ $t('IN_PLAY') }}
          </div>
        </template>
        <template v-else-if="room.gameType === 'mtt'">
          <img class="rd-full size-23" :src="room.tableLogoUrl" alt="" />
          <h5 class="italic c-yellow lh-tight">MTT</h5>
          <div v-if="room.gameStatus" class="flex items-center mt-a c-green">
            <span class="size-2 rd-full bg-current mr-1" />{{ $t('IN_PLAY') }}
          </div>
          <div v-else-if="room.userStatus === 5" class="mt-a">{{ $t('FINISHED') }}</div>
          <p v-else class="mt-a -mx-3 text-center">{{ $t('START_AT') }} {{ dt(room.startTime).strDateTimeShort }}</p>
        </template>
        <template v-else-if="room.gameType === 'private'">
          <h5 class="rd-lg bg-black/70 px-2 c-yellow italic">PRIVATE</h5>
          <p>{{ $t('mtt.BLINDS') }}</p>
          <h5>
            {{ room.blindStructure.smallBlind }}/{{ room.blindStructure.bigBlind }}({{ room.blindStructure.ante }})
          </h5>
          <div class="flex items-center mt-a c-green">
            <span class="size-2 rd-full bg-current mr-1" />{{ $t('IN_PLAY') }}
          </div>
        </template>
        <template v-else-if="room.gameType === 'sng'">
          <h5 class="rd-lg bg-black/70 px-2 c-yellow italic">SNG</h5>
          <p>{{ $t('CURRENT_LEVEL') }}</p>
          <h5>{{ room.blindStructure.smallBlind }}/{{ room.blindStructure.bigBlind }}</h5>
          <div v-if="room.gameStatus" class="flex items-center mt-a c-green">
            <span class="size-2 rd-full bg-current mr-1" />{{ $t('IN_PLAY') }}
          </div>
          <p v-else-if="room.startTime" class="mt-a -mx-3 text-center">
            {{ $t('START_AT') }} {{ dt(room.startTime).strDateTimeShort }}
          </p>
          <div v-else class="mt-a">{{ $t('WAITING') }}</div>
        </template>
      </div>
      <div class="flex-1 ml-4 flex flex-col justify-between of-hidden">
        <h3 v-if="room.gameType === 'sng'" class="truncate">LePoker Ladder Game</h3>
        <h3 v-else class="truncate">{{ room.gameName }}</h3>
        <div class="text-base" un-children="flex justify-between items-center">
          <template v-if="room.gameType === 'golden'">
            <div>
              {{ $t('MIN_MAX_BUYIN') }}
              <img class="size-4 ml-a" src="@/assets/img/coin.png" />
              <b>{{ room.minBuyIn }}-{{ room.maxBuyIn }}</b>
            </div>
            <div>
              {{ $t('TABLE_SIZE') }}<span class="size-4 i-local-user ml-a" />
              <b>{{ room.seatedPlayerNumber }}/{{ room.maxPlayerNumber }}</b>
            </div>
          </template>
          <template v-else-if="room.gameType === 'mtt'">
            <div>
              {{ $t('REMAINING_ENTRIES') }} <b>{{ room.leftPlayers }}/{{ room.firstEntries }}</b>
            </div>
            <div>
              {{ $t('mtt.PRIZE_POOL') }}

              <b v-if="room.prizePool.prizePoolType === 3">-</b>
              <b v-else-if="room.prizePool.prizePoolValue > 0">
                {{ room.prizePool.prizePoolValue }} {{ $t('mtt.PLAYERS') }}
              </b>
              <b v-else>
                {{ room.prizePool.prizePoolRate }}%/{{
                  Math.ceil((room.prizePool.prizePoolRate * room.firstEntries) / 100)
                }}
                {{ $t('mtt.PLAYERS') }}
              </b>
            </div>
            <div>
              {{ $t('BUYIN') }} <b>{{ $t(regModeMap[room.regApprovalMode]) }}</b>
            </div>
          </template>
          <template v-else-if="room.gameType === 'private'">
            <div>
              {{ $t('BUYIN') }} <b>{{ room.minBuyIn }}-{{ room.maxBuyIn }}</b>
            </div>
            <div>
              {{ $t('SEATED_PLAYERS') }}<span class="size-4 i-local-user ml-a" />
              <b>{{ room.seatedPlayerNumber }}/{{ room.maxPlayerNumber }}</b>
            </div>
          </template>
          <template v-else-if="room.gameType === 'sng'">
            <div>
              {{ $t('REMAININGS') }}<span class="size-4 i-local-user ml-a" />
              <b>{{ room.seatedPlayerNumber }}/{{ room.maxPlayerNumber }}</b>
            </div>
          </template>
        </div>
        <AButton class="w-full text-base" @click="openRoom(room)">{{ $t('GO_TO_TABLE') }}</AButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useDialog } from '@/components/ui/APopPad.vue';
import { toast } from '@/components/ui/AToast.vue';
import { showLoading, showLogin, showTaskEntry, user } from '@/stores';
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { formatNumber } from '@/utils/strings';
import { computed, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const dialog = useDialog();
const { t } = useI18n();

const emits = defineEmits<{ jump: [string, boolean?] }>();

const regModeMap = ['mtt.REVIEW_ORG', 'mtt.REVIEW_AUTO', 'mtt.REVIEW_TICKET'];

const gameInfo = ref({ ring: 0, mtt: 0, sng: { season: '-', dur: '-', play: 0 } });

authAPI.get('v1/lobby/game').then((v) => {
  gameInfo.value = {
    ring: v.ringGames.players,
    mtt: v.mtt.players,
    sng: { season: v.sng.dropName, dur: `${v.sng.startDate}~${v.sng.endDate}`, play: v.sng.players },
  };
});

const ringRooms = ref<any[]>([]);
const mttRooms = ref<any[]>([]);
const sngRooms = ref<any[]>([]);
const privateRooms = ref<any[]>([]);

const rooms = computed(() => [...ringRooms.value, ...mttRooms.value, ...sngRooms.value, ...privateRooms.value]);

authAPI.get('v1/lobby/ring_table').then((v) => (ringRooms.value = v));
authAPI.get('v1/lobby/mtt_table').then((v) => (mttRooms.value = v));
authAPI.get('v1/lobby/sng_table').then((v) => (sngRooms.value = v));
authAPI.get('v1/lobby/private_table').then((v) => (privateRooms.value = v));

const openRoom = (v: any) => {
  if (v.gameType === 'golden') emits('jump', '/g/g/' + v.gameId);
  if (v.gameType === 'sng') {
    if (v.gameStatus) emits('jump', '/g/s/' + v.gameId);
    else emits('jump', '/s');
  }
  if (v.gameType === 'mtt') {
    if (v.gameStatus && v.userStatus !== 5) emits('jump', '/g/m/' + v.gameId);
    else emits('jump', '/m/' + v.gameId);
  }
  if (v.gameType === 'private') emits('jump', '/g/p/' + v.gameId);
};

const quickGame = (index?: number, level?: number) => {
  if (!user.value) return (showLogin.value = true);
  showLoading.value = true;
  authAPI
    .post('v1/goldCoinTable/quickPlay', { index, level }, undefined, undefined, { timeout: 3e5 })
    .then(({ roomId, buyInAmount }) => {
      if (buyInAmount) toast.success(t('BUY_IN_CHIPS_AUTOMATICALLY', [buyInAmount]));
      emits('jump', `/g/g/${roomId}`);
    })
    .catch((e) => {
      if (e.code === 1203)
        return dialog.confirm({
          title: t('INSUFFICIENT_COINS'),
          content: t('YOUR_BALANCE_CANT_MEET_MIN_BUYIN'),
          btn: t('GET_COINS'),
          action: () => emits('jump', '/mall/coin'),
        });
    })
    .finally(() => {
      showLoading.value = false;
    });
};

showTaskEntry.value = true;
onUnmounted(() => {
  showTaskEntry.value = false;
});
</script>
