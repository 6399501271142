<template>
  <div
    v-for="v in data"
    :key="v.id"
    :class="v.isClaim ? 'bg-gray-900/30' : ''"
    class="relative rd-lg of-hidden flex items-center p-2 bg-[#353741]"
  >
    <i class="relative rd-lg z-100 bg-red" />
    <div class="flex-1 m-2 space-y-2">
      <div class="flex justify-between">
        <p class="break-words text-xl w-full">{{ $t(v.contentKey, { v: v.taskLadder, vstart: v.taskLadder * 50 }) }}</p>
        <div v-if="v.taskLadder > 0" class="c-white text-xl ml-1">
          <span :class="v.times >= v.taskLadder ? 'c-green' : ''">{{
            v.times > v.taskLadder ? v.taskLadder : v.times
          }}</span
          >/{{ v.taskLadder }}
        </div>
      </div>
      <div class="flex justify-between items-end">
        <div class="flex flex-grid space-x-2 items-center justify-center">
          <img v-if="v.prizeGold > 0" src="@/assets/img/gold-coin.png" class="h-6 mt-1" />
          <span v-if="v.prizeGold > 0" class="fw-700 text-2xl c-green">{{ v.prizeGold }}</span>
          <img v-if="v.prizeDiamond > 0" src="@/assets/img/task-diamond.png" class="h-6 mt-1" />
          <span v-if="v.prizeDiamond > 0" class="fw-700 text-2xl c-green">{{ v.prizeDiamond }}</span>
          <img v-if="v.prizeExperience > 0" src="@/assets/img/task-experience.png" class="h-6" />
          <span v-if="v.prizeExperience > 0" class="fw-700 text-2xl c-green">{{ v.prizeExperience }}</span>
        </div>
        <div class="text-right mt-2">
          <AButton v-if="!v.isFinish && v.taskUrl" class="w-20 text-sm" outlined @click="openUrl(v.taskUrl)">{{
            $t('GO')
          }}</AButton>
          <AButton v-else-if="v.isFinish && !v.isClaim" class="w-20 text-sm" @click="claimTask(v)">{{
            $t('CLAIM')
          }}</AButton>
          <div v-else-if="v.isClaim" class="i-local-completed text-4xl bg-green"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PlatformTask } from '@/types';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import { toast } from '@/components/ui/AToast.vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';

const emits = defineEmits<{ callback: any }>();
const props = defineProps<{ data: PlatformTask[] }>();

const taskData = ref<PlatformTask>();

const { t } = useI18n();

const openUrl = (url: string) => {
  if (url.indexOf('http') >= 0) {
    window.open(url);
  } else {
    if (url == 'CONNECT_TG') {
      toast.info(t('TASK_CONNECT_TG_TOAST'));
    } else if (url == 'CONNECT_DISCORD') {
      toast.info(t('TASK_CONNECT_DISCORD_TOAST'));
    } else {
      router.push(url);
    }
  }
};

const claimTask = async (task: PlatformTask) => {
  taskData.value = task;
  const params = {
    taskKey: task.taskKey,
    taskId: task.id,
    finishId: task.finishId,
  };
  await authAPI
    .post('v1/task/claimTaskPrize', params)
    .then((v) => {
      emits('callback', v);
    })
    .catch((e: any) => {
      toast.error(e.msg);
    });
};
</script>
