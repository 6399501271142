<template>
  <div class="home-page -mt-12 bg-dark-900 tracking-wide">
    <section
      class="bg-cover bg-top bg-no-repeat pt-40 text-center px-4 box-content"
      :class="landscape ? 'create-game h-180' : 'create-game-mobile h-120'"
    >
      <img v-if="landscape" class="h-10" src="@/assets/img/company-site/logo.png" alt="" />
      <h1 class="mt-7 w-100 m-auto">{{ $t('home.CREATE_GAME_H') }}</h1>
      <p class="c-gray-200 text-lg mt-2 mb-8" portrait="px-4">{{ $t('home.CREATE_GAME_DESC') }}</p>
      <AButton v-if="platform != 'playdeck'" class="text-5 m-auto" @click="openGame">{{ $t('play-now') }}</AButton>
    </section>
    <section>
      <h1 class="text-center mb-4">{{ $t('LEPOKER_OFFICIAL') }}</h1>
      <ASwiperSlide class="mb-8" :items="officalLinks">
        <template #default="{ value }">
          <div class="flex justify-between items-center px-12" portrait="flex-col">
            <div class="mr-a pb-4 pr-4">
              <h2>{{ value.title }}</h2>
              <div class="my-4">{{ value.content }}</div>
              <AButton v-if="value.link" class="text-sm" @click="$router.push(value.link)">{{
                $t('MORE_DETAILS')
              }}</AButton>
            </div>
            <img class="w-full max-w-110 rd-lg" :src="value.img" alt="" @click="redirectToURL(value.links)" />
          </div>
        </template>
      </ASwiperSlide>
    </section>
    <section class="bgi-mtt-main-bg bg-no-repeat bg-top py-12 px-4 box-content max-w-none text-center">
      <h1>{{ $t('home.EVENTS_H') }}</h1>
      <p class="my-4 text">
        <span class="inline-block max-w-120">
          {{ $t('home.EVENTS_DESC') }}
        </span>
      </p>
      <div v-if="platform != 'playdeck'" class="flex justify-center items-center space-x-4">
        <a href="https://www.facebook.com/lepokerpage" target="_blank">
          <div class="i-local-facebook w-8 h-8 c-gray-200" />
        </a>
        <a href="https://twitter.com/LePokerGame" target="_blank">
          <div class="i-local-twitter w-8 h-8 c-gray-200" />
        </a>
        <a href="https://discord.gg/fzhzcfz3Xp" target="_blank">
          <div class="i-local-discord w-9 h-9 c-gray-200" />
        </a>
        <a href="https://t.me/lepokergame" class="rd-full bg-gray-200 p-1" target="_blank">
          <div class="i-local-telegram size-6 c-gray-900" />
        </a>
        <a href="https://m.debox.pro/group?id=w8cgmhuv" class="rd-lg bg-gray-200 p-.5" target="_blank">
          <div class="i-local-debox w-6 h-6 c-gray-900" />
        </a>
      </div>
    </section>
    <ASwiper class="mb-8" :items="EVENT_SLIDES">
      <template #default="{ value }">
        <img
          class="w-full block rd-lg b-solid b-gray-700"
          :src="value.image"
          alt=""
          @click="redirectToURL(value.links)"
        />
      </template>
    </ASwiper>
    <section class="my-9">
      <div class="flex justify-center items-center" portrait="flex-col">
        <div class="flex flex-col justify-center basis-2/5 mr-9" portrait="basis-1/1 mr-0 mb-8">
          <h1 class="text-left" portrait="text-center">{{ $t('home.DETAIL_H_1') }}</h1>
          <div class="text text-left" portrait="text-center">
            {{ $t('home.DETAIL_DESC_1') }}
          </div>
        </div>
        <div class="basis-3/5 rd-xl b-solid b-gray-900 of-hidden">
          <img class="w-full block" src="@/assets/img/company-site/video-top.png" />
          <video class="w-full block" playsinline autoplay muted loop>
            <source src="@/assets/img/company-site/send-link.mp4" type="video/mp4" />
            <img src="@/assets/img/company-site/send-link.png" alt="Send link" />
          </video>
        </div>
      </div>
      <div class="flex flex-row-reverse mt-36 justify-center items-center" portrait="flex-col mt-60px">
        <div class="flex flex-col justify-center basis-2/5 ml-9" portrait="basis-1/1 mr-0 mb-8 ml-0">
          <h1 class="text-left" portrait="text-center">{{ $t('home.DETAIL_H_2') }}</h1>
          <div class="text text-left" portrait="text-center">
            {{ $t('home.DETAIL_DESC_2') }}
          </div>
        </div>
        <div class="basis-3/5 rd-xl b-solid b-gray-900 of-hidden">
          <img class="w-full block" src="@/assets/img/company-site/video-top.png" />
          <video class="w-full block" playsinline autoplay muted loop>
            <source src="@/assets/img/company-site/video-chat.mp4" type="video/mp4" />
            <img src="@/assets/img/company-site/video-chat.png" alt="Video Chat" />
          </video>
        </div>
      </div>
      <div class="flex flex-row mt-36 justify-center items-center" portrait="flex-col mt-20">
        <div class="flex flex-col justify-center basis-1/2 mr-9" portrait="basis-1/1 mr-0 mb-8">
          <h1 class="text-left" portrait="text-center">{{ $t('home.DETAIL_H_3') }}</h1>
          <div class="text text-left" portrait="text-center">
            {{ $t('home.DETAIL_DESC_3') }}
          </div>
        </div>
        <div class="flex basis-1/2">
          <img class="w-full" src="@/assets/img/company-site/fair-game.png" alt="Fair Game" />
        </div>
      </div>
    </section>
    <section class="my-9">
      <h1 class="text-center mb-9 justify-center">{{ $t('home.FEATURE_H') }}</h1>
      <div
        class="features flex flex-wrap children:min-w-340px children:flex-1 children:mx-10px children:my-10px"
        portrait="children:mx-0 children:my-10px"
      >
        <div class="item p-20px">
          <img src="@/assets/img/company-site/free-to-play.svg" alt="Free to Play" />
          <h4>{{ $t('home.FEATURE_H_1') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_1') }}
          </div>
        </div>
        <div class="item p-20px">
          <img src="@/assets/img/company-site/no-download-required.svg" alt="No Download Required" />
          <h4>{{ $t('home.FEATURE_H_2') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_2') }}
          </div>
        </div>
        <div class="item p-20px">
          <img src="@/assets/img/company-site/multiple-language-supported.svg" alt="Multiple Language Supported" />
          <h4>{{ $t('home.FEATURE_H_3') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_3') }}
          </div>
        </div>
        <div class="item p-20px">
          <img src="@/assets/img/company-site/community-centric.svg" alt="Community centric" />
          <h4>{{ $t('home.FEATURE_H_4') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_4') }}
          </div>
        </div>
        <div class="item p-20px">
          <img src="@/assets/img/company-site/web3-native.svg" alt="Web3 native" />
          <h4>{{ $t('home.FEATURE_H_5') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_5') }}
          </div>
        </div>
        <div class="item p-20px">
          <img src="@/assets/img/company-site/blockchain-technology.svg" alt="Blockchain Technology" />
          <h4>{{ $t('home.FEATURE_H_6') }}</h4>
          <div class="detail">
            {{ $t('home.FEATURE_H_DESC_6') }}
          </div>
        </div>
      </div>
    </section>
    <section class="my-9">
      <div class="flex justify-between items-center">
        <div class="flex-1" />
        <h1 class="text-center mb-9 justify-center flex-1">Blog</h1>
        <a href="https://blog.lepoker.io" target="_blank" class="flex items-center flex-1 justify-end">
          More
          <div class="i-local-arrow-l rotate-180" />
        </a>
      </div>
      <Carousel :value="blogs" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
        <template #item="slotProps">
          <a :href="'https://blog.lepoker.io/post/' + slotProps.data.slug" target="_blank">
            <div class="rd-md bg-gray-900 ml-2 of-hidden">
              <img :src="slotProps.data.img" class="w-full h-auto" alt="" />
              <div class="p-4">
                <div class="fw-bold c-white">{{ slotProps.data.title }}</div>
                <div class="c-white/50 text-sm mt-2">{{ dt(slotProps.data.date).strDate }}</div>
              </div>
            </div>
          </a>
        </template>
      </Carousel>
    </section>
    <section class="about py-15 text-center px-4 box-content">
      <div class="m-auto max-w-200">
        <h1>{{ $t('home.ABOUT_H') }}</h1>
        <p class="description">
          {{ $t('home.ABOUT_DESC') }}
        </p>
        <p class="description">
          {{ $t('home.ABOUT_DESC_2') }}
        </p>
      </div>
    </section>
    <footer class="py-4 flex items-center justify-around" portrait="flex-col-reverse h-50">
      <p class="c-gray-300">{{ $t('home.COPYRIGHT') }}</p>
      <p class="c-gray-300" portrait="order--1">
        <a href="/static/terms.html">{{ $t('TERMS_OF_SERVICE') }}</a>
        |
        <a href="/static/privacy.html">{{ $t('PRIVACY_POLICY') }}</a>
        <!-- <span class="cursor-pointer" @click="openTab('/static/terms.html')">{{ $t('TERMS_OF_SERVICE') }}</span> |
        <span class="cursor-pointer" @click="openTab('/static/privacy.html')">{{ $t('PRIVACY_POLICY') }}</span> -->
      </p>
      <template v-if="platform !== 'playdeck'">
        <div class="flex justify-center items-center space-x-4">
          <a href="https://www.facebook.com/lepokerpage" target="_blank">
            <div class="i-local-facebook w-8 h-8 c-gray-400" />
          </a>
          <a href="https://twitter.com/LePokerGame" target="_blank">
            <div class="i-local-twitter w-7 h-7 c-gray-400" />
          </a>
          <a href="https://discord.gg/fzhzcfz3Xp" target="_blank">
            <div class="i-local-discord w-8 h-8 c-gray-400" />
          </a>
          <a href="https://t.me/lepokergame/1" class="rd-full bg-gray-400 p-1 c-black" target="_blank">
            <div class="i-local-telegram size-5 c-dark-900" />
          </a>
          <a href="https://m.debox.pro/group?id=w8cgmhuv" class="rd-lg bg-gray-400 p-.5" target="_blank">
            <div class="i-local-debox w-6 h-6 c-dark-900" />
          </a>
        </div>
        <a href="mailto:team@lepoker.io" class="flex bg-gray-800 rd b-solid b-gray-700 p-1 c-gray-300">
          <span>team@lepoker.io</span>
          <span class="i-local-mail w-6 h-6 ml-3" />
        </a>
      </template>
    </footer>
    <div
      v-if="showTerms"
      class="sticky z-2 bottom-0 p-2 max-w-none bg-gray-900 flex justify-center items-center px-4 box-content"
    >
      <p class="text-xs c-gray-200">
        <span class="fw-bold">{{ $t('IMPORTANT') }}:</span> {{ $t('ACKNOWLEDE_AND_AGREE') }}
        <a class="underline cursor-pointer" href="/static/terms.html">{{ $t('TERMS_OF_SERVICE') }}</a
        >{{ $t('AND') }}<a class="underline cursor-pointer" href="/static/privacy.html">{{ $t('PRIVACY_POLICY') }}</a
        >.
      </p>
      <AButton class="ml-3 text-sm !p-1 shrink-0" @click="showTerms = false">{{ $t('GOT_IT') }}</AButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import qs from 'qs';
import router from '@/router';
import { landscape, platform } from '@/stores';
import { computed, ref } from 'vue';
import { EVENT_SLIDES } from '@/utils/constant';
import { blogFetch } from '@/utils/network';
import { dt } from '@/utils/datetime';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const officalLinks = computed(() => [
  {
    title: t('EVENT_TITLE_2'),
    content: t('EVENT_CONTENT_2'),
    img: new URL('@/assets/img/tmp/event-2.png', import.meta.url).href,
    link: '/poker-to-mint',
  },
]);

const showTerms = ref(true);
const openGame = () => router.push('/lobby');

const redirectToURL = (link: string) => {
  if (!link) return;
  location.href = link;
};

const blogs = ref<any[]>([]);

const getBlogs = async () => {
  const query = qs.stringify(
    {
      fields: ['title', 'date', 'summary', 'slug'],
      populate: {
        img: {
          fields: ['url'],
        },
      },
      sort: 'date:desc',
      pagination: { limit: 10 },
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  );
  const result = blogFetch.get(`articles?${query}`);
  result.json().then((data: any) => {
    blogs.value = data.data.map((item: any) => ({
      ...item.attributes,
      img: item.attributes.img.data.attributes.url,
      id: item.id,
    }));
  });
};
getBlogs();

const responsiveOptions = ref([
  {
    breakpoint: '1400px',
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: '1199px',
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: '767px',
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: '575px',
    numVisible: 1,
    numScroll: 1,
  },
]);
</script>
<style>
.home-page {
  .create-game {
    background-image: url('@/assets/img/company-site/table.png');
  }
  .create-game-mobile {
    background-image: url('@/assets/img/company-site/table-mobile.png');
  }
  .text,
  .about .detail,
  .description {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
  }

  .description {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .features .item {
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: #25262d;
    box-sizing: border-box;
  }
  .features .item h4 {
    margin: 16px 0 12px 0;
  }
  .features .item img {
    height: 64px;
    width: 64px;
  }

  .features .item .detail {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 400;
  }

  .about {
    background-image: url('@/assets/img/company-site/about-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: none;
  }
}
</style>
