import { useLocalStorage, type RemovableRef } from '@vueuse/core';
import { customRef } from 'vue';
import { Hand } from 'pokersolver';
import { formatNumber } from './strings';

export const TXSDKID = 20004848;

export const HSLToRGB = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return `rgb(${255 * f(0)}, ${255 * f(8)}, ${255 * f(4)})`;
};

export const showBB = useLocalStorage('show-bb', false);
export const userGameSetting = useLocalStorage('user-game-setting', {
  cardType: 1,
  betSize: [1, 2, 3, 4],
  raiseSize: [1, 2, 3, 4],
  voiceEffect: true,
  voice: 0,
});
export const volume: RemovableRef<number> = useLocalStorage('volume', 1);

export const formatChip = (v: number, bb: number) => {
  if (!bb) return formatNumber(v, true);
  return showBB.value ? Math.ceil((v * 100) / bb) / 100 + 'BB' : formatNumber(v, true);
};

export const formatAmount = (value: number, floatNum: number = 0): string => {
  if (!value) return '0';
  const val: any = value.toFixed(floatNum).replace('.', '.');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const range = (end: number, start = 0, step = 1) => {
  const arr = [];
  for (let i = start; i < end; i += step) arr.push(i);
  return arr;
};

export const genCookieRef = (name: string) =>
  customRef((track, trigger) => ({
    get() {
      track();
      return Object.fromEntries(document.cookie.split(';').map((v) => v.split('=').map((v) => v.trim())))[name];
    },
    set(v: string) {
      document.cookie = `${name}=${v};Max-Age=${v ? 36000000000 : 0};path=/;${
        import.meta.env.MODE === 'development' ? '' : 'Domain=lepoker.io'
      }`;
      trigger();
    },
  }));

export const useExpiredStorage = <T>(key: string, expired?: number, init?: T) => {
  const raw = useLocalStorage(key, init !== undefined ? { data: init, ts: expired || -1 } : undefined);
  return {
    storage: customRef<T | undefined>((track, trigger) => ({
      get() {
        track();
        if (raw.value == undefined) return init;
        if (raw.value.ts < Date.now()) {
          raw.value = null;
          return init;
        }
        return raw.value.data;
      },
      set(v?: T) {
        if (v == null || !expired) raw.value = null;
        else raw.value = { data: v, ts: expired };
        trigger();
      },
    })),
    update: (ts: number) => {
      if (raw.value) raw.value.ts = ts;
    },
  };
};

export const getHand = (cards: string[]) => {
  const hand = Hand.solve(cards);
  return {
    name: hand.descr === 'Royal Flush' ? hand.descr : hand.name,
    cards: hand.cards.slice(0, 5).map((c) => c.wildValue.replace('1', 'A') + c.suit),
  };
};
