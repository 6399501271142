import { bnbUser, ethUser, lineaUser, solanaUser, zksyncUser } from '@/stores';

type NetworkConfig = {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
};

type NetworkConfigs = {
  [key: string]: NetworkConfig;
};

export const networkConfigs: NetworkConfigs = {
  '0x61': {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.bnbchain.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  '0x38': {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://bsc-rpc.publicnode.com'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  '0x310c5': {
    chainId: '0x310c5',
    chainName: 'Bitlayer',
    nativeCurrency: {
      name: 'Bitlayer',
      symbol: 'BTC', // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ['https://rpc.bitlayer.org'],
    blockExplorerUrls: ['https://www.btrscan.com'],
  },
};

export const setUserAddress = async (chain: string, address: string) => {
  if (chain == 'eth') {
    ethUser.address = address;
  } else if (chain == 'bsc') {
    bnbUser.address = address;
  } else if (chain == 'zksync') {
    zksyncUser.address = address;
  } else if (chain == 'sol') {
    solanaUser.address = address;
  } else if (chain == 'linea') {
    lineaUser.address = address;
  }
};
