<template>
  <Teleport to="#m-nav-add">
    <div class="i-local-tutorial size-7 cursor-pointer" @click="$router.push('/faq/clan')" />
  </Teleport>
  <DPHomeBanner class="mb-4" tag="Clan" />
  <section
    class="grid grid-cols-2 gap-3 mb-8"
    un-children="p-4 h-22 rd-lg bg-gray-800 pr-20 bg-no-repeat bg-contain bg-right"
  >
    <div class="bgi-clan-create-bg" @click="openCreator">
      <h4>{{ $t('CREATE_CLAN') }}</h4>
      <p class="c-gray mt-1" portrait="hidden">{{ $t('CLAN_CREATE_TIPS') }}</p>
    </div>
    <div class="bgi-clan-search-bg" @click="showSearch = true">
      <h4>{{ $t('SEARCH_CLAN') }}</h4>
      <p class="c-gray mt-1" portrait="hidden">{{ $t('CLAN_SEARCH_TIPS') }}</p>
    </div>
  </section>
  <section class="pb-4">
    <div class="flex justify-around mb-4" un-children="text-center flex-1 p-2 b-2 b-b-solid cursor-pointer ">
      <h5 :class="tab === 0 ? 'b-green bg-green/20 c-white' : 'c-gray-300 b-transparent'" @click="tab = 0">
        {{ $t('ALL_CLAN') }}
      </h5>
      <h5 :class="tab === 1 ? 'b-green bg-green/20 c-white' : 'c-gray-300 b-transparent '" @click="tab = 1">
        {{ $t('MY_CLAN') }}
      </h5>
    </div>
    <template v-if="tab === 0">
      <div class="grid grid-cols-4 gap-3" un-children="rd-lg bg-gray-900 p-4" portrait="grid-cols-2">
        <div v-for="(clan, i) in all" :key="i" @click="openClan(clan)">
          <img class="block rd size-16" :src="clan.logoUrl" alt="" />
          <h4 class="truncate mt-2">{{ clan.name }}</h4>
          <div class="flex space-x-6 c-gray-200">
            <div><span class="c-gray">ID:</span>{{ clan.clanId }}</div>
            <div><span class="c-gray inline-block align-middle i-local-user" /> {{ clan.memberCount }}</div>
          </div>
        </div>
      </div>
      <APaginator class="mt-4 justify-center" v-model="page" :total="total" :size="pageSize" />
    </template>
    <template v-else>
      <div v-if="!mine.length" class="text-center p-8">
        <img class="w-46" src="@/assets/img/no-data-2.png" alt="" />
        <div class="c-gray">{{ $t('MY_CLAN_EMPTY_TIPS') }}</div>
        <AButton outlined class="m-a mt-4 min-w-30" @click="showSearch = true">{{ $t('JOIN') }}</AButton>
      </div>
      <div v-else class="grid grid-cols-4 gap-3" un-children="rd-lg bg-gray-900 p-4" portrait="grid-cols-2">
        <div v-for="(clan, i) in mine" :key="i" @click="openClan(clan)">
          <div class="flex justify-between text-xl">
            <img class="rd size-16" :src="clan.logoUrl" alt="" />
            <div
              :class="{ 'c-yellow': clan.isStar }"
              class="i-local-star-fill c-gray-600 cursor-pointer"
              @click.stop="markStar(clan)"
            />
          </div>
          <div class="flex items-center mt-2">
            <h4 class="truncate">{{ clan.name }}</h4>
            <img v-if="clan.clanRole === 2" class="w-5 ml-1" src="@/assets/img/clan-owner-tag.png" alt="" />
          </div>
          <div class="flex space-x-6 c-gray-200">
            <div><span class="c-gray">ID:</span>{{ clan.clanId }}</div>
            <div><span class="c-gray inline-block align-middle i-local-user" /> {{ clan.memberCount }}</div>
          </div>
        </div>
      </div>
    </template>
  </section>
  <ADialog class="text-lg" v-model="showCreator" :title="$t('CREATE_CLAN')" fullscreen>
    <div class="space-y-4">
      <div class="flex flex-col items-center space-y-2">
        <AUpload :maxSize="1e6" v-model="createValue.logoUrl">
          <img v-if="createValue.logoUrl" class="block h-27 w-27" :src="createValue.logoUrl" />
          <div v-else class="h-27 w-27 p-6 rd b-solid c-gray-600">
            <div class="i-local-plus w-full h-full"></div>
          </div>
        </AUpload>
        <div class="text-lg">LOGO</div>
        <p class="c-gray-400">At least 150x150, PNG, JPG, size&lt;1M</p>
      </div>
      <div>
        <AInput v-model="createValue.name" :limit="40">
          <h4>{{ $t('CLAN_NAME') }}</h4>
        </AInput>
      </div>
      <div>
        <AInput v-model="createValue.description" :limit="500" :rows="4" class="mt-1">
          <h4>{{ $t('CLAN_DESCRIPTION') }}</h4>
        </AInput>
      </div>
    </div>
    <template #footer>
      <AButton :disabled="!(createValue.logoUrl && createValue.name)" @click="createClan">
        {{ $t('CREATE') }}
      </AButton>
    </template>
  </ADialog>
  <ADialog class="text-lg w-100" v-model="showSearch" :title="$t('ENTER_CLAN_ID')">
    <p class="c-gray-300 text-center">{{ $t('JOIN_CLAN_TIPS') }}</p>
    <ASplitInput v-model="searchValue" :len="7" class="my-4" />
    <template #footer>
      <AButton :disabled="searchValue.length !== 7" @click="search">{{ $t('SEARCH') }}</AButton>
    </template>
  </ADialog>
  <ADialog class="text-lg" v-model="showDetail" :title="$t('JOIN_CLAN')" fullscreen>
    <div v-if="cur" class="space-y-6 max-w-130">
      <div class="flex flex-col text-center space-y-2">
        <img class="h-27 w-27 self-center" :src="cur.logoUrl" />
        <div class="text-lg truncate">{{ cur.name }}</div>
        <p class="c-gray-400">{{ $t('mtt.OWNER') }}: {{ cur.creator }} ID: {{ cur.clanId }}</p>
      </div>
      <div class="rd-lg bg-gray-700 p-3 flex items-center">
        <div class="flex-1">
          <b class="c-green mr-1">{{ cur.memberCount }} </b>
          {{ $t('MEMBERS') }}
        </div>
        <DPAvatar v-for="(item, i) in cur.users" :key="i" class="-ml-3" :id="item.userId" />
      </div>
      <ATitle class="text-lg fw-bold">{{ $t('CLAN_DESCRIPTION') }}</ATitle>
      <p class="c-gray-300 ws-pre-wrap break-all">{{ cur?.description }}</p>
    </div>
    <template #footer>
      <AAuth value="clan_advanced_setting">
        <AButton type="secondary" @click="openEdit">{{ $t('mtt.EDIT') }}</AButton>
      </AAuth>
      <AButton @click="openJoin">{{ $t('JOIN_CLAN') }}</AButton>
    </template>
  </ADialog>
  <ADialog class="text-lg" v-model="showJoin" :title="$t('REGISTRATION_SUBMISSION')">
    <div class="space-y-3">
      <p class="c-gray-300">{{ cur?.registrationGuidance || '-' }}</p>
      <AInput v-model="applyValue.content" :limit="40">
        <h5>{{ $t('TEXT_SUBMISSION') }}</h5>
      </AInput>
      <div v-if="cur?.approveMode === 2" class="space-y-2">
        <h5 class="flex justify-between">{{ $t('UPLOAD_IMAGE') }} <span class="c-gray-300">3 Maximum</span></h5>
        <AUpload v-if="applyValue.imgUrls.length < 3" class="w-full" :maxSize="2e6" v-model="applyValue.imgUrls">
          <div class="rd b-solid b-gray-600 bg-gray-700 p-3 text-center">
            <p class="c-green">{{ $t('SELECT_IMAGE') }}</p>
            <p class="c-gray-300">PNG, JPG, size&lt;2M</p>
          </div>
        </AUpload>
        <div
          v-for="(item, i) in applyValue.imgUrls"
          :key="i"
          class="flex justify-between items-center rd b-solid b-gray-600 bg-gray-700 p-3"
        >
          <img class="h-12" :src="item" alt="" />
          <div class="i-local-delete c-gray-300" @click="applyValue.imgUrls.splice(i, 1)"></div>
        </div>
      </div>
    </div>
    <template #footer>
      <AButton @click="submitJoin">{{ $t('mtt.SUBMIT') }}</AButton>
    </template>
  </ADialog>
</template>
<script lang="ts" setup>
import { useDlgCtrl } from '@/components/ui/ADialog.vue';
import { useDialog } from '@/components/ui/APopPad.vue';
import { toast } from '@/components/ui/AToast.vue';
import router from '@/router';
import { showLogin, user } from '@/stores';
import type { Clan } from '@/types';
import { authAPI } from '@/utils/network';
import { useRouteQuery } from '@vueuse/router';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const id = useRouteQuery<string | null>('id');

const tab = ref(0);
const showCreator = useDlgCtrl();
const showSearch = useDlgCtrl();
const showDetail = useDlgCtrl();
const showJoin = useDlgCtrl();

const all = ref<Clan[]>([]);
const mine = ref<Clan[]>([]);

const cur = ref<Clan>();
const searchValue = ref('');
const applyValue = ref({ content: '', imgUrls: [] as string[] });
const createValue = ref({
  name: '',
  description: '',
  logoUrl: '',
  isPublic: true,
});

const total = ref(1);
const page = ref(1);
const pageSize = 500;

function updateAll() {
  authAPI.get('v1/clan/listPublic', { pageIndex: page.value, pageSize }).then((res) => {
    all.value = res.records;
    total.value = res.total;
  });
}

watch(page, updateAll);

const updateList = () => {
  authAPI
    .get('v1/clan/listJoined', undefined, undefined, true)
    .then((res) => {
      mine.value = res.map((v: any) => ({ ...v.clan, ...v }));
    })
    .finally(() => {
      if (id.value) {
        authAPI
          .get('v1/clan/getById', { clanId: id.value })
          .then((res) => openClan({ ...res.clanVO, creator: res.owner.userName, users: res.clanUsers }));
        id.value = null;
      }
    });
  updateAll();
};
updateList();

const popup = useDialog();
const { t } = useI18n();

function openCreator() {
  if (!user.value) return (showLogin.value = true);
  if (mine.value.length > 9 && !user.value.permissions.includes('clan_advanced_limit'))
    return popup.alert({ title: t('ATTENTION'), content: t('JOINED_CLAN_LIMIT_TIPS') });
  if (!user.value.permissions.includes('create_clan'))
    return popup.alert({ title: t('ATTENTION'), content: t('NO_PREMISSION') });
  showCreator.value = true;
}

const openClan = async (v: Clan) => {
  if (mine.value.find((c) => c.clanId === v.clanId)) return router.push(`/cl/${v.clanId}`);
  if (v.creator) cur.value = v;
  else {
    const res = await authAPI.get('v1/clan/getById', { clanId: v.clanId });
    cur.value = { ...res.clanVO, creator: res.owner.userName, users: res.clanUsers };
  }
  showDetail.value = true;
};

const search = async () => {
  try {
    const res = await authAPI.get('v1/clan/getById', { clanId: searchValue.value });
    openClan({ ...res.clanVO, creator: res.owner.userName, users: res.clanUsers });
    showSearch.value = false;
  } catch {
    popup.alert({ title: t('NO_RESULTS') });
  }
};

const openEdit = () => {
  // const r = router.addRoute('base', {
  //   path: '/cl/edit',
  //   component: EditPage,
  //   props: { id: cur.value?.clanId },
  //   meta: { title: 'CLAN_SETTINGS' },
  // });
  // showDetail.value = false;
  // router.push('/cl/edit').then(r);
};

const openJoin = () => {
  if (!user.value) return (showLogin.value = true);
  if (mine.value.length > 9 && !user.value.permissions.includes('clan_advanced_limit'))
    return popup.alert({ title: t('ATTENTION'), content: t('JOINED_CLAN_LIMIT_TIPS') });
  if (!cur.value) return;
  if ([1, 2].includes(cur.value.approveMode)) {
    showJoin.value = true;
  } else submitJoin();
};

const submitJoin = () => {
  if (!cur.value) return;
  authAPI.post('v1/clan/joinRequest/create', { clanId: cur.value.clanId, detail: applyValue.value }).then((res) => {
    showDetail.value = false;
    showJoin.value = false;
    updateList();
    if (res.joined) toast.success(t('SUCCESS'));
    else if (res.created) toast.success(t('SUBMISSION_SUCCESSFUL'));
  });
};

const createClan = () => {
  authAPI.post('v1/clan/create', createValue.value).then(() => {
    showCreator.value = false;
    updateList();
    createValue.value = { name: '', description: '', logoUrl: '', isPublic: true };
  });
};

const markStar = async (v: Clan) => {
  if (v.isStar) return;
  await authAPI.post('v1/clan/user/star', { clanId: v.clanId });
  authAPI
    .get('v1/clan/listJoined', undefined, undefined, true)
    .then((res) => (mine.value = res.map((v: any) => ({ ...v.clan, ...v }))));
};
</script>
