<template>
  <div
    v-if="data.isClaim && data.taskLadder < 5"
    class="relative rd-lg flex flex-col justify-center text-center bg-gray-700 op-80 w-30 h-30"
  >
    <div class="bg-[#464750] rd-t-md c-green text-xs w-full top-0 p-1 fw-bold">
      {{ title }}
    </div>
    <div class="rd-b-lg h-full of-hidden flex flex-col justify-center items-center space-y-1.5 bg-[#3C3D45]">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/img/task-daily-signed.png" class="h-12" />
        <span class="c-white pt-2 fw-bold">{{ data.prizeGold }}</span>
      </div>
    </div>
  </div>
  <div
    v-else-if="data.isClaim && data.taskLadder >= 5"
    class="relative rd-lg flex flex-col justify-center text-center bg-gray-700 w-30 h-30"
  >
    <div
      v-if="showTime"
      class="bg-[#464750] rd-t-md c-white text-xs w-full top-0 p-1 relative flex justify-center items-center"
    >
      <div class="i-local-clock"></div>
      <span class="ml-1">{{ dur(remainingTime) }}</span>
    </div>
    <div v-else class="bg-[#464750] rd-t-md c-green text-xs w-full top-0 p-1 fw-bold">
      {{ title }}
    </div>
    <div class="rd-b-lg h-full of-hidden flex flex-row justify-center items-center space-y-1.5 bg-[#3C3D45]">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/img/gold-coin.png" class="h-12" />
        <span class="c-white pt-2 fw-bold">{{ data.prizeGold }}</span>
      </div>
    </div>
  </div>
  <div
    v-else-if="data.isFinish && !data.isClaim"
    class="relative b-solid b-green rd-lg w-30 h-30 text-center flex flex-col justify-center text-center bg-[#3C3D45]"
    @click="claimTask"
  >
    <div class="c-white rd-t-md c-white text-xs w-full bg-green top-0 p-1 fw-bold">{{ title }}</div>
    <div class="relative h-full of-hidden flex flex-col justify-center items-center space-y-1.5">
      <img
        src="@/assets/img/task-reward-bg.png"
        class="absolute left-1/2 transform -translate-x-1/2 w-30 h-30 -top-6"
      />
      <img src="@/assets/img/gold-coin.png" class="h-12 z-1" />
      <span class="c-yellow fw-bold z-1">{{ data.prizeGold }}</span>
    </div>
  </div>
  <div v-else-if="!data.isFinish" class="relative rd-lg flex flex-col justify-center text-center bg-gray-700 w-30 h-30">
    <div
      v-if="showTime"
      class="bg-[#464750] rd-t-md c-white text-xs w-full top-0 p-1 relative flex justify-center items-center"
    >
      <div class="i-local-clock"></div>
      <span class="ml-1">{{ dur(remainingTime) }}</span>
    </div>
    <div v-else class="bg-[#464750] rd-t-md c-green text-xs w-full top-0 p-1 fw-bold">
      {{ title }}
    </div>
    <div class="rd-b-lg h-full of-hidden flex flex-row justify-center items-center space-y-1.5 bg-[#3C3D45]">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/img/gold-coin.png" class="h-12" />
        <span class="c-white pt-2 fw-bold">{{ data.prizeGold }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PlatformTask } from '@/types';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import { toast } from '@/components/ui/AToast.vue';
import { useIntervalFn } from '@vueuse/core';
import { dur } from '@/utils/datetime';

const emits = defineEmits<{ callback: any }>();
const props = defineProps<{ data: PlatformTask; title: string; showTime?: boolean }>();

const prizeNumber = ref('');

const claimTask = async () => {
  if (props.data.prizeDiamond > 0) {
    prizeNumber.value = props.data.prizeDiamond.toString();
  } else if (props.data.prizePoint > 0) {
    prizeNumber.value = props.data.prizePoint.toString();
  }
  const params = {
    taskKey: props.data.taskKey,
    taskId: props.data.id,
    finishId: props.data.finishId,
  };
  await authAPI
    .post('v1/task/claimTaskPrize', params)
    .then((v) => {
      emits('callback', v);
    })
    .catch((e: any) => {
      toast.error(e.msg);
    });
};
const remainingTime = ref(0);

const remaining = async () => {
  const now = new Date();
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);
  remainingTime.value = midnight.getTime() - now.getTime() - now.getTimezoneOffset() * 60000 - 24 * 60 * 60 * 1000;
  if (remainingTime.value < 0) {
    remainingTime.value += 24 * 60 * 60 * 1000;
  }
};

useIntervalFn(() => {
  remaining();
});
</script>
