<template>
  <div class="relative items-center justify-center">
    <img
      v-if="showBorder && avatarBorder"
      class="absolute h-4.5em rd-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
      :src="avatarBorder"
    />
    <div
      class="rd-full w-1.8em h-1.8em flex items-center justify-center shrink-0 bg-gray-900"
      :class="`default-avatar-${(vm.name.codePointAt(0) || 0) % 1}`"
      :style="{ backgroundImage: vm.avatar ? `url(${vm.avatar})` : '' }"
    >
      {{ vm.avatar ? '' : vm.name[0] }}
    </div>
    <slot :user="vm" />
  </div>
</template>
<script setup lang="ts">
import { loadUser } from '@/stores/cache';
import { computed, ref, watch } from 'vue';

const avatarBorder = ref('');

const props = defineProps<{
  simple?: boolean;
  id?: string;
  name?: string;
  avatar?: string;
  country?: string;
  title?: string;
  showBorder?: boolean;
  border?: string;
  seed?: boolean;
}>();
const data = ref({ name: '', avatar: '', country: '', title: 'TITLE_0', seed: false });

const vm = computed(() => ({
  name: props.name || data.value.name,
  avatar: props.avatar || data.value.avatar,
  country: props.country || data.value.country,
  title: props.title || data.value.title,
  seed: props.seed || data.value.seed,
}));

watch(
  () => props.id,
  async (v, old) => {
    if (old) {
      data.value.avatar = '';
      data.value.title = '';
    }
    if (!v) return;
    const user = await loadUser(v);
    if (props.id !== v) return;
    if (!user) return;
    data.value.name = user.userName;
    data.value.avatar = user.userAvatarUrl;
    data.value.country = user.countryCode;
    data.value.title = user.userTitle;
    data.value.seed = user?.seed === '1';
    if (data.value.title) {
      const borderNum = data.value.title.split('_')[1];
      if (parseInt(borderNum) > 0) {
        avatarBorder.value = '/avatar-border/border' + borderNum + '.png';
      }
    }
  },
  { immediate: true },
);

watch(
  () => props.border,
  async (v) => {
    if (props.showBorder && props.border) {
      avatarBorder.value = v!;
    }
  },
  { immediate: true },
);

const updateBorder = async () => {
  if (vm.value.title) {
    const borderNum = vm.value.title.split('_')[1];
    if (parseInt(borderNum) > 0) {
      avatarBorder.value = '/avatar-border/border' + borderNum + '.png';
    }
  }
};
updateBorder();
</script>
<style>
.default-avatar-0 {
  background: url(../assets/img/avatar.png) center/cover;
}
</style>
