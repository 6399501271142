<template>
  <div class="pt-4">
    <h4 class="text-center">{{ $t('CLAIM_YOUR_REWARD') }}</h4>
    <p class="mt-2 op-60 break-words ws-pre-wrap">
      {{ data.info }}
    </p>
    <div class="relative rd bg-gray-800 p-4 mt-4">
      <h4>{{ data.title }}</h4>
      <p class="c-white/50">{{ data.time }}</p>
      <Divider />
      <div class="flex items-center">
        <DPAvatar class="text-3xl" :name="user!.name" :avatar="user?.avatar" />
        <div class="flex-1 px-3">
          <h6>{{ user!.name }}</h6>
        </div>
        <div>
          <h1 class="c-green">{{ data.rank }}</h1>
          <h6>{{ $t('mtt.RANKING') }}</h6>
        </div>
      </div>
      <div class="absolute bg-gradient-to-t from-green-900/50 w-30 h-24 bottom-0 right-0"></div>
      <div class="absolute bottom-0.4 right-24 w-10 h-24 bg-gray-800 rd rotate-15"></div>
      <!-- <div class="triangle-down absolute bottom-0 right-20 bg-gray-800"></div> -->
    </div>
    <template v-if="!mttType">
      <h6 class="mt-4">{{ $t('CLAIM_INFO') }}</h6>
      <Textarea class="my-4" v-model="claimInfo" :disabled="!canSubmit" />
      <Button
        class="w-full"
        :disabled="!claimInfo || !canSubmit"
        :label="submitted ? $t('MODIFY') : $t('mtt.SUBMIT')"
        @click="submitClaim"
      />
    </template>
    <template v-else-if="mttType == 1">
      <template v-if="step == 1">
        <div class="relative bg-gray-800 mt-8 content-center h-28">
          <div class="i-local-reward-ticket size-40 absolute left-0 -top-3"></div>
          <div class="flex-1 flex-col content-center ml-30">
            <h4>{{ $t('CONGRATS') }}</h4>
            <span class="c-white/50"> {{ $t('REWARD_TICKET', { mttName: remark.mttName }) }}</span>
          </div>
        </div>
        <div class="flex flex-row justify-center items-center mt-12 w-full pb-2 space-x-1">
          <div class="i-local-clock c-green size-4 flex-none"></div>
          <span class="text-center"
            >{{ $t('mtt.CLAIM_DEADLINE_NOTICE') }} {{ dur((data.prizeClaimDeadline - currentTime) * 1e3) }}</span
          >
        </div>
        <Button class="w-full" :disabled="claimInfo != '' || !canSubmit" :label="$t('CLAIM')" @click="submitClaim" />
        <!-- <Button class="w-full" :label="$t('CLAIM')" @click="submitClaim" /> -->
      </template>
      <template v-if="step == 2">
        <div class="relative bg-gray-800 mt-8 content-center h-28">
          <div class="i-local-reward-ticket size-40 absolute left-0 -top-3"></div>
          <div class="flex-1 flex-col content-center ml-30">
            <span class="c-white/50"> {{ $t('mtt.PRELIMINARY_TICKET_REWARD', { mttName: remark.mttName }) }}</span>
          </div>
        </div>
        <Button class="w-full mt-20" :label="$t('mtt.REGISTRATION')" @click="$router.push('/m/' + remark.mttId)" />
      </template>
    </template>
    <div class="w-full mt-10" v-else-if="mttType == 2">
      <MTTOnChainClaimButton
        :mtt-id="props.id"
        :pool-id="onChainData.poolId"
        :rank="onChainData.rank"
        :chain-name="onChainData.chainName"
        :transaction-hash="claimInfo"
      />
    </div>
    <Dialog class="w-100" modal :closable="false" :visible="showSucess" header="">
      <div class="text-center space-y-4">
        <div class="m-auto i-local-completed text-8xl c-green"></div>
        <h5>{{ $t('SUBMISSION_SUCCESSFUL') }}</h5>
        <p>{{ $t('PRIZE_WILL_DELIVER') }}</p>
        <Button class="w-full" :label="$t('GOT_IT')" @click="$router.back()" />
      </div>
    </Dialog>
    <transition name="box">
      <div
        class="flex flex-col justify-center items-center w-screen! h-screen bg-[#25262D]/20 fixed top-0"
        v-if="ticketPagVisible"
      >
        <div class="relative -rotate-15">
          <div class="box i-local-reward-ticket-animation size-48"></div>
          <span class="absolute c-black font-semibold top-14 left-10">{{
            dt(remark.startTime * 1e3).strYearMonth
          }}</span>
          <span class="absolute c-white top-20 left-6 text-xs rd bg-[#137853] p-0.5">{{ week }} Week game</span>
          <span class="absolute c-white font-semibold top-25 left-7 text-3xl">Ticket</span>
        </div>
        <span class="font-bold text-3xl -mt-5">X 1</span>
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { user } from '@/stores';
import type { Mtt } from '@/types';
import { dt, dur } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
const ticketPagVisible = ref<boolean>(false);
import { computed, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

const props = defineProps<{ id: string }>();

const currentTime = ref(new Date().getTime() / 1e3);
useIntervalFn(() => {
  currentTime.value = new Date().getTime() / 1e3;
});

const step = ref(1);

const canSubmit = computed(() => {
  return !data.value.prizeClaimDeadline || data.value.prizeClaimDeadline >= currentTime.value;
});

const claimInfo = ref('');
const mttType = ref(-1);
const remark = ref();
const onChainData = ref({ chainName: '', poolId: 0, contractAddress: '', rank: 0 });
const submitted = ref(false);
const data = ref({ title: '', time: '', info: '', rank: '-', prizeClaimDeadline: 0 });
const week = ref('');

type PrizeInfo = { showExport: boolean; showClaimButton: boolean; claimInfo: string; mttType: number; remark: string };

authAPI
  .get<PrizeInfo>('v1/mtt/getMTTPriceInfo', {
    mttID: props.id,
  })
  .then((v) => {
    claimInfo.value = v.claimInfo;
    submitted.value = !!v.claimInfo;
    mttType.value = v.mttType;
    remark.value = JSON.parse(v.remark);
    if (v.mttType === 2) {
      onChainData.value = JSON.parse(v.remark);
    }
    const w = dt(remark.value.startTime).getWeekOfMonth;
    if (w == 1) {
      week.value = '1st';
    } else if (w == 2) {
      week.value = '2nd';
    } else if (w == 3) {
      week.value = '3rd';
    } else if (w == 4) {
      week.value = '4th';
    } else if (w == 5) {
      week.value = '5th';
    }
  });

authAPI.get<Mtt>('v1/mtt/show_details', { mttID: props.id }).then((v) => {
  data.value.title = v.mttName;
  data.value.time = dt(v.startTime * 1e3).strDatetime;
  data.value.info = v.prizeDetail;
  data.value.prizeClaimDeadline = v.prizeClaimDeadline;
});

authAPI.get<any[]>('v1/mtt/getMTTRanking', { mttID: props.id }).then((v) => {
  data.value.rank = v.find((u) => u.userID === user.value!.id).rank;
});

const showSucess = ref(false);
const submitClaim = async () => {
  if (mttType.value == 1) {
    claimInfo.value = 'WEEK_GAME_TICKET_REWARD';
    ticketPagVisible.value = true;
    step.value = 2;
  } else {
    showSucess.value = true;
  }
  bounceAnimation();
  await authAPI.post('v1/mtt/saveUserPriceInfo', { claimInfo: claimInfo.value, mttId: props.id });
};

const bounceAnimation = () => {
  setTimeout(() => {
    ticketPagVisible.value = false;
  }, 2000);
};

const updateTransactionHash = (v: string) => {
  claimInfo.value = v;
};
</script>

<style scoped>
.box-enter-active {
  animation: bounce-in 0.5s;
}

.box-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid transparent;
}
</style>
