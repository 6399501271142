<template>
  <DPHomeBanner class="mb-4" tag="Private" />
  <div
    class="grid grid-cols-2 gap-3 mb-8"
    un-children="p-4 h-22 rd-lg bg-gray-800 pr-20 cursor-pointer bg-no-repeat bg-contain bg-right"
  >
    <div class="bgi-private-create-bg" @click="$router.push('/g/p')">
      <h4>{{ $t('game.CREATE_GAME') }}</h4>
      <p class="c-gray mt-1" portrait="hidden">{{ $t('PRIVATE_CREATE_TIPS') }}</p>
    </div>
    <div class="bgi-private-join-bg" @click="showSearch = true">
      <h4>{{ $t('JOIN_GAME') }}</h4>
      <p class="c-gray mt-1" portrait="hidden">{{ $t('PRIVATE_JOIN_TIPS') }}</p>
    </div>
  </div>
  <ATitle class="fw-bold text-2xl mb-4">{{ $t('MY_PRIVATE_GAME') }}</ATitle>
  <div v-if="!tables.length" class="text-center pt-8">
    <img class="w-46" src="@/assets/img/no-data-0.png" alt="" />
    <div class="c-gray">{{ $t('NO_GAME_IN_PROGRESS') }}</div>
  </div>
  <div
    v-for="(t, i) in tables"
    :key="i"
    class="rd-lg bg-gray-800 p-4 mb-3 cursor-pointer"
    @click="$router.push(`/g/p/${t.roomId}`)"
  >
    <div class="flex mb-2">
      <h3>{{ t.roomName }}</h3>
      <div class="fw-900 rd bg-dark-900 c-yellow italic px-1 ml-2">{{ t.gameMode }}</div>
    </div>
    <div class="flex items-center c-gray-200 mb-6">
      <DPAvatar class="text-xs" :name="t.ownerName" :avatar="t.ownerAvatar" />
      <div class="mx-1">{{ t.ownerName }}</div>
      <div class="i-local-buyin ml-a" />
      <div class="mx-1">
        {{ t.blindStructure.smallBlind }}/{{ t.blindStructure.bigBlind }}({{ t.blindStructure.ante }})
      </div>
    </div>
    <div class="flex">
      <DPAvatar
        v-for="(u, i) in t.userList"
        :key="i"
        class="text-xl -mr-2"
        :name="u.userName"
        :avatar="u.userAvatarUrl"
      />
      <div
        class="rd-full p-1 h-9 px-3 ring-2 ring-offset-2 ring-offset-gray-800 ring-gray-600 flex items-center bg-gray-600 ml-a"
      >
        <div class="i-local-user" />
        {{ t.playerCount }}/{{ t.maxPlayerCount }}
      </div>
    </div>
  </div>
  <ADialog v-model="showSearch" class="w-100" :title="$t('ENTER_ROOM_PIN_TO_JOIN')">
    <div class="text-center c-gray">{{ $t('JOIN_GAME_AND_COMPETE_WITH_FRIENDS') }}</div>
    <ASplitInput class="text-xl my-4" v-model="pin" :len="6" />
    <template #footer>
      <AButton :action="searchRoom">{{ $t('JOIN') }}</AButton>
    </template>
  </ADialog>
</template>
<script setup lang="ts">
import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const showSearch = ref(false);
const pin = ref('');

const tables = ref<
  {
    blindStructure: {
      ante: number;
      bigBlind: number;
      smallBlind: number;
    };
    gameMode: string;
    ownerAvatar: string;
    ownerName: string;
    playerCount: number;
    maxPlayerCount: number;
    roomId: string;
    roomName: string;
    userList: { userAvatarUrl: string; userName: string }[];
  }[]
>([]);

authAPI.get('v1/private/dashboard').then((v) => (tables.value = v));

const searchRoom = () => {
  authAPI.get('v1/private/search', { searchCode: pin.value }).then(({ roomID }) => {
    router.push(`/g/p/${roomID}`);
  });
};
</script>
