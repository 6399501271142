<template>
  <div class="flex space-x-2 mb-4" un-children="flex-1 text-center rd-lg p-1.5  b-solid cursor-pointer">
    <h5
      :class="$route.name === 'sng-rank-d' ? 'b-green' : 'bg-gray-800 b-gray-800 c-gray-300'"
      @click="$router.replace('/s/rank')"
    >
      {{ $t('DAILY_RANK') }}
    </h5>
    <h5
      :class="$route.name === 'sng-rank-m' ? 'b-green' : 'bg-gray-800 b-gray-800 c-gray-300'"
      @click="$router.replace('/s/rank/m')"
    >
      {{ $t('SEASON_RANK') }}
    </h5>
  </div>
  <RouterView />
</template>
