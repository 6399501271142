<template>
  <div class="c-green cursor-pointer self-end h-0" @click="markReaded()">{{ $t('MARK_AS_READED') }}</div>
  <h3 class="text-center mb04">Inbox</h3>
  <div class="flex mb-4" un-children="relative text-center flex-1 p-2 b-b-solid cursor-pointer">
    <h5 v-for="(v, i) in tabs" :key="i" :class="tab === i ? 'b-green bg-green/20' : 'b-transparent'" @click="setTab(i)">
      {{ $t(v.typeCode) }}
      <h6 v-if="unreadData[v.typeCode]" class="absolute -top-2 right-0 size-5 rd-full bg-red text-center">
        {{ unreadData[v.typeCode] }}
      </h6>
    </h5>
  </div>
  <div
    v-if="(tabs[tab].children?.length || 0) > 1"
    class="flex mb-4 space-x-2"
    un-children="text-center flex-1 rd-lg p-2 b-solid cursor-pointer"
  >
    <h6
      v-for="(v, i) in tabs[tab].children"
      :key="i"
      class="flex items-center justify-center"
      :class="subTab === i ? 'b-green' : 'b-transparent bg-gray-800 c-gray'"
      @click="subTab = i"
    >
      {{ $t(v.typeCode || 'ALL') }}
    </h6>
  </div>
  <div v-if="messages?.length === 0" class="pt-20">
    <img class="h-40 m-a block" src="@/assets/img/empty-msg.png" alt="" />
    <p class="text-center c-gray">{{ $t('NO_MESSAGE_HERE') }}</p>
  </div>
  <div class="flex-1 of-auto">
    <div v-for="(msg, i) in messages" :key="i" class="flex b-b-solid b-gray-700 pb-4 mb-4">
      <img v-if="msg.icon" :src="msg.icon" alt="" class="rd-full shrink-0 object-cover size-12 mr-4" />
      <div v-else class="size-12 shrink-0 rd-full bg-green p-3 mr-4">
        <div class="size-full i-local-bell" />
      </div>
      <div class="space-y-2">
        <h3>{{ msg.subject }}</h3>
        <div class="c-white/80" v-html="msg.content" />
        <h5
          v-if="msg.buttonText"
          class="c-green flex items-center cursor-pointer"
          @click="$router.push(msg.forwardLink)"
        >
          {{ $t(msg.buttonText) }}
          <span class="i-local-arrow-l rotate-180 ml-1" />
        </h5>
        <p class="c-gray text-base">{{ dt(msg.receiveTime).strDatetime }}</p>
      </div>
    </div>
    <ALoader v-if="messages?.length !== 0" :key="tab + ':' + subTab" :loader="loadData" />
  </div>
</template>
<script setup lang="ts">
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const tab = ref(0);
const subTab = ref(0);
const messages = ref<any[]>();
const unreadData = ref<Record<string, number>>({});

const tabs = [
  {
    children: [{}, { typeCode: 'MTT' }, { typeCode: 'SNG' }, { typeCode: 'HOLDEM' }],
    typeCode: 'GAMES',
  },
  { typeCode: 'CLAN' },
  {
    children: [{}, { typeCode: 'TICKET' }, { typeCode: 'GOLD' }, { typeCode: 'DIAMOND' }],
    typeCode: 'PACKAGE',
  },
  { typeCode: 'SYSTEM' },
];

const { locale } = useI18n();
watch(
  () => [tab.value, subTab.value],
  () => (messages.value = undefined),
);

const loadData = async (id?: number) => {
  const data = await authAPI.get('inbox/pageUserInbox', {
    language: locale.value,
    lastId: id,
    pageSize: 10,
    categoryCode: tabs[tab.value].typeCode,
    subCategoryCode: tabs[tab.value].children?.[subTab.value].typeCode,
  });
  if (!messages.value) messages.value = [];
  messages.value?.push(...data);
  if (data.length < 10) return 'end';
  return data[9].id;
};

const setTab = (i: number) => {
  tab.value = i;
  subTab.value = 0;
  markReaded(tabs[i].typeCode);
};

authAPI.get('inbox/inboxUnreadCount').then((v) => {
  unreadData.value = v || {};
});

const markReaded = (categoryCode = '') => {
  authAPI.post('inbox/batchReadMessage', { categoryCode }).then(() => {
    unreadData.value[categoryCode] = 0;
    if (!categoryCode) unreadData.value = {};
  });
};
</script>
