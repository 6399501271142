<template>
  <ADialog class="w-110" v-model="showLanguage" :title="$t('LANGUAGE')">
    <div class="grid grid-cols-2 gap-3">
      <div
        v-for="(lang, i) in LANGS"
        :key="i"
        class="rd-lg b-solid p-3 cursor-pointer"
        :class="locale === lang.val ? 'b-green' : 'b-transparent'"
        @click="updateLang(lang.val)"
      >
        {{ lang.label }}
      </div>
    </div>
  </ADialog>
</template>
<script lang="tsx" setup>
import { lang, showLanguage } from '@/stores/lang';
import { native } from '@/utils/device';
import { LANGS } from '@/utils/language';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();

const updateLang = (v: any) => {
  locale.value = v;
  lang.value = v;
  native?.updateLang(v);
};
</script>
