<template>
  <div class="space-y-3 py-4 text-lg" v-if="detail">
    <AUpload class="self-center" :maxSize="1e6" v-model="detail.logoUrl">
      <img v-if="detail.logoUrl" class="block h-27 w-27" :src="detail.logoUrl" />
      <div v-else class="h-27 w-27 rd b-solid b-gray-600"></div>
    </AUpload>
    <div class="text-center">
      <h4>LOGO</h4>
      <p class="c-gray-300">At least 150x150, PNG, JPG, size&lt;1M</p>
    </div>
    <AAuth value="clan_advanced_setting" :or="isOwner">
      <AInput :disabled="!isOwner" v-model="detail.name" :limit="40">{{ $t('CLAN_NAME') }}</AInput>
      <template #fallback>
        <div>{{ $t('CLAN_NAME') }}</div>
        {{ detail.name }}
      </template>
    </AAuth>
    <AInput v-model="detail.description" :rows="4" :limit="500">{{ $t('CLAN_DESCRIPTION') }}</AInput>
    <AInput v-model="detail.rules" :rows="4" :limit="500">{{ $t('CLAN_RULES') }}</AInput>

    <div class="b-t-solid b-gray-800 flex justify-between items-center pt-3">
      <div>{{ $t('REGISTRATION_REVIEW') }}</div>
      <InputSwitch
        :modelValue="detail.approveMode !== 0"
        @update:modelValue="(v) => (detail!.approveMode = v ? 1 : 0)"
      />
    </div>
    <div v-if="detail.approveMode">
      <AInput v-model="detail.registrationGuidance" :limit="50">{{ $t('REGISTRATION_GUIDANCE') }}</AInput>
    </div>
    <div v-if="detail.approveMode" class="flex justify-between items-center pt-3">
      <div>{{ $t('UPLOAD_IMAGE') }}</div>
      <InputSwitch
        :modelValue="detail.approveMode === 2"
        @update:modelValue="(v) => (detail!.approveMode = v ? 2 : 1)"
      />
    </div>
    <AAuth value="clan_advanced_setting">
      <div class="b-t-solid b-gray-800 flex justify-between items-center pt-3">
        <div>{{ $t('CLAN_SIZE') }}</div>
        <ASelect class="text-sm" v-model="detail.maxMembers" reverse :options="[500, 1000, 1500, 2000, 3000, 5000]">
          <template #default="{ label }">
            <div class="flex-1 px-1 text-left fw-normal">{{ label }}</div>
            <div class="text-1.5em i-local-arrow-d" />
          </template>
        </ASelect>
      </div>
      <div class="flex">
        <h4 class="mr-auto">{{ $t('CLAN_ACCESS') }}</h4>
        <InputSwitch v-model="detail.isPublic" />
      </div>
      <div v-for="(item, i) in groups" :key="i">
        <AInput>
          <h5>{{ item.name }} link</h5>
        </AInput>
      </div>
    </AAuth>
    <AButton @click="sumbit">{{ $t('SAVE') }}</AButton>
  </div>
</template>
<script lang="ts" setup>
import router from '@/router';
import type { Clan } from '@/types';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';

const props = defineProps<{ id: string; isOwner?: boolean }>();

const detail = ref<Clan>();

const groups = [{ name: 'Facebook' }, { name: 'X' }, { name: 'discord' }, { name: 'telegram' }, { name: 'debox' }];

authAPI.get('v1/clan/getById', { clanId: props.id }).then(async (res) => {
  detail.value = res.clanVO;
});

const sumbit = async () => {
  if (!detail.value) return;
  await authAPI.post('v1/clan/edit', {
    clanId: detail.value.clanId,
    name: detail.value.name,
    description: detail.value.description,
    logoUrl: detail.value.logoUrl,
    registrationGuidance: detail.value.registrationGuidance,
    isPublic: detail.value.isPublic,
    rules: detail.value.rules,
    approveMode: +detail.value.approveMode,
    maxMembers: detail.value.maxMembers,
  });
  router.back();
};
</script>
