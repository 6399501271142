<script setup lang="ts">
import { showLogin, user } from '@/stores';
import { convertToLangeLabel } from '@/utils/strings';
import { computed, watch } from 'vue';

const emits = defineEmits<{ rabbit: [] }>();

const cardsMask = defineModel<[boolean[], boolean[]]>('mask', { default: [[], []] });

const props = defineProps<{
  cards: string[];
  twice?: string[];
  hl?: string[];
  name?: string;
  winners: string[];
  rabbitCards?: string[];
  simple?: boolean;
  slow?: boolean;
}>();

watch(
  () => props.cards.length,
  () => {
    if (!props.cards.length) {
      cardsMask.value[1] = [];
      if (!props.twice?.length) cardsMask.value[0] = [];
    }
  },
  { immediate: true },
);

const cardGroups = computed(() => {
  const res = Array(5)
    .fill(0)
    .map((_, i) => [props.twice?.[i], props.cards[i]]);
  return res
    .map((v) => {
      if (!v[0] && !v[1]) return [];
      if (!v[0]) return [v[1]];
      if (!v[1]) return [v[0], ''];
      if (v[0] === v[1]) return [v[0]];
      return v;
    })
    .filter((v) => v.length);
});

function rabbitHunt(v: string) {
  if (!user.value) return (showLogin.value = true);
  if (!v) emits('rabbit');
}

const updateMask = (row: number, i: number) => {
  Object.assign(cardsMask.value[row], Array(i + 1).fill(true));
};
</script>
<template>
  <div class="flex flex-col items-center">
    <div v-if="name" class="relative h-7 w-70 text-center bgi-hand-flare bg-[length:100%] bg-top bg-no-repeat">
      <div class="table-hl-text text-2xl">{{ $t(convertToLangeLabel(name)).toUpperCase() }}</div>
    </div>
    <div v-else class="h-7 flex items-center gred-pad">
      <h5 v-if="winners[0]" class="px-3">{{ winners[0] }} Win #1</h5>
    </div>
    <div class="relative grid grid-cols-5 place-items-center h-6em w-16em z-2">
      <div v-for="(g, i) in cardGroups" class="h-3.9em w-2.8em" :key="i">
        <transition appear name="flip-in" @after-enter="updateMask(0, i)">
          <DPCard
            class="relative"
            :class="{ 'bottom-23% right-10%': g.length > 1, 'duration-1500': slow }"
            :name="g[0]"
            :disabled="hl && !hl.includes(g[0] || '')"
            :hl="hl?.includes(g[0] || '')"
          />
        </transition>
        <transition name="flip-in" @after-enter="updateMask(1, i)">
          <DPCard
            v-if="g[1]"
            class="relative z-1 bottom-77% left-10%"
            :class="{ 'duration-1500': slow }"
            :name="g[1]"
            :hl="hl?.includes(g[1])"
            :disabled="hl && !hl.includes(g[1])"
          />
        </transition>
      </div>
      <Transition v-for="(c, i) in rabbitCards" :key="i" :name="c ? '' : 'fade-in'" appear>
        <DPCard :simple="simple" rabbit :name="c" @click="rabbitHunt(c)" />
      </Transition>
    </div>
    <div v-if="rabbitCards && !simple" class="h-0">
      <p class="flex items-center gred-pad px-6 -mt-3">
        {{ $t('RABBIT_HUNTING') }}: <img class="w-3 mx-1" src="@/assets/img/diamond.png" alt="" /> 1
      </p>
    </div>
    <div class="h-7 flex items-center gred-pad">
      <h5 v-if="winners[1]" class="px-3">{{ winners[1] }} Win #2</h5>
    </div>
  </div>
</template>
<style>
.table-hl-text {
  font-weight: 800;
  font-style: italic;
  -webkit-text-stroke: 0.3px rgba(255, 250, 139, 0.5);
  background: linear-gradient(#ffc93d, #f9e36d, #de9c2b);
  -webkit-background-clip: text;
  color: transparent;
  filter: drop-shadow(1px 0.5px #c07c00) drop-shadow(0 2px 4px #441d00);
}
</style>
