import { useRafFn } from '@vueuse/core';
import { type Ref } from 'vue';

export const useTimer = (data: Record<string, Ref<number | undefined>>) => {
  const value = { ...data };
  let last = Date.now();
  const trigger = () => {
    const now = Date.now();
    const diff = (now - last) / 1000;
    last = now;
    Object.values(value).forEach((v) => {
      if (!v.value) return;
      v.value = v.value - diff;
      if (v.value < 0) v.value = 0;
    });
  };
  useRafFn(trigger);
  const set = (key: string, val: Ref<number | undefined>) => {
    trigger();
    value[key] = val;
  };
  return { trigger, set, value };
};
export const cutDown = useTimer({});
