<template>
  <swiper
    :centeredSlides="true"
    :loop="true"
    :autoplay="{ delay }"
    :navigation="{
      nextEl: '.swiper-pre',
      prevEl: '.swiper-next',
      enabled: true,
    }"
    :modules="modules"
  >
    <swiper-slide v-for="(v, i) in items" :key="i">
      <slot :value="v" />
    </swiper-slide>
    <slot name="btns">
      <div
        class="flex h-0 items-center justify-between absolute top-50% w-full z-1"
        un-children="rd-full b-solid c-green p-2"
      >
        <div class="swiper-next">
          <div class="i-local-arrow-l" />
        </div>
        <div class="swiper-pre">
          <div class="i-local-arrow-d -rotate-90" />
        </div>
      </div>
    </slot>
  </swiper>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';
const modules = [Autoplay, Navigation];

withDefaults(defineProps<{ items: any[]; delay?: number }>(), {
  delay: 3000,
});
</script>
