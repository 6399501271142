<template>
  <div class="py-4">
    <div class="flex flex-wrap children:flex-1">
      <div class="basis-75 mr-4 mb-4">
        <h1 class="c-green">{{ $t('INVITE_YOUR_FRIENDS') }}</h1>
        <p class="my-4">{{ $t('COPY_LINK_TO_SHARE_WITH_YOUR_FRIENDS') }}</p>

        <div class="p-inputgroup" @click="copyUrl">
          <InputText class="w-full" :modelValue="`lepoker.io/home?r=${refCode}`" readonly />
          <AButton class="rd-l-none">{{ $t('COPY_LINK') }}</AButton>
        </div>
      </div>
    </div>
    <ATitle class="fw-bold mb-4">{{ $t('REFERRAL_STATS') }}</ATitle>
    <div class="flex mb-4 children:flex-1">
      <div class="rd bg-gray-900 p-4 mr-4">
        <p>{{ $t('POINTS_EARNED') }}</p>
        <h2 class="c-green">{{ $t('COMING_SOON') }}</h2>
      </div>
      <div class="rd bg-gray-900 p-4">
        <p>{{ $t('INVITED_FRIENDS') }}</p>
        <h2 class="c-green">{{ count }}</h2>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toast } from '@/components/ui/AToast.vue';
import { refCode } from '@/stores/user';
import { authAPI } from '@/utils/network';
import { genRefUrl } from '@/utils/user';
import { useClipboard } from '@vueuse/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { copy } = useClipboard({ legacy: true });

const { t } = useI18n();

const count = ref(0);
authAPI.get<any[]>('invitation/getInvitationList').then((v) => (count.value = v.length));

function copyUrl() {
  copy(genRefUrl('/home')).then(() => toast.success(t('LINK_COPIED')));
}
</script>
