<template>
  <div v-if="detail" class="pt-7 of-auto">
    <div class="flex justify-around relative">
      <h5
        v-for="l in ['overview', 'players', 'tables', 'blinds']"
        :key="l"
        class="text-center p-2 flex-1 b-2 b-b-solid cursor-pointer items-center justify-center"
        :class="tab === l ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="updateTab(l)"
      >
        {{ $t('mtt.' + l.toUpperCase()) }}
      </h5>
      <h5
        class="relative text-center p-2 b-2 b-b-solid cursor-pointer"
        :class="[
          tab === 'authorization' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent',
          {
            hidden: !showAuth,
            'flex-1': landscape,
          },
        ]"
        @click="updateTab('authorization')"
      >
        <span v-if="landscape">{{ $t('mtt.AUTHORIZATION') }}</span>
        <div v-else class="w-5 h-5 i-local-bell mx-2" />
        <div v-if="authUsers?.length" class="absolute top-0 right-0 text-xs w-4 rd-full bg-red w-4 c-gray-50">
          {{ authUsers?.length }}
        </div>
      </h5>
      <DPMttDraw :id="id" :locked="!(status && [2, 5, 6].includes(status.status))" />
    </div>
    <div
      v-if="showAuth && !landscape"
      class="absolute top-4px right-64px p-10px z-1"
      @click="$router.replace(`/m/${id}/authorization`)"
    >
      <div v-if="authUsers?.length" class="absolute top-0 right-0 text-12px w-16px text-center bg-red rd-full">
        {{ authUsers?.length }}
      </div>
      <div class="w-30px h-30px i-local-bell" />
    </div>
    <div class="pt-3 flex-1 relative of-auto">
      <div v-if="tab === 'overview'" class="[&_p]:c-gray-300">
        <header class="relative flex items-start" portrait="flex-col">
          <AButton
            v-if="detail.enableAnnouncement"
            class="!absolute right-0 !c-green text-sm"
            type="secondary"
            icon="i-local-announce"
            @click="openAnnouncement"
            >{{ $t('ANNOUNCEMENT') }}
          </AButton>
          <div class="flex flex-col items-start" portrait="flex-row items-end">
            <DPAvatar v-if="detail.tableLogoUrl" class="text-5rem shrink-0 mb-3" :avatar="detail.tableLogoUrl" />
            <div portrait="ml--4" class="mb-5" v-if="detail.socialAccountList?.length">
              <p class="pl-3 pb-2 op-70" portrait="pl-8">{{ $t('mtt.CLICK_TO_PAGE') }}</p>
              <div
                class="flex space-x-1 py-1 px-3 rounded-full bg-gradient-to-r from-[#3E3F4900] via-[#3E3F49] to-[#3E3F49]"
                portrait="space-x-4 px-8"
              >
                <template v-for="item in detail.socialAccountList" :key="item.socialType">
                  <a v-if="item.socialId" :href="item.socialId" target="_blank">
                    <img :alt="item.socialType" :src="socialImgs[item.socialType]" class="h-8 w-8" />
                  </a>
                  <img v-else :alt="item.socialType" :src="socialImgs[item.socialType]" class="h-8 w-8 op-50" />
                </template>
              </div>
            </div>
          </div>

          <div class="space-y-2 mb-3" landscape="ml-8">
            <h3>{{ detail.mttName }}</h3>
            <p class="op-70 ws-pre-wrap" v-html="encodeText(detail.description)"></p>
          </div>
        </header>
        <AButton
          class="w-full mb-2"
          icon="i-local-ticket"
          @click="router.push({ path: `/m/t/${props.id}` })"
          v-if="showTickets"
        >
          {{ $t('mtt.TICKET_MANAGEMENT') }}
        </AButton>
        <div class="rd-lg bg-gray-800 mb-6 of-hidden">
          <h3 v-if="detail.status === 1" class="py-5 text-center c-blue">{{ $t('mtt.REG_OPEN_IN') }}</h3>
          <h3 v-else-if="detail.status === 2" class="py-5 text-center c-green">{{ $t('mtt.START_IN') }}</h3>
          <h3 v-else-if="detail.status < 5" class="py-5 text-center c-blue">{{ $t('mtt.RUNNING_FOR') }}</h3>
          <DPCountdown v-if="detail.status < 5" class="pb-5 justify-center text-2.5rem" :value="countDownSec" />
          <div
            class="grid grid-cols-2"
            landscape="[&:nth-child(4n)]:children:bg-dark-800 [&:nth-child(4n+3)]:children:bg-dark-800"
            portrait="grid-cols-1 even:children:bg-dark-800"
            un-children="flex px-3 h-11 items-center justify-between"
          >
            <div>
              <p>{{ $t('STATUS') }}</p>
              <div
                class="p-1 fw-bold text-xs rd"
                :class="{
                  'bg-yellow': detail.status === 1,
                  'bg-green': detail.status === 2,
                  'bg-red': detail.status === 3,
                  'bg-blue': detail.status === 4,
                  'bg-gray-500': detail.status > 4,
                }"
              >
                {{ $t(MttStatus[detail.status]) }}
              </div>
            </div>
            <div>
              <p>{{ $t('mtt.ORGANIZER') }}</p>
              <h6 class="flex items-center">
                {{ detail.hostName }}
                <AButton
                  v-if="detail.isHost || user?.permissions.includes('mtt_tranfer_owner')"
                  class="ml-2 text-xs py-1"
                  @click="showOwnerTransfer = true"
                >
                  {{ $t('TRANSFER') }}
                </AButton>
              </h6>
            </div>
            <div>
              <p>{{ $t('mtt.FIRST_ENTRIES') }}</p>
              <h6>{{ detail.totalEntries }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.RE_ENTRIES') }}</p>
              <h6>{{ detail.reEntries }}</h6>
            </div>
            <div
              v-if="
              detail.countryRestrict &&
              detail.countryRestrict!.restrictType != '' &&
              detail.countryRestrict!.restrictType != 'off'
            "
            >
              <p>{{ $t('mtt.IP_ACCESS_SETTING') }}</p>
              <div class="flex flex-wrap items-center justify-center">
                <h6 class="mr-4">{{ detail.countryRestrict.countryList.length }}</h6>
                <AButton class="py-1" @click="showRejectCountry = true">{{ $t('VIEW') }}</AButton>
              </div>
              <!-- <template v-for="value in detail.countryRestrict.countryList" :key="value">
                <img class="h-4 mr-2" alt="" :src="`/flags/${value}.png`" />
              </template> -->
            </div>
            <div
              v-if="
              detail.clanRestrict &&
              detail.clanRestrict!.restrictType != '' &&
              detail.clanRestrict!.restrictType != 'off'
            "
            >
              <p>{{ $t('CLAN_SETTINGS') }}</p>
              <div class="flex flex-wrap items-center justify-center">
                <h6 class="mr-4">{{ detail.clanRestrict.clanIdList.length }}</h6>
                <AButton class="py-1" @click="showRejectClan = true">{{ $t('VIEW') }}</AButton>
              </div>
            </div>
            <div v-if="detail.mttTask?.taskType">
              <p>{{ $t('mtt.TASK_REQUIREMENT') }}</p>
              <DPTaskList :taskList="detail.mttTask.taskType.split(',')" />
            </div>
          </div>
        </div>
        <div class="rd-lg pt-3 bg-gray-800 mb-6 of-hidden">
          <ATitle class="text-lg fw-bold c-green">{{ $t('mtt.PRIZE_POOL') }}</ATitle>
          <div class="p-4">
            <p class="ws-pre-wrap mb-2" v-html="encodeText(detail.prizeDescription)" />
            <div class="py-2 flex items-center justify-start" v-if="detail.prizeClaimDeadline">
              <div class="ws-pre-wrap flex items-center op-80 mr-2">
                <div class="i-local-clock mr-1 c-green" />
                {{ $t('mtt.DEADLINE_FOR_CLAIM') }}
              </div>
              <div class="ws-pre-wrap" v-if="detail.prizeClaimDeadline > currentTime">
                {{ dt(detail.prizeClaimDeadline).strDatetime }}
              </div>
              <div class="ws-pre-wrap" v-else>{{ $t('mtt.DEADLINE_OVER') }}</div>
            </div>
            <p v-if="detail.prizeDetail" class="p-2 rd-lg bg-gray-800 ws-pre-wrap">
              {{ $t('mtt.PRIZE_CLAIM_GUIDE') }}: {{ detail.prizeDetail }}
            </p>
          </div>
        </div>
        <div class="rd-lg pt-3 bg-gray-800 mb-6 of-hidden">
          <ATitle class="text-lg fw-bold c-green">{{ $t('mtt.TOURNAMENT_SETTINGS') }}</ATitle>
          <div
            class="grid grid-cols-2 pt-3"
            landscape="[&:nth-child(4n+1)]:children:bg-dark-800 [&:nth-child(4n+2)]:children:bg-dark-800"
            portrait="grid-cols-1 odd:children:bg-dark-800"
            un-children="flex px-3 h-11 items-center justify-between"
          >
            <div>
              <p>{{ $t('mtt.START_TIME') }}</p>
              <h6>{{ dt(detail.startTime * 1e3).strDatetime }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.REGISTRATION_OPEN') }}</p>
              <h6>{{ dt(detail.registrationStartTime * 1e3).strDatetime }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.LATE_REGISTRATION') }}</p>
              <h6 v-if="detail.lateRegistrationTime">
                {{ $t('mtt.format.MIN', { v: detail.lateRegistrationTime / 60 }) }}
              </h6>
              <h6 v-else class="case-upper">{{ $t('OFF') }}</h6>
            </div>
            <div>
              <p>{{ $t('REGISTRATION_REVIEW') }}</p>
              <h6 v-if="detail.regConfig?.length">
                {{ detail.regConfig.map((v) => `${$t('MIX_REG_TYPE_' + v.regType)} * ${v.regFee}`).join(' / ') }}
              </h6>
              <h6 v-else>{{ registMap[detail.autoRegApproval] }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.TABLE_SIZE') }}</p>
              <h6>{{ detail.maxPlayerNumber }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.ACTION_TIME') }}</p>
              <h6>{{ $t('X_SEC', { v: detail.clientActTimeOut }) }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.BLIND_STRUCTURE') }}</p>
              <AButton class="py-1" @click="$router.replace(`/m/${id}/blinds`)">{{ $t('VIEW') }}</AButton>
            </div>
            <div>
              <p>{{ $t('mtt.STARTING_CHIPS') }}</p>
              <h6>{{ detail.initBlind * initBlind }}({{ detail.initBlind }}BB)</h6>
            </div>
            <div>
              <p>{{ $t('mtt.OBSERVER_MODE') }}</p>
              <h4 :class="detail.observeMode ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <div>
              <p>{{ $t('mtt.RTC_SETTING_LABEL') }}</p>
              <h4 :class="detail.videoChat ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <div>
              <p>{{ $t('RABBIT_HUNTING') }}</p>
              <h4 :class="detail.enableRabbitHunting ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <!-- <div>
              <p>{{ $t('mtt.RE_ENTRY') }}</p>
              <h6>
                {{ $t('mtt.format.TIMES', { v: detail.reEntryLimits }) }}
              </h6>
            </div>

            <div>
              <p>{{ $t('mtt.INSTANT_REGISTRATION') }}</p>
              <h6>{{ entryOptions[detail.autoRegApproval] }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.BREAK_TIME') }}</p>
              <h6 v-if="detail.breakTime">
                {{ $t('mtt.format.MIN', { v: detail.breakTime / 60 }) }}
              </h6>
              <h4 v-else class="i-local-remove c-red" />
            </div>
 -->
            <!-- <div>
              <p>{{ $t('mtt.ALLOW_QUITE') }}</p>
              <h4
                :class="detail.allowQuit ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']"
              />
            </div> -->
          </div>
        </div>
        <div class="rd-lg pt-3 bg-gray-800 mb-6 of-hidden">
          <ATitle class="text-lg fw-bold c-green">{{ $t('PROFESSIONAL_SETTINGS') }}</ATitle>
          <div
            class="grid grid-cols-2 pt-3"
            landscape="[&:nth-child(4n+1)]:children:bg-dark-800 [&:nth-child(4n+2)]:children:bg-dark-800"
            portrait="grid-cols-1 odd:children:bg-dark-800"
            un-children="flex px-3 h-11 items-center justify-between"
          >
            <div>
              <p>{{ $t('mtt.GAME_TYPE') }}</p>
              <h6>{{ detail.gameType }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.REGISTER_PLAYERS') }}</p>
              <h6>{{ detail.minPlayers }}-{{ detail.maxPlayers }}</h6>
            </div>
            <div>
              <p>{{ $t('REWARD_CLAIM') }}</p>
              <h4
                :class="
                  detail.prizePool?.prizePoolType !== 3 ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']
                "
              />
            </div>
            <template v-if="detail.prizePool?.prizePoolType !== 3">
              <div>
                <p>{{ $t('mtt.PRIZE_POOL_SIZE') }}</p>
                <h6 v-if="detail.prizePool">
                  {{
                    detail.prizePool.prizePoolType === 1
                      ? detail.prizePool.prizePoolValue
                      : `${detail.prizePool.prizePoolRate}% (${
                          detail.prizePool.prizePoolValue
                            ? detail.prizePool.prizePoolValue
                            : Math.ceil(((detail.prizePool.prizePoolRate || 0) * (detail.totalEntries || 0)) / 100)
                        }
                  ${$t('mtt.PLAYERS')})`
                  }}
                </h6>
                <h6 v-else>{{ detail.prizePoolSize }}</h6>
              </div>
              <!-- <div>
                <p>{{ $t('CLAIM_METHOD') }}</p>
                <h6>{{ detail.prizePoolType === 1 ? 'popup' : 'address' }}</h6>
              </div> -->
            </template>
            <div>
              <p>{{ $t('REBUY') }}</p>
              <h4 :class="detail.reEntryLimits ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <template v-if="detail.reEntryLimits">
              <div>
                <p>{{ $t('MAX_REBUY_PER_PLAYER') }}</p>
                <h6>{{ detail.reEntryLimits }}</h6>
              </div>
              <div>
                <p>{{ $t('REBUY_AUTHORIZATION') }}</p>
                <h6 v-if="detail.reEntryConfig?.length">
                  {{ detail.reEntryConfig.map((v) => `${$t('MIX_REG_TYPE_' + v.regType)} * ${v.regFee}`).join('/') }}
                </h6>
                <h6 v-else>{{ entryOptions[detail.autoReEntry] }}</h6>
              </div>
            </template>
            <div>
              <p>{{ $t('mtt.TIME_BANK') }}</p>
              <h4 :class="detail.timeBank ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <template v-if="detail.timeBank">
              <div>
                <p>{{ $t('mtt.TIME_BANK_PERIOD') }}</p>
                <h6>{{ $t('X_SEC', { v: detail.timeBank.duration }) }}</h6>
              </div>
              <div>
                <p>{{ $t('START_TIME_BANK_TIMES') }}</p>
                <h6>{{ $t('mtt.format.TIMES', { v: detail.timeBank.number }) }}</h6>
              </div>
              <div>
                <p>{{ $t('mtt.ADDITIONAL_TIME_BANK') }}</p>
                <h6>{{ $t('mtt.format.HAND', { v: detail.timeBank.addPolicy }) }}</h6>
              </div>
            </template>
            <div>
              <p>{{ $t('CLAN') }}</p>
              <h4 v-if="!detail.clanConfig?.type" class="i-local-remove c-red" />
              <h6 v-else-if="detail.clanConfig.type === 1">{{ $t('REGISTER_GAME_AND_JOIN_CLAN') }}</h6>
              <h6 v-else>{{ $t('JOIN_CLAN') }}</h6>
            </div>
          </div>
        </div>
        <div class="rd-lg pt-3 bg-gray-800 mb-6">
          <ATitle class="text-lg fw-bold c-green">{{ $t('ADMIN_SETTINGS') }}</ATitle>
          <div
            class="grid grid-cols-2 pt-3"
            landscape="[&:nth-child(4n+1)]:children:bg-dark-800 [&:nth-child(4n+2)]:children:bg-dark-800"
            portrait="grid-cols-1 odd:children:bg-dark-800"
            un-children="flex px-3 h-11 items-center justify-between"
          >
            <div>
              <p>{{ $t('mtt.MTT_TYPE') }}</p>
              <h6>{{ mttTypeMap[detail.mttType] }}</h6>
            </div>
            <div>
              <p>{{ $t('mtt.REGISTRATION_CONDITION') }}</p>
              <h4 :class="detail.mttTask?.taskType ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
            <div>
              <p>{{ $t('SHOW_CLAN_RANKINGS') }}</p>
              <h4 :class="detail.showClanRanking ? ['i-local-check', 'c-green'] : ['i-local-remove', 'c-red']" />
            </div>
          </div>
        </div>
        <div class="rd-lg pt-3 bg-gray-800 mb-6">
          <ATitle class="text-lg fw-bold c-green">{{ $t('mtt.LOGO_TABLE_BG') }}</ATitle>
          <div class="flex items-center justify-around pb-5 mt-4">
            <img class="w-30 h-30 object-cover rd-full" :src="detail.tableLogoUrl" alt="" />
            <div class="table-landscape w-40 h-20 relative z-0">
              <div
                class="custom-bg absolute inset-0"
                :style="{
                  backgroundImage: `url(${detail.themeType ? detail.themeRoomConfig.tableBackgroundUrl : detail.tableBackgroundUrl})`,
                }"
              />
            </div>
            <div class="table-portrait w-19 h-27 relative z-0">
              <div
                class="custom-bg absolute inset-0"
                :style="{
                  backgroundImage: `url(${detail.themeType ? detail.themeRoomConfig.verticalTableBackgroundUrl : detail.verticalTableBackgroundUrl})`,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <PlayerPage
        class="h-full"
        v-if="tab === 'players'"
        :id="id"
        :showClan="!!detail.showClanRanking"
        :show-export="showExport"
      />
      <TablePage
        v-if="tab === 'tables'"
        :id="id"
        :opened="[3, 4, 8].includes(detail.status)"
        :observe="detail.observeMode"
      />
      <div v-if="tab === 'blinds'">
        <DPBlindTable :duration="detail.blindDuration / 60" :id="id" />
      </div>
      <div v-if="tab === 'authorization'">
        <div class="flex space-x-2 items-start h-26" v-for="(u, index) in authUsers" :key="index">
          <DPAvatar :id="u.userID" class="text-2xl" />
          <div class="flex-1 space-y-1 of-hidden">
            <h6>{{ u.playerName }}</h6>
            <p class="op-40">{{ $t(u.entryNumber ? 'mtt.APPLY_FOR_RE_ENTRY' : 'mtt.APPLY_FOR_REGISTRATION') }}.</p>
            <p v-if="u.remarks" class="op-40">remarks: {{ formatRemark(u.remarks) }}</p>
            <div v-if="u.clan" class="flex p-1 w-max max-w-full rd bg-gray-800 items-center">
              <img class="w-4 h-4 mr-1 rd" :src="u.clan.logoUrl" alt="" />
              <p class="truncate">{{ u.clan.name }}</p>
            </div>
            <p class="op-40">{{ dt(u.applyTime * 1e3).strDatetime }}</p>
          </div>
          <AButton type="secondary" @click="reject(u.userID)">{{ $t('mtt.REJECT') }}</AButton>
          <AButton @click="approve(u.userID)">{{ $t('mtt.APPROVE') }}</AButton>
        </div>
      </div>
    </div>
    <AAuth value="modify_mtt_setting" :or="detail.isHost">
      <AButton class="mt-3 !w-auto" outlined @click="createWithCurCfg">
        {{ $t('START_NEW_TOURNAMENT_WITH_STRUCTURE') }}
      </AButton>
    </AAuth>
    <footer class="flex space-x-3 bottom-0 py-3 bg-dark-900 children:flex-1 children:p-x-0">
      <AButton type="secondary" @click="share">{{ $t('mtt.SHARE') }}</AButton>
      <!-- <AButton type="secondary" @click="openClaim">{{ $t('mtt.SHARE') }}</AButton> -->
      <AAuth v-if="detail.status < 3 && !detail.preTable" value="modify_mtt_setting" :or="detail.isHost">
        <AButton :label="$t('mtt.EDIT')" type="secondary" @click="openEdit">
          {{ $t('mtt.EDIT') }}
        </AButton>
      </AAuth>
      <div v-if="showClaimButton" class="relative">
        <div
          class="bg-black text-center absolute top--6 b-yellow b-solid b-1 w-full rd-md pb-4"
          v-if="(detail.prizeClaimDeadline || 0) >= currentTime"
        >
          {{ $t('mtt.END_IN') }} {{ dur((detail.prizeClaimDeadline - currentTime) * 1e3) }}
        </div>
        <AButton
          class="w-full !c-black !bg-yellow"
          @click="openClaim"
          :disabled="!!detail.prizeClaimDeadline && detail.prizeClaimDeadline < currentTime"
          >{{ claimInfo ? $t('SUBMITTED') : $t('mtt.CLAIM_REWARD') }}
        </AButton>
      </div>
      <MTTTicketButton v-else-if="showRoom" :left-ticket="leftTicket">
        <AButton class="w-full" @click="openRoom">{{ $t('mtt.GO_TO_TABLE') }}</AButton>
      </MTTTicketButton>
      <AButton v-else-if="status?.status === 1 && detail.status < 4" disabled>{{ $t('mtt.WAIT_APPROVAL') }}</AButton>
      <MTTTicketButton v-else-if="showReEntry" :left-ticket="leftTicket">
        <AButton class="w-full" @click="regist(false)" :icon="detail.autoReEntry === 2 ? 'i-local-ticket' : ''">
          {{ $t('mtt.RE_ENTRY') }}
        </AButton>
      </MTTTicketButton>
      <MTTTicketButton v-else-if="showRegister" :left-ticket="leftTicket" :disabled="detail.status === 1">
        <div v-if="showTicketTips" class="absolute bottom-full w-full flex flex-col items-center">
          <div class="b-solid p-2 w-60 b-green mb-2 rd-lg bg-gray-900 flex items-center">
            <img class="w-14 mr-2" src="@/assets/img/ticket.png" alt="" />
            <h6>{{ $t('TICKET_REGISTER_GUILD') }}</h6>
          </div>
          <div class="animate-bounce">
            <img class="w-8 rotate-180" src="@/assets/img/hand.svg" alt="" />
          </div>
        </div>
        <AButton
          class="w-full"
          @click="regist(true)"
          :disabled="detail.status === 1"
          :icon="detail.autoRegApproval === 2 ? 'i-local-small-ticket' : ''"
        >
          {{ $t('mtt.REGISTER') }}
        </AButton>
      </MTTTicketButton>
      <template v-else-if="showUnRegister">
        <MTTTicketButton
          v-if="detail.addtionalTimeStamp! + 600 < detail.startTime"
          :left-ticket="leftTicket"
          :red="true"
        >
          <AButton outlined class="!c-red w-full" @click="showUnRegistDlg = true">
            {{ $t('mtt.UNREGISTER') }}
          </AButton>
        </MTTTicketButton>
        <MTTTicketButton v-else-if="status?.status !== 6" :left-ticket="leftTicket" :disabled="true">
          <AButton class="w-full" disabled>{{ $t('mtt.WAITING_FOR_MY_TABLE') }}</AButton>
        </MTTTicketButton>
      </template>
      <AButton v-if="detail.status > 2" @click="$router.replace(`/m/${id}/players`)">
        {{ $t('mtt.RANKING') }}
      </AButton>
      <AButton v-if="status?.status === 6 && detail.status < 4" @click="joinGameRequest">{{ $t('JOIN') }}</AButton>
    </footer>
    <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
      <div class="text-center">
        <ProgressSpinner strokeWidth="3" />
        <h5 class="mt-4">{{ $t('mtt.WAIT_TABLE_ALLOCATE') }}...</h5>
      </div>
    </Dialog>
    <ADialog class="w-140" v-model="showQuest">
      <template #header>
        <div class="flex items-center justify-between mb-4">
          <div
            class="flex justify-around items-center b-1 rd-md b-solid b-white/20 bg-gray-700 text-sm py-1 w-22 cursor-pointer"
            @click="$router.push('/profile/feedback')"
          >
            <i class="i-local-feedback c-dark-950" />
            {{ $t('FEEDBACK') }}
          </div>
          <h3 class="text-center">{{ $t('NOTICE') }}</h3>
          <div class="w-22" />
        </div>
      </template>
      <DPTask v-if="taskType" :mttTask="detail.mttTask" @regist="regist" @close="closeQuest" />
    </ADialog>
    <ADialog class="w-100" unclosable v-model="showUnRegistDlg">
      <div class="flex flex-col items-center p-2 space-y-2">
        <div class="i-local-info text-6xl c-red"></div>
        <h4>{{ $t('NOTICE') }}</h4>
        <p class="c-gray-400 text-center">{{ $t('mtt.UNREGISTER_TOURNAMENT_CONFIRM') }}</p>
      </div>
      <template #footer>
        <div class="flex space-x-2 children:flex-1">
          <AButton type="secondary" @click="unRegist">{{ $t('game.CONFIRM') }}</AButton>
          <AButton @click="showUnRegistDlg = false">{{ $t('CANCEL') }}</AButton>
        </div>
      </template>
    </ADialog>
    <ADialog class="w-100 p-6 pt-10" v-model="showFull">
      <img class="w-30 block m-auto mb-4" src="@/assets/img/review.png" alt="" />
      <div class="text-center space-y-4">
        <h5>{{ $t('FULL_REGISTRATION') }}</h5>
        <p class="px-4 text-center">
          {{ $t('MTT_PLEASE_FOLLOW_OUR_OFFICIAL_SOCIAL') }}
        </p>
        <DPSocailBtns />
      </div>
    </ADialog>
    <ADialog class="w-100" v-model="showRegisterSuccess">
      <div class="m-auto i-local-completed text-6xl c-green"></div>
      <div class="text-center space-y-4 pt-4">
        <h5>{{ $t('REGISTRATION_SUCCESSFULL') }}</h5>
        <p class="px-4 text-center">
          {{ $t('JOIN_SOCIAL_BEFORE_MTT_START', { v: dt(detail.startTime * 1e3).strDatetime }) }}
        </p>
        <DPSocailBtns />
      </div>
    </ADialog>
    <Dialog class="w-80" modal v-model:visible="showPlaydeckNotify" header=" " :pt="{ content: { class: 'mt--8' } }">
      <div class="text-center space-y-4">
        <div class="inline-block p-4 bg-blue-400 rd-full">
          <div class="i-local-telegram w-14 h-14"></div>
        </div>
        <h5>Go to LePoker TeLegram Offcial Group And Join MTT Game</h5>
        <a class="block bg-blue-400 rd p-2 c-white fw-bold" href="https://t.me/lepokergame/3" target="_blank">
          <span class="inline-block i-local-telegram text-1.5em align-top mr-2" /> {{ $t('OFFICIAL_GROUP') }}
        </a>
      </div>
    </Dialog>
    <ADialog class="w-100" v-model="showNoTicketNotice" :title="$t('NOTICE')">
      <div class="flex flex-col items-center p-2 space-y-2">
        <p class="c-gray-400 text-center">{{ $t('mtt.NO_TICKET_NOTICE') }}</p>
      </div>
      <template #footer>
        <AButton v-if="detail.mttType == 2" @click="$router.push('/m?tab=OFFICIAL')">
          {{ $t('JOIN_DAILY_MTT') }}
        </AButton>
        <Button
          class="bg-gray-700"
          @click="
            showNoTicketNotice = false;
            showTicketRedeem = true;
          "
          :label="$t('mtt.REDEEM_THE_TICKET')"
        ></Button>
      </template>
    </ADialog>
    <MTTTicketRedeem v-model="showTicketRedeem" @refresh="loadDetail" />
    <MTTOwnerTranfer
      v-model="showOwnerTransfer"
      @refresh="loadDetail"
      :mtt-id="props.id"
      :mtt-name="detail.mttName"
      v-if="detail.isHost || user?.permissions.includes('mtt_tranfer_owner')"
    />
    <ADialog class="w-110" v-model="showClan" :title="$t('CLAN_SELECTION')">
      <div class="space-y-4 px-1 text-center">
        <p>{{ $t('MTT_CHOOSE_CLAN_TIPS') }}</p>
        <Dropdown class="w-full" v-model="clan" :options="clans" option-value="id">
          <template #value="slotProps">
            <div class="flex items-center">
              <img
                v-if="slotProps.value"
                class="w-8 h-8 rd mr-2"
                :src="clans.find((v) => v.id === slotProps.value).logo"
                alt=""
              />
              <DPAvatar v-else class="mr-2 text-lg" :id="user?.id" />
              <div>{{ clans.find((v) => v.id === slotProps.value).name }}</div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex items-center">
              <img v-if="slotProps.option.id" class="w-8 h-8 rd mr-2" :src="slotProps.option.logo" alt="" />
              <DPAvatar v-else class="mr-2 text-lg" :id="user?.id" />
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <AButton class="w-full" @click="applyRegist">{{ $t('mtt.SUBMIT') }}</AButton>
      </div>
    </ADialog>
    <ADialog
      class="w-120"
      v-model="showRejectCountry"
      :title="'IP ' + $t(detail.countryRestrict!.restrictType.toUpperCase())"
    >
      <div class="flex flex-col items-center p-2 space-y-2">
        <p class="c-gray-400 text-center">
          {{ detail.countryRestrict!.restrictType == 'disallowed' ? $t('IP_RESTRICTED_DESCRIPTION_DISALLOWED') :
            $t('IP_RESTRICTED_DESCRIPTION_ALLOWED') }}
        </p>
      </div>
      <div class="grid grid-cols-2 pt-3" un-children="flex px-3 h-11 items-center justify-between">
        <div v-for="value in restrictCountryData" :key="value.code" class="flex items-center">
          <img class="h-6 mr-2" alt="" :src="`/flags/${value.code}.png`" />
          <div>{{ value.name }}</div>
        </div>
      </div>
      <template #footer>
        <AButton @click="showRejectCountry = false">{{ $t('OK') }}</AButton>
      </template>
    </ADialog>
    <ADialog class="w-120" v-model="showRejectClan" :title="$t('CLAN')">
      <div class="flex flex-col items-center p-2 space-y-2">
        <p class="c-gray-400 text-center">
          {{ detail.clanRestrict!.restrictType == 'disallowed' ? $t('CLAN_RESTRICTED_DESCRIPTION_DISALLOWED') :
            $t('CLAN_RESTRICTED_DESCRIPTION_ALLOWED') }}
        </p>
      </div>
      <div class="grid grid-flow-row-dense grid-cols-2 pt-3" un-children="flex px-3 h-15 items-center">
        <div v-for="value in restrictClanData" :key="value.clanId">
          <img class="h-12 mr-2 rounded-lg" alt="" :src="`${value.logoUrl}`" />
          <p class="break-all">{{ value.name }}</p>
        </div>
      </div>
      <template #footer>
        <AButton @click="showRejectClan = false">{{ $t('OK') }}</AButton>
      </template>
    </ADialog>
    <ADialog v-model="showTicketRedeemConfirm" class="w-100">
      <div class="flex flex-col items-center">
        <template v-if="user">
          <div class="c-gray">{{ $t('LOGIN_ACCOUNT') }}</div>
          <div class="rd-full flex bg-gray-700 my-2 p-1 pr-2 c-gray items-center">
            <DPAvatar class="text-xs mr-1" :id="user.id" /> {{ user.name }}
          </div>
        </template>
        <h4 class="c-green">{{ detail.mttName }}</h4>
        <DPPopItem class="w-45">
          <img class="w-35" src="@/assets/img/ticket.png" alt="" />
        </DPPopItem>
        <div class="c-gray w-full mb-2">{{ $t('TICKET_CODE') }}:</div>
        <h2 class="text-center b-solid b-gray-600 bg-gray-700 rd-lg p-1 w-full c-gray">{{ ticketCode }}</h2>
      </div>
      <template #footer>
        <div class="flex space-x-2" un-children="flex-1 ">
          <AButton v-if="user" type="secondary" @click="rejectTicketRedeem">{{ $t('NO_LOGOUT') }}</AButton>
          <AButton v-else type="secondary" @click="showTicketRedeemConfirm = false">{{ $t('CANCEL') }}</AButton>
          <AButton :action="redeemTicket">
            {{ $t('YES_REDEEM') }}
            <img class="absolute w-8 rotate-180 -top-12 right-10" src="@/assets/img/hand.svg" alt="" />
          </AButton>
        </div>
      </template>
    </ADialog>
    <GeetestCaptcha :config="config" @initialized="getCaptcha" />
    <ADialog v-model="showAdvanceReg" :title="$t('mtt.APPLY_FOR_RE_ENTRY')">
      <DPMTTAdvanceReg
        :cfg="advanceRegOptions"
        :chips="detail.initBlind * initBlind"
        :remain="curExistPlayer"
        @resolve="resolve"
        @reject="showAdvanceReg = false"
      />
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import router from '@/router';
import { countryData, landscape, showLogin, userTask } from '@/stores';
import { MttStatus, MttUserStatus } from '@/types';
import { dt, dur } from '@/utils/datetime';
import { token, authAPI, botAPI, refreshToken } from '@/utils/network';
import { useClipboard, useIntervalFn, useLocalStorage } from '@vueuse/core';
import { computed, nextTick, ref, watch } from 'vue';
import { useShareURL } from '@/tg/useShareURL';
import { user, tgUser, platform } from '@/stores';
import { useI18n } from 'vue-i18n';
import { useSound } from '@/stores/sound';
import { convertToLangeLabel, encodeText } from '@/utils/strings';
import { useDialog } from '@/components/ui/APopPad.vue';
import { toast } from '@/components/ui/AToast.vue';
import TablePage from './TablePage.vue';
import PlayerPage from './PlayerPage.vue';

import { socialImgs } from './socialIcons';
import { autoRedeemTicket, userPromise } from '@/stores/user';

import { GeetestCaptcha } from 'vue3-geetest';
import type { CaptchaConfig } from 'vue3-geetest';

// import { sliderVerification, type ApplySlider } from '@/types/sliderValidator';
// let applySlider: ApplySlider = sliderVerification({ enableDarkMode: 'force', userLanguage: navigator.language });
type Mtt = {
  verticalTableBackgroundUrl?: string;
  mttName: string;
  mttType: number;
  status: number;
  startTime: number;
  registrationStartTime: number;
  minPlayers: number;
  maxPlayers: number;
  prizePoolSize: number;
  prizePool?: {
    prizePoolType?: 1 | 2 | 3;
    prizePoolRate?: number;
    prizePoolValue?: number;
  };
  prizeClaimDeadline: number;
  lateRegistrationTime: number;
  reEntryLimits: number;
  maxPlayerNumber: number;
  clientActTimeOut: number;
  minBuyBlind: number;
  maxBuyBlind: number;
  initBlind: number;
  autoReEntry: number;
  autoRegApproval: number;
  allowQuit: boolean;
  isHost?: boolean;
  hostName?: string;
  description?: string;
  breakTime?: number;
  observeMode?: boolean;
  blindDuration: number;
  totalEntries?: number;
  timeBank?: { duration: number; number: number; addPolicy: number };
  tableLogoUrl?: string;
  tableBackgroundUrl?: string;
  gameType?: string;
  waiting?: boolean;
  reEntries?: number;
  preTable?: boolean;
  addtionalTimeStamp?: number;
  mttTask?: { id: number; mttID: string; taskType: string; taskConfig: any[] };
  prizeDetail: string;
  prizeDescription: string;
  prizeHighLight: string;
  countryRestrict?: { restrictType: string; countryList: string[] };
  clanRestrict?: { restrictType: string; clanIdList: string[] };
  announcement?: string;
  enableAnnouncement?: boolean;
  announcementContentChanged?: number;
  themeType?: number;
  themeRoomConfig?: any;
  preHostId?: string;
  preHostName?: string;
  socialAccountList?: { socialType: string; socialId: string }[];
  showClanRanking: 0 | 1;
  videoChat: boolean;
  enableRabbitHunting: boolean;
  rebuyBlindTermination: number;
  clanConfig?: { type: number; clanId: string };
  showRewardClaim: false;
  showRebuy: false;
  showBreakTime: false;
  showClan: false;
  regConfig: { regType: number; regFee: number }[];
  reEntryConfig: { regType: number; regFee: number }[];
  waitingRoom?: boolean;
};
const props = defineProps<{ id: string; label?: string }>();

const tab = computed(() => props.label || 'overview');
const i18n = useI18n();

const dialog = useDialog();
const showAnnouncement = useLocalStorage(`mtt:${props.id}:announcement`, 0);
const { t } = useI18n();

const mttTypeMap = computed(() => [t('mtt.MTT_TYPE_NORMAL'), t('mtt.MTT_TYPE_PRELIMINARY'), t('mtt.MTT_TYPE_FINALS')]);
const registMap = computed(() => [t('mtt.REVIEW_ORG'), t('mtt.REVIEW_AUTO'), t('mtt.REVIEW_TICKET')]);

const initBlind = ref(20);
const detail = ref<Mtt>();
const status = ref<{ status: number; entryNumber: number }>();
const authUsers = ref<
  {
    userID: string;
    playerName: string;
    entryNumber: number;
    status: number;
    applyTime: number;
    remarks: string;
    clan?: { name: string; logoUrl: string };
  }[]
>([]);

const showQuest = ref(false);
const taskType = ref('');

const closeQuest = () => {
  showQuest.value = false;
};
const tgInviteUrl = useShareURL(
  `${import.meta.env.VITE_TG_BOT_WEBAPP_URL}?startapp=mtt_` + location.href.split('/')[4],
  "Let's play MTT game",
).value;

const showTicketTips = ref(false);
const showTicketRedeemConfirm = ref(false);
const ticketCode = ref('');
userPromise.then(() => {
  const url = new URL(location.href);
  const code = url.searchParams.get('t');
  if (user.value && autoRedeemTicket.value && code) return redeemTicket();
  if (code) {
    autoRedeemTicket.value = code;
    authAPI.get('ticket/checkTicketStatus', { code }).then(({ ticketStatus, isOwnerRedeem }) => {
      ticketCode.value = code;
      if (ticketStatus === 0) showTicketRedeemConfirm.value = true;
      if (ticketStatus === 1) {
        if (user.value && isOwnerRedeem) {
          showTicketTips.value = true;
        } else {
          dialog.alert({ title: t('NOTICE'), content: t('TICKET_CODE_USED_TIPS') });
        }
      }
      if (ticketStatus === 2) dialog.alert({ title: t('NOTICE'), content: t('TICKET_CODE_EXPIRED_TIPS') });
    });
  }
});

const rejectTicketRedeem = () => {
  token.value = '';
  refreshToken.value = '';
};

const redeemTicket = () => {
  if (!user.value) {
    showLogin.value = true;
    showTicketRedeemConfirm.value = false;
    autoRedeemTicket.value = ticketCode.value;
    return;
  }
  const url = new URL(location.href);
  const code = url.searchParams.get('t');
  if (!code) return;
  url.searchParams.delete('t');
  history.replaceState(history.state, '', url.href);
  authAPI.post('ticket/exchangeTournament', { code }).then(() => {
    authAPI.get<number>('v1/mtt/getMTTLeftTicket', { mttId: props.id }).then((v) => (leftTicket.value = v));
    showTicketRedeemConfirm.value = false;
    showTicketTips.value = true;
  });
};

authAPI
  .get<{ bigBlind: number }[]>('v1/mtt/blinds_structure', { mttID: props.id })
  .then((v) => (initBlind.value = v[0]?.bigBlind || 20));

const showUnRegister = computed(() => {
  if (!token.value || !detail.value || !status.value) return false;
  return (status.value.status === 2 || status.value.status === 6) && detail.value.status === 2;
});

const showRegister = computed(() => {
  if (!detail.value) return false;
  if (detail.value.status > 3) return false;
  if (!token.value) return true;
  return status.value && [0, 3].includes(status.value.status) && status.value?.entryNumber === 0;
});

const showReEntry = computed(() => {
  if (!token.value || !detail.value || !status.value) return false;
  return (
    detail.value.showRebuy &&
    status.value.status === 5 &&
    status.value.entryNumber > 0 &&
    status.value.entryNumber <= detail.value.reEntryLimits
  );
});

const showTickets = computed(() => {
  return detail.value?.isHost && (detail.value?.autoReEntry === 2 || detail.value?.autoRegApproval === 2);
});

const showRoom = computed(() => {
  if (!detail.value || !status.value) return false;
  return detail.value.waiting && [2, 3, 4, 8].includes(detail.value.status) && status?.value.status === 2;
});

const openAnnouncement = () =>
  dialog.alert({
    title: t('ANNOUNCEMENT'),
    content: <p class="ws-pre-wrap max-w-110" v-html={encodeText(detail.value!.announcement)} />,
    btn: t('GOT_IT'),
  });

const restrictCountryData = ref([] as any[]);
const restrictClanData = ref([] as any[]);
let stopLoadDetail = false;
const leftTicket = ref(0);
const loadDetail = async () => {
  if ((detail.value?.status || 0) > 4) return;
  if (stopLoadDetail) return;
  try {
    detail.value = await authAPI.get<Mtt>('v1/mtt/show_details', { mttID: props.id });
    if (
      detail.value.enableAnnouncement &&
      detail.value.announcement &&
      showAnnouncement.value < detail.value.announcementContentChanged!
    ) {
      openAnnouncement();
      showAnnouncement.value = detail.value.announcementContentChanged;
    }
    if (detail.value.isHost) loadUser();
    if (detail.value?.status === 6) getPrizeInfo();
    if (detail.value.mttTask) {
      taskType.value = detail.value.mttTask.taskType;
      if (
        detail.value.mttTask.taskType == 'OTHER,' &&
        detail.value.mttTask.taskConfig.length == 2 &&
        detail.value.mttTask.taskConfig[1]['taskKey'] == 'OTHER_CLAN_ALLOWED'
      ) {
        taskType.value = '';
        clanIdList.value = detail.value.mttTask.taskConfig[1]['channelId'];
      }
      if (userTask.showQuest) {
        regist(true);
        userTask.showQuest = false;
      }
    }
    if (detail.value.autoRegApproval == 2 || detail.value.autoReEntry == 2) {
      leftTicket.value = await authAPI.get<number>('v1/mtt/getMTTLeftTicket', { mttId: props.id });
    }
    if (
      detail.value.countryRestrict &&
      detail.value.countryRestrict.restrictType != 'off' &&
      restrictCountryData.value.length == 0
    ) {
      countryData.forEach((v: any) => {
        if (detail.value!.countryRestrict!.countryList.includes(v.code)) {
          restrictCountryData.value.push(v);
        }
      });
    }
    if (
      detail.value.clanRestrict &&
      detail.value.clanRestrict.restrictType != 'off' &&
      restrictClanData.value.length == 0
    ) {
      // clanIdList.value = detail.value.clanRestrict.clanIdList.join(',');
      // detail.value.clanRestrict.clanIdList.forEach((v: any) => {
      authAPI.post('v1/clan/getByIdList', detail.value.clanRestrict.clanIdList).then((res) => {
        restrictClanData.value = res;
      });
      // });
    }
    if (
      !!detail.value.isHost &&
      !!detail.value.preHostId &&
      !!user?.value?.id &&
      detail.value.preHostId !== user?.value?.id &&
      ownerTransferCache.value !== detail.value.preHostId
    ) {
      openOwnerTransferNotice();
      ownerTransferCache.value = detail.value.preHostId;
    }
    if (ownerTransferCache.value && ownerTransferCache.value !== detail.value.preHostId) {
      ownerTransferCache.value = '';
    }
  } catch (e: any) {
    if (e.code === 430) {
      // console.info('================================');
      stopLoadDetail = true;
      dialog.confirm({
        title: t('NOTICE'),
        content: t(convertToLangeLabel(e.msg)),
        action: async () => {
          router.push('/m');
        },
      });
    }
  }
};

const showExport = ref(false);
const showClaimButton = ref(false);
const claimInfo = ref('');
const clanIdList = ref('');

const loadUser = async () =>
  (authUsers.value = await authAPI.post('v1/mtt/player/authorization_player_list', {
    mttID: props.id,
    status: [1],
    pageSize: 1e5,
  }));

let waitApprove = false;
const loadStatus = async () => {
  status.value = await authAPI.get('v1/mtt/player/check_status', { mttID: props.id }, undefined, true);
  console.log('status:', status.value);
  if (status.value?.status === 1 && (detail.value?.status || 0) < 4) {
    waitApprove = true;
    setTimeout(loadStatus, 1e4);
  }
  if (status.value?.status === 5) getPrizeInfo();
  if (status.value?.status === 2 && waitApprove === true) {
    waitApprove = false;
    showRegisterSuccess.value = true;
  }
  if (status.value?.status === 0 && sessionStorage.getItem('mtt:' + props.id)) regist(false);
  if (status.value?.status === 6 && detail.value?.waitingRoom) {
    setTimeout(loadStatus, 1e4);
  }
  sessionStorage.removeItem('mtt:' + props.id);
};
loadStatus();

const getPrizeInfo = async () => {
  const data = await authAPI.get<{ showExport: boolean; showClaimButton: boolean; claimInfo: string }>(
    'v1/mtt/getMTTPriceInfo',
    { mttID: props.id },
  );
  showExport.value = data.showExport;
  showClaimButton.value = data.showClaimButton;
  claimInfo.value = data.claimInfo;
};

const formatRemark = (v: string) => {
  const data = JSON.parse(v);
  return i18n.t(`${MttUserStatus[data.stauts]}_AT`, { v: dt(data.timeStamp * 1e3).strDatetime });
};

const showAuth = computed(() => {
  if (!detail.value) return false;
  return (
    detail.value.isHost &&
    detail.value?.status > 1 &&
    detail.value.status < 4 &&
    (!detail.value.autoRegApproval || (detail.value.reEntryLimits && !detail.value.autoReEntry))
  );
});

const showWaiting = ref(false);
const openRoom = async () => {
  showWaiting.value = true;
  try {
    const data = await authAPI.get<{ mttID: string; type: string }>('v1/mtt/player/room_info', {
      mttID: props.id,
      type: 'mtt',
    });
    if (data.mttID) router.push(`/g/m/${data.mttID}`);
    else throw 'err';
  } catch (e: any) {
    if (e.code === 418) {
      showWaiting.value = false;
      loadDetail();
      loadStatus();
      return;
    }
    setTimeout(openRoom, 5e3);
  }
};

const autoReg = useRouteQuery('auto-reg');
if (autoReg.value) {
  const stopWatch = watch(showReEntry, (v) => {
    if (!v) return;
    regist(false);
    stopWatch();
  });
  autoReg.value = null;
}

const showRegisterSuccess = ref(false);
const showPlaydeckNotify = ref(false);
const showClan = ref(false);
const clan = ref();
const clans = ref<any[]>([]);
const showFull = ref(false);
const sendNotify = ref(false);
const isTestPlayer = computed(() => {
  const usernameTest = /^\d+_test_player@lepoker\.io$/;
  return usernameTest.test(user.value?.email!);
});

const regist = async (notify: boolean) => {
  showTicketTips.value = false;
  sendNotify.value = notify;
  if (detail.value!.totalEntries! >= detail.value!.maxPlayers) return (showFull.value = true);
  if (!token.value) return (showLogin.value = true);
  if (detail.value?.mttTask != null && detail.value?.mttTask.taskType != '') {
    if (!isTestPlayer.value) {
      if (taskType.value && userTask.finishTask != props.id && sendNotify.value) {
        if (platform.value == 'playdeck') {
          return (showPlaydeckNotify.value = true);
        } else {
          return (showQuest.value = true);
        }
      }
    }
  }
  nextTick(closeQuest);

  if (showRegister.value && detail.value?.autoRegApproval === 2 && leftTicket.value === 0)
    return (showNoTicketNotice.value = true);

  clans.value = (await authAPI.get('v1/clan/listJoined')).map((v: any) => ({
    id: v.clan.clanId,
    name: v.clan.name,
    logo: v.clan.logoUrl,
  }));
  if (!clans.value.length) return applyRegist();
  else if (showReEntry.value) return applyRegist();
  clans.value.push({ name: i18n.t('REPRESENT_ONLY_MYSELF') });
  clan.value = clans.value[0].id;
  showClan.value = true;
};

const showCaptcha = ref(false);
const config: CaptchaConfig = {
  product: 'bind',
};

const getCaptcha = (instance: any) => {
  (window as any).captcha = instance;
  instance
    .appendTo('#captcha')
    // instance
    .onReady(function () {
      console.log('ready');
    })
    .onNextReady(function () {
      console.log('nextReady');
    })
    .onBoxShow(function () {
      console.log('boxShow');
    })
    .onError(function (e: any) {
      console.log(e);
    })
    .onSuccess(function () {
      const result = instance.getValidate();
      sendApply(result);
    });
};
let resolve: (v: 2 | 3 | 4) => void;
const advanceRegOptions = ref();
const showAdvanceReg = ref(false);
const advanceRegType = ref<number>();
const curExistPlayer = ref();
const checkAdvanceRegCfg = () => {
  advanceRegType.value = undefined;
  authAPI.get(`v1/mtt/getUserMTTRanking`, { mttID: props.id }).then((v) => (curExistPlayer.value = v.existNumber));
  return new Promise<2 | 3 | 4 | undefined>((res) => {
    resolve = res;
    if (showReEntry.value && detail.value?.reEntryConfig?.length) advanceRegOptions.value = detail.value.reEntryConfig;
    else if (!showReEntry.value && detail.value?.regConfig?.length) advanceRegOptions.value = detail.value.regConfig;
    else return res(undefined);
    showAdvanceReg.value = true;
  });
};

const showRejectCountry = ref(false);
const showRejectClan = ref(false);
const applyRegist = async () => {
  showClan.value = false;
  if (clanIdList.value) {
    if (
      (detail.value!.clanRestrict!.restrictType == 'disallowed' && clanIdList.value.indexOf(clan.value) >= 0) ||
      (detail.value!.clanRestrict!.restrictType == 'allowed' && clanIdList.value.indexOf(clan.value) == -1)
    ) {
      dialog.alert({
        title: t('NOTICE'),
        content: <p class="ws-pre-wrap max-w-110" v-html={t('mtt.CLAN_REJECT')} />,
        btn: t('GOT_IT'),
      });
      return;
    }
  }
  checkAdvanceRegCfg()
    .then(async (v) => {
      advanceRegType.value = v;
      if (!isTestPlayer.value) {
        showCaptcha.value = true;
        if (window.captcha) {
          (window as any).captcha.showCaptcha();
        }
      } else sendApply();
    })
    .finally(() => (showAdvanceReg.value = false));
};

const sendApply = async (captchaInstant?: any) => {
  try {
    let testAccount = 190211;
    if (!isTestPlayer.value) testAccount = 0;
    await authAPI.post<boolean>('v1/mtt/player/apply', {
      mttID: props.id,
      clanId: clan.value,
      testAccount: testAccount,
      lotNumber: captchaInstant?.lot_number || '0',
      captchaOutput: captchaInstant?.captcha_output || '0',
      passToken: captchaInstant?.pass_token || '0',
      genTime: captchaInstant?.gen_time || '0',
      regType: advanceRegType.value,
    });
    //清空任务完成状态
    userTask.finishTask = '';
    userTask.tgFinishTask = false;
    userTask.discordFinishTask = false;
    userTask.xFinishTask = false;
    userTask.googleFinishTask = false;
    userTask.web3FinishTask = false;
    userTask.emailFinishTask = false;
    userTask.otherFinishTask = false;
    loadStatus();
    if (!detail.value?.autoRegApproval) toast.success(i18n.t('REQUEST_SENDED'));
    else if (status.value?.entryNumber === 0) showRegisterSuccess.value = true;
    if (tgUser.id > 0 && sendNotify.value) {
      //只有报名的时候才发送通知
      botAPI.post(`tg/sendMttRegisterMsg/${tgUser.id}/${props.id}`, {
        organizer: detail.value?.hostName,
        mttType: 'NLH',
        mttName: detail.value?.mttName,
        startTime: detail.value!.startTime,
      });
    }
    await loadDetail();
    if (detail.value?.clanConfig?.clanId) {
      const clanId = detail.value.clanConfig.clanId;
      authAPI.get('v1/clan/user/getUserStatusById', { clanId }).then((v) => {
        if (v.status === 3)
          if (v.approvalMode === 0) authAPI.post('v1/clan/joinRequest/create', { clanId });
          else router.push(`/cl?id=${clanId}`);
      });
    }
  } catch (e: any) {
    if (e.code === 418)
      dialog.alert({
        content: (
          <div class="flex flex-col items-center space-y-2">
            <div class="i-local-info w-24 h-24 c-red" />
            <h5>{t('SNG_BANNED_TITLE')}</h5>
            <p class="c-gray-300">{t('MTT_BANED_CONTENT')}</p>
            <p>{dt(e.msg * 1e3).strDatetime}</p>
          </div>
        ),
      });
    else if (e.code === 456) showNoTicketNotice.value = true;
    else if ([1203, 1211].includes(e.code)) toast.error(i18n.t('ERROR_' + e.code));
    else toast.error(i18n.t(convertToLangeLabel(e.msg)));
  }
};

const approve = (id: string) =>
  authAPI.post<boolean>('v1/mtt/player/manage', { mttID: props.id, status: 2, userIDs: [id] }).then(loadUser);

const reject = (id: string) =>
  authAPI.post<boolean>('v1/mtt/player/manage', { mttID: props.id, status: 3, userIDs: [id] }).then(loadUser);

const showUnRegistDlg = ref(false);
const unRegist = async () => {
  showUnRegistDlg.value = false;
  await authAPI.post('v1/mtt/player/cancel', { ID: props.id, type: 'mtt' });
  await loadStatus();
  await loadDetail();
  if (tgUser.id > 0) botAPI.post(`tg/sendMttUnRegisterMsg/${tgUser.id}/${props.id}`);
};

const { copy } = useClipboard({ legacy: true });

const copyUrl = async () => {
  await copy(genRefUrl());
  toast.info(i18n.t('LINK_COPIED'));
};

let ts = Date.now();
useIntervalFn(loadDetail, 3e4, { immediateCallback: true });
useIntervalFn(() => {
  const now = Date.now();
  if (detail.value?.addtionalTimeStamp) {
    detail.value.addtionalTimeStamp += (now - ts) / 1e3;
    if (detail.value.status > 3) return (ts = now);
    if (detail.value.addtionalTimeStamp! < detail.value.registrationStartTime) detail.value.status = 1;
    else if (detail.value.addtionalTimeStamp! < detail.value.startTime) detail.value.status = 2;
    else if (detail.value.addtionalTimeStamp! < detail.value.lateRegistrationTime + detail.value.startTime)
      detail.value.status = 3;
    else detail.value.status = 4;
  }
  ts = now;
}, 500);

const share = async () => {
  if (tgUser.useTgShare == 1) {
    window.open(tgInviteUrl);
  } else {
    copyUrl();
  }
};

const updateTab = (v: string) => {
  if (v === 'overview') {
    loadDetail();
    return router.replace(`/m/${props.id}`);
  }
  if (v === 'authorization') loadUser();
  router.replace(`/m/${props.id}/${v}`);
};

import msgSound from '@/assets/sound/msg.mp3';
const soundMsg = useSound(msgSound);
watch(
  () => authUsers.value?.length,
  (val, old) => {
    if (old == null || val == null) return;
    if (val > old) soundMsg.play();
  },
);
import RewardClaimPage from '@/views/mtt/RewardClaimPage.vue';

const openClaim = () => {
  const r = router.addRoute('base', {
    path: 'm/rc',
    component: RewardClaimPage,
    props: { id: props.id },
    meta: { title: 'MTT' },
  });
  router.push('/m/rc').then(r);
};

const countDownSec = computed(() => {
  if (!detail.value?.addtionalTimeStamp) return 0;
  if (detail.value.status === 1) return detail.value.registrationStartTime - detail.value.addtionalTimeStamp;
  return Math.abs(detail.value.addtionalTimeStamp - detail.value.startTime);
});

import MTTEditPage from '@/views/mtt/EditPage.vue';
import { genRefUrl } from '@/utils/user';
import DPAvatar from '@/components/DPAvatar.vue';
import { useRouteQuery } from '@vueuse/router';

const openEdit = () => {
  const r = router.addRoute('base', {
    path: 'm/edit',
    component: MTTEditPage,
    props: { id: props.id },
    meta: { title: 'MTT' },
  });
  router.push('/m/edit').then(r);
};

const createWithCurCfg = () => {
  router.push({ path: '/m/new', query: { id: props.id } });
};

const entryOptions = computed(() => [t('mtt.REVIEW_ORG'), t('mtt.REVIEW_AUTO'), t('mtt.REVIEW_TICKET')]);

const showTicketRedeem = ref(false);
const showNoTicketNotice = ref(false);

const currentTime = ref(new Date().getTime() / 1e3);
useIntervalFn(() => {
  currentTime.value = new Date().getTime() / 1e3;
});

const showOwnerTransfer = ref(false);
const ownerTransferCache = useLocalStorage(`mtt:${props.id}:ownTransfer`, '');
const openOwnerTransferNotice = () => {
  dialog.alert({
    title: t('mtt.TRANSFER_OWNER'),
    content: (
      <p
        class="ws-pre-wrap max-w-110"
        v-html={t('mtt.GET_OWNER_FROM', { mttName: detail?.value?.mttName, username: detail?.value?.preHostName })}
      />
    ),
    btn: t('GOT_IT'),
  });
};

const joinGameRequest = () => {
  if (detail.value?.waitingRoom || detail.value?.status === 3) {
    authAPI
      .post('v1/mtt/player/join', { mttId: props.id })
      .then(() => {
        toast.success(t('SUCCESS'));
        loadStatus();
      })
      .catch((e: any) => {
        toast.error(i18n.t(convertToLangeLabel(e.msg)));
      });
  } else {
    toast.info(i18n.t('mtt.JOIN_MIN_LATER', [60]));
  }
};
</script>
