<script setup lang="ts">
import { ref } from 'vue';
import { discordUser, tgUser, xUser, userTask, googleUser, user } from '@/stores';
import type { TaskInfo } from '@/types';
import { token, botAPI } from '@/utils/network';
import { socialLink } from '@/utils/user';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
import { connectX } from '@/utils/twitter';
import { connectDiscord } from '@/utils/discord';
import { connectGoogle } from '@/utils/google';

const { t } = useI18n();

const emits = defineEmits<{ regist: [any]; close: [any] }>();
const props = defineProps<{
  mttTask: { id: number; mttID: string; taskType: string; taskConfig: any[] } | undefined;
}>();
const toast = useToast();
const botName = import.meta.env.VITE_TG_BOT;

const tgFinishedList = ref(new Array<boolean>());
const discordFinishedList = ref(new Array<boolean>());
const xFinishedList = ref(new Array<boolean>());
const googleFinishedList = ref(new Array<boolean>());
const web3FinishedList = ref(new Array<boolean>());
const emailFinishedList = ref(new Array<boolean>());
const otherFinishedList = ref(new Array<boolean>());
const tgTaskList = ref(new Array<TaskInfo>());
const discordTaskList = ref(new Array<TaskInfo>());
const xTaskList = ref(new Array<TaskInfo>());
const googleTaskList = ref(new Array<TaskInfo>());
const web3TaskList = ref(new Array<TaskInfo>());
const emailTaskList = ref(new Array<TaskInfo>());
const otherTaskList = ref(new Array<TaskInfo>());

const list = props.mttTask!.taskType.split(',');

if (list.indexOf('TG') >= 0) {
  tgFinishedList.value.push(false);
}
if (list.indexOf('DISCORD') >= 0) {
  discordFinishedList.value.push(false);
}
if (list.indexOf('X') >= 0) {
  xFinishedList.value.push(false);
}
if (list.indexOf('YOUTUBE') >= 0) {
  googleFinishedList.value.push(false);
}
if (list.indexOf('EMAIL') >= 0) {
  emailFinishedList.value.push(false);
}
if (list.indexOf('WEB3') >= 0) {
  //web3无需做link任务，只要即时查询即可
  web3FinishedList.value.push(true);
}
if (list.indexOf('OTHER') >= 0) {
  //其他任务，只要即时查询即可
  otherFinishedList.value.push(true);
}

// init task finish status
props.mttTask?.taskConfig.forEach((v) => {
  if (v['taskKey'] == 'TG_JOIN_CHANNEL') {
    const i = tgFinishedList.value.length;
    tgFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'TG',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      taskUrl: v['taskUrl'],
      checkUrl: 'tg/checkJoinOfficeChannel?channelId=' + v['channelId'] + '&userId=',
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
    };
    tgTaskList.value.push(task);
  } else if (v['taskKey'] == 'TG_JOIN_GROUP') {
    const i = tgFinishedList.value.length;
    tgFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'TG',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      taskUrl: v['taskUrl'],
      checkUrl: 'tg/checkJoinOfficeChannel?channelId=' + v['groupId'] + '&userId=',
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
    };
    tgTaskList.value.push(task);
  } else if (v['taskKey'] == 'TG_START_BOT') {
    const i = tgFinishedList.value.length;
    tgFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'TG',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey']),
      desc: t('task.' + v['taskKey'] + '_DESC'),
      taskUrl: v['taskUrl'],
      checkUrl: 'tg/checkSubBot?userId=',
      btnText: t('task.' + v['taskKey']),
    };
    tgTaskList.value.push(task);
  } else if (v['taskKey'] == 'X_FOLLOW') {
    const i = xFinishedList.value.length;
    xFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'X',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      // taskUrl: 'x/follow',
      taskUrl: 'https://x.com/intent/follow?screen_name=' + v['name'],
      checkUrl:
        'x/checkFollow?lepokerUserId=' +
        user.value?.id +
        '&userId=' +
        xUser.id +
        '&mttId=' +
        props.mttTask?.mttID +
        '&taskId=' +
        i +
        '&targetUserId=' +
        v['userId'],
      userId: v['userId'],
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
    };
    xTaskList.value.push(task);
  } else if (v['taskKey'] == 'X_TWEET') {
    const i = xFinishedList.value.length;
    xFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'X',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      // taskUrl: 'x/tweet',
      taskUrl:
        'https://x.com/intent/post?text=' +
        encodeURIComponent(v['text']) +
        '&url=' +
        encodeURIComponent(window.location.href),
      checkUrl:
        'x/checkTweet?lepokerUserId=' +
        user.value?.id +
        '&userId=' +
        xUser.id +
        '&mttId=' +
        props.mttTask?.mttID +
        '&taskId=' +
        i +
        '&keywords=' +
        encodeURIComponent(v['text']),
      text: v['text'],
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
      mttId: props.mttTask?.mttID,
    };
    xTaskList.value.push(task);
  } else if (v['taskKey'] == 'X_RETWEET') {
    const i = xFinishedList.value.length;
    xFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'X',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey']),
      desc: t('task.' + v['taskKey'] + '_DESC'),
      // taskUrl: 'x/retweet',
      taskUrl: 'https://x.com/intent/retweet?tweet_id=' + v['textId'],
      checkUrl:
        'x/checkTweet?lepokerUserId=' +
        user.value?.id +
        '&userId=' +
        xUser.id +
        '&mttId=' +
        props.mttTask?.mttID +
        '&taskId=' +
        i +
        '&tweet_id=' +
        v['textId'],
      textId: v['textId'],
      btnText: t('task.' + v['taskKey']),
      mttId: props.mttTask?.mttID,
    };
    xTaskList.value.push(task);
  } else if (v['taskKey'] == 'DISCORD_JOIN_SERVER') {
    const i = discordFinishedList.value.length;
    discordFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'DISCORD',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      taskUrl: v['taskUrl'],
      checkUrl: 'discord/checkJoinGuild?userId=' + discordUser.id + '&channelId=' + v['serverId'],
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
    };
    discordTaskList.value.push(task);
  } else if (v['taskKey'] == 'YOUTUBE_SUBSCRIPT_CHANNEL') {
    const i = googleFinishedList.value.length;
    googleFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'YOUTUBE',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['name'] }),
      taskUrl: v['taskUrl'],
      checkUrl: 'google/checkSubscript?channelId=' + v['channelId'] + '&userId=' + googleUser.id,
      btnText: t('task.' + v['taskKey'], { v1: v['name'] }),
    };
    googleTaskList.value.push(task);
  } else if (v['taskKey'] == 'WEB3_HOLDING_TOKEN' || v['taskKey'] == 'WEB3_HOLDING_NFT') {
    let checkUrl = 'wallet/checkToken';
    if (v['taskKey'] == 'WEB3_HOLDING_NFT') {
      checkUrl = 'wallet/checkNFT';
    }
    const i = web3FinishedList.value.length;
    web3FinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'WEB3',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['symbol'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['balance'], v2: v['symbol'] }),
      chain: v['chain'],
      checkUrl:
        checkUrl +
        '?chain=' +
        v['chain'] +
        '&holdingBalance=' +
        v['balance'] +
        '&tokenAddress=' +
        v['contractAddress'] +
        '&taskId=' +
        i +
        '&userId=' +
        user.value!.id +
        '&taskType=' +
        v['taskKey'] +
        '&mttId=' +
        props.mttTask?.mttID +
        '&walletAddress=',
      btnText: t('task.' + v['taskKey'], { v1: v['symbol'] }),
      balance: v['balance'],
    };
    web3TaskList.value.push(task);
  } else if (v['taskKey'] == 'WEB3_CHECK_TRANSACTION') {
    let checkUrl = 'wallet/checkTransaction';
    const i = web3FinishedList.value.length;
    web3FinishedList.value.push(false);
    const time = v['duration'] / 3600;
    const task: TaskInfo = {
      taskId: i,
      taskType: 'WEB3',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v1: v['symbol'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v1: v['balance'], v2: v['symbol'], time: time.toFixed(0) }),
      chain: v['chain'],
      checkUrl:
        checkUrl +
        '?chain=' +
        v['chain'] +
        '&transactionAmount=' +
        v['balance'] +
        '&duration=' +
        v['duration'] +
        '&taskId=' +
        i +
        '&userId=' +
        user.value!.id +
        '&taskType=' +
        v['taskKey'] +
        '&mttId=' +
        props.mttTask?.mttID +
        '&walletAddress=',
      btnText: t('task.' + v['taskKey'], { v1: v['symbol'] }),
      balance: v['balance'],
      duration: v['duration'],
    };
    web3TaskList.value.push(task);
  } else if (v['taskKey'] == 'OTHER_VISIT_WEBSITE') {
    const i = otherFinishedList.value.length;
    otherFinishedList.value.push(false);
    const task: TaskInfo = {
      taskId: i,
      taskType: 'OTHER',
      taskKey: v['taskKey'],
      title: '·' + t('task.' + v['taskKey'], { v: v['name'] }),
      desc: t('task.' + v['taskKey'] + '_DESC', { v: v['name'] }),
      taskUrl: v['taskUrl'],
      checkUrl:
        'other/openWebsite?taskId=' + i + '&userId=' + user.value?.id + '&taskType=OTHER&mttId=' + props.mttTask?.mttID,
      btnText: t('task.' + v['taskKey'], { v: v['name'] }),
    };
    otherTaskList.value.push(task);
  }
});

const finalCheck = async () => {
  console.info('finalCheck');
  if (tgFinishedList.value.length > 0 && !userTask.tgFinishTask) {
    return false;
  }
  if (discordFinishedList.value.length > 0 && !userTask.discordFinishTask) {
    return false;
  }
  if (xFinishedList.value.length > 0 && !userTask.xFinishTask) {
    return false;
  }
  if (googleFinishedList.value.length > 0 && !userTask.googleFinishTask) {
    return false;
  }
  if (web3FinishedList.value.length > 0 && !userTask.web3FinishTask) {
    return false;
  }
  if (emailFinishedList.value.length > 0 && !userTask.emailFinishTask) {
    return false;
  }
  if (otherFinishedList.value.length > 0 && !userTask.otherFinishTask) {
    return false;
  }
  userTask.finishTask = props.mttTask!.mttID;
  emits('regist', true);
};

const taskCallback = async (index: number, type: string) => {
  if (type == 'TG') {
    tgFinishedList.value[index] = true;
  } else if (type == 'DISCORD') {
    discordFinishedList.value[index] = true;
  } else if (type == 'X') {
    xFinishedList.value[index] = true;
  } else if (type == 'YOUTUBE') {
    googleFinishedList.value[index] = true;
  } else if (type == 'WEB3') {
    web3FinishedList.value[index] = true;
  } else if (type == 'EMAIL') {
    emailFinishedList.value[index] = true;
  } else if (type == 'OTHER') {
    otherFinishedList.value[index] = true;
  }
  checkTask(type);
};

const checkTask = async (tt: string) => {
  let fi = true;
  try {
    if (tt == 'TG') {
      tgFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkTgTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'DISCORD') {
      discordFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkDiscordTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'X') {
      xFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkXTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'YOUTUBE') {
      googleFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkYoutubeTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'WEB3') {
      web3FinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkWeb3Task false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'EMAIL') {
      emailFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkEmailTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    } else if (tt == 'OTHER') {
      otherFinishedList.value.forEach((v) => {
        if (!v) {
          fi = false;
          console.info('checkOtherTask false');
          throw '终止循环'; // 抛出一个异常
        }
      });
    }
  } catch (e) {
    console.info('');
  }
  if (fi) {
    if (tt == 'TG') {
      userTask.tgFinishTask = true;
    } else if (tt == 'DISCORD') {
      userTask.discordFinishTask = true;
    } else if (tt == 'X') {
      userTask.xFinishTask = true;
    } else if (tt == 'YOUTUBE') {
      userTask.googleFinishTask = true;
    } else if (tt == 'WEB3') {
      userTask.web3FinishTask = true;
    } else if (tt == 'EMAIL') {
      userTask.emailFinishTask = true;
    } else if (tt == 'OTHER') {
      userTask.otherFinishTask = true;
    }
    finalCheck();
  }
};

const loginWithTelegram = async (userLoginUser: any) => {
  if (token.value) {
    let userName = '';
    userName = userLoginUser['username'];
    const firstName = userLoginUser['first_name'];
    const lastName = userLoginUser['last_name'];
    if (!userName && firstName) {
      userName = firstName + userLoginUser['id'];
    } else if (!userName && lastName) {
      userName = lastName + userLoginUser['id'];
    }
    try {
      const userID = await socialLink(userLoginUser['id'], userName, userLoginUser['photo_url'], 'TG');
      userLoginUser['authType'] = 'browser';
      userLoginUser['lepokerUserId'] = userID;
      try {
        await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
        tgUser.id = userLoginUser['id'];
        tgUser.username = userName;
        tgFinishedList.value[0] = true;
        await checkTask('TG');
      } catch (e: any) {
        toast.add({ severity: 'error', summary: t('FAIL'), detail: t(convertToLangeLabel(e.msg)), life: 3000 });
      }
    } catch (e: any) {
      toast.add({ severity: 'error', summary: t('FAIL'), detail: t(convertToLangeLabel(e)), life: 3000 });
    }
  }
};

const loginDiscord = async () => {
  let loginType = '---showQuest';
  if (token.value) {
    loginType = loginType + '---linkDiscord';
  }
  connectDiscord(loginType);
};

let clickLoginX = false;
const loginX = async () => {
  if (!clickLoginX) {
    clickLoginX = true;
    let loginType = '---showQuest';

    if (token.value) {
      loginType = loginType + '---linkX';
    }
    await connectX(loginType);
  }
};

let clickLoginGoogle = false;
const loginGoogle = async () => {
  if (!clickLoginGoogle) {
    clickLoginGoogle = true;
    let loginType = '---showQuest';

    if (token.value) {
      loginType = loginType + '---linkGoogle';
    }
    await connectGoogle(loginType);
  }
};

const loginEmail = async () => {
  console.info();
};

function close() {
  emits('close', null);
}
const tgLoginBtn = ref();
if (token.value != '') {
  if (tgUser.id && tgFinishedList.value.length > 0) {
    tgFinishedList.value[0] = true;
    checkTask('TG');
  }
  if (discordUser.id && discordFinishedList.value.length > 0) {
    discordFinishedList.value[0] = true;
    checkTask('DISCORD');
  }
  if (xUser.id && xFinishedList.value.length > 0) {
    xFinishedList.value[0] = true;
    checkTask('X');
  }
  if (googleUser.id && googleFinishedList.value.length > 0) {
    googleFinishedList.value[0] = true;
    checkTask('YOUTUBE');
  }
  if (user.value?.email && emailFinishedList.value.length > 0) {
    emailFinishedList.value[0] = true;
    checkTask('EMAIL');
  }
  if (otherFinishedList.value.length > 0) {
    otherFinishedList.value[0] = true;
    checkTask('OTHER');
  }
}

const showBindEmail = ref(false);
const finishBindEmail = async () => {
  showBindEmail.value = false;
  emailFinishedList.value[0] = true;
};
</script>

<template>
  <ADialog class="w-100" v-model="showBindEmail" :title="$t('CONNECT_WITH_EMAIL')">
    <DPBindEmail connectType="connect" @callback="finishBindEmail" />
  </ADialog>
  <div>
    <p class="op-60 mb-4 text-center">{{ $t('task.NOTICE_DESC') }}</p>
    <div v-if="emailFinishedList.length > 0" class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>· {{ $t('task.EMAIL_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.EMAIL_LOGIN_DESC') }}</p>
        </div>
        <div v-if="emailFinishedList[0]" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button
        v-if="!emailFinishedList[0]"
        severity="info"
        class="p-.5 text-sm w-full justify-center"
        @click="showBindEmail = true"
      >
        <div class="bg-green rd-full p-2 mr-4">
          <div class="i-local-mail size-4"></div>
        </div>
        <span class="ml-3">{{ $t('task.EMAIL_LOGIN') }}</span>
      </Button>
    </div>
    <div v-if="tgFinishedList.length > 0" class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>· {{ $t('task.TG_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.TG_LOGIN_DESC') }}</p>
        </div>
        <div v-if="tgFinishedList[0]" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <TgButtonLogin
        v-if="!tgFinishedList[0]"
        ref="tgLoginBtn"
        :botUsername="botName"
        mode="callback"
        requestAccess="write"
        @callback="loginWithTelegram"
        size="large"
        radius="5"
        style="display: flex; align-items: center; justify-content: center"
      >
      </TgButtonLogin>
    </div>
    <DPTaskItem v-for="(task, index) in tgTaskList" :key="index" :task="task" @callback="taskCallback" />
    <div v-if="discordFinishedList.length > 0" class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>· {{ $t('task.DISCORD_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.DISCORD_LOGIN_DESC') }}</p>
        </div>
        <div v-if="discordFinishedList[0]" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button
        v-if="!discordFinishedList[0]"
        severity="info"
        class="p-.5 text-sm w-full justify-center"
        @click="loginDiscord"
      >
        <DiscordIcon />
        <span class="ml-3">{{ $t('task.DISCORD_LOGIN') }}</span>
      </Button>
    </div>
    <DPTaskItem v-for="(task, index) in discordTaskList" :key="index" :task="task" @callback="taskCallback" />
    <div v-if="xFinishedList.length > 0" class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>· {{ $t('task.X_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.X_LOGIN_DESC') }}</p>
        </div>
        <div v-if="xFinishedList[0]" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button v-if="!xFinishedList[0]" severity="info" class="p-.5 text-sm w-full justify-center" @click="loginX">
        <div class="rd-full bg-white p-2">
          <div class="i-local-twitter text-xl c-black" />
        </div>
        <span class="ml-3">{{ $t('task.X_LOGIN') }}</span>
      </Button>
    </div>
    <DPTaskItem v-for="(task, index) in xTaskList" :key="index" :task="task" @callback="taskCallback" />
    <div v-if="googleFinishedList.length > 0" class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>· {{ $t('task.GOOGLE_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.GOOGLE_LOGIN_DESC') }}</p>
        </div>
        <div v-if="googleFinishedList[0]" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button
        v-if="!googleFinishedList[0]"
        severity="info"
        class="p-.5 text-sm w-full justify-center"
        @click="loginGoogle"
      >
        <div class="rd-full bg-white p-1">
          <div class="i-local-google text-3xl c-black" />
        </div>
        <span class="ml-3">{{ $t('task.GOOGLE_LOGIN') }}</span>
      </Button>
    </div>
    <DPTaskItem v-for="(task, index) in googleTaskList" :key="index" :task="task" @callback="taskCallback" />
    <DPTaskItem v-for="(task, index) in web3TaskList" :key="index" :task="task" @callback="taskCallback" />
    <DPTaskItem v-for="(task, index) in otherTaskList" :key="index" :task="task" @callback="taskCallback" />
    <Button
      v-if="userTask.finishTask && userTask.finishTask == mttTask?.mttID"
      size="small"
      class="w-full mt-4"
      :label="$t('REGISTRATION_SUCCESSFULL')"
      @click="close"
      autofocus
    />
  </div>
</template>
