<template>
  <div v-if="detail" class="pt-4">
    <header class="space-y-2 relative">
      <div v-if="landscape" class="absolute right-0 top-0 flex space-x-2">
        <AButton v-if="isAdmin" class="!c-green" type="secondary" icon="i-local-bell" @click="openAuth">
          {{ $t('APPLICANTS') }}
          <div v-if="applyCnt" class="inline-block c-white text-sm w-5 bg-red rd-full">
            {{ applyCnt }}
          </div>
        </AButton>
        <APopButton align="end">
          <AButton type="secondary" icon="i-local-settings" />
          <template #pad>
            <div class="rd z-2 bg-gray-800 w-max" un-children="px-4 py-2 cursor-pointer">
              <div v-if="isAdmin" @click="openEdit">{{ $t('CLAN_SETTINGS') }}</div>
              <div v-if="isOwner" class="c-red" @click="deleteClan">{{ $t('DELETE_CLAN') }}</div>
              <div v-else class="c-red" @click="leaveClan">{{ $t('LEAVE_CLAN') }}</div>
            </div>
          </template>
        </APopButton>
      </div>
      <div class="flex justify-between items-end">
        <img class="w-26 h-26 rd" :src="detail.logoUrl" alt="" />
        <div class="flex space-x-2 relative" :class="{ 'top-10': landscape }">
          <AButton type="secondary" class="!c-green" @click="openRule">{{ $t('rules') }}</AButton>
          <AButton type="secondary" icon="i-local-share" @click="share"></AButton>
          <AButton
            type="secondary"
            icon="i-local-star-fill"
            :class="{
              'c-yellow': detail.isStar,
            }"
            @click="setStar"
          ></AButton>
        </div>
      </div>
      <h2 class="truncate">{{ detail.name }}</h2>
      <p>{{ $t('mtt.OWNER') }}: {{ detail.creator }} ID: {{ detail.clanId }}</p>
      <p class="break-all ws-pre-wrap">{{ detail.description }}</p>
      <div class="rd-lg bg-gray-700 p-3 flex items-center">
        <div class="flex-1">
          <b class="c-green mr-1">{{ detail.memberCount }}</b>
          {{ $t('MEMBERS') }}
        </div>
        <DPAvatar v-for="(item, i) in detail.users" :key="i" class="-ml-3" :id="item.userId" />
      </div>
      <teleport v-if="!landscape" to="#m-nav-add">
        <div class="flex space-x-2">
          <AButton v-if="isAdmin" text type="secondary" icon="i-local-bell" @click="openAuth">
            <div v-if="applyCnt" class="absolute top-1 right-2 c-white text-xs w-4 bg-red rd-full">
              {{ applyCnt }}
            </div>
          </AButton>
          <APopButton align="end">
            <AButton text type="secondary" icon="i-local-settings" />
            <template #pad>
              <div class="rd z-2 bg-gray-800 w-max" un-children="px-4 py-2 cursor-pointer">
                <div v-if="isAdmin" @click="openEdit">{{ $t('CLAN_SETTINGS') }}</div>
                <div v-if="isOwner" class="c-red" @click="deleteClan">{{ $t('DELETE_CLAN') }}</div>
                <div v-else class="c-red" @click="leaveClan">{{ $t('LEAVE_CLAN') }}</div>
              </div>
            </template>
          </APopButton>
        </div>
      </teleport>
    </header>
    <div class="flex justify-around my-4" un-children="text-center flex-1 p-2  b-2 b-b-solid cursor-pointer">
      <h5
        :class="tab === 0 ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="tab = 0"
      >
        {{ $t('ANNOUNCEMENTS') }}
      </h5>
      <h5
        :class="tab === 1 ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="tab = 1"
      >
        {{ $t('MEMBERS') }}
      </h5>
    </div>
    <section v-show="tab === 0">
      <AButton v-if="isAdmin" class="m-auto mb-4" outlined @click="showCreateAnnouncement = true">
        {{ $t('NEW_ANNOUNCEMENT') }}
      </AButton>
      <div v-for="(a, i) in annos" :key="i" class="flex">
        <div class="flex flex-col items-center pt-1">
          <div class="h-3 w-3 b-.2rem b-solid b-green rd-full" />
          <div class="flex-1 w-.3 bg-gray-700 mt-1"></div>
        </div>
        <div class="flex-1 pl-2 pb-3 of-hidden">
          <h5 class="mb-2 flex justify-between">
            {{ dt(a.createTime).strDatetime }}
            <div v-if="isAdmin" class="c-red flex items-center cursor-pointer" @click="deleteAnno(i)">
              <div class="i-local-remove mr-1" />
              {{ $t('DELETE') }}
            </div>
          </h5>
          <div class="bg-gray-800 p-3 rd-lg space-y-2">
            <h5 class="truncate">{{ a.detail.title }}</h5>
            <p class="c-gray-300 break-all ws-pre-wrap">{{ a.detail.content }}</p>
            <DPAvatar class="text-sm flex items-center" :id="a.creator">
              <template #default="{ user }">
                <div class="ml-1 c-gray-300">{{ user.name }}</div>
              </template>
            </DPAvatar>
          </div>
        </div>
      </div>
      <ALoader v-model="annoIdx" :loader="loadAnnos" />
    </section>
    <section v-show="tab === 1" class="space-y-4">
      <div v-for="(u, i) in users" :key="i" class="flex items-center">
        <DPAvatar class="mr-2 text-2xl" :name="u.userName" :avatar="u.userAvatarUrl" />
        <div class="flex-1">
          <h5 class="flex items-center">
            {{ u.userName }}
            <span
              v-if="u.clanRole > 0"
              class="rd ml-1 c-white px-1 text-xs"
              :class="{ 'bg-red': u.clanRole === 1, 'bg-yellow': u.clanRole !== 1 }"
              >{{ u.clanRole === 2 ? 'OWNER' : 'ADMIN' }}</span
            >
          </h5>
          <p class="c-gray-300 mt-1">{{ $t('JOIN_DATE') }}: {{ dt(u.lastJoinTime).strDatetime }}</p>
        </div>
        <APopButton v-if="isAdmin && u.clanRole < detail.clanRole!" align="end">
          <div class="i-local-more c-gray-300 text-xl"></div>
          <template #pad>
            <div class="px-3 rd-lg bg-gray-700 w-max text-lg" un-children="p-2 cursor-pointer">
              <div v-if="u.clanRole === 1" @click="updateUser(u, 0)">{{ $t('CONVERT_TO_MEMBER') }}</div>
              <div v-else-if="u.clanRole === 0 && isOwner" @click="updateUser(u, 1)">{{ $t('CONVERT_TO_ADMIN') }}</div>
              <div @click="removeUser(i)">{{ $t('REMOVAL') }}</div>
            </div>
          </template>
        </APopButton>
      </div>
      <ALoader v-model="userIdx" :loader="loadUsers" />
    </section>
    <ADialog class="text-lg" v-model="showCreateAnnouncement" :title="$t('NEW_ANNOUNCEMENT')" fullscreen>
      <AInput class="mb-4" v-model="anno.title" :limit="40">
        <h5>{{ $t('TITLE') }}</h5>
      </AInput>
      <AInput v-model="anno.content" :rows="7" :limit="1000">
        <h5>{{ $t('CONTENT') }}</h5>
      </AInput>
      <template #footer>
        <AButton :disabled="!anno.title" @click="createAnno">{{ $t('PUBLISH') }}</AButton>
      </template>
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import router from '@/router';
import { landscape } from '@/stores';
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { genRefUrl } from '@/utils/user';
import { useClipboard } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import EditPage from './edit.vue';
import AuthPage from './auth.vue';
import type { Clan } from '@/types';
import { useDialog } from '@/components/ui/APopPad.vue';
import { toast } from '@/components/ui/AToast.vue';

const props = defineProps<{ id: string }>();
const detail = ref<Clan>();
const users = ref<any[]>([]);
const annos = ref<any[]>([]);
const tab = ref(0);
const showCreateAnnouncement = ref(false);
const anno = ref({ title: '', content: '' });

const userIdx = ref<string>();
const annoIdx = ref<string>();

const createAnno = () => {
  authAPI.post('v1/clan/announcement/create', { detail: anno.value, clanId: detail.value!.clanId }).then(async () => {
    showCreateAnnouncement.value = false;
    annos.value = (
      await authAPI.get('v1/clan/announcement/list', { clanId: detail.value!.clanId, pageSize: 10 })
    ).records;
  });
};

const isAdmin = computed(() => detail.value?.clanRole! > 0);
const isOwner = computed(() => detail.value!.clanRole === 2);

const deleteAnno = (idx: number) => {
  authAPI.post('v1/clan/announcement/delete', { announcementId: annos.value[idx].id }).then(async () => {
    annos.value.splice(idx, 1);
  });
};

authAPI.get('v1/clan/getById', { clanId: props.id }).then(async (res) => {
  try {
    const user = await authAPI.get('v1/clan/user/get', { clanId: res.clanVO.clanId });
    detail.value = { ...user, ...res.clanVO, creator: res.owner.userName, users: res.clanUsers };
    if (isAdmin.value)
      authAPI.get('v1/clan/joinRequest/countByClan', { clanId: detail.value!.clanId }).then((v) => {
        applyCnt.value = v;
      });
  } catch {
    router.replace(`/cl?id=${res.clanVO.clanId}`);
  }
});

const dialog = useDialog();
const openRule = () => {
  dialog.alert({
    title: t('CLAN_RULES'),
    content: <div class="ws-pre-wrap break-all w-100">{detail.value!.rules}</div>,
  });
};

const { copy } = useClipboard({ legacy: true });
const { t } = useI18n();

const applyCnt = ref(0);

const share = () => copy(genRefUrl('/cl', { id: detail.value!.clanId })).then(() => toast.error(t('LINK_COPIED')));

const setStar = () => {
  if (detail.value!.isStar) return;
  authAPI.post('v1/clan/user/star', { clanId: detail.value!.clanId }).then(() => (detail.value!.isStar = true));
};

function leaveClan() {
  dialog.confirm({
    title: t('ATTENTION'),
    content: t('LEAVE_CLAN_TIPS'),
    action: async () => {
      await authAPI.post('v1/clan/user/quit', { clanId: detail.value!.clanId });
      router.back();
    },
  });
}

const deleteClan = () => {
  dialog.confirm({
    content: (
      <div class="flex flex-col items-center space-y-2 text-center">
        <div class="text-8rem i-local-info c-red" />
        <h4>{t('ATTENTION')}</h4>
        <p class="w-100">{t('DELETE_CLAN_TIPS')}</p>
      </div>
    ),
    action: async () => {
      await authAPI.post('v1/clan/delete', { clanId: detail.value!.clanId });
      router.back();
    },
  });
};

const loadAnnos = async (index?: string) => {
  const res = await authAPI.get('v1/clan/announcement/list', {
    clanId: detail.value!.clanId,
    pageSize: 10,
    index,
  });
  annos.value.push(...res.records);
  if (res.records.length < 10) return 'end';
  return res.index as string;
};

const loadUsers = async (index?: string) => {
  const res = await authAPI.get('v1/clan/user/list', {
    clanId: detail.value!.clanId,
    pageSize: 10,
    index,
  });
  users.value.push(...res.records);
  if (res.records.length < 10) return 'end';
  return res.index as string;
};

const updateUser = async (u: any, role: number) => {
  await authAPI.post('v1/clan/user/edit', {
    clanId: detail.value!.clanId,
    userId: u.userId,
    clanRole: role,
  });
  u.clanRole = role;
};

const removeUser = async (i: number) => {
  await authAPI.post('v1/clan/user/kick', { clanId: detail.value!.clanId, userId: users.value[i].userId });
  users.value.splice(i, i);
};

const openEdit = () => {
  const r = router.addRoute('base', {
    path: '/cl/edit',
    component: EditPage,
    props: { id: props.id, isOwner: isOwner.value },
    meta: { title: 'CLAN_SETTINGS' },
  });
  router.push('/cl/edit').then(r);
};

const openAuth = () => {
  const r = router.addRoute('base', {
    path: '/cl/auth',
    component: AuthPage,
    props: { id: props.id },
    meta: { title: 'APPLICANTS' },
  });
  router.push('/cl/auth').then(r);
};
</script>
