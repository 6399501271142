<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { tgUser, discordUser, xUser, user, googleUser } from '@/stores';
import type { TaskInfo } from '@/types';
import { botAPI } from '@/utils/network';
import { useI18n } from 'vue-i18n';
import { getOKXEVMToken, getOKXSOLToken, makeOKXEVMTransaction } from '@/utils/okx';
import { tonLogin } from '@/utils/ton';
import { convertToLangeLabel } from '@/utils/strings';
import { getMetaMaskEVMToken, makeMetaMaskEVMTransaction } from '@/utils/metamask';
import { cutDown } from '@/utils/timer';
import { getDeboxEVMToken, makeDeboxEVMTransaction } from '@/utils/debox';
import { toast } from './ui/AToast.vue';
import { queryBlockNumber, queryTransactionCount, queryNativeBalance } from '@/utils/bitlayer';

const emits = defineEmits<{ callback: [any, any] }>();
const props = defineProps<{ task: TaskInfo }>();

const finished = ref(false);
const { t } = useI18n();
const showWeb3Wallet = ref(false);
const showTransactionWallet = ref(false);
const chain = ref('');
const loading = ref(false);
const showCheck = ref(true);
let walletAddress = '';

const checkWeb3Task = async (wallet: string) => {
  loading.value = true;
  try {
    if (wallet == 'okx') {
      if (props.task.chain != 'solana') {
        walletAddress = await getOKXEVMToken(props.task.chain!);
      } else {
        walletAddress = await getOKXSOLToken();
      }
    } else if (wallet === 'ton-keeper') {
      //未完善
      await tonLogin('link');
    } else if (wallet === 'metamask') {
      walletAddress = await getMetaMaskEVMToken(props.task.chain!);
    } else if (wallet === 'debox') {
      walletAddress = await getDeboxEVMToken();
    }
    if (walletAddress) {
      if (props.task.taskKey == 'WEB3_HOLDING_TOKEN' || props.task.taskKey == 'WEB3_HOLDING_NFT') {
        if (props.task.chain === 'bitlayer') {
          const status = await checkBitlayerBalance(walletAddress, props.task.balance!);
          if (status) {
            finished.value = true;
            emits('callback', props.task.taskId, props.task.taskType);
          } else {
            toast.error(props.task.desc);
          }
        } else if (props.task.chain != 'ton') {
          try {
            console.info(props.task.checkUrl + walletAddress);
            const status = await botAPI.get<boolean>(props.task.checkUrl + walletAddress);
            if (status) {
              finished.value = true;
              emits('callback', props.task.taskId, props.task.taskType);
            } else {
              toast.error(props.task.desc);
            }
          } catch (e: any) {
            if (e && e.msg) {
              toast.error(e.msg);
            } else {
              toast.error('Request timeout.');
            }
          }
        }
      }
      if (props.task.taskKey == 'WEB3_CHECK_TRANSACTION') {
        const status = await checkBitlayerTransactions(walletAddress, props.task.balance!, props.task.duration!);
        if (status) {
          finished.value = true;
          emits('callback', props.task.taskId, props.task.taskType);
        } else {
          toast.error(props.task.desc);
        }
      }
    }
  } catch (msg: any) {
    toast.error(t(convertToLangeLabel(msg)));
  }
  showWeb3Wallet.value = false;
  loading.value = false;
};

async function checkBitlayerTransactions(walletAddress: string, transactionAmount: number, duration: number) {
  try {
    const _timestamp = Math.ceil(new Date().getTime() / 1000 - duration);
    const result = await queryBlockNumber(_timestamp);
    if (result && result.status === 1) {
      const transactions = await queryTransactionCount(walletAddress, transactionAmount, result.result);
      if (transactions && transactions.status === 1) {
        return transactions.result.length >= transactionAmount;
      }
    }
    return false;
  } catch (e: any) {
    return false;
  }
}
async function checkBitlayerBalance(walletAddress: string, balance: number) {
  try {
    const result = await queryNativeBalance(walletAddress);
    if (result && result.status === 1) {
      const _balance = Number(result.result) / 1e18;
      return _balance >= balance;
    }
    return false;
  } catch (e: any) {
    return false;
  }
}

async function clickTask() {
  if (props.task.taskType == 'TG') {
    window.open(props.task.taskUrl);
  } else if (props.task.taskType == 'X') {
    if (props.task.taskKey == 'X_FOLLOW') {
      window.open(props.task.taskUrl);
    } else if (props.task.taskKey == 'X_TWEET') {
      window.open(props.task.taskUrl);
    } else if (props.task.taskKey == 'X_RETWEET') {
      window.open(props.task.taskUrl);
    }
  } else if (props.task.taskType == 'YOUTUBE') {
    window.open(props.task.taskUrl);
  } else if (props.task.taskType == 'DISCORD') {
    window.open(props.task.taskUrl);
  } else if (props.task.taskType == 'WEB3') {
    chain.value = props.task.chain!;
    showWeb3Wallet.value = true;
  } else if (props.task.taskType == 'OTHER') {
    if (props.task.taskKey == 'OTHER_VISIT_WEBSITE') {
      const tempPage = window.open('_blank');
      tempPage!.location = props.task.taskUrl!;
      botAPI.get<boolean>(props.task.checkUrl);
      showCheck.value = true;
    }
  }
}

async function requestCheckUrl(url: string, taskId: number, taskType: string, needDelay = 0) {
  try {
    const status = await botAPI.get<boolean>(url);
    if (needDelay) {
      cd.value = needDelay;
      cutDown.set('mttTask' + props.task.taskId, cd);
    }

    if (status) {
      finished.value = true;
      emits('callback', taskId, taskType);
    } else {
      toast.error(t('MTT_TASK_NOT_FINISH'));
    }
  } catch (e: any) {
    if (e.msg) {
      toast.error(t(convertToLangeLabel(e.msg)));
    }
  }
  loading.value = false;
}

async function checkTask() {
  loading.value = true;
  if (props.task.taskType == 'TG' && tgUser.id) {
    requestCheckUrl(props.task.checkUrl + tgUser.id, props.task.taskId, props.task.taskType);
  } else if (props.task.taskType == 'X' && xUser.id) {
    setTimeout(() => requestCheckUrl(props.task.checkUrl, props.task.taskId, props.task.taskType), 5000);
  } else if (props.task.taskType == 'YOUTUBE' && googleUser.id) {
    if (props.task.taskKey == 'YOUTUBE_SUBSCRIPT_CHANNEL') {
      requestCheckUrl(props.task.checkUrl, props.task.taskId, props.task.taskType);
    }
  } else if (props.task.taskType == 'DISCORD' && discordUser.id) {
    if (props.task.taskKey == 'DISCORD_JOIN_SERVER') {
      requestCheckUrl(props.task.checkUrl, props.task.taskId, props.task.taskType, 60);
    }
  } else if (props.task.taskType == 'WEB3') {
    chain.value = props.task.chain!;
    showWeb3Wallet.value = true;
    loading.value = false;
  } else if (props.task.taskType == 'OTHER') {
    if (props.task.taskKey == 'OTHER_VISIT_WEBSITE') {
      requestCheckUrl(props.task.checkUrl, props.task.taskId, props.task.taskType);
    }
  } else {
    if (props.task.taskType == 'TG') {
      toast.info(t('task.COMPLETE_LOGIN_NOTIFY', { v: 'telegram' }));
    } else if (props.task.taskType == 'DISCORD') {
      toast.info(t('task.COMPLETE_LOGIN_NOTIFY', { v: 'discord' }));
    } else if (props.task.taskType == 'X') {
      toast.info(t('task.COMPLETE_LOGIN_NOTIFY', { v: 'X(twitter)' }));
    } else if (props.task.taskType == 'YOUTUBE') {
      toast.info(t('task.COMPLETE_LOGIN_NOTIFY', { v: 'google' }));
    }
    loading.value = false;
  }
}

async function checkResult() {
  let status = false;
  if (props.task.taskType == 'TG') {
    if (tgUser.id) {
      try {
        status = await botAPI.get<boolean>(props.task.checkUrl + tgUser.id);
      } catch (e: any) {
        if (e.msg) {
          toast.error(e.msg);
        }
      }
    }
  } else {
    if (props.task.taskType == 'YOUTUBE' && googleUser.id) {
      status = await botAPI.get<boolean>(props.task.checkUrl);
    } else if (
      props.task.taskType == 'DISCORD' ||
      props.task.taskType == 'WEB3' ||
      props.task.taskType == 'X' ||
      props.task.taskType == 'OTHER'
    ) {
      if (props.task.taskKey == 'OTHER_VISIT_WEBSITE') {
        showCheck.value = false;
      } else {
        return;
      }
    }
  }

  if (status) {
    finished.value = true;
    emits('callback', props.task.taskId, props.task.taskType);
  }
}

const makeTransaction = async (wallet: string) => {
  try {
    if (wallet == 'okx') {
      await makeOKXEVMTransaction('0x310c5');
    } else if (wallet === 'metamask') {
      await makeMetaMaskEVMTransaction('0x310c5');
    } else if (wallet === 'debox') {
      await makeDeboxEVMTransaction('0x310c5');
    }
    cd.value = 10;
    cutDown.set('web3Task', cd);
    toast.info('Please wait for confirmation of the transaction.');
    setTimeout(() => checkWeb3Task(wallet), 10000);
  } catch (error: any) {
    toast.error(error);
  }
  showTransactionWallet.value = false;
};

onMounted(async () => {
  checkResult();
});

const cd = ref(0);
if (cd.value > 0) {
  cutDown.set('mttTask' + props.task.taskId, cd);
}
</script>

<template>
  <div class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
    <div class="flex">
      <div class="flex-1">
        <h6>{{ task.title }}</h6>
        <p class="text-xs">{{ task.desc }}</p>
      </div>
      <div v-if="finished" class="i-local-completed text-4xl bg-green"></div>
    </div>
    <div v-if="!finished" class="flex flex-row justify-center space-x-2">
      <Button
        v-if="!finished && task.taskKey == 'WEB3_CHECK_TRANSACTION'"
        severity="info"
        class="p-.5 w-full justify-center"
        @click="showTransactionWallet = true"
      >
        <div class="rd-full p-1">
          <div class="i-local-wallet-task text-3xl c-black" />
        </div>
        <span class="text-sm ml-3">{{ $t('MAKE_TRANSACTION') }}</span>
      </Button>
      <Button severity="info" class="p-.5 w-full justify-center" @click="clickTask">
        <div v-if="task.taskType == 'TG'" class="rd-full p-1">
          <div class="i-local-telegram text-3xl c-white" />
        </div>
        <div v-if="task.taskType == 'DISCORD'" class="rd-full p-1">
          <div class="i-local-discord text-3xl c-white" />
        </div>
        <div v-if="task.taskType == 'X'" class="rd-full bg-white p-2">
          <div class="i-local-twitter text-xl c-black" />
        </div>
        <div v-if="task.taskType == 'YOUTUBE'" class="rd-full p-1">
          <div class="i-local-youtube text-3xl c-black" />
        </div>
        <div v-if="task.taskType == 'WEB3'" class="rd-full p-1">
          <div class="i-local-wallet-task text-3xl c-black" />
        </div>
        <div v-if="task.taskType == 'EMAIL'" class="bg-green rd-full p-2">
          <div class="i-local-mail size-4" />
        </div>
        <div v-if="task.taskType == 'OTHER'" class="rd-full p-1">
          <div class="i-local-other-task text-3xl c-black" />
        </div>
        <span class="text-sm ml-3">{{ task.btnText }}</span>
      </Button>
      <Button
        v-if="task.taskType != 'WEB3' && showCheck"
        :disabled="cd > 0 || loading"
        severity="info"
        class="p-.5 w-1/4 justify-center"
        @click="checkTask"
      >
        <span class="text-sm">{{ cd ? $t('X_SEC', { v: cd.toFixed(0) }) : $t('MTT_TASK_CHECK') }}</span>
        <div v-show="loading" class="absolute inset-0 bg-black/40 flex items-center justify-center">
          <div class="text-lg i-local-loading" />
        </div>
      </Button>
    </div>
  </div>
  <DPWeb3Wallet v-model="showWeb3Wallet" :chain="chain" @loginWeb3="checkWeb3Task" />
  <DPWeb3Wallet v-model="showTransactionWallet" :chain="'bitlayer'" @loginWeb3="makeTransaction" />
</template>
