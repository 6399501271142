<template>
  <!-- <div class="flex my-4" un-children="text-center flex-1 p-2 b-b-solid cursor-pointer">
      <h5
        v-for="(item, i) in tabs"
        :key="i"
        :class="tab === i ? 'b-green bg-green/20' : 'b-transparent'"
        @click="tab = i"
      >
        {{ item }}
      </h5>
    </div> -->
  <teleport to="body">
    <div class="absolute bottom-8 right-4 flex flex-col items-center" @click="quickGame()">
      <img class="size-20" src="@/assets/img/quick-game.png" alt="" />
      <p class="b-solid rd-full b-green bg-black -mt-5 relative c-white text-center px-1">Quick Play</p>
    </div>
  </teleport>
  <div class="grid gap-2 of-auto" landscape="grid-cols-2">
    <div
      v-for="(t, i) in tables"
      :key="i"
      class="flex flex-col items-center pt-16 h-66 cursor-pointer bgi-golden-table-bg bg-contain bg-no-repeat bg-center"
      @click="quickGame(t.index, t.level)"
    >
      <h3 class="c-gray-100">{{ t.name }}</h3>
      <div class="text-base flex items-center c-gray-200">
        {{ t.maxPlayerNumber }} Max · Min/Max Buy-in: <img class="size-4 mx-1" src="@/assets/img/coin.png" alt="" />
        <span class="c-white">{{ formatNumber(t.minBuy, true) }}-{{ formatNumber(t.maxBuy, true) }}</span>
      </div>
      <div class="flex text-3xl my-3 items-center">
        Blinds:
        <img class="mx-1 w-7" src="@/assets/img/chip.png" alt="" /> <b>{{ t.smallBlind }}/{{ t.bigBlind }}</b>
      </div>
      <div class="flex items-center text-lg px-15 bg-gradient-to-r from-black/0 via-black/10 to-black/0">
        <span class="i-local-user mr-2" />
        {{ t.playingCount }}
      </div>
    </div>
  </div>
  <ADialog class="w-100 text-center" unclosable v-model="showLoading">
    <ProgressSpinner strokeWidth="3" />
    <h5 class="mt-4">{{ $t('mtt.WAIT_TABLE_ALLOCATE') }}...</h5>
  </ADialog>
</template>
<script lang="ts" setup>
import { useDialog } from '@/components/ui/APopPad.vue';
import { toast } from '@/components/ui/AToast.vue';
import { showLogin, user } from '@/stores';
import type { GoldenRoom } from '@/types';
import { authAPI } from '@/utils/network';
import { formatNumber } from '@/utils/strings';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

// const tabs = ['Newbie', 'Beginner', 'Intermediate', 'Advanced'];
// const tab = ref(0);
const tables = ref<GoldenRoom[]>([]);
const router = useRouter();
const { t } = useI18n();
const dialog = useDialog();
const showLoading = ref(false);

onMounted(() => {
  authAPI.get('v1/goldCoinTable/getAllTableType').then((v) => {
    tables.value = v.map((k: any) => k.tableTypes).flat();
  });
});

const quickGame = (index?: number, level?: number) => {
  if (!user.value) return (showLogin.value = true);
  showLoading.value = true;
  authAPI
    .post('v1/goldCoinTable/quickPlay', { index, level }, undefined, undefined, { timeout: 3e5 })
    .then(({ roomId, buyInAmount }) => {
      if (buyInAmount) toast.success(t('BUY_IN_CHIPS_AUTOMATICALLY', [buyInAmount]));
      router.push(`/g/g/${roomId}`);
    })
    .catch((e) => {
      if (e.code === 1203)
        return dialog.confirm({
          title: t('INSUFFICIENT_COINS'),
          content: t('YOUR_BALANCE_CANT_MEET_MIN_BUYIN'),
          btn: t('GET_COINS'),
          action: () => router.push('/mall/coin'),
        });
    })
    .finally(() => {
      showLoading.value = false;
    });
};
</script>
