<script setup lang="ts">
import type { PropType } from 'vue';
import { onMounted, ref } from 'vue';
import { useDialog } from '@/components/ui/APopPad.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const dialog = useDialog();

const showTgLoginBtn = ref(false);

const props = defineProps({
  mode: {
    type: String as PropType<Mode>,
    required: true,
    validator: (prop: Mode) => ['callback', 'redirect'].includes(prop),
  },
  botUsername: {
    type: String,
    required: true,
    validator: (prop: String) => prop.endsWith('bot') || prop.endsWith('Bot'),
  },
  redirectUrl: {
    type: String,
    default: '',
  },
  requestAccess: {
    type: String as PropType<Access>,
    default: 'read' as Access,
    validator: (prop: Access) => ['read', 'write'].includes(prop),
  },
  size: {
    type: String as PropType<Size>,
    default: 'medium' as Size,
    validator: (prop: Size) => ['small', 'medium', 'large'].includes(prop),
  },
  hideUserPic: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: String,
  },
});

const emit = defineEmits(['callback']);

type Mode = 'callback' | 'redirect';
type Access = 'read' | 'write';
type Size = 'small' | 'medium' | 'large';

const button = ref<HTMLElement | undefined>();

const onTelegramAuth = (user: any) => {
  emit('callback', user);
};

onMounted(() => {
  // checkIframeLoaded();
  showTgLoginBtn.value = true;
  const script = document.createElement('script');
  script.async = false;
  script.src = 'https://telegram.org/js/telegram-widget.js?3';
  script.setAttribute('data-size', props.size);
  script.setAttribute('data-userpic', (!props.hideUserPic).toString());
  script.setAttribute('data-telegram-login', props.botUsername);
  script.setAttribute('data-request-access', props.requestAccess);
  if (props.radius) script.setAttribute('data-radius', props.radius);
  if (props.mode === ('callback' as Mode)) {
    (window as any).onTelegramAuth = onTelegramAuth;
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
  } else {
    script.setAttribute('data-auth-url', props.redirectUrl);
  }
  if (button.value) button.value.appendChild(script);
});

const checkIframeLoaded = async () => {
  let iframe = document.getElementById('telegram-login-' + props.botUsername);
  if (iframe) {
    iframe.onload = () => {
      console.log('Iframe加载完成');
      pb.value = 'relative pb-10';
      showTgLoginBtn.value = true;
    };
    // console.info('iframe load');
  } else {
    setTimeout(checkIframeLoaded, 2000);
  }
};

const loginTgTips = async () => {
  dialog.alert({
    title: t('NOTICE'),
    content: t('TG_BTN_TIPS'),
  });
};

const pb = ref('relative pb-1');
</script>

<template>
  <!-- <div ref="button" /> -->
  <div :class="pb">
    <AButton v-if="!showTgLoginBtn" style="background-color: #54a9eb" class="absolute z-1 w-full" @click="loginTgTips">
      <span class="ml-3">{{ $t('task.TG_LOGIN') }}</span>
    </AButton>
    <div v-show="showTgLoginBtn" ref="button" />
  </div>
</template>
