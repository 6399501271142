<template>
  <h2 class="flex justify-center items-center mb-4">
    {{ seasons?.[cur]?.seasonName || '-' }}
    <APopButton v-model="showPad" align="end">
      <AButton class="text-xs ml-2" type="secondary" icon="i-local-arrow-d" />
      <template #pad>
        <div class="bg-gray-700 rd p-4 w-max space-y-2 fw-normal" un-children="cursor-pointer">
          <p v-for="(s, i) in seasons" :key="i" :class="{ 'c-green': cur === i }" @click="cur = i">
            {{ s.dropName }}
          </p>
        </div>
      </template>
    </APopButton>
  </h2>
  <p class="text-center mb-4">{{ seasonRange }}</p>
  <div class="flex-1 of-auto mb-4">
    <div v-for="(item, i) in records" :key="i" class="py-4 b-t-solid b-1 c-gray-900 space-y-2">
      <h3 class="c-white">{{ item.name }}</h3>
      <div class="flex justify-between children:c-gray-300">
        <p v-if="item.reason">
          {{ $t('REASON') }}: <span class="c-white fw-bold">{{ $t(convertToLangeLabel(item.reason)) }}</span>
        </p>
        <template v-else>
          <p>
            {{ $t('mtt.RANKING') }}: <span class="c-white fw-bold">{{ item.ranking }}</span>
          </p>
          <p>
            {{ $t('PLAYERS_QUANTITY') }}: <span class="c-white fw-bold">{{ item.players }}</span>
          </p>
        </template>
        <p>{{ dt(item.gameTime * 1e3).strDate }}</p>
      </div>
      <div class="flex space-x-3 text-center children:flex-1">
        <div class="bg-gray-900 p-2 rd-lg">
          <div class="c-gray-300">
            {{ $t('POWER') }}:
            <span class="text-lg fw-bold ml-1" :class="item.power < 0 ? 'c-red' : 'c-green'">
              {{ item.power < 0 ? item.power : `+${item.power}` }}
            </span>
          </div>
        </div>
        <div class="bg-gray-900 p-2 rd-lg">
          <div class="c-gray-300">
            {{ $t('POINTS') }}: <span class="c-green text-lg fw-bold ml-1">{{ item.points }}</span>
          </div>
        </div>
      </div>
    </div>
    <ALoader class="mb-4" :key="cur" :loader="loadHistory" />
  </div>
</template>
<script lang="ts" setup>
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { computed, ref, watch } from 'vue';
import { convertToLangeLabel } from '@/utils/strings';

type record = {
  gameTime: number;
  name: string;
  players: number;
  points: number;
  power: number;
  ranking: number;
  reason?: string;
};

const records = ref<record[]>([]);

const seasons =
  ref<{ seasonName: string; dropName: string; seasonFlag: number; startDate: string; endDate: string }[]>();
const cur = ref(0);
const showPad = ref(false);

authAPI.get('v1/sng/getLeaderBoardList').then((v) => (seasons.value = v));

watch(cur, () => {
  showPad.value = false;
  records.value = [];
});

const seasonRange = computed(() => {
  if (!seasons.value) return '-';
  return `${seasons.value[cur.value].startDate} ~ ${seasons.value[cur.value].endDate}`;
});

async function loadHistory(idx?: string) {
  const res = await authAPI.get(
    'v1/sng/get_power_historys',
    {
      pageSize: 10,
      pageId: idx,
      seasonFlag: seasons.value?.[cur.value].seasonFlag,
    },
    undefined,
    true,
  );
  const data = res.powerDetail.map((v: any) => {
    v.name = `LePoker Ladder Game (starts at ${dt(+v.name * 1e3).strDateTimeShort})`;
    if (v.reason) v.name = `LePoker Ladder Game #${v.repairCount} power adjustment`;
    return v;
  });
  records.value.push(...data);
  if (data.length < 10) return 'end';
  return res.pageId;
}
</script>
