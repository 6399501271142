<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { toTypedSchema } from '@vee-validate/yup';
import { cutDown } from '@/utils/timer';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { ref } from 'vue';
import { emailBind, emailLink, emailOPT, emailOperation } from '@/utils/user';
import { convertToLangeLabel } from '@/utils/strings';
import { socialToken, user } from '@/stores';

const i18n = useI18n();
const toast = useToast();

const emits = defineEmits<{ callback: [] }>();
const props = defineProps<{ connectType: string }>();

const loginName = ref('');

const schema = toTypedSchema(
  yup.object({
    email: yup.string().required().email(),
    otp: yup.string().required(),
  }),
);

const { errors, values, meta, defineComponentBinds } = useForm({
  validationSchema: schema,
  initialValues: { email: loginName.value },
  validateOnMount: loginName.value ? true : false,
});
const email = defineComponentBinds('email');
const otp = defineComponentBinds('otp');
const cd = ref(cutDown.value.otp?.value || 0);

const requestOTP = async () => {
  if (props.connectType == 'connect') {
    await connectOTP();
  } else if (props.connectType == 'bind') {
    await bindOTP();
  }
};

const connectOTP = async () => {
  try {
    await emailOperation(values.email!, 1);
    cd.value = 60;
    cutDown.set('otp', cd);
  } catch (e: any) {
    cd.value = 0;
    toast.add({
      severity: 'error',
      summary: i18n.t('ERROR_MESSAGE'),
      detail: i18n.t(convertToLangeLabel(e.msg ? e.msg : e)),
      life: 3000,
    });
  }
};

const bindOTP = async () => {
  try {
    await emailOPT(values.email!, socialToken.value);
    cd.value = 60;
    cutDown.set('otp', cd);
  } catch (e) {
    cd.value = 0;
    toast.add({ severity: 'error', summary: i18n.t('ERROR_MESSAGE'), detail: i18n.t('GET_OTP_ERROR'), life: 3000 });
  }
};

const submitEmail = async () => {
  if (props.connectType == 'connect') {
    await requestLink();
  } else if (props.connectType == 'bind') {
    await requestBind();
  }
};

const requestLink = async () => {
  try {
    await emailLink(values.email!, values.otp!, 1);
    user.value!.email = values.email!;
    emits('callback');
  } catch (e: any) {
    toast.add({
      severity: 'error',
      summary: i18n.t('ERROR_MESSAGE'),
      detail: i18n.t(convertToLangeLabel(e.msg ? e.msg : e)),
      life: 3000,
    });
  }
};

const requestBind = async () => {
  try {
    await emailBind(values.email!, values.otp!, socialToken.value);
  } catch (e: any) {
    toast.add({
      severity: 'error',
      summary: i18n.t('ERROR_MESSAGE'),
      detail: i18n.t(convertToLangeLabel(e.msg ? e.msg : e)),
      life: 3000,
    });
  }
};
</script>

<template>
  <form class="p-1 flex flex-col">
    <p>{{ $t('EMAIL') }}</p>
    <InputText id="value" class="w-full mt-1" v-bind="email" />
    <small class="p-error">{{ (!!errors.email && $t(convertToLangeLabel(errors.email))) || '&nbsp;' }}</small>
    <div class="p-inputgroup">
      <InputText id="ipt-login-otp" v-bind="otp" :placeholder="$t('OTP')" />
      <AButton
        id="btn-get-otp"
        class="w-30 rd-l-none text-sm"
        outlined
        :disabled="!values.email || !!errors.email || !!cd"
        :action="requestOTP"
      >
        {{ cd ? $t('X_SEC', { v: cd.toFixed(0) }) : $t('GET_OTP') }}
      </AButton>
    </div>
    <small class="p-error">{{ '&nbsp;' }}</small>
    <Button id="btn-login" :disabled="!meta.valid" :label="$t('CONNECT')" @click="submitEmail" />
  </form>
</template>
