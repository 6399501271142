<template>
  <div>
    <div v-if="userExperience" class="h-34 rd-lg of-hidden flex flex-row items-center m-2 bg-gray-900">
      <div class="flex flex-col justify-center items-center ml-6 space-y-2">
        <DPAvatar
          class="flex items-center text-3xl"
          :name="user!.name"
          :id="user!.id"
          :title="user!.title"
          :showBorder="true"
          :border="claimBorder"
        >
        </DPAvatar>
        <span class="text-xs rd-lg bg-[#5D6270] p-1">{{ $t(user!.title) }}</span>
      </div>
      <div v-if="userExperience" class="ml-6 flex flex-col w-full space-y-1.5">
        <div class="flex flex-row items-center">
          <span class="c-green text-xl ml-2">Lv.{{ userExperience.level }}</span>
          <span class="i-local-tutorial c-white ml-2 mr-auto" @click="openTips"></span>
        </div>
        <div class="flex flex-row items-center">
          <img src="@/assets/img/task-experience.png" class="h-8" />
          <div class="flex flex-col ml-1">
            <span class="c-gray-400"
              >{{ userExperience.currentExperience - userExperience.lastExperience }}/{{
                userExperience.nextExperience - userExperience.lastExperience
              }}</span
            >
            <div class="rd-lg h-3 relative" :class="landscape ? 'w-[25rem]' : 'w-[10rem]'">
              <div
                class="absolute rd-lg h-3 bg-gradient-to-t from-[#413F3F] to-[#090909]"
                :class="landscape ? 'w-[25rem]' : 'w-[10rem]'"
              ></div>
              <div
                class="absolute rd-lg h-3 bg-green justify-center items-center z-10"
                :style="{ width: `${experienceLong}rem` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="userExperience && userExperience.canClaim"
        class="relative rd-lg b-2 b-solid h-full b-green b-greenof-hidden flex flex-col justify-center text-center bg-gray-700 space-y-1.5 w-36"
        @click="claimLevelPrize(userExperience.finishId)"
      >
        <div class="absolute bg-green c-white text-xs w-full top-0">
          {{ $t('TASK_CLICK_TO_CLAIM') }}
        </div>
        <div class="flex flex-row justify-center items-center space-x-4 pl-2 pr-2">
          <div class="flex flex-col justify-center items-center" :class="{ '-mr-2 pl-1': userExperience.title }">
            <img src="@/assets/img/task-diamond.png" class="h-10" />
            <span class="c-green pt-2">{{ userExperience?.prizeDiamond }}</span>
          </div>
          <div v-if="userExperience.title" class="flex flex-col justify-center items-center">
            <img class="h-20 -mt-4" :src="border" />
            <span class="text-xs rd-lg bg-[#5D6270] -mt-3 pl-1 pr-1">{{ $t(userExperience.title) }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="userExperience && !userExperience.canClaim && userExperience.prizeDiamond > 0"
        class="relative rd-lg flex flex-col justify-center items-center bg-gray-700 space-y-1.5 w-36 h-full"
      >
        <div class="absolute rd-lg justify-center items-center c-white text-xs pl-1 pr-1 bg-[#5A5B64] top-2">
          {{ $t('TASK_LEVEL_PRIZE') }}
        </div>
        <div class="flex flex-row justify-center items-center space-x-2 pl-2 pr-2">
          <div class="flex flex-col justify-center items-center pt-2">
            <img src="@/assets/img/task-diamond.png" class="h-10" />
            <span class="c-green pt-2">{{ userExperience.prizeDiamond }}</span>
          </div>
          <div v-if="userExperience.title" class="flex flex-col justify-center items-center">
            <img class="h-20 -mt-4" :src="border" />
            <span class="text-xs rd-lg bg-[#5D6270] -mt-3 pl-1 pr-1">{{ $t(userExperience.title) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="experienceData" class="space-y-2.5 p-2">
      <span class="text-xl">{{ $t('TASK_LIST') }}</span>
      <DPGoldPlatformTaskItem :data="experienceData" @callback="claimExperienceCallback" />
    </div>
    <ADialog class="w-100 item-center justify-center" :unclosable="true" :transparent="true" v-model="showClaimNotify">
      <TaskAnim :diamond="claimData?.diamond" :title="claimData?.title" :experience="claimData?.experience" />
      <div class="flex justify-center">
        <AButton
          class="w-30"
          @click="
            showClaimNotify = false;
            loadExperienceInfo();
          "
          >{{ $t('game.CONFIRM') }}</AButton
        >
      </div>
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import type { PlatformTask, PlatformTaskGroup, TaskClaimPrize, UserExperience } from '@/types';
import { authAPI } from '@/utils/network';
import { ref, watch } from 'vue';
import {
  bnbUser,
  deboxUser,
  discordUser,
  ethUser,
  landscape,
  lineaUser,
  showLoading,
  solanaUser,
  taskRedDot,
  tgUser,
  tonUser,
  user,
  xUser,
  zksyncUser,
} from '@/stores';
import { useDialog } from '@/components/ui/APopPad.vue';
import { useI18n } from 'vue-i18n';
import { toast } from '@/components/ui/AToast.vue';
import { checkConnectTaskFinish } from '@/utils/task';
import { checkSubBot } from '@/utils/telegram';

const { t } = useI18n();

const experienceData = ref([] as PlatformTask[]);
const userExperience = ref<UserExperience>();
const experienceLong = ref(0);
const border = ref('');
const claimBorder = ref('');
const experienceClaimTips = ref(0);
const showClaimNotify = ref(false);
const claimData = ref<TaskClaimPrize>();
showLoading.value = true;

watch(
  () => userExperience.value,
  (v) => {
    if (v) {
      if (landscape.value) {
        experienceLong.value = ((v.currentExperience - v.lastExperience) / (v.nextExperience - v.lastExperience)) * 25;
      } else {
        experienceLong.value = ((v.currentExperience - v.lastExperience) / (v.nextExperience - v.lastExperience)) * 10;
      }
      if (v.title) {
        border.value = '/avatar-border/border' + v.title.replace('TITLE_', '') + '.png';
      }
    }
  },
  { immediate: true },
);

//以防死循环
let reloadTime = 0;
const loadExperienceTaskList = async () => {
  authAPI
    .get<PlatformTaskGroup[]>('v1/task/list', { taskTime: 'experience' })
    .then((v) => {
      let reload = false;
      v.forEach((group) => {
        // let claimTask = 0;
        group.list.forEach((task) => {
          // if (task.isFinish && !task.isClaim) claimTask = claimTask + 1;
          if (task.taskKey === 'CONNECT_TG' && tgUser.id && !task.isFinish) {
            checkConnectTaskFinish('TG', tgUser.id + '');
            reload = true;
          } else if (task.taskKey === 'CONNECT_DISCORD' && discordUser.id && !task.isFinish) {
            checkConnectTaskFinish('DISCORD', discordUser.id);
            reload = true;
          } else if (task.taskKey === 'CONNECT_X' && xUser.id && !task.isFinish) {
            checkConnectTaskFinish('X', xUser.id);
            reload = true;
          } else if (task.taskKey === 'CONNECT_DEBOX' && deboxUser.id && !task.isFinish) {
            checkConnectTaskFinish('DEBOX', deboxUser.id);
            reload = true;
          } else if (task.taskKey === 'CONNECT_TON' && tonUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_TON#TON', tonUser.address);
            reload = true;
          } else if (task.taskKey === 'CONNECT_ETH' && ethUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_EVM#ETH', ethUser.address);
            reload = true;
          } else if (task.taskKey === 'CONNECT_BSC' && bnbUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_EVM#BSC', bnbUser.address);
            reload = true;
          } else if (task.taskKey === 'CONNECT_SOLANA' && solanaUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_SOL#SOL', solanaUser.address);
            reload = true;
          } else if (task.taskKey === 'CONNECT_ZKSYNC' && zksyncUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_EVM#ZKSYNC', zksyncUser.address);
            reload = true;
          } else if (task.taskKey === 'CONNECT_LINEA' && lineaUser.address && !task.isFinish) {
            checkConnectTaskFinish('WALLET_EVM#LINEA', lineaUser.address);
            reload = true;
          } else if (task.taskKey === 'START_TG_BOT' && !task.isFinish) {
            if (tgUser.id) {
              checkSubBot().then((v) => {
                if (v) {
                  checkConnectTaskFinish('START_TG_BOT', tgUser.id + '');
                  reload = true;
                }
              });
            }
          }
        });
        // experienceClaimTips.value = claimTask;
        experienceData.value = group.list;
      });
      if (reload && reloadTime < 3) {
        reloadTime++;
        loadExperienceTaskList();
      }
      showLoading.value = false;
    })
    .catch((e: any) => {
      toast.error(e.msg);
      showLoading.value = false;
    });
};
loadExperienceTaskList();

const loadExperienceInfo = async () => {
  authAPI.get<UserExperience>('v1/task/getExperienceInfo').then((v) => {
    userExperience.value = v;
    if (userExperience.value.canClaim) experienceClaimTips.value += 1;
  });
};
loadExperienceInfo();

const claimExperienceCallback = async (v: any) => {
  claimData.value = v;
  showClaimNotify.value = true;
  taskRedDot.value.experienceTask = taskRedDot.value.experienceTask - 1;
  await loadExperienceTaskList();
};

const dialog = useDialog();
const openTips = () => {
  dialog.alert({
    title: t('TASK_LEVEL_TIPS_TITLE'),
    content: (
      <div class="space-y-2" un-children="p-3 rd-lg bg-gray-800">
        <p>
          <b class="c-green"> 1 </b> {t('TASK_LEVEL_TIPS_1')}
        </p>
        <p>
          <b class="c-green"> 2 </b> {t('TASK_LEVEL_TIPS_2')}
        </p>
        <p>
          <b class="c-green"> 3 </b> {t('TASK_LEVEL_TIPS_3')}
        </p>
      </div>
    ),
  });
};

const claimLevelPrize = async (finishId: number) => {
  const params = {
    taskKey: 'USER_LEVEL',
    taskId: 0,
    finishId: finishId,
  };
  await authAPI
    .post('v1/task/claimTaskPrize', params)
    .then((v) => {
      claimData.value = v;
      if (v.title) {
        claimBorder.value = '/avatar-border/border' + v.title.replace('TITLE_', '') + '.png';
        user.value!.title = v.title;
      }
      showClaimNotify.value = true;
      taskRedDot.value.levelTask = taskRedDot.value.levelTask - 1;
      loadExperienceInfo();
    })
    .catch((e: any) => {
      toast.error(e.msg);
    });
};
</script>
