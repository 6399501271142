<template>
  <div class="pt-4">
    <div v-for="(v, i) in items" :key="i" class="rd-lg bg-gray-900 p-4 mb-4">
      <div class="c-gray mb-3 flex">
        {{ dt(v.orderTime).strDatetime }} <span class="ml-a">Order ID:{{ v.orderId }}</span>
      </div>
      <div class="flex items-center">
        <span class="fw-bold c-green mr-a">{{ $t(v.productName, [v.productNum]) }}</span>
        <span class="i-local-tg-star mr-1" />{{ v.amount }}
      </div>
    </div>
    <ALoader class="mb-4" :loader="loadData" />
  </div>
</template>
<script setup lang="ts">
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';

const items = ref<any>([]);

async function loadData() {
  items.value = await authAPI.get('v1/market/product_transaction_history', {
    productType: 0,
    channel: 1,
  });
  return 'end';
}
</script>
