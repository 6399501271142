<template>
  <div class="py-4 of-hidden">
    <Teleport to="#m-nav-add">
      <div class="i-local-tutorial size-7 cursor-pointer" @click="$router.push('/faq')" />
    </Teleport>
    <div class="flex mb-4" un-children="text-center flex-1 p-2 b-b-solid cursor-pointer">
      <h5
        :class="$route.name === 'ring-tables' ? 'b-green bg-green/20' : 'b-transparent'"
        @click="$router.replace('/rg')"
      >
        {{ $t('mtt.TABLES') }}
      </h5>
      <h5
        :class="$route.name === 'ring-rank' ? 'b-green bg-green/20' : 'b-transparent'"
        @click="$router.replace('/rg/rank')"
      >
        {{ $t('rank') }}
      </h5>
    </div>
    <RouterView />
  </div>
</template>
