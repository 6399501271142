<template>
  <input class="input-comp" :value="num" @input="update" type="number" @change="reset" />
</template>
<script lang="ts" setup>
const num = defineModel<number>();

const update = (e: Event) => {
  const v = (e.target as HTMLInputElement).value;
  if (v === '') return;
  const n = parseInt(v);
  if (!isNaN(n)) num.value = n;
  (e.target as HTMLInputElement).value = '' + num.value;
};
const reset = (e: Event) => {
  const v = (e.target as HTMLInputElement).value;
  if (!v) (e.target as HTMLInputElement).value = '' + num.value;
};
</script>
<style scoped>
.input-comp {
  display: block;
  font-size: 1rem;
  max-width: 5rem;
  background: transparent;
  border: 0;
  outline: 0;
  color: white;
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}
.input-comp::-webkit-inner-spin-button,
.input-comp::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Safari */
  margin: 0;
}
</style>
