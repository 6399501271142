<template>
  <div v-if="eventId" class="absolute z-3 top-100 right-0 cursor-pointer" @click="openDraw">
    <img class="w-26" src="@/assets/img/a-draw.png" alt="" />
  </div>
  <ADialog v-if="drawInfo" v-model="showDraw" class="w-110" :fullBg="drawInfo.picBackground">
    <div
      class="flex flex-col items-center bg-center bg-no-repeat bg-contain"
      :class="{ 'bgi-draw-bg': !drawInfo.picBackground }"
    >
      <div class="flex items-center justify-around w-full mt-2">
        <div class="i-local-star-fill text-4xl c-iceblue" v-if="theme === 'iceblue'" />
        <div
          class="rd-lg text-4xl fw-extrabold px-4 italic c-black bg-green"
          :class="{ 'bg-iceblue! text-2xl!': theme === 'iceblue' }"
        >
          {{ drawInfo.name }}
        </div>
        <div class="i-local-star-fill text-4xl c-iceblue" v-if="theme === 'iceblue'" />
      </div>
      <div
        class="rd-lg b-solid py-2 px-4 flex items-center w-full my-3 fw-bold c-green"
        :class="{
          'c-[#F4F4C1]!': theme === 'iceblue',
          'b-iceblue!': theme === 'iceblue',
        }"
      >
        <template v-if="leftSeconds > 0">
          <div class="i-local-countdown" />
          <div class="c-white ml-1 mr-auto">{{ dur(leftSeconds * 1e3) }}</div>
          <h5>{{ $t('SPIN') }}</h5>
          <h5 class="c-white ml-1">{{ spinCnt }}</h5>
        </template>
        <div v-else class="c-gray m-auto flex items-center">
          <div class="i-local-countdown mr-1" />
          {{ $t('EVENT_END_TIPS') }}
        </div>
      </div>
      <DPDraw class="w-90 mb-4" :count="drawInfo.prizeList.length" :getResult="draw" @finish="openResult">
        <img class="w-full" :src="drawInfo.pic" alt="" />
        <template #background v-if="drawInfo.picPad">
          <img class="w-full block" :src="drawInfo.picPad" alt="" />
        </template>
        <template #btn>
          <img class="h-55 cursor-pointer" :src="drawInfo.picSpin" alt="" />
        </template>
        <template v-if="leftSeconds <= 0" #disable></template>
      </DPDraw>
      <AButton class="w-full mb-2 py-3" @click="openTips" :class="{ 'bg-iceblue': theme === 'iceblue' }">
        {{ $t('GET_MORE_SPINS') }}
        <template #suffix>
          <div class="ml-auto text-2xl -rotate-90 i-local-arrow-d" />
        </template>
      </AButton>
      <AButton class="w-full py-3" badge outlined @click="openHistory" :class="{ 'c-iceblue': theme === 'iceblue' }">
        {{ $t('SPIN_HISTORY') }}
        <template #suffix>
          <div
            v-if="redPot"
            class="size-2 rd-full ml-2 bg-red"
            :class="{
              'bg-[#F4F4C1]!': theme === 'iceblue',
            }"
          />
          <div class="ml-auto text-2xl -rotate-90 i-local-arrow-d" />
        </template>
      </AButton>
    </div>
    <ADialog v-model="showHistory" class="w-100" :title="t('SPIN_HISTORY')">
      <div class="h-130 mt-2 space-y-2">
        <div v-for="(h, i) in historys" :key="i" class="flex items-center bg-gray-700 p-3 rd-lg">
          <div class="mr-auto">
            <p class="c-gray">{{ dt(h.createTime).strDatetime }}</p>
            <p v-if="h.prizeCode !== 'NOTHING' && prizeDict[h.prizeCode]">
              {{ $t('PRIZE') }} <span class="text-lg c-green fw-bold">{{ prizeDict[h.prizeCode].prizeName }}</span>
            </p>
            <p v-else>{{ $t('SORRY_NO_AWARD') }}</p>
          </div>
          <template v-if="h.prizeCode !== 'NOTHING'">
            <AButton v-if="!h.isClaim" class="text-sm" @click="openClaim(h)">{{ $t('CLAIM') }}</AButton>
            <div v-else class="i-local-completed c-green text-2xl" />
          </template>
        </div>
      </div>
    </ADialog>
    <ADialog v-model="showClaim" class="w-100" :title="$t('CLAIM_YOUR_REWARD')">
      <p class="c-gray text-center">{{ $t('CLAIM_ADDRESS_TIPS', [addressType]) }}</p>
      <p v-if="prizeDict[claimInfo!.prizeCode].prizeDesc" class="my-2">
        {{ $t(prizeDict[claimInfo!.prizeCode].prizeDesc) }}
      </p>
      <div class="bg-gray-700 p-2 my-4 rd-lg">
        <div class="pb-1 mb-2 b-b-solid b-gray-600">
          <div class="mb-1">{{ $t('REWARD_FROM', { v: prizeDict[claimInfo!.prizeCode].sponsor }) }}</div>
          <h4 class="c-green">{{ prizeDict[claimInfo!.prizeCode].prizeName }}</h4>
        </div>
        <DPAvatar :id="user!.id" class="flex items-center text-2xl">
          <h4 class="ml-2 mr-a">{{ user!.name }}</h4>
        </DPAvatar>
      </div>
      <h5 class="mb-2">{{ $t('WALLET_ADDRESS') }}</h5>
      <AInput v-model="address" :placeholder="addressPlaceholder" :rows="4" class="w-full mb-2" />
      <template #footer>
        <AButton :disabled="!address.length" @click="claim">{{ $t('mtt.SUBMIT') }}</AButton>
      </template>
    </ADialog>
  </ADialog>
</template>
<script lang="tsx" setup>
import { computed, ref } from 'vue';
import { useDialog } from './ui/APopPad.vue';

import DPPopItem from '@/components/DPPopItem.vue';
import { authAPI } from '@/utils/network';
import { showLogin, user } from '@/stores';
import { useI18n } from 'vue-i18n';
import { dur, dt } from '@/utils/datetime';
import { useClipboard, useIntervalFn } from '@vueuse/core';
import { genRefUrl } from '@/utils/user';
import { toast } from '@/components/ui/AToast.vue';

const { copy } = useClipboard({ legacy: true });
const { t } = useI18n();
const dialog = useDialog();

const theme = ref('default');

const props = defineProps<{ id: string; locked?: boolean }>();

const showDraw = ref(false);
const historys = ref<{ recordId: number; createTime: number; isClaim: boolean; prizeCode: string }[]>([]);
const showHistory = ref(false);

const eventId = ref<number>();
const spinCnt = ref(0);

const leftSeconds = ref(0);
const needClaim = ref(false);
const canDraw = ref(false);
const redPot = computed(() =>
  historys.value.length ? historys.value.some((v) => !v.isClaim && v.prizeCode !== 'NOTHING') : needClaim.value,
);
const drawInfo = ref<{
  eventId: number;
  name: string;
  canDraw: boolean;
  endTime: number;
  drawTime: number;
  pic: string;
  picSpin: string;
  picBackground: string;
  picPad: string;
  claimRules: string;
  spinTimes: number;
  prizeList: { prizeCode: string; prizeName: string; prizePic: string; sponsor: string; prizeDesc: string }[];
}>();
const addressPlaceholder = ref('0xae7573cdd716488a58b1d67742ae98013ca4xxxx');
const addressType = ref('EVM');

const prizeDict = computed(
  () =>
    drawInfo.value?.prizeList.reduce<
      Record<
        string,
        { prizeCode: string; prizeName: string; prizePic: string; sponsor: string; index: number; prizeDesc: string }
      >
    >((acc, v, i) => ({ ...acc, [v.prizeCode]: { ...v, index: i } }), {}) || {},
);

authAPI.get('v1/turntableLottery/getCurrentLottery', { mttId: props.id }).then((v) => {
  eventId.value = v.eventId;
  if (!eventId.value) return;
  drawInfo.value = v;
  canDraw.value = v.canDraw;
  spinCnt.value = v.spinTimes;
  leftSeconds.value = v.drawTime - Date.now() / 1000;
  needClaim.value = v.claimReward;

  if (v.theme === 'iceblue') theme.value = 'iceblue';

  try {
    const h = JSON.parse(v.claimRules || '{}');
    if (h.addressPlaceholder) addressPlaceholder.value = h.addressPlaceholder;
    if (h.addressType) addressType.value = h.addressType;
  } catch (e) {
    console.error(e);
  }
});

useIntervalFn(() => (leftSeconds.value -= 1), 1000);

const address = ref('');
const claim = async () => {
  await authAPI.post('v1/turntableLottery/claim', { recordId: claimInfo.value!.recordId, address: address.value });
  claimInfo.value!.isClaim = true;
  showClaim.value = false;
};

const openDraw = () => {
  if (!user.value) return (showLogin.value = true);
  if (props.locked) return toast.warn(t('NEED_REGISTER_TIPS'));
  showDraw.value = true;
};

const openHistory = () => {
  showHistory.value = true;
  if (historys.value.length) return;
  authAPI.get('v1/turntableLottery/getPrizeRecord', { eventId: eventId.value }).then((v) => {
    historys.value = v;
  });
};

const showClaim = ref(false);
const claimInfo = ref<(typeof historys.value)[number]>();

const openClaim = (h: any) => {
  address.value = '';
  claimInfo.value = h;
  showClaim.value = true;
};

const draw = async () => {
  if (spinCnt.value <= 0) {
    openTips();
    return;
  }
  const res = await authAPI.post('v1/turntableLottery/drawLottery', { eventId: eventId.value, accountType: '' });
  spinCnt.value = res.spinTimes;
  if (res.prizeCode === 'NOTHING') {
    const t = drawInfo.value!.prizeList.reduce<number[]>((acc, v, i) => {
      if (v.prizeCode === 'NOTHING') acc.push(i);
      return acc;
    }, []);
    return t[~~(Math.random() * t.length)];
  }
  return prizeDict.value[res.prizeCode].index;
};

const openResult = (v: number) => {
  authAPI.get('v1/turntableLottery/getPrizeRecord', { eventId: eventId.value }).then((v) => {
    historys.value = v;
  });

  const prize = Object.values(prizeDict.value)[0];
  if (!prize || prize.prizeCode === 'NOTHING')
    return dialog.alert({
      title: t('BAD_LUCK'),
      content: t('BAD_DRAW_TIPS'),
    });
  dialog.alert({
    content: (
      <div class="text-center mb-8 w-110">
        <h3>{t('PLATFORM_TASK_CLAIM_TITLE')}</h3>
        <p class="mt-4">{t('DRAW_SUCCESS_TIPS', { prize: prize.prizeName, sponsor: prize.sponsor })}</p>
        <DPPopItem class="-z-1 w-90 m-auto">
          <img class="h-40 w-90 object-contain" src={prize.prizePic} alt="" />
        </DPPopItem>
        <h2 class="-mt-10 h-10">{prize.prizeName}</h2>
      </div>
    ),
    transparent: true,
  });
};

const openTips = () => {
  dialog.alert({
    title: t('GET_MORE_SPINS'),
    content: (
      <div class="space-y-3 w-100" un-children="p-4 rd-lg bg-gray-700">
        <div>
          <b class="c-green">1</b> {t('MORE_SPIN_TIPS_1')}
        </div>
        <div>
          <b class="c-green">2</b> {t('MORE_SPIN_TIPS_2')}
        </div>
        <div>
          <b class="c-green">3</b> {t('MORE_SPIN_TIPS_3')}
        </div>
        <div>
          <b class="c-green">4</b> {t('MORE_SPIN_TIPS_4')}
        </div>
      </div>
    ),
    btn: t('COPY_LINK'),
    action: share,
  });
};

const share = () => {
  copy(genRefUrl());
  toast.success(t('LINK_COPIED'));
};
</script>
