<template>
  <div class="pt-6 pb-3.2 space-y-5 h-full">
    <template v-if="detail">
      <div class="flex space-x-2" portrait="flex-col space-y-2 space-x-0">
        <div class="flex-1 flex flex-col space-y-2" portrait="flex-row space-x-2 space-y-0">
          <div class="rd-lg bg-gray-900 p-5 of-hidden flex-1">
            {{ $t('mtt.TICKET_GENERATED') }}
            <div class="c-green fw-bold text-xl mt-2">{{ ticketTotalNum }}</div>
          </div>
          <div class="rd-lg bg-gray-900 p-5 of-hidden flex-1">
            {{ $t('mtt.TICKET_REDEEMED') }}
            <div class="c-green fw-bold text-xl mt-2">{{ ticketRedeemedNum }}</div>
          </div>
        </div>

        <div
          class="flex-1 rd-lg bg-gray-900 p-5 of-hidden flex flex-col justify-around"
          v-if="ticketTotalNum || detail.startTime * 1e3 > new Date().getTime()"
        >
          <div class="text-center px-5 c-gray-400" v-if="!ticketTotalNum">
            {{ $t('mtt.GENERATE_DESC') }}
          </div>
          <div class="flex justify-between space-x-3" v-else>
            <div class="i-local-csv w-12 h-12 c-gray-400" />
            <div class="flex-1">
              <div class="fw-bold text-lg">Code list.csv</div>
              <div>
                {{ ticketTotalNum }} <span class="op-70">{{ $t('mtt.DOWNLOAD_DESC') }}</span>
              </div>
            </div>
            <AButton class="!w-12" outlined icon="i-local-download" @click="downloadTickets" :disabled="downloading" />
          </div>
          <template v-if="detail.startTime * 1e3 > new Date().getTime()">
            <div class="w-full my-5 b-b-solid b-b-1 b-b-white/5" />
            <Button class="w-full p-y-5" :label="$t('mtt.GENERATE_CODE')" @click="showGenerate = true" />
          </template>
        </div>
      </div>

      <ATitle class="fw-bold mt-4">{{ $t('mtt.REDEEMED_LIST') }}</ATitle>
      <div class="my-2 text-sm" v-if="detail.status < 5">
        <span class="op-70">{{ $t('mtt.TICKET_EXPRIED_IN') }}</span>
        {{ dt((detail.lateRegistrationTime + detail.startTime) * 1e3).strDateTimeShort }}
      </div>

      <div v-if="!ticketData.length" class="flex flex-col items-center mt-30">
        <img src="@/assets/img/no-ticket.png" alt="No ticket" class="w-32" />
        <div class="mt-5 c-gray">{{ $t('NO_RECORDS_FOUND') }}</div>
      </div>

      <div class="flex-1 of-y-scroll">
        <div class="items-start md:grid md:grid-cols-2 gap-x-20">
          <template v-for="item in ticketData" v-bind:key="item.code">
            <div v-if="item.avaliable" class="py-2 b-b-solid b-b-1 b-b-white/5 op-40 col-span-full">
              {{ $t('mtt.AVALIABLE') }}
            </div>
            <div v-if="item.redeemed" class="py-2 b-b-solid b-b-1 b-b-white/5 op-40 col-span-full">
              {{ $t('mtt.REDEEMED') }}
            </div>
            <div class="flex items-center justify-between py-4 b-b-solid b-b-1 b-b-white/5">
              <div class="c-white" :class="{ 'op-70': item.exchangeStatus }">
                {{ item.code }}
              </div>

              <div v-if="item.exchangeStatus === 0">
                <Button class="!py-1" outlined :label="$t('COPY')" size="small" @click="copyCode(item.code)" />
              </div>
              <div class="op-70" v-else-if="item.exchangeStatus === 1">{{ $t('CANCELED') }}</div>
              <div class="op-70" v-else-if="item.exchangeStatus === 2">{{ $t('EXPIRED') }}</div>
              <div class="op-70" v-else-if="item.exchangeStatus === 3">
                {{ $t('mtt.REDEEMED_BY') }} {{ item.userName }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <APaginator class="justify-center" v-model="ticketPageIndex" :size="ticketPageSize" :total="ticketTotalNum" />
    </template>
    <Dialog class="w-100" modal v-model:visible="showGenerate" :header="$t('mtt.GENERATE_CODE')">
      <p class="c-gray-400 text-center px-2">{{ $t('mtt.GENERATE_NUM_DESC') }}</p>
      <div class="c-white mt-5">
        {{ $t('mtt.TICKET_NUMBER') }}
      </div>
      <InputNumber class="w-full mt-2" v-model="generateNum" />
      <div class="flex mt-4">
        <Button class="flex-1" :label="$t('mtt.GENERATE')" @click="ticketGenerateRequest" />
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import type { Mtt, TicketInfo } from '@/types';
import { useClipboard } from '@vueuse/core';
import { authAPI } from '@/utils/network';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { dt } from '@/utils/datetime';
import { saveAs } from 'file-saver';
import { convertToLangeLabel } from '@/utils/strings';
import router from '@/router';
import { toast } from '@/components/ui/AToast.vue';

const i18n = useI18n();
const { copy } = useClipboard({ legacy: true });
const props = defineProps<{ id: string }>();

const detail = ref<Mtt>();
const ticketPageIndex = ref(1);
const ticketData = ref<TicketInfo[]>([]);
const ticketTotalNum = ref(0);
const ticketRedeemedNum = ref(0);
const ticketPageSize = 100;

const loadDetail = async () => {
  detail.value = await authAPI.get<Mtt>('v1/mtt/show_details', { mttID: props.id });
};
loadDetail();

const getTicketInfo = async () => {
  try {
    const data = await authAPI.get('ticket/listExchangeTournament', {
      mttId: props.id,
      pageIndex: ticketPageIndex.value,
      pageSize: ticketPageSize,
    });
    let available = false;
    let redeemed = false;
    ticketData.value = data.results.map((item: TicketInfo) => {
      if (item.exchangeStatus === 0 && !available) {
        item.avaliable = true;
        available = true;
      }
      if (item.exchangeStatus === 3 && !redeemed) {
        item.redeemed = true;
        redeemed = true;
      }
      return item;
    });
    ticketTotalNum.value = data.totalCount;
    ticketRedeemedNum.value = data.redeemTotal;
  } catch (e) {
    await router.push({ path: `/m/${props.id}` });
  }
};

watch(ticketPageIndex, getTicketInfo, { immediate: true });

const copyCode = async (code: string) => {
  await copy(code);
  toast.info(i18n.t('SUCCESS'));
};

const downloading = ref(false);
const downloadTickets = async () => {
  downloading.value = true;
  try {
    const v = await authAPI.blob('ticket/exportExchangeTournament', { mttId: props.id });
    saveAs(v.data, `ticket-${props.id}.csv`);
  } catch (e: any) {
    toast.error(i18n.t(convertToLangeLabel(e.msg)));
  }
  downloading.value = false;
};

const showGenerate = ref(false);
const generateNum = ref(undefined);

const ticketGenerateRequest = async () => {
  if (!generateNum.value) return;
  try {
    await authAPI.post('ticket/generateTournamentTicket', { number: generateNum.value, mttId: props.id });
    showGenerate.value = false;
    toast.success(i18n.t('SUCCESS'));
    await getTicketInfo();
  } catch (e: any) {
    toast.error(i18n.t(convertToLangeLabel(e.msg)));
  }
};
</script>
