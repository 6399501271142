<template>
  <div class="relative uploader w-fit" @click="openFileInput">
    <input :accept="accept" class="hidden" type="file" ref="fileInput" @change="handleFileChange" />
    <slot>
      <AButton>{{ $t('UPLOAD') }}</AButton>
    </slot>
    <div v-if="loading" class="absolute inset-0 backdrop-brightness-70 flex justify-center items-center" @click.stop>
      <div class="text-xl i-local-loading" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { upload } from '@/utils/general';
import { ref } from 'vue';

const props = withDefaults(defineProps<{ maxSize?: number; accept?: string }>(), {
  maxSize: 1e6,
  accept: 'image/*',
});

const fileInput = ref();
const loading = ref(false);
const model = defineModel<string | string[]>();

function openFileInput() {
  fileInput.value.click();
}

async function handleFileChange(event: any) {
  if (event.target.files[0].size > props.maxSize) return alert('File size too large');
  try {
    loading.value = true;
    const url = await upload(event.target);
    event.target.value = '';
    if (Array.isArray(model.value)) model.value.push(url);
    else model.value = url;
  } finally {
    loading.value = false;
  }
}
</script>
