import { tgUser } from '@/stores';
import { botAPI } from './network';

export const checkSubBot = () => {
  const dataMap = { userId: tgUser.id };
  const status = botAPI.get<boolean>('tg/checkSubBot', dataMap);
  return status;
};

export const checkJoinOfficialGroup = () => {
  let dataMap = { userId: tgUser.id, channelId: '@lepokergame' };
  if (import.meta.env.MODE == 'sit') {
    dataMap = { userId: tgUser.id, channelId: '@sitgroup11' };
  } else if (import.meta.env.MODE == 'newdev') {
    dataMap = { userId: tgUser.id, channelId: '@kintestgroup' };
  }
  const status = botAPI.get<boolean>('tg/checkJoinOfficeChannel', dataMap);
  return status;
};
