import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import {
  bnbUser,
  deboxUser,
  discordUser,
  ethUser,
  googleUser,
  lineaUser,
  showBindAccount,
  solanaUser,
  taskRedDot,
  tgUser,
  tonUser,
  user,
  xUser,
  zksyncUser,
} from '.';

import { connectSocket } from '@/utils/stomp';
import { showBB, userGameSetting, volume } from '@/utils/value';
import type { TaskRedDot } from '@/types';
import EventEmitter from 'eventemitter3';

export const inbox = ref(0);
export const assets = ref<{ diamond?: number; goldCoin?: number }>();
export const beginnerGuide = ref<number>();
export let updateResolve: ((v?: unknown) => void) | undefined;
export const initAccount = ref(false);
export const refCode = ref('');
export const level = ref({ exp: 0, level: 1, next: 1 });

export const updateLevel = () => {
  authAPI.get('v1/task/getExperienceInfo').then((v) => {
    level.value = { exp: v.currentExperience, level: v.level, next: v.nextExperience };
  });
};

const checkAccountUpdate = async () => {
  initAccount.value = true;
  showBindAccount.value = 0;
  if (!user.value || user.value.profileUpdated) return;
  showBindAccount.value = 4;
  return new Promise((r) => (updateResolve = r));
};

export const autoRedeemTicket = ref('');
const checkGuide = async () => {
  const data = await authAPI.get<{ status: boolean; lastTaskCode: number }>(
    'v1/user/onboarding_status',
    undefined,
    undefined,
    true,
  );
  if (!data.status) {
    if (autoRedeemTicket.value) {
      authAPI.post('v1/user/onboarding_task_skip', { code: autoRedeemTicket.value });
      return;
    }
    beginnerGuide.value = data.lastTaskCode;
  }
};

export const checkTaskRedDot = async () => {
  const data = await authAPI.get<TaskRedDot>('v1/task/redDotInfo');
  taskRedDot.value = data;
};

export const updateWalletAccount = async (address: string, socialAppType: string) => {
  if (socialAppType.indexOf('WALLET_EVM') >= 0) {
    if (socialAppType.indexOf('BSC') > 0) {
      bnbUser.address = address;
    } else if (socialAppType.indexOf('ZKS') > 0) {
      zksyncUser.address = address;
    } else if (socialAppType.indexOf('ETH') > 0) {
      ethUser.address = address;
    } else if (socialAppType.indexOf('LINEA') > 0) {
      lineaUser.address = address;
    }
  } else if (socialAppType.indexOf('WALLET_SOL') >= 0) {
    if (socialAppType.indexOf('SOL') > 0) {
      solanaUser.address = address;
    }
  } else if (socialAppType.indexOf('WALLET_TON') >= 0) {
    if (socialAppType.indexOf('TON') > 0) {
      tonUser.address = address;
    }
  }
};

const updateLinkAccount = async (data: any) => {
  bnbUser.address = '';
  zksyncUser.address = '';
  ethUser.address = '';
  lineaUser.address = '';
  solanaUser.address = '';
  tonUser.address = '';
  tgUser.id = 0;
  tgUser.username = '';
  discordUser.id = '';
  discordUser.username = '';
  xUser.id = '';
  xUser.username = '';
  googleUser.id = '';
  googleUser.username = '';
  deboxUser.id = '';
  deboxUser.username = '';
  if (data && data.linkAccountList) {
    const tg = data.linkAccountList.find((v: any) => v.linkAccountType === 'TG');
    const discord = data.linkAccountList.find((v: any) => v.linkAccountType === 'DISCORD');
    const x = data.linkAccountList.find((v: any) => v.linkAccountType === 'X');
    const google = data.linkAccountList.find((v: any) => v.linkAccountType === 'GOOGLE');
    const debox = data.linkAccountList.find((v: any) => v.linkAccountType === 'DEBOX');
    const walletEVM = data.linkAccountList.find((v: any) => v.linkAccountType === 'WALLET_EVM');
    const walletSOL = data.linkAccountList.find((v: any) => v.linkAccountType === 'WALLET_SOL');
    const walletTON = data.linkAccountList.find((v: any) => v.linkAccountType === 'WALLET_TON');
    if (discord) {
      discordUser.id = discord.linkAccountID;
      discordUser.username = discord.linkAccountName;
    }
    if (tg) {
      tgUser.id = parseInt(tg.linkAccountID);
      tgUser.username = tg.linkAccountName;
    }
    if (x) {
      xUser.id = x.linkAccountID;
      xUser.username = x.linkAccountName;
    }
    if (google) {
      googleUser.id = google.linkAccountID;
      googleUser.username = google.linkAccountName;
    }
    if (debox) {
      deboxUser.id = debox.linkAccountID;
      deboxUser.username = debox.linkAccountName;
    }
    if (walletEVM) {
      const walletTypeList = data.walletTypeList?.find((v: any) => v.walletAddressType === 'WALLET_EVM');
      walletTypeList?.chainType.forEach((v: any) => {
        if (v === 'BSC') {
          bnbUser.address = walletEVM.linkAccountID;
        } else if (v === 'ZKS') {
          zksyncUser.address = walletEVM.linkAccountID;
        } else if (v === 'ETH') {
          ethUser.address = walletEVM.linkAccountID;
        } else if (v === 'LINEA') {
          lineaUser.address = walletEVM.linkAccountID;
        }
      });
    }
    if (walletSOL) {
      const walletTypeList = data.walletTypeList?.find((v: any) => v.walletAddressType === 'WALLET_SOL');
      walletTypeList?.chainType.forEach((v: any) => {
        if (v === 'SOL') {
          solanaUser.address = walletSOL.linkAccountID;
        }
      });
    }
    if (walletTON) {
      const walletTypeList = data.walletTypeList?.find((v: any) => v.walletAddressType === 'WALLET_TON');
      walletTypeList?.chainType.forEach((v: any) => {
        if (v === 'TON') {
          tonUser.address = walletTON.linkAccountID;
        }
      });
    }
  }
};
const loadRefCode = () => authAPI.get('invitation/getInvitationCode').then((v) => (refCode.value = v.invitationCode));

export const loadUserSetting = () => {
  const betSizeMap = [1, 2, 3, 4, 5, 6, 7, 18, 19, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const potSizeMap = [1, 2, 3, 4, 5, 6, 7, 13, 8, 9, 10, 11, 12];
  authAPI.get('v1/user/personal_game_setting').then((v) => {
    showBB.value = !!v.displayType;
    userGameSetting.value = {
      cardType: v.deckType,
      betSize: v.buttonBetSize.map((v: number) => betSizeMap[v - 1] || 1),
      raiseSize: v.buttonPotSize.map((v: number) => potSizeMap[v - 1] || 1),
      voiceEffect: v.voiceEffect === null ? true : !!v.voiceEffect,
      voice: v.voice || 0,
    };
    volume.value = isNaN(v.soundEffect) ? 1 : v.soundEffect;
  });
};

export const updateGameSetting = async (bb: boolean, cfg?: typeof userGameSetting.value, soundEffect?: boolean) => {
  const betSizeMap = [1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 8, 9];
  const potSizeMap = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 8];
  showBB.value = bb;
  if (cfg) userGameSetting.value = cfg;
  if (soundEffect !== undefined) volume.value = soundEffect ? 1 : 0;
  if (user.value)
    await authAPI.post('v1/user/edit_game_setting', {
      displayType: +showBB.value,
      deckType: userGameSetting.value.cardType,
      buttonBetSize: userGameSetting.value.betSize.map((v) => betSizeMap[v - 1]),
      buttonPotSize: userGameSetting.value.raiseSize.map((v) => potSizeMap[v - 1]),
      soundEffect: volume.value,
      voiceEffect: +userGameSetting.value.voiceEffect,
      voice: userGameSetting.value.voice,
    });
};

export const loadUser = () =>
  authAPI
    .get('v1/user/profile', undefined, undefined, true, { retry: 3 })
    .then((v) => ({
      id: v.userID,
      name: v.userName,
      avatar: v.userAvatarUrl,
      country: v.countryCode,
      permissions: v.permissions,
      title: v.userTitle,
      email: v.email,
      registerChannel: v.registerChannel,
      profileUpdated: v.profileUpdated,
      linkAccountList: v.linkAccountList,
      walletTypeList: v.walletTypeList,
      flag: v.flag,
      seed: v.seed,
    }))
    .catch(() => undefined);

let resolve: (v: any) => void;
export const userPromise = new Promise<any>((r) => (resolve = r));

export const emitter = new EventEmitter();

export const initUser = async () => {
  // authAPI.post<any>('v1/points/getPoints').then((res) => {
  //   balance.value = res.balances;
  // });
  assets.value = undefined;
  const u = await loadUser();
  updateLinkAccount(u);
  resolve(u);
  emitter.emit('permissions', u?.permissions);
  user.value = u;
  connectSocket();
  if (!u) return;
  loadRefCode();
  loadUserSetting();
  await checkAccountUpdate();
  // checkGuide();
  checkTaskRedDot();
};

export const balance = ref(0);
