<template>
  <ADialog
    :modelValue="show"
    :title="dialog.title"
    :unclosable="dialog.unclosable"
    :transparent="dialog.transparent"
    :zIdx="100"
    @update:modelValue="(v: boolean) => v || close(dialog.reject)"
  >
    <template v-if="dialog.content" #default>
      <component v-if="isVNode(dialog.content)" :is="dialog.content" />
      <div v-else class="w-92 min-h-16 text-center break-words">{{ dialog.content }}</div>
    </template>
    <template #footer>
      <footer class="flex space-x-2 children:flex-1">
        <AButton v-if="dialog.cancel" type="secondary" @click="close(dialog.reject)">{{ dialog.cancel }}</AButton>
        <AButton @click="close(dialog.action)">{{ dialog.btn || $t('OK') }}</AButton>
      </footer>
    </template>
  </ADialog>
</template>
<script lang="ts">
import { computed, isVNode, ref, type VNode } from 'vue';
import { useDlgCtrl } from './ADialog.vue';
import { useRouter } from 'vue-router';
import i18n from '@/stores/lang';

type Dialog = {
  title?: string;
  unclosable?: boolean;
  transparent?: boolean;
  content?: VNode | string;
  btn?: string;
  cancel?: string;
  action?: () => void;
  reject?: () => void;
};

const dialogs = ref<Dialog[]>([]);

const dialog = computed(() => dialogs.value[0] || {});

const close = (f?: () => any) => {
  f?.();
  dialogs.value.shift();
};

const show = computed({
  get() {
    return !!dialogs.value.length;
  },
  set(v) {
    if (!v && !dialog.value.unclosable) close(dialog.value.reject);
  },
});

export const useDialog = () => {
  useDlgCtrl(show);
  const confirm = (cfg: Partial<Dialog>) => dialogs.value.unshift({ cancel: i18n.global.t('CANCEL'), ...cfg });
  const alert = (cfg: Partial<Dialog>) => dialogs.value.unshift({ ...cfg, cancel: undefined });
  return { alert, confirm };
};

export default {
  setup() {
    const router = useRouter();
    router.beforeEach(() => {
      dialogs.value = [];
    });

    return { show, dialog, close, isVNode };
  },
};
</script>
