<template>
  <div class="flex-1 of-auto">
    <Teleport to="#m-nav-add">
      <div class="i-local-tutorial size-7 cursor-pointer" @click="$router.push('/faq/ladder')" />
    </Teleport>
    <div class="flex w-full justify-between mb-4 bg-gray-900 p-3 rd-lg" @click="$router.replace('/s/rank')">
      <div class="flex">
        <span class="c-gray-400">{{ $t('MY_POWER') }}:</span>
        <span class="c-green ml-3 fw-bold">{{ userInfo.power || '-' }}</span>
      </div>
      <div class="flex items-center">
        <span class="c-gray-400">{{ $t('YOUR_CURRENT_RANKING') }}:</span>
        <span class="c-green ml-3 fw-bold">{{ userInfo.rank || '-' }}</span>
        <i class="align-middle i-local-arrow-d rotate-270 w-4 ml-1" />
      </div>
    </div>
    <template v-if="detail">
      <div class="relative mb-4 bg-gray-900 rd-lg bg-gradient-to-t via-transparent via-20%" :class="sponsor.bg">
        <div class="absolute top-4 right-4 text-xl i-local-share c-gray-300 cursor-pointer" @click="share"></div>
        <div class="space-y-4 max-w-104 m-auto text-center py-6">
          <h3 class="mb-2">{{ $t('NEXT_LADDER_GAME') }}</h3>
          <div class="mb-5 text-xs">
            {{ $t('X_PLAYERS_REGISTERED_LADDER_GAME', { v: detail.totalEntries }) }}
            <div v-if="startCD" class="text-center mt-1">
              <span class="c-gray-300">{{ $t('mtt.START_IN') }}</span>
              {{ dur(startCD * 1e3) }}
            </div>
          </div>
          <div class="flex flex-row justify-between w-full mb-5">
            <div v-for="i of 6" :key="i" class="rd-full w-3.2rem h-3.2rem bg-gray-700 flex justify-center items-center">
              <template v-if="i <= detail.userAccountVOList.length">
                <DPAvatar
                  class="text-1.7rem"
                  :name="detail.userAccountVOList[i - 1].userName"
                  :avatar="detail.userAccountVOList[i - 1].userAvatarUrl"
                />
              </template>
              <template v-else><span class="text-3.2rem lh-3.2rem c-gray-500 mt--8">...</span></template>
            </div>
          </div>
          <AButton v-if="detail.userStatus !== 1" class="w-full mb-5" trust @click="apply()">
            {{ $t('mtt.REGISTER') }}
          </AButton>
          <AButton v-else-if="detail.preTable" class="w-full mb-5" @click="openRoom">
            {{ $t('mtt.GO_TO_TABLE') + (cd ? `(${cd.toFixed(0)})` : '') }}
          </AButton>
          <AButton v-else class="w-full mb-5 bg-red" @click="unapply">
            {{ $t('CANCEL_REGISTRATION') }}
          </AButton>
          <!-- <label  for="ladder-notify" class="ml-2 green-check flex items-center">
          <Checkbox v-model="ladderNotifyMe" inputId="ladder-notify" name="Ladder game notify" value="check" />
          <span class="ml-2 c-gray-400"> {{ $t('LADDER_NOTIFY_ME') }} </span>
        </label> -->
        </div>
        <div class="text-center pb-4" :class="sponsor.color">
          <p class="fw-bold cursor-pointer" @click="$router.push('/poker-to-mint')">
            {{ $t('SNG_PRIZE_' + sponsor.seq) }} >
          </p>
          <div class="bg-current my-4 h-.3 op-20" />
          <h5 class="flex justify-center items-center c-gray-50">
            {{ $t('SPONSORED_BY') }}
            <img class="w-4.5 h-4.5 ml-2 mr-1" src="@/assets/img/logo-new.png" alt="" />
            <span class="w-16 i-local-lepoker" />
          </h5>
          <p class="c-gray-300 mt-2">{{ $t('SNG_SPONSOR_DESC_' + +sponsor.seq) }}</p>
        </div>
      </div>
      <div class="rd-lg pt-3 bg-gray-800 mb-6 of-hidden">
        <ATitle class="text-lg c-green fw-bold">
          {{ $t('GAME_INFO') }}
        </ATitle>
        <div
          class="grid grid-cols-2 pt-3 [&_p]:op-60"
          landscape="[&:nth-child(4n+1)]:children:bg-dark-800 [&:nth-child(4n+2)]:children:bg-dark-800"
          portrait="grid-cols-1 odd:children:bg-dark-800"
          un-children="flex h-12 px-3 items-center justify-between"
        >
          <div>
            <p>{{ $t('mtt.REGISTRATION_CONDITION') }}</p>
            <h6>Free Roll</h6>
          </div>
          <div>
            <p>{{ $t('mtt.GAME_TYPE') }}</p>
            <h6>NLH</h6>
          </div>
          <div>
            <p>{{ $t('mtt.REGISTER_PLAYERS') }}</p>
            <h6>{{ detail.minPlayers }}-{{ detail.maxPlayers }}</h6>
          </div>
          <div>
            <p>{{ $t('mtt.TABLE_SIZE') }}</p>
            <h6>{{ detail.maxPlayerNumber }}</h6>
          </div>
          <div>
            <p>{{ $t('mtt.ACTION_TIME') }}</p>
            <h6>{{ $t('X_SEC', { v: detail.clientActTimeOut }) }}</h6>
          </div>
          <div>
            <p>{{ $t('mtt.TIME_BANK') }}</p>
            <h6>
              {{ $t('mtt.format.TIMES', { v: detail.timeBank.number }) }}
            </h6>
          </div>
          <div>
            <p>{{ $t('mtt.TIME_BANK_PERIOD') }}</p>
            <h6>{{ $t('X_SEC', { v: detail.timeBank.duration }) }}</h6>
          </div>
          <div>
            <p>{{ $t('mtt.ADDITIONAL_TIME_BANK') }}</p>
            <h6>
              {{ $t('mtt.format.HAND', { v: detail.timeBank.addPolicy }) }}
            </h6>
          </div>
          <div>
            <p>{{ $t('mtt.BLIND_LEVELUP') }}</p>
            <h6>{{ $t('mtt.format.MIN', { v: detail.blindDuration / 60 }) }}</h6>
          </div>
          <div>
            <p>{{ $t('mtt.BLIND_STRUCTURE') }}</p>
            <AButton class="text-xs" @click="showBlind = true">{{ $t('VIEW') }}</AButton>
          </div>
          <div>
            <p>{{ $t('mtt.STARTING_CHIPS') }}</p>
            <h6>{{ detail.initBlind * 20 }}({{ detail.initBlind }}BB)</h6>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="detail === null"
      class="relative flex flex-col w-full justify-center items-center mb-4 bg-gray-900 p-8 rd-lg"
    >
      <img class="w-30 mb-4" src="@/assets/img/maintain.png" alt="" />
      <h4 class="mb-2">{{ $t('NOTICE') }}</h4>
      <p class="c-gray-300">{{ $t('LADDER_GAME_IS_UNDER_MAINTENANCE') }}</p>
    </div>
  </div>
  <Dialog class="w-100" v-model:visible="showBlind" modal :header="$t('mtt.BLIND_STRUCTURE')">
    <div class="c-gray-400 text-center">
      {{ $t('BLINDS_STARTING_AT') }} <span class="fw-bold c-white">10/20</span>.
      {{ $t('BLINDS_INCREASING_EVERY_X', { v: 5 }) }}. {{ $t('BLINDS_STRUCTURE') }}
      <span class="fw-bold c-white">10/20, 20/40, 30/60, 50/100, 75/150, 100/200, 150/300, 250/500, 300/600</span>.
    </div>
    <template #footer>
      <Button class="!w-full" :label="$t('OK')" @click="showBlind = false" />
    </template>
  </Dialog>
  <Dialog class="w-100" v-model:visible="showPoint" modal :header="$t('NOTICE')">
    <div class="c-gray-400 text-center">
      {{ $t('POINT_IS_NOT_ENOUGH', { v: 30 }) }}
    </div>
    <template #footer>
      <div class="flex flex-row justify-between">
        <Button class="flex-1" text :label="$t('CANCEL')" @click="showPoint = false" />
        <Button class="flex-1" :label="$t('GO_TO_TASK_CENTER')" @click="$router.push('/profile/task')" />
      </div>
    </template>
  </Dialog>
  <Dialog class="w-100" v-model:visible="showAlert" modal :header="$t('NOTICE')">
    <div class="c-gray-400 text-center">
      {{ $t('REGISTRATION_COST_POINTS_X', { v: 30 }) }}
    </div>
    <template #footer>
      <div class="flex flex-row justify-between">
        <Button class="flex-1" text :label="$t('CANCEL')" @click="showAlert = false" />
        <Button class="flex-1" :label="$t('game.CONFIRM')" @click="apply(true)" />
      </div>
    </template>
  </Dialog>
  <ADialog class="w-96 pt-8" v-model="showSuccess">
    <div class="text-center space-y-4">
      <div class="m-auto i-local-completed text-6xl c-green"></div>
      <h5>{{ $t('SNG_REGISTER_SUCCESS') }}</h5>
      <!-- <div class="text-center">
        {{ $t('LADDER_REGISTRATION_DESC', { v: 30 }) }}
      </div> -->
      <p class="px-4 text-center">
        {{ $t('SNG_REGISTER_SUCCESS_TIPS') }}
      </p>
      <DPSocailBtns />
    </div>
  </ADialog>
  <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
      <h5 class="mt-4">{{ $t('mtt.WAIT_TABLE_ALLOCATE') }}...</h5>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showSngTask"
    :closable="true"
    :draggable="false"
    :style="{ width: '35rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    modal
  >
    <template #header>
      <h3 class="flex-1 text-center">{{ $t('NOTICE') }}</h3>
    </template>
    <DPSNGTask
      @regist="apply"
      @close="
        showSngTask = false;
        showSuccess = true;
      "
    />
  </Dialog>
</template>
<script setup lang="tsx">
import router from '@/router';
import { showLogin, tgUser, user } from '@/stores';
import type { SNG } from '@/types';
import { dur, dt } from '@/utils/datetime';
import { authAPI, botAPI } from '@/utils/network';
import { cutDown } from '@/utils/timer';
import { genRefUrl } from '@/utils/user';
import { useClipboard } from '@vueuse/core';
import { computed, inject, onUnmounted, ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useDialog } from '@/components/ui/APopPad.vue';
import { userPromise } from '@/stores/user';
import { toast } from '@/components/ui/AToast.vue';

const sponsor = [
  {
    seq: 1,
    color: 'c-green',
    bg: 'from-green-700/50',
  },
  {
    seq: 2,
    color: 'c-yellow',
    bg: 'from-yellow-600/50',
  },
][Math.floor(Math.random() * 2)];

const detail = inject('sng') as Ref<SNG | undefined | null>;

const showBlind = ref(false);
const showPoint = ref(false);
const showSuccess = ref(false);
const showAlert = ref(false);

const { t } = useI18n();
const dialog = useDialog();
const showSngTask = ref(false);
const apply = async (force = false) => {
  if (!user.value) return (showLogin.value = true);
  // if (detail.value!.costPoints! > balance.value) return (showPoint.value = true);
  // if (!force) return (showAlert.value = true);
  // 20240503 winston要求去掉tg任务
  // const usernameTest1 = /^\d+_test_player@lepoker\.io$/;
  // if (!usernameTest1.test(user.value.email)) {
  //   if (import.meta.env.MODE !== 'prod') {
  //     const usernameTest2 = /^userTestWalletTon\d+$/;
  //     if (!usernameTest2.test(user.value.name)) {
  //       if (!tgUser.subBot || !tgUser.joinGroup) {
  //         showSngTask.value = true;
  //         return;
  //       }
  //     }
  //   } else if (!tgUser.subBot || !tgUser.joinGroup) {
  //     showSngTask.value = true;
  //     return;
  //   }
  // }

  try {
    await authAPI.post<{ success: boolean }>('v1/sng/player/apply');
    if (tgUser.id) {
      botAPI.post(`tg/registerSng`, {
        userId: user.value.id,
        tgUserId: tgUser.id,
        sngID: detail.value?.sngID,
      });
    }
    tgUser.joinGroup = false;
    showSuccess.value = true;
  } catch (e: any) {
    if (e.code === 418)
      dialog.alert({
        content: (
          <div class="flex flex-col items-center space-y-2">
            <div class="i-local-info w-24 h-24 c-red" />
            <h5>{t('SNG_BANNED_TITLE')}</h5>
            <p class="c-gray-300">{t('SNG_BANED_CONTENT')}</p>
            <p>{dt(e.msg * 1e3).strDatetime}</p>
          </div>
        ),
      });
    else toast.error(e.msg);
  }
};

const unapply = async () => {
  try {
    await authAPI.post('v1/sng/player/cancel');
    toast.success(t('SNG_CANCELED'));
    if (tgUser.id) {
      botAPI.post(`tg/unregisterSng`, {
        tgUserId: tgUser.id,
        sngID: detail.value?.sngID,
      });
    }
  } catch {
    toast.error('Failed to unapply');
  }
};

const startCD = computed({
  get() {
    if (!detail.value?.startTime) return 0;
    return detail.value.startTime - detail.value.currentTime;
  },
  set(v) {
    if (!detail.value?.startTime) return;
    detail.value.currentTime = detail.value.startTime - v;
  },
});
const cd = ref(0);
cutDown.set('sng-open', cd);
cutDown.set('sng-start', startCD);

let timer: ReturnType<typeof setTimeout>;

onUnmounted(() => clearTimeout(timer));

const showWaiting = ref(false);

const openRoom = async () => {
  if (detail.value?.userStatus !== 1) return;
  showWaiting.value = true;
  try {
    const data = await authAPI.get<{ mttID: string; type: string }>('v1/mtt/player/room_info', {
      mttID: detail.value!.sngID!,
      type: 'sng',
    });
    if (data.mttID) router.push(`/g/s/${data.mttID}`);
    else throw 'err';
  } catch (e: any) {
    if (e.code === 418) return (showWaiting.value = false);
    setTimeout(openRoom, 5e3);
  }
};

const cutDownGame = () => {
  cd.value = 30;
  return setTimeout(openRoom, 3e4);
};

const tryStart = () => {
  if (detail.value?.startTime && detail.value.currentTime >= detail.value.startTime) return;
  if (detail.value?.startTime && detail.value.startTime - 60 < detail.value.currentTime) timer = cutDownGame();
  else timer = setTimeout(tryStart, 1e3);
};

tryStart();

const { copy } = useClipboard({ legacy: true });

const share = () => {
  copy(genRefUrl()).then(() => toast.info(t('LINK_COPIED')));
};

const userInfo = ref({ power: 0, rank: 0 });

userPromise.then((v) => {
  if (!v) return;
  authAPI.get('v1/sng/get_user_power_rank', { userId: v.id }).then((v) => {
    userInfo.value = { power: v.points, rank: v.userRank };
  });
});
</script>
