<script setup lang="ts">
const props = defineProps<{ line: number; comment?: boolean }>();
</script>

<template>
  <div class="flex items-start">
    <div class="w-8 c-white/50">{{ props.line }}</div>
    <div class="flex-1 c-white" :class="{ 'op-50': props.comment }">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped></style>
