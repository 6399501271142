<template>
  <div class="text-center c-gray-300" ref="el">{{ finish ? '- END -' : 'loading...' }}</div>
</template>
<script lang="ts" setup generic="T">
import { useIntersectionObserver } from '@vueuse/core';
import { ref } from 'vue';

const el = ref();
const loading = ref(false);
const finish = ref(false);
const index = defineModel<T | 'end'>();
const props = defineProps<{ loader: (idx?: T) => Promise<T | 'end'> }>();
let visible = false;

const loadData = async (stop: any) => {
  if (index.value === 'end') {
    finish.value = true;
    stop();
    return;
  }
  if (loading.value) return;
  loading.value = true;
  try {
    const res = await props.loader(index.value);
    index.value = res;
  } catch {
    index.value = 'end';
  }
  loading.value = false;
  setTimeout(() => {
    if (visible && !finish.value) loadData(stop);
  }, 100);
};

const { stop } = useIntersectionObserver(el, async ([{ isIntersecting }]) => {
  visible = isIntersecting;
  if (!isIntersecting) return;
  loadData(stop);
});
</script>
