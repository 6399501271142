<template>
  <button
    type="button"
    class="relative select-none flex items-center justify-center fw-bold p-.5em b-solid b-.13em text-1em lh-140%"
    hover="brightness-105"
    active="brightness-90"
    :class="[
      rounded ? 'rd-full' : 'rd-.5em',
      disabled || loading ? 'op-60 cursor-not-allowed' : 'cursor-pointer',
      {
        'bg-transparent b-transparent': text,
        'c-white': !outlined,
        'c-green bg-transparent !b-current': outlined,
        'bg-green b-transparent': type === 'primary',
        'bg-red b-transparent': type === 'danger',
        'bg-blue b-transparent': type === 'info',
        'bg-yellow b-transparent': type === 'warn',
        'bg-gray-700 b-gray-600': type === 'secondary',
      },
    ]"
    :disabled="disabled || loading"
    @click.capture="onClick"
  >
    <div v-if="loading" class="text-1.4em i-local-loading" />
    <div v-else-if="icon" class="text-1.4em" :class="icon" />
    <div v-if="$slots.default && !loading" :class="{ 'mx-.2em': icon }">
      <slot></slot>
    </div>
    <slot name="suffix"></slot>
    <div v-if="$slots.badge" class="absolute -top-1 -right-1">
      <slot name="badge"></slot>
    </div>
  </button>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

const loading = ref(false);
const props = withDefaults(
  defineProps<{
    type?: string;
    disabled?: boolean;
    icon?: string;
    outlined?: boolean;
    rounded?: boolean;
    action?: Function;
    text?: boolean;
    trust?: boolean;
  }>(),
  { type: 'primary' },
);
const onClick = async (e: Event) => {
  if (props.trust && !e.isTrusted) return e.stopPropagation();
  if (!props.action) return;
  try {
    loading.value = true;
    await props.action(e);
  } finally {
    loading.value = false;
  }
};
</script>
