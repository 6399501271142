<template>
  <div v-if="totalPages > 1" class="flex space-x-1" un-children="w-2.5em h-2.5em">
    <AButton icon="i-local-arrow-l" type="secondary" :disabled="page === 1" @click="page -= 1" />
    <AButton
      v-for="i in pages"
      :key="i"
      type="secondary"
      :disabled="page === i"
      :outlined="page === i"
      @click="updatePage(i)"
    >
      {{ i > 0 ? i : '...' }}
    </AButton>
    <AButton
      class="rotate-180"
      :disabled="page === totalPages"
      type="secondary"
      icon="i-local-arrow-l"
      @click="page += 1"
    >
    </AButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{ total: number; size: number }>();
const page = defineModel<number>({ default: 1 });

const totalPages = computed(() => Math.ceil(props.total / props.size));

const pages = computed(() => {
  if (totalPages.value < 8) return Array.from({ length: totalPages.value }, (_, i) => i + 1);
  if (page.value < 4) return [1, 2, 3, 4, 5, 0, totalPages.value];
  if (page.value > totalPages.value - 4)
    return [
      1,
      -1,
      totalPages.value - 4,
      totalPages.value - 3,
      totalPages.value - 2,
      totalPages.value - 1,
      totalPages.value,
    ];
  return [1, -1, page.value - 1, page.value, page.value + 1, 0, totalPages.value];
});
function updatePage(v: number) {
  if (v === 0) page.value = Math.min(page.value + 5, totalPages.value);
  else if (v < 0) page.value = Math.max(page.value - 5, 1);
  else page.value = v;
}
</script>
