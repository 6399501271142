<template>
  <div class="flex items-center mb-10">
    <i class="i-local-bb mr-2"></i>
    <div class="flex-1">{{ $t('game.DISPLAY_IN_BB') }}</div>
    <InputSwitch v-model="cfg.displayType" />
  </div>
  <div class="flex items-center mb-10 cursor-pointer" @click="showBetSetting = true">
    <i class="i-local-ratio mr-2"></i>
    <div class="flex-1 mr-a">{{ $t('CUSTOMIZE_BETTING_BUTTON') }}</div>
    <i class="i-local-arrow-l rotate-180 mr-2"></i>
  </div>
  <div class="flex items-center mb-10">
    <i class="i-local-volume-full mr-2"></i>
    <div class="flex-1">{{ $t('game.SOUND_EFFECT') }}</div>
    <InputSwitch v-model="cfg.soundEffect" />
  </div>
  <div class="flex items-center mb-2">
    <i class="i-local-volume-full mr-2"></i>
    <div class="flex-1">{{ $t('VOICE') }}</div>
    <InputSwitch v-model="cfg.voiceEffect" />
  </div>
  <div class="flex mb-10 space-x-5">
    <div class="relative">
      <img
        class="b-2 b-solid rd-4 h-auto w-36"
        :class="{ 'b-green': cfg.voice === 0, 'b-white op-40': cfg.voice !== 0 }"
        src="@/assets/img/voice-0.png"
        alt=""
        @click="cfg.voice = 0"
      />
      <img
        class="absolute right-0.5 top-0.5 w-15 cursor-pointer"
        :class="{ 'op-40': playing }"
        src="@/assets/img/voice-play.png"
        alt=""
        @click="play(0)"
      />
    </div>
    <div class="relative">
      <img
        class="b-green b-2 b-solid rd-4 h-auto w-36"
        :class="{ 'b-green': cfg.voice === 1, 'b-white op-40': cfg.voice !== 1 }"
        src="@/assets/img/voice-1.png"
        alt=""
        @click="cfg.voice = 1"
      />
      <img
        class="absolute right-0.5 top-0.5 w-15 cursor-pointer"
        :class="{ 'op-40': playing }"
        src="@/assets/img/voice-play.png"
        alt=""
        @click="play(1)"
      />
    </div>
  </div>
  <div class="flex items-center">
    <i class="i-local-switch mr-2"></i>
    {{ $t('DECK_STYLE') }}
  </div>
  <div class="flex justify-start w-106 mt-4" un-children="b-solid rd-lg p-3 cursor-pointer">
    <div :class="cfg.cardType === 1 ? 'b-green' : 'b-transparent'" @click="cfg.cardType = 1">
      <div class="c-gray text-center mb-2">{{ $t('CLASSIC') }}</div>
      <div class="flex text-xs -space-x-2">
        <DPCard name="As" :type="1" />
        <DPCard name="Ah" :type="1" />
        <DPCard name="Ac" :type="1" />
        <DPCard name="Ad" :type="1" />
      </div>
    </div>
    <div class="ml-2" :class="cfg.cardType === 2 ? 'b-green' : 'b-transparent'" @click="cfg.cardType = 2">
      <div class="c-gray text-center mb-2">{{ $t('FOUR_COLOR') }}</div>
      <div class="flex text-xs -space-x-2">
        <DPCard name="As" :type="2" />
        <DPCard name="Ah" :type="2" />
        <DPCard name="Ac" :type="2" />
        <DPCard name="Ad" :type="2" />
      </div>
    </div>
    <div class="ml-2" :class="cfg.cardType === 3 ? 'b-green' : 'b-transparent'" @click="cfg.cardType = 3">
      <div class="c-gray text-center mb-2">{{ $t('FOUR_COLOR') }}</div>
      <div class="flex text-xs -space-x-2">
        <DPCard name="As" :type="3" />
        <DPCard name="Ah" :type="3" />
        <DPCard name="Ac" :type="3" />
        <DPCard name="Ad" :type="3" />
      </div>
    </div>
  </div>
  <div class="flex mt-8">
    <AButton class="flex-1 mr-4" v-if="popup" type="secondary" @click="$emit('close')">{{ $t('CANCEL') }}</AButton>
    <AButton class="w-55" :action="update">{{ $t('game.CONFIRM') }}</AButton>
  </div>
  <ADialog class="w-100" v-model="showBetSetting" title="Customize betting button">
    <img class="w-full h-40 mb-2 rd-lg b-solid b-gray-700" src="@/assets/img/bet-setting-demo.png" alt="" />
    <div class="flex justify-between">
      <div class="space-y-2 w-44 p-2 bg-gray-700 rd-lg text-center">
        <h5>{{ $t('BET_SIZE') }}</h5>
        <div v-for="i in [3, 2, 1, 0]" :key="i" class="of-hidden rd flex">
          <div class="bg-gray-600 p-2 px-3 cursor-pointer" @click="addSize(cfg.betSize, i, -1, 19)">-</div>
          <div class="bg-dark-900 flex-1">
            <p class="c-gray">Button{{ i + 1 }}</p>
            <h5 class="lh-tight">{{ betSizeList[cfg.betSize[i] - 1] }}</h5>
          </div>
          <div class="bg-gray-600 p-2 px-3 cursor-pointer" @click="addSize(cfg.betSize, i, 1, 19)">+</div>
        </div>
      </div>
      <div class="space-y-2 w-44 p-2 bg-gray-700 rd text-center">
        <h5>{{ $t('POT_SIZE') }}</h5>
        <div v-for="i in [3, 2, 1, 0]" :key="i" class="of-hidden rd-lg flex">
          <div class="bg-gray-600 p-2 px-3 cursor-pointer" @click="addSize(cfg.raiseSize, i, -1, 13)">-</div>
          <div class="bg-dark-900 flex-1">
            <p class="c-gray">Button{{ i + 1 }}</p>
            <h5 class="lh-tight">{{ potSizeList[cfg.raiseSize[i] - 1] }}</h5>
          </div>
          <div class="bg-gray-600 p-2 px-3 cursor-pointer" @click="addSize(cfg.raiseSize, i, 1, 13)">+</div>
        </div>
      </div>
    </div>
  </ADialog>
</template>
<script lang="ts" setup>
import { updateGameSetting } from '@/stores/user';
import { showBB, userGameSetting, volume } from '@/utils/value';
import { ref } from 'vue';
import { useGameSound } from '@/stores/sound';

defineProps<{ popup?: boolean }>();
const emits = defineEmits<{ close: [] }>();

const showBetSetting = ref(false);

const cfg = ref({
  displayType: showBB.value,
  cardType: userGameSetting.value.cardType,
  betSize: userGameSetting.value.betSize,
  raiseSize: userGameSetting.value.raiseSize,
  soundEffect: !!volume.value,
  voiceEffect: userGameSetting.value.voiceEffect,
  voice: userGameSetting.value.voice,
});

const betSizeList = [
  '2BB',
  '2.5BB',
  '3BB',
  '3.5BB',
  '4BB',
  '4.5BB',
  '5BB',
  '5.5BB',
  '6BB',
  '6.5BB',
  '7BB',
  '7.5BB',
  '8BB',
  '8.5BB',
  '9BB',
  '9.5BB',
  '10BB',
  'POT',
  'AllIn',
];
const potSizeList = ['33%', '50%', '66%', '75%', '80%', '90%', '100%', '110%', '120%', '130%', '140%', '150%', 'AllIn'];

const addSize = (arr: number[], idx: number, num: number, max: number) => {
  arr[idx] = Math.min(Math.max(1, arr[idx] + num), max);
};

const update = async () => {
  // if (user.value)
  //   await authAPI.post('v1/user/edit_game_setting', {
  //     displayType: +cfg.value.displayType,
  //     deckType: cfg.value.cardType,
  //     buttonBetSize: cfg.value.betSize.map((v) => betSizeMap[v - 1]),
  //     buttonPotSize: cfg.value.raiseSize.map((v) => potSizeMap[v - 1]),
  //   });
  // showBB.value = cfg.value.displayType;
  // userGameSetting.value = {
  //   cardType: cfg.value.cardType,
  //   betSize: cfg.value.betSize,
  //   raiseSize: cfg.value.raiseSize,
  // };
  const { displayType, soundEffect, ...gameSettings } = cfg.value;
  updateGameSetting(displayType, gameSettings, soundEffect);
  emits('close');
};

const sound = useGameSound();
const playing = ref(false);
const play = async (idx: number) => {
  const voices = ['check', 'fold', 'call', 'bet', 'raise', 'allin'];
  if (playing.value) return;
  playing.value = true;
  for (let i = 0; i < 6; i++) {
    await sound.playAwait(`${voices[i]}_${idx}`);
  }
  playing.value = false;
};
</script>
