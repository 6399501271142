<template>
  <swiper
    effect="coverflow"
    :centeredSlides="true"
    :slidesPerView="'auto'"
    :autoplay="{ delay: 5e3 }"
    loop
    :navigation="{
      nextEl: '.swiper-pre',
      prevEl: '.swiper-next',
      enabled: true,
    }"
    :coverflowEffect="{
      rotate: 0,
      stretch: 225,
      depth: 300,
    }"
    :modules="modules"
  >
    <swiper-slide v-for="(v, i) in items" :key="i" v-slot="{ isActive }" class="w-140 max-w-full">
      <slot :value="v" :isActive="isActive || !landscape" />
    </swiper-slide>
    <div
      class="flex h-0 items-center justify-between absolute top-50% w-full z-1 px-12%"
      un-children="rd-full b-solid c-green p-2"
      portrait="hidden"
    >
      <div class="swiper-next">
        <div class="i-local-arrow-l" />
      </div>
      <div class="swiper-pre">
        <div class="i-local-arrow-d -rotate-90" />
      </div>
    </div>
  </swiper>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectCoverflow, Autoplay, Navigation } from 'swiper/modules';
import { landscape } from '@/stores';
const modules = [EffectCoverflow, Autoplay, Navigation];

defineProps<{ items: any[] }>();
</script>
