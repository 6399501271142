<template>
  <DPHomeBanner class="mb-4" tag="Lobby" />
  <DPGameLobby @jump="openPage" />
</template>
<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const openPage = (path: string, replace = false) => {
  router.push({ path, replace });
};
</script>
