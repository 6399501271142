<template>
  <div
    class="flex items-center"
    @click="update"
    :class="{ 'cursor-pointer': !props.disabled, 'cursor-not-allowed': props.disabled }"
  >
    <div
      class="b-2 b-solid mr-2 rd-full"
      :class="{
        'b-white/20': props.value !== val,
        'b-green/20': props.value === val,
        'op-30': props.disabled,
      }"
    >
      <div v-if="props.value === val" class="w-5 h-5 rd-full b-5 b-solid bg-white b-green" />
      <div class="w-5 h-5 rd-full bg-[#424751]" v-else />
    </div>

    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
const val = defineModel<any>({ default: '' });
const props = withDefaults(defineProps<{ value: any; disabled?: boolean }>(), { disabled: false });

const update = () => {
  if (props.disabled) return;
  val.value = props.value;
};
</script>
