import DetailPage from './detail.vue';

export default [
  {
    name: 'clan-detail',
    path: '/cl/:id([\\d]+)',
    component: DetailPage,
    props: true,
    meta: { title: 'CLAN_DETAIL' },
  },
];
