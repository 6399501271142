<script setup lang="ts">
import { blogFetch } from '@/utils/network';
import qs from 'qs';
import { ref } from 'vue';

const sponsors = ref<any[]>([]);

const getData = async () => {
  const query = qs.stringify(
    {
      fields: ['sponsor_name', 'sponsor_level', 'website', 'x', 'discord', 'telegram', 'debox', 'description'],
      populate: {
        logo: {
          fields: ['url'],
        },
      },
      sort: 'sponsor_level:desc',
    },
    {
      encodeValuesOnly: true, // prettify URL
    },
  );
  const result = blogFetch.get(`sponsors?${query}`);
  const data: any = await result.json();
  sponsors.value = data.data.map((item: any) => ({
    ...item.attributes,
    logo: item.attributes.logo.data.attributes.url,
    id: item.id,
  }));
};
getData();

import { socialImgs } from '@/views/mtt/socialIcons';
import level1 from '@/assets/img/sponsor_v1.png';
import level2 from '@/assets/img/sponsor_v2.png';
import level3 from '@/assets/img/sponsor_v4.png';
import level4 from '@/assets/img/sponsor_v1.png';
const levelImages = ['', level1, level2, level3, level4];
</script>

<template>
  <div class="py-10 of-hidden">
    <div class="grid grid-cols-3 gap-x-10" portrait="grid-cols-1">
      <div class="rd-lg bg-gray-900" v-for="item in sponsors" :key="item.id">
        <div class="bg-gradient-to-b from-[#08BB7A] to-[#197374] rd-lb-md rd-t-md relative">
          <img :src="levelImages[item.sponsor_level]" class="w-16 absolute left--2" alt="" />
          <div class="text-center">
            <img :src="item.logo" class="h-14 mt--1" alt="" />
          </div>
          <div class="flex items-center justify-between px-4 pt-3 py-6">
            <div class="text-lg fw-500">{{ item.sponsor_name }}</div>
            <img src="../../assets/img/sponsor_icon.png" class="w-10" alt="" />
          </div>
        </div>
        <div class="rd-tr-lg flex items-stretch h-16">
          <div class="flex-1 pl-2 flex items-center space-x-4">
            <a :href="item.telegram" target="_blank" v-if="item.telegram">
              <img :src="socialImgs.telegram" class="w-7" alt="telegram" />
            </a>
            <a :href="item.x" target="_blank" v-if="item.x">
              <img :src="socialImgs.x" class="w-7" alt="x" />
            </a>
            <a :href="item.discord" target="_blank" v-if="item.discord">
              <img :src="socialImgs.discord" class="w-7" alt="discord" />
            </a>
            <a :href="item.debox" target="_blank" v-if="item.debox">
              <img :src="socialImgs.debox" class="w-7" alt="debox" />
            </a>
          </div>
          <div class="bg-[#197374] min-w-16">
            <div class="bg-gray-900 rd-tr-full w-full h-full" />
          </div>
        </div>
        <div class="p-2">{{ item.description }}</div>
        <div class="p-4 flex items-center justify-between space-x-2">
          <AButton type="secondary" size="small" class="flex-1 w-full px-0" @click="$router.push(`/sponsor/${item.id}`)"
            >More</AButton
          >
          <a :href="item.website" target="_blank" class="flex-1">
            <AButton type="secondary" size="small" class="flex-1 w-full" icon="i-local-link">Website</AButton>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
