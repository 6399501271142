<script setup lang="ts">
import { LAYOUT } from '@/utils/constant';
import { computed, ref } from 'vue';
import { getHand } from '@/utils/value';
import type { RoundRecord } from '@/types';
import { authAPI } from '@/utils/network';
import { landscape } from '@/stores';
import { dt } from '@/utils/datetime';

type VDF = {
  allCards: string[];
  unDealtCards: string[];
  players: string[];
  seeds: string[];
  startHash: string;
  endHash: string;
  cardHash?: string;
  startUrl: string;
  endUrl: string;
  cardUrl?: string;
  xSeeds: any;
  x: string;
  n: string;
  time: string;
  pi: string;
  createTime: string;
  gameId: string;
  [key: string]: any;
};

const props = defineProps<{ room: string; seq: string }>();

const data = ref<RoundRecord | null>(null);
const vdf = ref<VDF>({
  allCards: [],
  unDealtCards: [],
  players: [],
  seeds: [],
  startHash: '',
  endHash: '',
  startUrl: '',
  endUrl: '',
  xSeeds: {},
  x: '',
  n: '',
  time: '',
  pi: '',
  createTime: '',
  gameId: `${props.room}:${props.seq}`,
});

async function getData() {
  data.value = await authAPI
    .get<RoundRecord>('v1/hand_history/getRecordByRoomIdAndSeq', {
      roomId: props.room,
      seq: Number(props.seq),
    })
    .then((data) => {
      if (data) {
        const record = data;
        try {
          let cards: string[] = [];
          record.rounds.forEach((r) => {
            if (r.cards) {
              cards = cards.concat(...(r.cards || []));
              r.cards = cards;
            }
            r.actions?.forEach((a) => {
              a.positionName = record.players.find((p) => p.position === a.position)?.positionName;
              if (!a.cards) return;
              const p = record.players.find((p) => p.position === a.position)!;
              if (!p.cards) p.cards = a.cards;
              else
                a.cards.map((v, i) => {
                  if (!v) return;
                  p.cards![i] = v;
                });
            });
            r.actions = r.actions?.filter((v) => v.actionNumber !== -1 && v.action !== 'InitiativeShowHands');
          });

          record.playerWinChips.forEach((w) => {
            const player = record.players.find((v) => w.position === v.position)!;
            player.win = w.winAmount;
          });

          record.players.forEach((p) => {
            p.pos = (record.playerNums + p.position - record.currentPosition) % record.playerNums;
          });
          if (record.vdf) {
            vdf.value = JSON.parse(record.vdf);
            vdf.value.unDealtCards = vdf.value.allCards.slice(record.players.length * 2);
            vdf.value.gameId = `${props.room}:${props.seq}`;
          }
          return record;
        } catch {
          return null;
        }
      }
      return null;
    });
}

getData().then();

const offsets = computed(() => LAYOUT[data.value ? data.value.playerNums : 9]);
const cards = computed(() =>
  data.value ? data.value.runTwiceCards || data.value.rounds.filter((v) => v.cards).at(-1)?.cards || [] : [],
);
const runTwiceCards = computed(() => (data.value && data.value.runTwiceCards ? data.value.normalCards : []));
const playerCount = computed(() => (data.value && data.value.players ? data.value.players.length : 0));

const cardGroups = computed(() => {
  const res = Array(5)
    .fill(0)
    .map((_, i) => [runTwiceCards.value?.[i], cards.value[i]]);
  return res
    .map((v) => {
      if (!v[0] && !v[1]) return [];
      if (!v[0]) return [v[1]];
      if (!v[1]) return [v[0], ''];
      if (v[0] === v[1]) return [v[0]];
      return v;
    })
    .filter((v) => v.length);
});

function getHandShape(cs?: string[]) {
  if (!cs || (data.value && data.value.runTwiceCards)) return;
  return getHand(cs.filter((v) => v).concat(cards.value)).name;
}

function getLabel(index: number) {
  const playerNum = vdf.value.players.length;
  let _players = [] as string[];
  vdf.value.players.forEach((item, i) => {
    if (!(playerNum > 2 && i === 0)) {
      _players.push(item);
    }
  });
  if (playerNum > 2) _players.push(vdf.value.players[0]);

  let lastEffectIndex = playerNum * 2 + 8;
  if (runTwiceCards.value?.length) {
    if (cards.value[0] !== runTwiceCards.value[0]) {
      lastEffectIndex += 8;
    } else if (cards.value[3] !== runTwiceCards.value[3]) {
      lastEffectIndex += 4;
    } else if (cards.value[4] !== runTwiceCards.value[4]) {
      lastEffectIndex += 2;
    }
  }

  if (index >= lastEffectIndex) {
    return '';
  }
  if (index < playerNum * 2) {
    return _players[Math.floor(index % playerNum)];
  }
  if (runTwiceCards.value?.length) {
    if ([...cards.value, ...(runTwiceCards.value || [])].includes(vdf.value.allCards[index])) {
      return 'Flop';
    }
  } else {
    if ([1, 2, 3, 5, 7].includes(index - playerNum * 2)) {
      return 'Flop';
    }
  }
  return 'Burn';
}
</script>

<template>
  <div class="my-20">
    <div
      class="w-200! text-center b-3 c-green b-green b-solid px-1 py-2 text-9xl font-anton"
      portrait="w-full! text-8xl"
    >
      Validation
    </div>

    <div class="w-200! space-y-7.5 mt-10 break-words" portrait="w-full! break-all">
      <div class="text-center">
        <img src="@/assets/img/validation-decr.png" class="w-full h-auto" alt="" />
      </div>

      <div class="validation-table w-199 h-140 pt-50 relative b-green b-solid b-2" portrait="w-117 h-80 pt-20">
        <DPTableCards
          v-if="data"
          :twice="runTwiceCards"
          :cards="cards"
          :winners="[]"
          :rabbitCards="data.rabbitHuntingCards"
          simple
        />
        <div class="absolute inset-1/2 flex items-center justify-center scale-85 z-3" v-if="data">
          <DPMiniSeat
            v-for="p in data.players"
            :key="p.position"
            :style="{
              '--un-translate-x': offsets[p.pos!][0] / (landscape ? 1.3 : 2) + 'rem',
              '--un-translate-y': offsets[p.pos!][1] / (landscape ? 1.3 : 2) + 'rem',
            }"
            :offset="offsets[p.pos!]"
            :name="p.playerName"
            :stack="p.endStack"
            :cards="p.cards"
            :win="p.win"
            :hand="getHandShape(p.cards)"
            :dealer="p.position === data.dealer"
            :id="p.userID"
          />
        </div>
      </div>

      <div class="flex items-stretch">
        <div class="b-3 c-green b-green b-solid px-1 py-2 text-3xl font-anton flex items-center">
          Board Cards Sequence
        </div>
        <div class="b-3 b-green b-solid mx-5" />
        <div>
          <!--          <div class="c-green flex-1">Board:</div>-->
          <div class="relative grid grid-cols-5 place-items-center z-2">
            <div v-for="(g, i) in cardGroups" class="h-3em w-2.8em" :key="i">
              <DPCard mini class="relative text-sm" :class="{ 'bottom-23% right-10%': g.length > 1 }" :name="g[0]" />
              <DPCard mini v-if="g[1]" class="relative z-1 bottom-77% left-15% text-sm" :name="g[1]" />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <img src="@/assets/img/validation-decr.png" class="w-full h-auto" alt="" />
      </div>

      <div class="flex flex-wrap">
        <div
          v-for="(card, index) in vdf.allCards"
          :key="card"
          class="mr-5 mb-5 flex flex-col items-center"
          portrait="mr-4"
        >
          <DPCard
            class="text-sm"
            :name="card"
            :disabled="![...cards, ...runTwiceCards].includes(card) && card !== '' && index >= playerCount * 2"
            mini
          />
          <div
            v-if="index < playerCount * 2 + 8 * (runTwiceCards?.length ? 2 : 1)"
            class="text-center bg-green c-black rd-md mt-2 px-0.5"
            :class="{
              'bg-yellow': index < playerCount * 2,
            }"
          >
            {{ getLabel(index) }}
          </div>
        </div>
      </div>

      <div class="flex items-stretch">
        <div class="b-3 c-green b-green b-solid px-1 py-2 text-3xl font-anton flex items-center">Info</div>
        <div class="b-3 b-green b-solid mx-5" />
        <div class="flex flex-col justify-around flex-1 truncate">
          <div class="w-full truncate">
            <span class="c-green!">OnChain Proof: </span>
            <a class="c-white underline" :href="vdf.startUrl" target="_blank">{{ vdf.startHash }}</a>
          </div>
          <div class="truncate w-full">
            <span class="c-green!">OnChain Verification: </span>
            <a class="c-white underline" :href="vdf.endUrl" target="_blank">{{ vdf.endHash }}</a>
          </div>
          <div class="truncate w-full" v-if="vdf.cardUrl">
            <span class="c-green!">OnChain Cards Verification: </span>
            <a class="c-white underline" :href="vdf.cardUrl" target="_blank">{{ vdf.cardHash }}</a>
          </div>
        </div>
      </div>

      <div class="text-center">
        <img src="@/assets/img/validation-decr.png" class="w-full h-auto" alt="" />
      </div>

      <div class="py-5">
        <h4>Step1: Collect seed phrases from players</h4>
        <div class="pl-5 py-5 space-y-5">
          <div class="flex items-start">
            <div class="w-20 mt-2">[Code]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" comment>
                # Players input their phrases to generate player random seeds combined with their usernames, which serve
                as their encrypted 'shake factor' for determining the deck order during shuffling.
              </VDLineItem>
              <VDLineItem :line="2" comment>
                # Aggregated seed = Hand id hash + Hand creat time hash + players' seed phrases...
              </VDLineItem>
              <VDLineItem :line="3"
                >Aggregated seed = AggregatedSeedPhrases([players' seed phrases, Hand id hash, Hand creat time hash])
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start break-all">
            <div class="w-20 mt-2">[Input]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1">
                Hand id hash = <span>{{ vdf.xSeeds.gameId }}</span>
              </VDLineItem>
              <VDLineItem :line="2">
                Hand creat time hash = <span>{{ vdf.xSeeds.createTime }}</span>
              </VDLineItem>
              <VDLineItem :line="index + 3" v-for="(player, index) in data?.players" :key="index">
                <div>
                  Player <span class="c-yellow!">{{ player.playerName }}</span>
                  <template v-if="vdf?.seeds?.[index]">
                    <span class="c-red!">[seed: {{ vdf.seeds[index] }}]</span> seed phrase =
                    <span>{{ vdf.xSeeds[`${player.playerName}Seed`] }}</span>
                  </template>
                  <template v-else>
                    default phrase = <span>{{ vdf.xSeeds[player.playerName] }}</span>
                  </template>
                </div>
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20">[Output]:</div>
            <div class="flex-1">Aggregated seed = {{ vdf.x }}</div>
          </div>
        </div>

        <h4 class="mt-10">Step2: VDF Proof</h4>
        <div class="pl-5 py-5 space-y-5">
          <div class="flex items-start">
            <div class="w-20 mt-2">[Code]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" comment> # VDF trapdoor generation, y is vdf result, pi is vdf proof</VDLineItem>
              <VDLineItem :line="2" comment>
                # The Aggregated seed generated in the previous step will be used as the input seed for the VDF in this
                step.
              </VDLineItem>
              <VDLineItem :line="3">vdf trapdoor preparation : pk, sk = get_rsa()</VDLineItem>
              <VDLineItem :line="4">
                pk=(<span>{{ vdf.n }}</span
                >, <span>{{ vdf.time }}</span
                >)
              </VDLineItem>
              <VDLineItem :line="5">y, pi = vdf.TrapEval_Wes18(pk, sk, x)</VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20 mt-2">[Input]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" class="break-all">
                VDF Seed(x) = <span>{{ vdf.x }}</span>
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20">[Output]:</div>
            <div class="flex-1">vdf proof={{ vdf.pi }}</div>
          </div>
        </div>

        <h4 class="mt-10">Step3: OnChain Shuffle Proof</h4>
        <div class="pl-5 py-5 space-y-5">
          <div class="flex items-start">
            <div class="w-20 mt-2">[Code]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" comment>
                # At the start of the game, we will record this data on the blockchain as proof, ensuring the fairness,
                transparency, and integrity of our entire shuffling process.
              </VDLineItem>
              <VDLineItem :line="2">
                OnChain Proof Transaction Hash = gameData.start(x,n,time,gameId,players,createTime)
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20 mt-2">[Input]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md break-all">
              <VDLineItem
                :line="index + 1"
                v-for="(item, index) in ['x', 'n', 'time', 'players', 'gameId', 'createTime']"
                :key="item"
              >
                {{ item }}=<span>{{ vdf[item] }}</span>
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20">[Output]:</div>
            <div class="flex-1 break-all">
              <div>
                OnChain Proof Transaction Hash =
                <a class="underline c-white" :href="vdf.startUrl" target="_blank">{{ vdf.startHash }}</a>
              </div>
              <div>Hand Create Time = {{ dt(Number(vdf.createTime) * 1e3).strDatetime }}</div>
              <div>Block Chain Confirm Time = {{ dt(Number(vdf.startBlockTime) * 1e3).strDatetime }}</div>
            </div>
          </div>
        </div>

        <h4 class="mt-10">Step4: Shuffle and cut</h4>
        <div class="pl-5 py-5 space-y-5">
          <div class="flex items-start">
            <div class="w-20 mt-2">[Code]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" comment> # generate 53 random numbers with sfmt</VDLineItem>
              <VDLineItem :line="2">data randNumbers[53] = SfmtGenerator(seed)</VDLineItem>
              <VDLineItem :line="3"></VDLineItem>
              <VDLineItem :line="4" comment># fisher-yates shuffling</VDLineItem>
              <VDLineItem :line="5">for i in range(52):</VDLineItem>
              <VDLineItem :line="6">
                <div class="ml-4">j = randNumbers[i] % (n - i) + i</div>
              </VDLineItem>
              <VDLineItem :line="7">
                <div class="ml-4">deck[i], deck[j] = deck[j], deck[i]</div>
              </VDLineItem>
              <VDLineItem :line="8"></VDLineItem>
              <VDLineItem :line="9" comment># Cut</VDLineItem>
              <VDLineItem :line="10">random_cut = randNumbers[53] % n</VDLineItem>
              <VDLineItem :line="11">result_cards = []</VDLineItem>
              <VDLineItem :line="12">result_cards.extend(deck[random_cut:])</VDLineItem>
              <VDLineItem :line="13">result_cards.extend(deck[:random_cut])</VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20 mt-2">[Input]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1"> seed = <span>VDF Proof</span></VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20">[Output]:</div>
            <div class="flex-1 flex flex-wrap">
              <div v-for="card in vdf.allCards" class="mr-1 mb-1 flex flex-col items-center" :key="card">
                <DPCard mini class="text-xs" :name="card" />
              </div>
              <div class="break-all" v-if="vdf.cardHash">
                <div>
                  OnChain Cards Verification Transaction Hash =
                  <a class="underline c-white" :href="vdf.cardUrl" target="_blank">{{ vdf.cardHash }}</a>
                </div>
                <div>Hand End Time = {{ dt(Number(vdf.gameEndTime) * 1e3).strDatetime }}</div>
                <div>Block Chain Confirm Time = {{ dt(Number(vdf.cardBlockTime) * 1e3).strDatetime }}</div>
              </div>
            </div>
          </div>
        </div>

        <h4 class="mt-10">Step5: OnChain Shuffle Verification</h4>
        <div class="pl-5 py-5 space-y-5">
          <div class="flex items-start">
            <div class="w-20 mt-2">[Code]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1" comment>
                # At the end of the game, the VDF proof will be submitted to the blockchain for verification.
                Additionally, zero-knowledge proof circuits will be used to confirm that the generated deck is derived
                from the VDF seed through SFMT. Only after all verifications are successfully passed will the data be
                recorded on the blockchain.
              </VDLineItem>
              <VDLineItem :line="2">zkProof, publicSignals = zkp_generate(y, publicCardsList)</VDLineItem>
              <VDLineItem :line="3"
                >OnChain Verification Transaction Hash = gameData.end(pi,challenge,gameId,zkp,publicCardsList)
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20 mt-2">[Input]:</div>
            <div class="b-solid b-[#8F8F8F] bg-white/15 flex-1 px-1 py-2 rd-md">
              <VDLineItem :line="1">
                publicCardsList=<span>{{ vdf.allCards }}</span>
              </VDLineItem>
            </div>
          </div>
          <div class="flex items-start">
            <div class="w-20">[Output]:</div>
            <div class="flex-1 break-all">
              <div>
                OnChain Verification Transaction Hash =
                <a class="underline c-white" :href="vdf.endUrl" target="_blank">{{ vdf.endHash }}</a>
              </div>
              <div>Hand End Time = {{ dt(Number(vdf.gameEndTime) * 1e3).strDatetime }}</div>
              <div>Block Chain Confirm Time = {{ dt(Number(vdf.endBlockTime) * 1e3).strDatetime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.validation-table {
  background:
    url('@/assets/img/table-border.svg') center/contain no-repeat,
    url('@/assets/img/table-bg.png') center/ 84% 60% no-repeat,
    #182b38;
  position: relative;
}

.validation-table::before,
.validation-table::after {
  content: '';
  position: absolute;
  width: 0.8rem; /* Width of the "ears" */
  height: 18rem; /* Height of the "ears" */
  background-color: #08bb7a; /* Same color as the border */
  top: 50%; /* Center vertically */
  transform: translateY(-55%);
}

.validation-table::before {
  left: -0.8rem; /* Position to the left */
  clip-path: polygon(100% 0%, 0% 5%, 0% 95%, 100% 100%);
}

.validation-table::after {
  right: -0.8rem; /* Position to the right */
  clip-path: polygon(0% 0%, 100% 5%, 100% 95%, 0% 100%);
}

@font-face {
  font-family: 'Anton';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Anton-Regular.ttf') format('truetype');
}

.font-anton {
  font-family: 'Anton', serif;
}

span {
  color: #86d36c;
}
</style>
