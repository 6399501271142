<template>
  <div class="item-center justify-center w-screen" :class="{ 'pr-30 pl-30': landscape }">
    <div class="space-y-2.5 p-4">
      <div class="relative rd-lg of-hidden flex justify-center items-center p-1 bg-gray-700">
        <div class="i-local-clock"></div>
        <span class="c-green ml-1">{{ $t('REMAINING_TIME') }}:</span>
        <span class="ml-1">{{ dur(remainingTime) }}</span>
      </div>
    </div>
    <div class="item-center justify-center flex">
      <div
        class="w-100 item-center justify-center flex flex-col"
        un-children="flex flex-col justify-center item-center rd-lg"
      >
        <div>
          <img src="/static/task-daily-sign-title-bg.png" class="w-100" />
        </div>
        <div class="w-100 bg-[#25262D]">
          <div v-if="showTotalSign" class="flex flex-row justify-center items-center p-4">
            <span class="i-local-calendar c-green"></span>
            <span class="c-green ml-1">{{ $t('DAILY_SIGN_TOTAL', { v: signDay }) }}</span>
          </div>
          <div v-if="signData" class="relative space-x-4 flex flex-row justify-center items-center pb-2 pr-4 pl-4">
            <DPDailySignTaskItem
              :data="signData[0]"
              :title="$t('DAILY_SIGN_TITLE', { v: 1 })"
              :showTime="showTimeDay == 1"
              @callback="claimCallback"
            />
            <DPDailySignTaskItem
              :data="signData[1]"
              :title="$t('DAILY_SIGN_TITLE', { v: 2 })"
              :showTime="showTimeDay == 2"
              @callback="claimCallback"
            />
            <DPDailySignTaskItem
              :data="signData[2]"
              :title="$t('DAILY_SIGN_TITLE', { v: 3 })"
              :showTime="showTimeDay == 3"
              @callback="claimCallback"
            />
          </div>
          <div v-if="signData" class="relative space-x-4 flex flex-row justify-center items-center pb-4 pr-4 pl-4">
            <DPDailySignTaskItem
              :data="signData[3]"
              :title="$t('DAILY_SIGN_TITLE', { v: 4 })"
              :showTime="showTimeDay == 4"
              @callback="claimCallback"
            />
            <DPDailySignTaskItem
              :data="signData[4]"
              :showTime="showTimeDay == 5"
              :title="$t('DAILY_SIGN_TITLE', { v: '5+' })"
              @callback="claimCallback"
            />
          </div>
        </div>
        <ADialog class="w-100" :unclosable="true" :transparent="true" v-model="showSignClaimNotify">
          <TaskAnim :coin="claimData?.gold" />
          <div class="flex justify-center">
            <AButton class="w-30" @click="showSignClaimNotify = false">{{ $t('game.CONFIRM') }}</AButton>
          </div>
        </ADialog>
      </div>
    </div>
    <div v-if="dailyData" class="space-y-2.5 p-2">
      <span class="text-xl">{{ $t('TASK_LIST') }}</span>
      <DPGoldPlatformTaskItem :data="dailyData" @callback="claimDailyCallback" />
    </div>
    <ADialog class="w-100 items-center justify-center" :unclosable="true" :transparent="true" v-model="showClaimNotify">
      <div class="flex flex-col rd-lg p-3 my-4 space-y-2 text-center item-center justify-center">
        <TaskAnim :coin="claimData?.gold" :title="claimData?.title" :experience="claimData?.experience" />
        <div class="flex justify-center">
          <AButton class="w-30" @click="showClaimNotify = false">{{ $t('game.CONFIRM') }}</AButton>
        </div>
      </div>
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import type { PlatformTask, PlatformTaskGroup, TaskClaimPrize } from '@/types';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';
import { landscape, showLoading, taskRedDot } from '@/stores';
import { dur } from '@/utils/datetime';
import { useIntervalFn } from '@vueuse/core';
import { toast } from '@/components/ui/AToast.vue';

const dailyData = ref([] as PlatformTask[]);
const showClaimNotify = ref(false);
const claimData = ref<TaskClaimPrize>();
showLoading.value = true;

const loadDailyTaskList = async () => {
  authAPI
    .get<PlatformTaskGroup[]>('v1/task/list', { taskTime: 'daily' })
    .then((v) => {
      v.forEach((group) => {
        // let claimTask = 0;
        // group.list.forEach((task) => {
        //   if (task.isFinish && !task.isClaim) claimTask = claimTask + 1;
        // });
        dailyData.value = group.list;
        showLoading.value = false;
      });
    })
    .catch((e: any) => {
      toast.error(e.msg);
      showLoading.value = false;
    });
};
loadDailyTaskList();

// const loadDailyPrizeList = async () => {
//   authAPI.get<PlatformTask[]>('v1/task/getDailyPrizeInfo').then((v) => {
//     dailyPrize.value = v;
//     totalActive.value = v[v.length - 1].taskLadder;
//     let claimTask = 0;
//     v.forEach((i) => {
//       if (i.isFinish) {
//         currentActive.value = i.taskLadder;
//         if (!i.isClaim) {
//           claimTask += 1;
//         }
//       }
//     });
//     dailyClaimTips.value = claimTask;
//   });
// };
// loadDailyPrizeList();

const claimDailyCallback = async (v: any) => {
  claimData.value = v;
  showClaimNotify.value = true;
  taskRedDot.value.dailyTask = taskRedDot.value.dailyTask - 1;
  await loadDailyTaskList();
};

const remainingTime = ref(0);

const remaining = async () => {
  const now = new Date();
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);
  remainingTime.value = midnight.getTime() - now.getTime() - now.getTimezoneOffset() * 60000 - 24 * 60 * 60 * 1000;
  if (remainingTime.value < 0) {
    remainingTime.value += 24 * 60 * 60 * 1000;
  }
};

useIntervalFn(() => {
  remaining();
});

const showTotalSign = ref(false);
const signData = ref<PlatformTask[]>();
const signDay = ref(0);
const showTimeDay = ref(0);
const showSignClaimNotify = ref(false);

const loadDailySignList = async () => {
  authAPI
    .get<PlatformTask[]>('v1/task/dailySignInfo')
    .then((v) => {
      let maxTaskLadder = v[v.length - 1].taskLadder;
      v.forEach((task) => {
        if (task.isClaim) {
          signDay.value = task.taskLadder;
        }
      });
      if (maxTaskLadder > 5 && signDay.value != maxTaskLadder) {
        signDay.value = maxTaskLadder - 1;
      }
      showTimeDay.value = signDay.value + 1;
      if (showTimeDay.value > 5) {
        showTimeDay.value = 5;
      }
      signData.value = v;
      showTotalSign.value = true;
      showLoading.value = false;
    })
    .catch((e: any) => {
      toast.error(e.msg);
      showLoading.value = false;
    });
};

const claimCallback = async (v: any) => {
  claimData.value = v;
  showSignClaimNotify.value = true;
  taskRedDot.value.signTask = taskRedDot.value.signTask - 1;
  loadDailySignList();
};

loadDailySignList();
</script>
