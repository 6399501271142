import { botAPI, xRedirect } from '@/utils/network';
import Web3 from 'web3';

const ethereum = (window as any).ethereum;
const w3 = (window as any).web3;

export const getDeboxEVMToken = async () => {
  let web3 = new Web3('https://eth.llamarpc.com');
  if (ethereum) {
    console.info('ethereum');
    web3 = new Web3(ethereum);
  } else if (w3) {
    console.info('currentProvider');
    web3 = new Web3(w3.currentProvider);
  }
  await web3.currentProvider!.request({ method: 'eth_requestAccounts', params: [] });
  const accounts = await web3.eth.requestAccounts();
  return accounts[0];
};

export const makeDeboxEVMTransaction = async (chainId: string) => {
  let web3 = new Web3('https://eth.llamarpc.com');
  if (ethereum) {
    console.info('ethereum');
    web3 = new Web3(ethereum);
  } else if (w3) {
    console.info('currentProvider');
    web3 = new Web3(w3.currentProvider);
  }
  try {
    await web3.currentProvider!.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    });
  } catch (error: any) {
    console.info(error);
    throw error;
  }
  await web3.currentProvider!.request({ method: 'eth_requestAccounts', params: [] });
  const accounts = await web3.eth.requestAccounts();
  const amountValue = web3.utils.toWei(0, 'ether');
  const gasprice = web3.utils.toWei(0, 'gwei');
  const transactionObject = {
    from: accounts[0],
    to: accounts[0],
    value: amountValue,
    gas: '21000',
    gasPrice: gasprice,
    data: '',
    chainId: chainId,
  };
  try {
    await web3.eth.sendTransaction(transactionObject);
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const connectDebox = async (loginType = '', path = '') => {
  if (!path) {
    path = location.pathname;
  }
  console.info('MODE===' + import.meta.env.MODE);
  xRedirect.value = encodeURIComponent(path) + loginType;
  if (import.meta.env.MODE == 'prod') {
    location.href =
      'https://app.debox.pro/oauth/authorize?app_id=z0yzvRWI70cdhX29&grant_type=authorization_code&scope=' +
      deboxScope +
      '&response_type=code&redirect_uri=https://app.lepoker.io/deboxAuth';
  } else if (import.meta.env.MODE == 'sit') {
    location.href =
      'https://app.debox.pro/oauth/authorize?app_id=z0yzvRWI70cdhX29&grant_type=authorization_code&scope=' +
      deboxScope +
      '&response_type=code&redirect_uri=https://sit-app.lepoker.io/deboxAuth';
  } else if (import.meta.env.MODE == 'newdev') {
    location.href =
      'https://app.debox.pro/oauth/authorize?app_id=z0yzvRWI70cdhX29&grant_type=authorization_code&scope=' +
      deboxScope +
      '&response_type=code&redirect_uri=https://dev-app.lepoker.io/deboxAuth';
  }
};

export const getDeBoxWalletAddress = async (userId: string) => {
  const { walletAddress } = await botAPI.get<{ walletAddress: string }>('debox/userInfo', { userId: userId });
  return walletAddress;
};

export const deboxScope = 'authorization';
