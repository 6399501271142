<template>
  <div class="pt-4">
    <div class="flex my-4" un-children="text-center p-2 cursor-pointer b-b-solid b-2">
      <div
        @click="$router.replace('daily')"
        class="relative flex justify-center items-center flex-1 py-2 px-1 b-2 b-b-solid cursor-pointer"
        :class="
          $route.path === '/profile/task/daily'
            ? 'b-green bg-gradient-to-t from-green-900 c-white'
            : 'c-gray-300 b-transparent'
        "
      >
        <h5>
          {{ $t('TASK_DAILY') }}
        </h5>
        <div
          v-if="taskRedDot.dailyTask > 0 || taskRedDot.signTask > 0"
          class="ml-2 w-4 h-4 rd-full bg-red-500 -mt-4 text-xs c-white"
        >
          {{ taskRedDot.dailyTask + taskRedDot.signTask }}
        </div>
      </div>

      <div
        @click="$router.replace('diamond')"
        class="relative flex justify-center items-center flex-1 py-2 px-1 b-2 b-b-solid cursor-pointer"
        :class="
          $route.path === '/profile/task/diamond'
            ? 'b-green bg-gradient-to-t from-green-900 c-white'
            : 'c-gray-300 b-transparent'
        "
      >
        <h5>
          {{ $t('DIAMOND') }}
        </h5>
        <div v-if="taskRedDot.diamondTask > 0" class="ml-2 w-4 h-4 rd-full bg-red-500 -mt-4 text-xs c-white">
          {{ taskRedDot.diamondTask }}
        </div>
      </div>
      <!-- <div
        @click="$router.replace('gold')"
        class="relative flex flex-grid justify-center items-center flex-1 py-2 px-1 b-2 b-b-solid cursor-pointer"
        :class="
          $route.path === '/profile/task/gold'
            ? 'b-green bg-gradient-to-t from-green-900 c-white'
            : 'c-gray-300 b-transparent'
        "
      >
        <h5>
          {{ $t('TASK_GOLD') }}
        </h5>
        <div v-if="taskRedDot.goldTask > 0" class="ml-2 w-4 h-4 rd-full bg-red-500 -mt-4 text-xs c-white">
          {{ taskRedDot.goldTask }}
        </div>
      </div> -->
      <div
        @click="$router.replace('experience')"
        class="relative flex justify-center items-center flex-1 py-2 px-1 b-2 b-b-solid cursor-pointer"
        :class="
          $route.path === '/profile/task/experience'
            ? 'b-green bg-gradient-to-t from-green-900 c-white'
            : 'c-gray-300 b-transparent'
        "
      >
        <h5>
          {{ $t('TASK_EXPERIENCE') }}
        </h5>
        <div
          v-if="taskRedDot.experienceTask > 0 || taskRedDot.levelTask > 0"
          class="ml-2 w-4 h-4 rd-full bg-red-500 -mt-4 text-xs c-white"
        >
          {{ taskRedDot.experienceTask + taskRedDot.levelTask }}
        </div>
      </div>
    </div>
    <div class="flex item-center justify-center">
      <RouterView />
    </div>
  </div>
</template>
<script setup lang="tsx">
import { showTaskEntry, taskRedDot } from '@/stores';
import { assets, checkTaskRedDot } from '@/stores/user';
import { onMounted, onUnmounted } from 'vue';

onUnmounted(() => {
  showTaskEntry.value = true;
});

onMounted(() => {
  checkTaskRedDot();
  showTaskEntry.value = false;
});
</script>
