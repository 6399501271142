import messages from '@/assets/lang';
import { useLocalStorage, type RemovableRef } from '@vueuse/core';
import { LANGS } from '@/utils/language';
import { ref } from 'vue';
import { createI18n } from 'vue-i18n';

export const lang: RemovableRef<string> = useLocalStorage('lang', '');

if (!lang.value) {
  if (navigator.language === 'zh-CN') lang.value = 'cn';
  else if (navigator.language === 'zh-TW') lang.value = 'tc';
  else if (navigator.language === 'ja') lang.value = 'jp';
  else if (navigator.language === 'ko') lang.value = 'kr';
  else if (navigator.language === 'ru') lang.value = 'ru';
  else if (navigator.language === 'vi') lang.value = 'vn';
  else lang.value = 'en';
}

const l = new URLSearchParams(location.search).get('lang');
if (l && LANGS.map((v) => v.val).includes(l)) lang.value = l;

export const showLanguage = ref(false);

export default createI18n({
  legacy: false,
  locale: lang.value,
  fallbackLocale: 'en',
  messages,
});
