import ky from 'ky';

const request = ky.create({
  retry: 0,
  timeout: 10000,
  prefixUrl: `https://api.btrscan.com/scan/api`,
});

export const queryBlockNumber = async (timestamp: number): Promise<any> => {
  return request
    .get('', {
      searchParams: {
        module: 'block',
        action: 'getblocknobytime',
        timestamp,
        closest: 'before',
      },
    })
    .json();
};

export const queryTransactionCount = async (address: string, amount: number, blocknumber: number): Promise<any> => {
  return request
    .get('', {
      searchParams: {
        module: 'account',
        action: 'txlist',
        address,
        startblock: blocknumber,
        page: 1,
        offset: amount,
      },
    })
    .json();
};

export const queryNativeBalance = async (address: string): Promise<any> => {
  return request
    .get('', {
      searchParams: {
        module: 'account',
        action: 'balance',
        address,
      },
    })
    .json();
};

export const queryTokenBalance = async (address: string, contractaddress: string): Promise<any> => {
  return request
    .get('', {
      searchParams: {
        module: 'account',
        action: 'tokenbalance',
        address,
        contractaddress,
        tag: 'latest',
      },
    })
    .json();
};
