<template>
  <div class="w-screen item-center justify-center" :class="{ 'pr-30 pl-30': landscape }">
    <div v-if="diamondData" class="space-y-2.5 p-2">
      <span class="text-xl">{{ $t('TASK_LIST') }}</span>
      <DPGoldPlatformTaskItem :data="diamondData" @callback="claimDiamondCallback" />
    </div>
    <ADialog
      class="w-100 shine-bg item-center justify-center"
      :unclosable="true"
      :transparent="true"
      v-model="showClaimNotify"
    >
      <p class="text-center pt-4 text-xl">{{ $t('PLATFORM_TASK_CLAIM_TITLE') }}</p>
      <div class="flex flex-col rd-lg p-3 my-4 space-y-2 text-center item-center justify-center">
        <div
          class="flex justify-center space-x-2 pt-10"
          un-children="flex flex-col justify-center item-center p-4 rd-lg"
        >
          <div v-if="claimData?.gold">
            <div>
              <img src="@/assets/img/task-gold.png" class="w-20" />
            </div>
            <span class="c-green fw-bold pt-5">{{ claimData.gold }} {{ $t('COIN') }}</span>
          </div>
          <div v-if="claimData?.diamond">
            <div>
              <img src="@/assets/img/task-diamond.png" class="w-25" />
            </div>
            <span class="c-green fw-bold">{{ claimData.diamond }} {{ $t('DIAMOND') }}</span>
          </div>
          <div v-if="claimData?.experience">
            <div>
              <img src="@/assets/img/task-experience.png" class="w-16" />
            </div>
            <span class="c-green fw-bold">{{ claimData.experience }} EXP</span>
          </div>
        </div>
        <div class="flex justify-center item-center pt-10">
          <AButton class="w-30" @click="showClaimNotify = false">{{ $t('game.CONFIRM') }}</AButton>
        </div>
      </div>
    </ADialog>
  </div>
</template>
<script setup lang="tsx">
import { toast } from '@/components/ui/AToast.vue';
import { discordUser, tgUser, landscape, taskRedDot, showLoading } from '@/stores';
import type { PlatformTask, PlatformTaskGroup, TaskClaimPrize } from '@/types';
import { authAPI } from '@/utils/network';
import { checkConnectTaskFinish } from '@/utils/task';
import { checkSubBot } from '@/utils/telegram';
import { onMounted, ref } from 'vue';

const diamondData = ref<PlatformTask[]>();
const showClaimNotify = ref(false);
const claimData = ref<TaskClaimPrize>();
showLoading.value = true;

const getDiamondTaskList = async () => {
  authAPI
    .get<PlatformTaskGroup[]>('v1/task/list', { taskTime: 'gold' })
    .then((v) => {
      v.forEach((group) => {
        group.list.forEach((task) => {
          if (task.taskKey === 'CONNECT_TG' && tgUser.id && !task.isFinish) {
            checkConnectTaskFinish('TG', tgUser.id + '').then(() => setTimeout(() => getDiamondTaskList(), 2000));
          } else if (task.taskKey === 'CONNECT_DISCORD' && discordUser.id && !task.isFinish) {
            checkConnectTaskFinish('DISCORD', discordUser.id).then(() => setTimeout(() => getDiamondTaskList(), 2000));
          } else if (task.taskKey === 'START_TG_BOT' && !task.isFinish) {
            if (tgUser.id) {
              checkSubBot().then((v) => {
                if (v) {
                  checkConnectTaskFinish('START_TG_BOT', tgUser.id + '').then(() =>
                    setTimeout(() => getDiamondTaskList(), 2000),
                  );
                }
              });
            } else {
              task.taskUrl = '';
            }
          }
        });
        diamondData.value = group.list;
      });
      showLoading.value = false;
    })
    .catch((e: any) => {
      toast.error(e.msg);
      showLoading.value = false;
    });
};

const claimDiamondCallback = async (v: any) => {
  claimData.value = v;
  showClaimNotify.value = true;
  taskRedDot.value.diamondTask = taskRedDot.value.diamondTask - 1;
  await getDiamondTaskList();
};

onMounted(() => {
  getDiamondTaskList();
});
</script>
