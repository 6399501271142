<template>
  <div class="py-4 text-lg">
    <h3>{{ $t('mtt.TOURNAMENT_SETTINGS') }}</h3>
    <div class="c-gray-400 mb-4">{{ $t('mtt.CHANGE_SETTINGS_BEFORE_PRETABLE') }}</div>
    <DPMttForm :name="name" :form="form" @submit="create" />
  </div>
</template>
<script setup lang="ts">
import router from '@/router';
import { user } from '@/stores';
import { authAPI } from '@/utils/network';
import { ref } from 'vue';

const props = defineProps<{ id?: string; name?: string }>();

const form = ref();

if (props.id)
  authAPI.get<any>('v1/mtt/show_details', { mttID: props.id }).then(async (v) => {
    if (!v.isHost && !user.value?.permissions.includes('modify_mtt_setting')) return;
    v.startTime = Math.ceil((Date.now() + 3.6e6) / 3.6e6) * 3600;
    v.registrationStartTime = Math.ceil(Date.now() / 3.6e6) * 3600;
    v.prizeClaimDeadline = Math.ceil((Date.now() + 3.6e6) / 3.6e6) * 3600 + 24 * 3600 * 2;
    const blind = await authAPI.get<{ smallBlind: number; bigBlind: number; ante: number }[]>(
      'v1/mtt/blinds_structure',
      { mttID: props.id! },
    );
    form.value = { ...v, blindStructureJson: blind };
  });

const create = (form: any) => {
  const postData = { ...form };
  if (props.id) postData.refID = props.id;
  authAPI.post<{ mttID: string }>('v1/mtt/create', postData).then((v) => router.replace(`/m/${v.mttID}`));
};
</script>
