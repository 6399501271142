export const LAYOUT: { [key: string]: number[][] } = {
  '9': [
    [0, 16, 0],
    [-23, 13, 1],
    [-30, 0, 2],
    [-26, -12, 2],
    [-12, -17, 3],
    [12, -17, 3],
    [26, -12, 2],
    [30, 0, 2],
    [23, 13, 1],
  ],
  '6': [
    [0, 16, 0],
    [-25, 7, 2],
    [-25, -8, 2],
    [0, -17, 3],
    [25, -8, 2],
    [25, 7, 2],
  ],
  '2': [
    [0, 16, 0],
    [0, -17, 3],
  ],
};

export const PLAYOUT: { [key: string]: number[][] } = {
  '9': [
    [-7, 22, 0],
    [-12, 11, 2],
    [-12, -3.5, 2],
    [-12, -12, 2],
    [-8.5, -20, 3],
    [8.5, -20, 3],
    [12, -12, 2],
    [12, -3.5, 2],
    [12, 11, 2],
  ],
  '6': [
    [-7, 22, 0],
    [-12, 11, 2],
    [-12, -10, 2],
    [0, -20, 3],
    [12, -10, 2],
    [12, 11, 2],
  ],
  '2': [
    [-7, 22, 0],
    [0, -20, 3],
  ],
};

import web3EventImg from '@/assets/img/events/web3.png';
export const EVENT_SLIDES = [
  {
    image: web3EventImg,
    text: '',
    links: 'https://app.lepoker.io/?login=web3',
  },
];

export const SOCIAL_TYPE: { [key: string]: string } = {
  TG: 'TG',
  DISCORD: 'DISCORD',
  X: 'X',
  GOOGLE: 'GOOGLE',
  FB: 'FB',
  ETH: 'WALLET_EVM#ETH',
  BNB: 'WALLET_EVM#BSC',
  POLYGAN: 'WALLET_EVM#POL',
  ZKSYNC: 'WALLET_EVM#ZKS',
  SOL: 'WALLET_SOL#SOL',
  TON: 'WALLET_TON#TON',
};

export const giftParams = {
  ['BOMB' as string]: {
    img: new URL('@/assets/img/gift/BOMB.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/BOMB-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/BOMB-l.pag', import.meta.url).href,
    duration: [500, 500],
  },
  BLUFF: {
    img: new URL('@/assets/img/gift/BLUFF.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/BLUFF-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/BLUFF-l.pag', import.meta.url).href,
    duration: [400, 300],
  },
  KISS: {
    img: new URL('@/assets/img/gift/KISS.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/KISS-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/KISS-l.pag', import.meta.url).href,
    duration: [666, 500],
  },
  TOMATO: {
    img: new URL('@/assets/img/gift/TOMATO.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/TOMATO-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/TOMATO-l.pag', import.meta.url).href,
    duration: [333, 666],
  },
  CHEERS: {
    img: new URL('@/assets/img/gift/CHEERS.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/CHEERS-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/CHEERS-l.pag', import.meta.url).href,
    duration: [666, 666],
  },
  LIKE: {
    img: new URL('@/assets/img/gift/LIKE.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/LIKE-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/LIKE-l.pag', import.meta.url).href,
    duration: [300, 300],
  },
  SHARK: {
    img: new URL('@/assets/img/gift/SHARK.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/SHARK-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/SHARK-l.pag', import.meta.url).href,
    duration: [666, 666],
  },
  FISH: {
    img: new URL('@/assets/img/gift/FISH.png', import.meta.url).href,
    small: new URL('@/assets/pag/gift/FISH-s.pag', import.meta.url).href,
    big: new URL('@/assets/pag/gift/FISH-l.pag', import.meta.url).href,
    duration: [666, 666],
  },
};

export const handRanking = [
  { name: 'ROYAL_FLUSH', cards: ['As', 'Ks', 'Qs', 'Js', 'Ts'] },
  { name: 'STRAIGHT_FLUSH', cards: ['Qh', 'Jh', 'Th', '9h', '8h'] },
  { name: 'FOUR_OF_A_KIND', cards: ['', 'Ad', 'Ah', 'Ac', 'As'] },
  { name: 'FULL_HOUSE', cards: ['Ts', 'Tc', 'Kc', 'Kd', 'Kh'] },
  { name: 'FLUSH', cards: ['Ah', 'Kh', '8h', '4h', '2h'] },
  { name: 'STRAIGHT', cards: ['Td', '9s', '8h', '7c', '6h'] },
  { name: 'THREE_OF_A_KIND', cards: ['', '', '7h', '7d', '7c'] },
  { name: 'TWO_PAIR', cards: ['', 'Kc', 'Ks', 'Qd', 'Qh'] },
  { name: 'ONE_PAIR', cards: ['', '', '', 'Ts', 'Th'] },
  { name: 'HIGH_CARD', cards: ['', '', '', '', 'As'] },
];
