<template>
  <div v-if="$slots.default || limit" class="flex mb-.3em items-end">
    <slot></slot>
    <div v-if="limit" class="c-white text-0.8em ml-a">
      {{ length }}/<span class="c-gray">{{ limit }}</span>
    </div>
  </div>
  <input
    v-if="!rows"
    ref="input"
    v-bind="$attrs"
    class="w-full p-.5em text-1em rd-lg bg-gray-700 b-solid b-gray-600 c-white outline-none disabled:op-50 focus-within:b-green"
    :type="inpuType"
    :value="value"
    @input="onInput"
    @change="onChange"
    @keyup.enter="emits('enter')"
  />
  <textarea
    v-else
    ref="input"
    v-bind="$attrs"
    class="w-full p-.4em text-1em rd-lg bg-gray-700 b-solid b-gray-600 c-white outline-none disabled:op-50 focus-within:b-green"
    :value="value"
    :rows="rows"
    @input="onInput"
    @change="onChange"
    @keyup.enter="emits('enter')"
    resize="none"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const emits = defineEmits<{ enter: [] }>();
const value = defineModel<string | number>();
const props = withDefaults(
  defineProps<{
    type?: string;
    rows?: number;
    limit?: number;
    icon?: string;
  }>(),
  {
    type: 'text',
  },
);

const inpuType = computed(() => (props.type === 'num' ? 'tel' : props.type));

const length = computed(() => {
  return typeof value.value === 'string' ? value.value.length : 0;
});

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (props.type === 'num') target.value = target.value.replace(/[^\d]/g, '');
  if (props.limit) target.value = target.value.slice(0, props.limit);
  value.value = typeof value.value === 'number' ? +target.value : target.value.trim();
};

// limit value in a range
const onChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (props.type === 'num') {
    const min = target.min ? +target.min : 0;
    const max = target.max ? +target.max : Infinity;
    const val = +target.value;
    if (val < min) target.value = min + '';
    else if (val > max) target.value = max + '';
  }
  value.value = typeof value.value === 'number' ? +target.value : target.value.trim();
};
const input = ref();
defineExpose({ input });
</script>
