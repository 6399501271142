<template>
  <swiper
    v-if="banners.length"
    :centeredSlides="true"
    :loop="true"
    :autoplay="{ delay: 5e3 }"
    :navigation="{ nextEl: '.swiper-pre', prevEl: '.swiper-next', enabled: true }"
    :modules="modules"
  >
    <swiper-slide v-for="(v, i) in banners" :key="i" class="w-full">
      <img
        class="w-full aspect-3.4 object-cover rd-lg cursor-pointer"
        landscape="aspect-5"
        :src="v.img"
        alt=""
        @click="$router.push(v.url)"
      />
    </swiper-slide>
    <div
      class="flex h-0 items-center justify-between absolute top-50% w-full z-1 px-4"
      un-children="rd bg-black py-4 text-xl"
      portrait="hidden"
    >
      <div class="swiper-next">
        <div class="i-local-arrow-l" />
      </div>
      <div class="swiper-pre">
        <div class="i-local-arrow-d -rotate-90" />
      </div>
    </div>
  </swiper>
</template>
<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import 'swiper/css/navigation';

import { Autoplay, Navigation } from 'swiper/modules';
const modules = [Autoplay, Navigation];

import { blogFetch } from '@/utils/network';
import qs from 'qs';
import { ref } from 'vue';

const props = defineProps<{ tag: string }>();
const banners = ref<any[]>([]);

blogFetch
  .get(
    `banners?${qs.stringify({
      fields: ['url'],
      sort: 'weight:desc',
      populate: { web_img: { fields: ['url'] } },
      filters: { banner_labels: { label: { $eq: props.tag } } },
    })}`,
  )
  .json<{ data: any[] }>()
  .then(({ data }) => {
    const raw = data.map((v) => ({
      url: v.attributes.url,
      img: v.attributes.web_img.data.attributes.url,
    }));
    if (raw.length === 2 || raw.length === 3) banners.value = [...raw, ...raw];
    else banners.value = raw;
  });
</script>
