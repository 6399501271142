<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { sendCode, login, loginSocialV2, checkAccountStatus } from '@/utils/user';
import { connectX } from '@/utils/twitter';
// import { connectGoogle } from '@/utils/google';
import { connectDiscord } from '@/utils/discord';
import { cutDown } from '@/utils/timer';
import { okxLogin } from '@/utils/okx';
import { showBindAccount, linkType, linkUserId, linkUserName, linkAvatar, socialToken, platform } from '@/stores';
import { botAPI } from '@/utils/network';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
import { tonLogin } from '@/utils/ton';
import { connectDebox } from '@/utils/debox';
import { metamaskLogin } from '@/utils/metamask';
import { toast } from './ui/AToast.vue';

const { t } = useI18n();

const emits = defineEmits<{ success: []; updateHeader: [string] }>();

const page = ref(0);
const checkPrivate = ref(true);

const email = ref('');
const otp = ref('');
const cd = ref(cutDown.value.otp?.value || 0);
if (cd.value > 0) cutDown.set('otp', cd);

const requestOTP = async () => {
  try {
    await sendCode(email.value);
    cd.value = 60;
    cutDown.set('otp', cd);
    page.value = 1;
  } catch (e) {
    cd.value = 0;
    toast.error(t('GET_OTP_ERROR'));
  }
};

onMounted(async () => {
  // 登录账号需要清除绑定信息
  linkType.value = '';
  linkUserId.value = '';
  linkUserName.value = '';
  linkAvatar.value = '';
});

const isEmail = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.value,
  );
});

async function requestLogin() {
  try {
    await login(email.value, otp.value);
    emits('success');
  } catch (e: any) {
    toast.error(t(convertToLangeLabel(e.msg ? e.msg : e)));
  }
}

const botName = import.meta.env.VITE_TG_BOT;

const loginWithTelegram = async (userLoginUser: any) => {
  let userName = '';
  userName = userLoginUser['username'];
  const firstName = userLoginUser['first_name'];
  const lastName = userLoginUser['last_name'];
  if (!userName && firstName) {
    userName = firstName + userLoginUser['id'];
  } else if (!userName && lastName) {
    userName = lastName + userLoginUser['id'];
  }
  userLoginUser['authType'] = 'login';
  try {
    const { token } = await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
    socialToken.value = token;
    if (await checkAccountStatus(token)) {
      await loginSocialV2(token);
    } else {
      linkType.value = 'TG';
      linkUserId.value = userLoginUser['id'];
      linkUserName.value = userName;
      linkAvatar.value = '';
      showBindAccount.value = 1;
    }
    emits('success');
  } catch (e: any) {
    emits('success');
  }
};

const loginDebox = async () => {
  await connectDebox();
};

const loginDiscord = async () => {
  await connectDiscord();
};

let clickLoginX = false;
const loginX = async () => {
  if (!clickLoginX) {
    clickLoginX = true;
    await connectX();
  }
};

// let clickLoginGoogle = false;
// const loginGoogle = async () => {
//   if (!clickLoginGoogle) {
//     clickLoginGoogle = true;
//     await connectGoogle();
//   }
// };

const chain = ref('');
const wallet = ref('');
const showWeb3 = ref(false);

const loginWeb3 = async () => {
  try {
    if (wallet.value === 'okx') await okxLogin(chain.value);
    if (wallet.value === 'metamask') await metamaskLogin(chain.value);
    if (wallet.value === 'tonkeeper') await tonLogin();
    emits('success');
  } catch (msg: any) {
    toast.error(t(convertToLangeLabel(msg)));
  }
};

const chooseChain = (v: string) => {
  if (chain.value === v) chain.value = '';
  else {
    chain.value = v;
    if (wallet.value) loginWeb3();
  }
};
const chooseWallet = (v: string) => {
  if (wallet.value === v) wallet.value = '';
  else {
    wallet.value = v;
    if (chain.value) loginWeb3();
  }
};

const url = new URL(location.href);
if (url.searchParams.get('login') === 'web3') {
  showWeb3.value = true;
  url.searchParams.delete('login');
  setTimeout(() => history.replaceState(history.state, '', url.href), 0);
}

// const loginByTon = () => {
//   loginTon();
// };
</script>
<template>
  <template v-if="page === 0">
    <div class="text-lg mb-2">{{ $t('EMAIL') }}</div>
    <AInput class="text-lg" v-model="email" name="email" autocomplete="email" placeholder="Email" />
    <small class="p-error">{{ isEmail || !email ? '&nbsp;' : $t('PLEASE_ENTER_A_VALID_EMAIL') }}</small>
    <AButton class="w-full" :disabled="!isEmail || !!cd || !checkPrivate" :action="requestOTP">
      {{ cd ? $t('X_SEC', { v: cd.toFixed(0) }) : $t('GET_OTP') }}
    </AButton>
    <div class="flex items-center c-gray-500 mt-6">
      <span class="h-.3 bg-current flex-1 mr-1" />{{ $t('OR') }}<span class="ml-1 h-.3 bg-current flex-1" />
    </div>
    <template v-if="platform !== 'playdeck'">
      <div class="text-lg mb-4">{{ $t('CONNECT_WITH') }}</div>
      <div class="grid grid-cols-4 h-11 gap-5 mb-4">
        <ButtonLogin
          class="h-full w-full rd-lg bg-blue-400 p-2"
          :botUsername="botName"
          mode="callback"
          requestAccess="write"
          @callback="loginWithTelegram"
        >
          <div class="i-local-telegram size-7 m-auto" />
        </ButtonLogin>
        <div class="rd-lg p-1.5 bg-indigo-500 cursor-pointer" @click="loginDiscord">
          <div class="i-local-discord size-full m-auto" />
        </div>
        <div class="rd-lg bg-white p-2 cursor-pointer" @click="loginX">
          <div class="i-local-twitter c-black size-full m-auto" />
        </div>
        <!-- <div v-if="platform != 'playdeck'" class="rd-full bg-white w-10 h-10 cursor-pointer" @click="loginGoogle">
        <div class="i-local-google w-10 h-10 c-black" />
      </div> -->
        <div class="rd-lg bg-green p-1.5 cursor-pointer" @click="loginDebox">
          <div class="i-local-debox c-white size-full m-auto" />
        </div>
      </div>
    </template>
    <AButton class="w-full !block" type="secondary" @click="showWeb3 = true">
      <div class="flex">
        <div class="mr-auto">{{ $t('CONNECT_WITH_WALLET') }}</div>
        <div class="bg-black rd-full p-1 size-6"><div class="i-local-tonkeeper size-full" /></div>
        <div class="-ml-1 p-1 size-6 rd-full bg-black"><div class="i-local-okx-wallet size-full" /></div>
        <div class="-ml-1 i-local-metamask size-6" />
      </div>
    </AButton>
  </template>
  <template v-if="page === 1">
    <div class="cursor-pointer flex mb-2 c-gray items-center w-max" @click="page = 0">
      <span class="i-local-arrow-l mr-1" /> {{ $t('BACK') }}
    </div>
    <div class="text-lg mb-2">{{ $t('ENTER_OTP') }}</div>
    <AInput class="text-lg" name="one-time-code" autocomplete="one-time-code" v-model="otp" :placeholder="$t('OTP')" />
    <AButton class="mt-6 w-full" :disabled="!otp || !checkPrivate" :action="requestLogin">{{
      $t('LOGIN_REGISTER')
    }}</AButton>
  </template>
  <ADialog title="Connect Wallet" v-model="showWeb3" class="w-110">
    <div class="mb-4 text-lg">{{ $t('CHOOSE_NETWORK') }}</div>
    <div class="grid grid-cols-4 gap-4 text-center mb-8" un-children="flex flex-col items-center relative">
      <div
        :class="wallet && wallet !== 'tonkeeper' ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseChain('ton')"
      >
        <img class="size-12 mb-2" src="@/assets/img/ton.svg" />
        Ton
        <div v-if="chain === 'ton'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div :class="wallet === 'tonkeeper' ? 'op-50 pointer-events-none' : 'cursor-pointer'" @click="chooseChain('eth')">
        <div class="size-12 i-local-eth mb-2" />
        ETH
        <div v-if="chain === 'eth'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div :class="wallet === 'tonkeeper' ? 'op-50 pointer-events-none' : 'cursor-pointer'" @click="chooseChain('bsc')">
        <img class="size-12 mb-2" src="@/assets/img/bnb.svg" />
        BNB Chain
        <div v-if="chain === 'bsc'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div
        :class="['tonkeeper', 'metamask'].includes(wallet) ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseChain('sol')"
      >
        <div class="i-local-sol size-12 mb-2" />
        Solana
        <div v-if="chain === 'sol'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div
        :class="wallet === 'tonkeeper' ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseChain('zksync')"
      >
        <div class="i-local-zks size-12 mb-2" />
        ZkSync
        <div v-if="chain === 'zksync'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div
        :class="wallet === 'tonkeeper' ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseChain('linea')"
      >
        <div class="i-local-linea size-12 mb-2" />
        Linea
        <div v-if="chain === 'linea'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div class="c-gray lh-none">
        <div class="size-12 p-2 mb-2 bg-gray-700 rd-full">
          <div class="i-local-web3chain size-full"></div>
        </div>
        {{ $t('COMING_SOON') }}
      </div>
    </div>
    <div class="text-lg mb-4">{{ $t('CHOOSE_WALLET') }}</div>
    <div class="grid grid-cols-4 gap-4 text-center mb-4" un-children="flex flex-col items-center relative">
      <div
        :class="chain && chain !== 'ton' ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseWallet('tonkeeper')"
      >
        <div class="bg-black rd-full p-2 size-12 mb-2"><div class="i-local-tonkeeper size-full" /></div>
        TonKeeper
        <div v-if="wallet === 'tonkeeper'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div :class="chain === 'ton' ? 'op-50 pointer-events-none' : 'cursor-pointer'" @click="chooseWallet('okx')">
        <div class="mb-2 p-3 size-12 rd-full bg-black"><div class="i-local-okx-wallet size-full" /></div>
        OKX Wallet
        <div v-if="wallet === 'okx'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div
        :class="['ton', 'sol'].includes(chain) ? 'op-50 pointer-events-none' : 'cursor-pointer'"
        @click="chooseWallet('metamask')"
      >
        <div class="i-local-metamask text-4xl mr-2 mb-2 h-12 w-12" />
        MetaMask
        <div v-if="wallet === 'metamask'" class="absolute top-7 right-4 b-solid b-gray-900 p-.5 rd-full bg-green">
          <div class="i-local-check size-3" />
        </div>
      </div>
      <div class="c-gray lh-none">
        <div class="mb-2 p-2 size-12 rd-full bg-gray-700"><div class="i-local-task-web3 size-full" /></div>
        {{ $t('COMING_SOON') }}
      </div>
    </div>
  </ADialog>
  <ACheckBox v-model="checkPrivate" class="mt-6 c-green flex items-center">
    <div class="c-gray-300 ml-2 [&>a]:fw-bold [&>a]:c-gray" v-html="$t('LOGIN_TIPS')"></div>
  </ACheckBox>
</template>
