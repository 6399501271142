<template>
  <div class="of-hidden">
    <div ref="record" class="relative mb--26 bg-dark-800 min-h-full">
      <header class="text-center p-2 bg-dark-800">
        <h5 class="px-12 flex flex-row justify-center">
          <div class="truncate">
            {{
              data.gameType === 'sng'
                ? `LePoker Ladder Game (starts at ${dt(+data.roomName * 1e3).strDateTimeShort})`
                : data.roomName
            }}
          </div>
          <div class="text-nowrap">{{ data.gameType === 'private' ? $t('ROOM') : '' }}</div>
        </h5>
        <p class="op-60 text-sm">
          NLH-{{ data.smallBlind }}/{{ data.bigBlind }} {{ $t('game.HAND_NO', { v: data.blindHands || data.seq }) }}
        </p>
      </header>
      <div class="mini-table h-84 pt-25 relative">
        <DPTableCards
          class="text-sm"
          :twice="runTwiceCards"
          :cards="cards"
          :winners="[]"
          :rabbitCards="data.rabbitHuntingCards"
          simple
        />
        <div class="absolute inset-1/2 flex items-center justify-center scale-85 z-3">
          <DPMiniSeat
            v-for="p in data.players"
            :key="p.position"
            :style="{
              '--un-translate-x': offsets[p.pos!][0] / 2 + 'rem',
              '--un-translate-y': offsets[p.pos!][1] / 2 + 'rem',
            }"
            :offset="offsets[p.pos!]"
            :name="p.playerName"
            :stack="p.endStack"
            :cards="p.cards"
            :win="p.win"
            :hand="getHandShape(p.cards)"
            :dealer="p.position === data.dealer"
            :id="p.userID"
          />
        </div>
      </div>
      <div v-if="vdfData" class="p-5">
        <AButton
          class="w-full"
          @click="openValidationPage(data.gameType, data.roomID, data.seq)"
          :disabled="vdfData.status && vdfData.status !== 5"
          :icon="vdfData.status && vdfData.status !== 5 ? 'i-local-loading' : ''"
        >
          <template v-if="vdfData.status === 1">VDF proof submitted</template>
          <template v-else-if="vdfData.status === 2">VDF proof confirmed</template>
          <template v-else-if="vdfData.status === 3">VDF verification submitted</template>
          <template v-else-if="vdfData.status === 4">VDF verification confirmed</template>
          <template v-else>Validation</template>
        </AButton>
      </div>
      <div v-for="(round, index) in data.rounds" :key="index">
        <div class="flex justify-between items-center px-4 py-2 h-14">
          <h5 class="w-24 case-capital c-white">{{ $t(convertToLangeLabel(round.street)) }}</h5>
          <div class="flex flex-1 text-xs space-x-1">
            <DPCard v-for="(c, i) in round.cards" :key="i" mini :name="c" />
          </div>
          <div class="flex flex-col justify-center items-end">
            <div class="flex items-center">
              <img class="w-4 h-4 mr-1" src="@/assets/img/chip.png" alt="" />
              <h5 class="c-yellow">{{ round.roundPot }}</h5>
            </div>
            <p class="text-xs c-gray-300" v-if="round.street === 'ante'">
              {{ $t('game.EVERYONE') }} <span class="c-yellow">{{ round.roundPot / data.players.length }}</span>
            </p>
          </div>
        </div>
        <div
          v-for="(act, index) in round.actions"
          :key="index"
          class="flex justify-between px-4 py-2 items-center c-white fw-bold bg-gray-900"
        >
          <template v-if="act.action === 'runItTwice'">
            <div class="flex -space-x-4">
              <DPAvatar
                v-for="(p, i) in data.players.filter((v) => act.runTwicePostions.includes(v.position))"
                :key="i"
                class="flex-shrink-0 text-xl"
                :name="p.playerName"
                :id="p.userID"
              />
            </div>
            <p class="flex-1 text-center">{{ $t('game.RUN_IT_TWICE') }}</p>
          </template>
          <template v-else-if="act.action === 'runTwiceWin'">
            <div class="flex basis-2/5">
              <DPAvatar
                class="flex-shrink-0 text-xl"
                :name="data.players.find((v) => v.position === act.position)?.playerName!"
                :id="data.players.find((v) => v.position === act.position)?.userID"
              />
              <div class="ml-2 w-24">
                <h6 class="truncate">{{ data.players.find((v) => v.position === act.position)?.playerName }}</h6>
                <p class="text-xs case-upper c-green" :class="mapPositionColor(act.positionName)">
                  {{ act.positionName }}
                </p>
              </div>
            </div>
            <div v-if="act.cards" class="relative m-y--1 text-xs flex space-x-1 flex-1">
              <DPCard
                v-for="(c, i) in getCardsShape(act.cards, act.runTwiceIndex)"
                :key="i"
                :disabled="c.disable"
                :name="c.v"
                mini
              />
            </div>
          </template>
          <template v-else>
            <div class="flex basis-2/5">
              <DPAvatar
                class="flex-shrink-0 text-xl"
                :name="data.players.find((v) => v.position === act.position)?.playerName!"
                :id="data.players.find((v) => v.position === act.position)?.userID"
              />
              <div class="ml-2 w-24">
                <h6 class="truncate">{{ data.players.find((v) => v.position === act.position)?.playerName }}</h6>
                <p class="text-xs case-upper c-green" :class="mapPositionColor(act.positionName)">
                  {{ act.positionName }}
                </p>
              </div>
            </div>
            <div v-if="act.cards" class="relative m-y--1">
              <div class="flex space-x-1 text-xs">
                <DPCard v-for="(c, i) in act.cards.filter((v) => v)" :key="i" :name="c" mini />
              </div>
              <div v-if="act.isWinningRate" class="absolute text-xs text-center rd left-0 w-full top-7 bg-green">
                {{ act.winningRate }}%
              </div>
            </div>
            <div
              v-else-if="act.action === 'insuranceDetermination'"
              class="rd p-1 w-18 text-xs"
              :class="act.confirmBuyInsurance ? 'bg-green' : 'bg-red'"
            >
              {{ act.confirmBuyInsurance ? $t('INSURANCE_ACCEPTED') : $t('INSURANCE_DECLINED') }}
            </div>
            <h6
              v-else
              class="rd bg-gray-700 p-1 w-18 text-center"
              :class="{
                'c-gray-400': act.action.toLowerCase() === 'fold',
                'c-red': act.action.toLowerCase() === 'allin',
                'c-green': ['raise', 'bet'].includes(act.action.toLowerCase()),
                'c-yellow': act.action.toLowerCase() === 'win',
                'c-white': !['fold', 'allin', 'win', 'raise', 'bet', 'insuranced'].includes(act.action.toLowerCase()),
              }"
            >
              {{ $t(convertToLangeLabel('ACT_' + act.action)) }}
            </h6>
            <p class="flex-1 text-right basis-1/5">
              {{ ['fold', 'check'].includes(act.action) ? '' : act.actionAmount }}
            </p>
            <p class="flex-1 text-right basis-1/5">{{ act.latestAmount }}</p>
          </template>
        </div>
      </div>
      <footer class="p-4 flex row justify-start">
        <div class="flex-1">
          <h4 class="c-white">LePoker.io</h4>
          <p class="text-xs c-gray-300">
            {{ $t('game.QR_DESC') }}
          </p>
        </div>
        <img class="w-20 h-20" src="@/assets/img/qrcode.png" alt="qrcode" />
      </footer>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RoundRecord } from '@/types';
import { computed, ref, watch } from 'vue';
import { mapPositionColor } from '@/utils/general';
import { LAYOUT } from '@/utils/constant';
import { convertToLangeLabel } from '@/utils/strings';
import { domToBlob } from 'modern-screenshot';
import { dt } from '@/utils/datetime';
import { getHand } from '@/utils/value';
import { shareBlob } from '@/utils/device';
import router from '@/router';

const props = defineProps<{ data: RoundRecord }>();
const cards = computed(() => props.data.runTwiceCards || props.data.rounds.filter((v) => v.cards).at(-1)?.cards || []);
const runTwiceCards = computed(() => (props.data.runTwiceCards ? props.data.normalCards : []));
function getHandShape(cs?: string[]) {
  if (!cs || props.data.runTwiceCards) return;
  return getHand(cs.filter((v) => v).concat(cards.value)).name;
}
const getCardsShape = (cs: string[], idx: 0 | 1) =>
  getHand(cs.filter((v) => v).concat(...(idx ? props.data.runTwiceCards! : props.data.normalCards!))).cards.map(
    (v) => ({ v, disable: !cs.includes(v) }),
  );

const record = ref(null);
const toImage = () => {
  domToBlob(record.value!, {
    scale: Math.min(devicePixelRatio, 2),
    fetch: { requestInit: { cache: 'no-cache' } },
    filter: (v) => !(v as any).dataset?.ignore,
    type: 'image/png',
  }).then((blob) => {
    if (blob) shareBlob(`Lepoker-${props.data.roomName}-${Date.now()}.png`, blob);
  });
};

const offsets = LAYOUT[props.data ? props.data.playerNums : 9];
defineExpose({ toImage });

const openValidationPage = (type: string, room: string, seq: number) => {
  window.open(`${window.location.origin}/validation/${room}/${seq}`, '_blank');
};

const vdfData = ref(props.data.vdf ? JSON.parse(props.data.vdf) : null);
watch(
  () => props.data,
  async (v) => {
    if (v.vdf) {
      vdfData.value = JSON.parse(v.vdf);
    }
  },
  { immediate: true },
);
</script>
<style>
.mini-table {
  background:
    url('@/assets/img/table-border.svg') center/auto 88% no-repeat,
    url('@/assets/img/table-bg.png') center/ auto 60% no-repeat,
    #182b38;
}
</style>
