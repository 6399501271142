<template>
  <div class="relative" :ref="(v) => (trigger = v)">
    <div v-bind="$attrs" @click="value = !value">
      <slot>
        <AButton>{{ title }}</AButton>
      </slot>
    </div>
    <div
      v-if="value"
      class="absolute flex"
      :class="{
        'w-full': direction === 'col',
        'h-full': direction === 'row',
        'bottom-100%': direction === 'col' && reverse,
        'top-100%': direction === 'col' && !reverse,
        'right-100% top-0': direction === 'row' && reverse,
        'left-100% top-0': direction === 'row' && !reverse,
        'flex-col': direction === 'col' && !reverse,
        'flex-row': direction === 'row' && !reverse,
        'flex-col-reverse': direction === 'col' && reverse,
        'flex-row-reverse': direction === 'row' && reverse,
      }"
    >
      <div class="shrink-0">
        <slot name="decorate">
          <div class="w-.25em h-.25em" />
        </slot>
      </div>
      <div
        class="z-1"
        :class="{
          'self-start': align === 'start',
          'self-center': align === 'center',
          'self-end': align === 'end',
          'min-w-full': direction === 'col',
        }"
      >
        <slot name="pad"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

defineOptions({ inheritAttrs: false });

const trigger = ref();

const value = defineModel<boolean>();

onClickOutside(trigger, () => (value.value = false));

withDefaults(
  defineProps<{
    title?: string;
    direction?: 'col' | 'row';
    reverse?: boolean;
    align?: 'start' | 'center' | 'end';
  }>(),
  { direction: 'col', align: 'start' },
);
</script>
