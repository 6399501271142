import { user } from '@/stores';

const log = (payload: Record<string, string | number | boolean>) => {
  const data = { location: location.pathname, uid: user.value?.id || '', ...payload };
  if (import.meta.env.MODE === 'prod')
    fetch(
      'https://ap-guangzhou.cls.tencentcs.com/track?topic_id=d51e7927-30ef-4bd1-a697-d32335c5afa8&' +
        Object.entries(data)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join('&'),
    );
  else console.log(data);
};

export default {
  log,
  click: (v: string) => log({ action: v, event: 'click' }),
  debug: (v: string, payload?: Record<string, string | number | boolean>) =>
    log({ action: v, event: 'debug', ...payload }),
  error: (v: string, payload?: Record<string, string | number | boolean>) =>
    log({ action: v, event: 'error', ...payload }),
};
