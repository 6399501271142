<template>
  <Teleport to="body">
    <div
      v-if="show"
      class="fixed inset-0 flex justify-center bg-black/80"
      :class="{ 'items-center': !full }"
      :style="{ 'z-index': zIdx ?? 20 }"
      @click.self="close"
    >
      <div
        v-bind="$attrs"
        class="relative flex flex-col bg-gray-900 c-gray-50 bg-center bg-no-repeat bg-cover"
        :class="{
          'w-full': full,
          'p-4': !titleBg,
          'rd-lg b-gray-700  max-h-90%': !full,
          'min-w-130': fullscreen && landscape,
          'min-w-90 ': !fullscreen,
          'bg-transparent': transparent,
          'b-solid': !full && !transparent,
        }"
        :style="{
          backgroundImage: fullBg ? `url(${fullBg})` : '',
        }"
      >
        <img v-if="titleBg" :src="titleBg" class="w-full" />
        <div
          v-if="!unclosable && !transparent"
          class="absolute z-1 top-3 right-3 i-local-close text-2xl op-40 cursor-pointer"
          @click="show = false"
        />
        <slot name="header">
          <h3 v-if="title" class="mb-4 text-center">{{ title }}</h3>
        </slot>
        <div class="flex-1 of-auto siblings:mt-4">
          <slot></slot>
        </div>
        <slot name="footer" />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { landscape } from '@/stores';
import { computed, ref, type Ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  title?: string;
  transparent?: boolean;
  unclosable?: boolean;
  ignoreMask?: boolean;
  fullscreen?: boolean;
  zIdx?: number;
  titleBg?: string;
  fullBg?: string;
}>();

const show = defineModel<boolean>();

const full = computed(() => !landscape.value && props.fullscreen);
const close = () => (show.value = props.unclosable || props.ignoreMask);
</script>
<script lang="ts">
let value: Ref<boolean>[] = [];
let newPage = false;
// router.beforeEach(() => {
//   value = [];
//   newPage = true;
// });

export function useDlgCtrl(v?: Ref<boolean>) {
  if (newPage) {
    newPage = false;
    onBeforeRouteLeave(() => {
      if (value.some((v) => v.value)) {
        value.forEach((v) => (v.value = false));
        return false;
      }
    });
  }
  const res = v || ref(false);
  value.push(res);
  return res;
}
</script>
