<template>
  <div class="relative h-full flex flex-col">
    <div class="flex-1 of-y-scroll">
      <DPRecordItem v-if="records[targetPage]" ref="record" :data="records[targetPage]!" />
      <div v-else class="op-80 text-center flex h-full flex-col justify-center items-center">
        <div class="i-local-history h-32 w-32 mb-4"></div>
        {{ $t('NO_RECORD_FOUND') }}
      </div>
    </div>
    <div class="bg-dark-800 flex flex-col justify-center">
      <p v-if="records" class="text-center mt-1">
        <span class="c-green">{{ records.length === 0 ? 0 : page + 1 }}</span> /
        <span class="c-white">{{ records.length }}</span>
      </p>
      <div class="flex px-2 items-center !children:c-white">
        <Button text class="text-2xl lh-none !p-2 !w-fit op-60" icon="i-mingcute-left-line" @click="minusPage" />
        <Slider class="flex-1 mx-2" :max="records.length - 1" v-model="page" />
        <Button text class="text-2xl lh-none !p-2 !w-fit op-60" icon="i-mingcute-right-line" @click="addPage" />
        <Button text class="text-2xl lh-none !p-2 !w-fit op-60" icon="i-local-share" @click="share" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RoundRecord } from '@/types';
import { ref } from 'vue';
import { authAPI } from '@/utils/network';
import { watchDebounced } from '@vueuse/core';

const props = defineProps<{ id: string; type: string }>();

const page = ref(0);
const targetPage = ref(0);

const records = ref<(RoundRecord | null)[]>([]);
records.value = await authAPI
  .get<{ handHistories: RoundRecord[]; count: number }>('v1/hand_history/query_record', {
    idType: props.type,
    gameID: props.id,
    offset: 0,
    pageSize: 1e5,
  })
  .then((data) => {
    if (data.count) {
      page.value = data.count - 1;
      targetPage.value = data.count - 1;
    }
    return data.handHistories.map((record) => {
      try {
        let cards: string[] = [];
        record.rounds.forEach((r) => {
          if (r.cards) {
            cards = cards.concat(...(r.cards || []));
            r.cards = cards;
          }
          r.actions?.forEach((a) => {
            a.positionName = record.players.find((p) => p.position === a.position)?.positionName;
            if (!a.cards) return;
            const p = record.players.find((p) => p.position === a.position)!;
            if (!p.cards) p.cards = a.cards;
            else
              a.cards.map((v, i) => {
                if (!v) return;
                p.cards![i] = v;
              });
          });
          r.actions = r.actions?.filter((v) => v.actionNumber !== -1 && v.action !== 'InitiativeShowHands');
        });

        record.playerWinChips.forEach((w) => {
          const player = record.players.find((v) => w.position === v.position)!;
          player.win = w.winAmount;
        });

        record.players.forEach((p) => {
          p.pos = (record.playerNums + p.position - record.currentPosition) % record.playerNums;
        });
        return record;
      } catch {
        return null;
      }
    });
  });

const record = ref<any>(null);
function share() {
  if (!records.value[page.value]) return;
  record.value.toImage();
}
const minusPage = () => {
  page.value > 0 && page.value--;
  targetPage.value > 0 && targetPage.value--;
};
const addPage = () => {
  page.value < records.value.length - 1 && page.value++;
  targetPage.value < records.value.length - 1 && targetPage.value++;
};

watchDebounced(page, () => (targetPage.value = page.value), { debounce: 500, maxWait: 1000 });
</script>
