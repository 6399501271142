<template>
  <div class="py-4">
    <div class="flex mb-4" un-children="text-center p-2 w-40 cursor-pointer b-b-solid b-2">
      <h5
        :class="type === 'private' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="updateType('private')"
      >
        {{ $t('PRIVATE_GAME') }}
      </h5>
      <h5
        :class="type === 'mtt' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="updateType('mtt')"
      >
        {{ $t('TOURNAMENT') }}
      </h5>
      <h5
        :class="type === 'sng' ? 'b-green bg-gradient-to-t from-green-900 c-white' : 'c-gray-300 b-transparent'"
        @click="updateType('sng')"
      >
        {{ $t('home.NAV_3') }}
      </h5>
    </div>
    <div v-if="hub" class="grid gap-1 grid-cols-3 text-center my-4" un-children="rd-2 bg-gray-900 of-hidden">
      <div class="h-16">
        <p class="bg-gray-700 c-gray-400">{{ $t('GAME_PLAYED') }}</p>
        <h3 class="c-green p-2">{{ hub.gamePlayed }}</h3>
      </div>
      <div class="h-16">
        <p class="bg-gray-700 c-gray-400">{{ $t('GAME_LEADER') }}</p>
        <h3 class="c-green p-2">{{ hub.gameWin }}</h3>
      </div>
      <div class="h-16">
        <p class="bg-gray-700 c-gray-400">{{ $t('GAME_PROFITABLE') }}</p>
        <h3 class="c-green p-2">{{ hub.gameProfitable }}</h3>
      </div>

      <div class="col-span-3 flex flex-wrap p-2" un-children="basis-1/4 mb-3">
        <div>
          <p class="c-gray-400">{{ $t('HANDS') }}</p>
          <h5 class="c-white pt-1">{{ hub.totalHands }}</h5>
        </div>
        <div>
          <p class="c-gray-400">VPIP</p>
          <h5 class="c-white pt-.5">{{ hub.vpip ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">PFR</p>
          <h5 class="c-white pt-.5">{{ hub.pfr ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">{{ $t('WIN') }} (%)</p>
          <h5 class="c-white pt-.5">{{ hub.win ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">C-BET</p>
          <h5 class="c-white pt-.5">{{ hub.cBet ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">3<span class="text-xs">+</span> BET</p>
          <h5 class="c-white pt-.5">{{ hub.threeBet ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">WTSD</p>
          <h5 class="c-white pt-.5">{{ hub.wtsd ?? '-' }}%</h5>
        </div>
        <div>
          <p class="c-gray-400">W$SD</p>
          <h5 class="c-white pt-.5">{{ hub.wssd ?? '-' }}%</h5>
        </div>
      </div>
    </div>
    <ATitle class="fw-bold">{{ $t('RECENT_GAMES') }}</ATitle>
    <div v-if="data" class="space-y-2.5 pt-4">
      <div
        v-for="(v, i) in data"
        :key="i"
        class="relative rd-lg bg-gray-900 of-hidden flex items-center p-4 cursor-pointer"
        @click="openRecord(v)"
      >
        <DPAvatar class="text-4xl" :avatar="type === 'sng' ? sngLogo : v.tableLogoUrl" :name="v.gameName" />

        <div class="flex-1 ml-4">
          <div class="flex justify-between">
            <h5>
              {{ type === 'sng' ? `LePoker Ladder Game (starts at ${dt(v.beginTime).strDatetime})` : v.gameName }}
              <span class="bg-dark-900 px-1 italic self-start c-yellow ml-3">{{ v.gameType }}</span>
            </h5>
            <div class="i-local-arrow-d rotate-270 c-gray-400" />
          </div>
          <div class="flex justify-between items-end">
            <div>
              <p v-if="v.status">
                <span class="c-gray-400">{{ $t('END_DATE') }}:</span> {{ dt(v.endTime).strDatetime }}
              </p>
              <p v-else>
                <span class="c-gray-400">{{ $t('STATUS') }}:</span> {{ $t('ONGOING') }}
              </p>
              <p>
                <span class="c-gray-400">{{ $t('MY_HAND') }}:</span> {{ $t('HANDS_X', { v: v.hands }) }}
              </p>
            </div>
            <div v-if="v.recentType == 1" class="c-gray-400 mt-4 flex-shrink-0">
              Net:
              <span class="ml-2" :class="v.net! < 0 ? 'c-red' : 'c-green'"> {{ (v.net! < 0 ? '' : '+') + v.net }}</span>
            </div>
            <div v-else-if="v.recentType == 0 || v.recentType == 2" class="mtt-ranking text-right mt-4">
              {{ $t('mtt.RANKING') }}: <span class="fw-700 text-xl c-green ml-1"> {{ v.userRank }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <APaginator class="justify-center mt-4" v-model="page" :size="10" :total="total" />
    <ADrawer v-model="showRecords" class="w-full" landscape="w-120" :position="1">
      <ASuspense v-if="curRecordId">
        <DPRecord :id="curRecordId" :type="curRecordType!" />
      </ASuspense>
    </ADrawer>
  </div>
</template>
<script setup lang="ts">
import { dt } from '@/utils/datetime';
import { authAPI } from '@/utils/network';
import { ref, watch } from 'vue';
import sngLogo from '@/assets/img/sng-logo.png';

type Record = {
  id: number;
  gameID: string;
  gameName: string;
  beginTime: number;
  endTime: number;
  hands: number;
  tableLogoUrl: string;
  userRank: number;
  net?: number;
  gameType: string;
  recentType: number;
  status: number;
};

type Hub = {
  cBet: number;
  gamePlayed?: number;
  gameProfitable?: number;
  gameWin?: number;
  pfr: number;
  threeBet: number;
  totalHands: number;
  vpip: number;
  win: number;
  wssd: number;
  wtsd?: number;
};

const showRecords = ref(false);

const curRecordId = ref<string>();
const curRecordType = ref<string>();

const data = ref<Record[]>([]);
const page = ref(1);
const type = ref('mtt');
const total = ref(0);
const hub = ref<Hub>();

watch(
  [page, type],
  async ([p, t]) => {
    const { recentRecordInfoList, totalSize } = await authAPI.get<{
      recentRecordInfoList: Record[];
      totalSize: number;
    }>('v1/hand_history/queryGameRecent', {
      pageIndex: p,
      pageSize: 10,
      idType: t,
    });
    authAPI.get<Hub>('v1/hand_history/getHud', { idType: t }).then((v) => (hub.value = v));
    // if (t === 'private') hub.value = userHudInfoPO;
    // else if (t === 'sng') authAPI.get<Hub>('v1/hand_history/query_sng_hud').then((v) => (hub.value = v));
    // else authAPI.get<Hub>('v1/hand_history/query_Hud_by_user').then((v) => (hub.value = v));
    data.value = recentRecordInfoList;
    total.value = totalSize;
  },
  { immediate: true },
);

const openRecord = (v: Record) => {
  curRecordId.value = v.gameID;
  curRecordType.value = v.recentType == 0 ? 'mtt' : v.recentType == 1 ? 'private' : 'sng';
  showRecords.value = true;
};

const updateType = (t: string) => {
  type.value = t;
  page.value = 1;
  data.value = [];
  total.value = 0;
  hub.value = undefined;
};
</script>
<style>
.mtt-ranking {
  background: url('@/assets/img/ranking-bg.png') center / 100% 100% no-repeat;
  min-width: 8rem;
  line-height: 2;
}
</style>
