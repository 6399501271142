<template>
  <div class="grid grid-cols-[repeat(auto-fill,9.3rem)] gap-2 justify-between mb-4">
    <div
      v-for="(v, i) in items"
      :key="i"
      class="relative rd-lg bg-gray-700 text-center pt-6 cursor-pointer"
      @click="buy(v)"
    >
      <div
        v-if="v.originalNum !== v.num"
        class="absolute -top-1 -left-1 fw-900 text-stroke-.05rem text-stroke-black size-12 pt-1 text-center bgi-mall-ratio-bg bg-contain bg-no-repeat"
      >
        <div class="lh-none text-2xl">{{ ((100 * v.num) / v.originalNum).toFixed() }}</div>
        <div class="lh-none">%</div>
      </div>
      <img class="h-20 w-full m-auto object-contain" :src="v.imageUrl" alt="" />
      <div class="h-13 fw-extrabold text-lg">
        <template v-if="v.originalNum !== v.num">
          <div class="text-sm td-thick line-through c-green-700">{{ v.originalNum }}</div>
          <div class="c-yellow">{{ v.num }}</div>
        </template>
        <h4 v-else class="c-green pt-2">{{ v.num }}</h4>
      </div>
      <div class="p-1 flex justify-center items-center text-lg bg-gray-600 rd-b-lg">
        <span class="i-local-tg-star mr-1" />
        {{ v.price }}
      </div>
    </div>
  </div>
  <ALoader :key="type" class="mb-4" :loader="loadData" />
  <ADialog class="w-80" v-model="showTGlogin" title=" ">
    <div class="text-center">{{ $t('TG_REQUIRED_TIPS') }}</div>
    <ButtonLogin
      class="w-full mt-8"
      :botUsername="botName"
      :hideUserPic="true"
      mode="callback"
      requestAccess="write"
      @callback="linkTg"
    >
      <AButton class="w-full">{{ $t('CONNECT') }}</AButton>
    </ButtonLogin>
  </ADialog>
</template>
<script lang="ts" setup>
import { toast } from '@/components/ui/AToast.vue';
import { tgUser } from '@/stores';
import { openTab } from '@/utils/device';
import { authAPI } from '@/utils/network';
import { convertToLangeLabel } from '@/utils/strings';
import { loginWithTelegram } from '@/utils/user';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{ type: string }>();
const { t } = useI18n();
const typeMap: Record<string, number> = { diamond: 2, coin: 3 };

const botName = import.meta.env.VITE_TG_BOT;

const showTGlogin = ref(false);

const items = ref<any>([]);
watch(
  () => props.type,
  () => (items.value = []),
);

async function loadData() {
  items.value = (
    await authAPI.get('v1/market/product_info', {
      currency: 1,
      productType: typeMap[props.type],
      channel: 1,
    })
  ).productDetails;
  return 'end';
}

const buy = (v: any) => {
  if (!tgUser.id) return (showTGlogin.value = true);
  authAPI
    .get('v1/market/payment_link', { currency: 1, productId: v.productId })
    .then(({ paymentLink }) => openTab(paymentLink));
};

const linkTg = async (v: any) => {
  showTGlogin.value = false;
  try {
    await loginWithTelegram(v);
  } catch (e: any) {
    toast.error(t(convertToLangeLabel(e)));
  }
};
</script>
