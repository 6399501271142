<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { tgUser } from '@/stores';
import { token, botAPI } from '@/utils/network';
import { socialLink } from '@/utils/user';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
const { t } = useI18n();

const emits = defineEmits<{ regist: [any]; close: [any] }>();

const toast = useToast();

const showTgTips = ref(false);

const botName = import.meta.env.VITE_TG_BOT;

onMounted(() => {
  setTimeout(() => {
    if (!tgUser.id) {
      showTgTips.value = true;
    }
  }, 5000);
  // 20240325要求加上，20240326要求屏蔽
  // checkJoinGroup();
  tgUser.joinGroup = true;
  checkSubBot();
  checkIframeLoaded();
  // checkJoinGroup();
});

const checkIframeLoaded = async () => {
  let iframe = document.getElementById('telegram-login-' + botName);
  if (iframe) {
    iframe.onload = () => {
      console.log('Iframe加载完成');
    };
    console.info('iframe load');
  } else {
    setTimeout(checkIframeLoaded, 3000);
  }
};

const loginWithTelegram = async (userLoginUser: any) => {
  if (token.value) {
    let userName = '';
    userName = userLoginUser['username'];
    const firstName = userLoginUser['first_name'];
    const lastName = userLoginUser['last_name'];
    if (!userName && firstName) {
      userName = firstName + userLoginUser['id'];
    } else if (!userName && lastName) {
      userName = lastName + userLoginUser['id'];
    }
    try {
      const userID = await socialLink(userLoginUser['id'], userName, userLoginUser['photo_url'], 'TG');
      userLoginUser['authType'] = 'browser';
      userLoginUser['lepokerUserId'] = userID;
      try {
        await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
        tgUser.id = userLoginUser['id'];
        tgUser.username = userName;
        checkSubBot();
        checkJoinGroup();
      } catch (e: any) {
        toast.add({ severity: 'error', summary: t('FAIL'), detail: t(convertToLangeLabel(e.msg)), life: 3000 });
      }
    } catch (e: any) {
      toast.add({ severity: 'error', summary: t('FAIL'), detail: t(convertToLangeLabel(e)), life: 3000 });
    }
  }
};

const checkFinish = async () => {
  if (tgUser.subBot && tgUser.joinGroup) {
    emits('regist', true);
    emits('close', null);
  }
};

const checkSubBot = async () => {
  const dataMap = { userId: tgUser.id };
  const status = await botAPI.get<boolean>('tg/checkSubBot', dataMap);
  if (!status) {
    setTimeout(checkSubBot, 3000);
  } else {
    tgUser.subBot = true;
    checkFinish();
  }
};

const checkJoinGroup = async () => {
  const dataMap = { userId: tgUser.id, channelId: '@lepokergame' };
  // const dataMap = { userId: tgUser.id, channelId: '@kintestgroup' };
  const status = await botAPI.get<boolean>('tg/checkJoinOfficeChannel', dataMap);
  if (!status) {
    setTimeout(checkJoinGroup, 3000);
  } else {
    tgUser.joinGroup = true;
    checkFinish();
  }
};

const subBot = async () => {
  if (tgUser.id) {
    window.open('https://t.me/' + botName);
    checkSubBot();
  } else {
    toast.add({ severity: 'info', summary: 'Info', detail: 'Connect telegram first', life: 3000 });
  }
};

const joinGroup = async () => {
  if (tgUser.id) {
    window.open('https://t.me/lepokergame/3');
    // window.open('https://t.me/kintestgroup');
    checkJoinGroup();
  } else {
    toast.add({ severity: 'info', summary: 'Info', detail: 'Connect telegram first', life: 3000 });
  }
};

// function close() {
//   emits('close', null);
// }
const tgLoginBtn = ref();
</script>

<template>
  <div>
    <p class="op-60 mb-4 text-center">{{ $t('task.NOTICE_DESC') }}</p>
    <div class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>1. {{ $t('task.TG_LOGIN') }}</h6>
          <p class="text-xs">{{ $t('task.TG_LOGIN_DESC') }}</p>
        </div>
        <div v-if="tgUser.id" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <p v-if="showTgTips" class="text-xs">{{ $t('TG_BTN_TIPS') }}</p>
      <TgButtonLogin
        v-if="!tgUser.id"
        ref="tgLoginBtn"
        :botUsername="botName"
        mode="callback"
        requestAccess="write"
        @callback="loginWithTelegram"
        size="large"
        radius="5"
        style="display: flex; align-items: center; justify-content: center"
      />
    </div>
    <div class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>2. {{ $t('task.TG_START_BOT') }}</h6>
          <p class="text-xs">{{ $t('task.TG_START_BOT_DESC') }}</p>
        </div>
        <div v-if="tgUser.subBot" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button v-if="!tgUser.subBot" severity="info" class="p-2 w-full justify-center" @click="subBot">
        <TelegramIcon class="w-4 mr-2" />
        <span class="text-sm">{{ $t('task.TG_START_BOT') }}</span>
      </Button>
    </div>
    <!-- <div class="b-t-solid b-gray-600 b-1 p-3 space-y-2">
      <div class="flex">
        <div class="flex-1">
          <h6>3. {{ $t('task.TG_JOIN_GROUP', { v1: 'LePoker' }) }}</h6>
          <p class="text-xs">{{ $t('task.TG_JOIN_GROUP_DESC', { v1: 'LePoker' }) }}</p>
        </div>
        <div v-if="tgUser.joinGroup" class="i-local-completed text-4xl bg-green"></div>
      </div>
      <Button v-if="!tgUser.joinGroup" severity="info" class="p-2 w-full justify-center" @click="joinGroup">
        <TelegramIcon class="w-4 mr-2" />
        <span class="text-sm">{{ $t('task.TG_JOIN_GROUP', { v1: 'LePoker' }) }}</span>
      </Button>
    </div> -->
    <!-- <Button
      v-if="tgUser.subBot && tgUser.joinGroup"
      size="small"
      
      class="w-full mt-4"
      :label="$t('REGISTRATION_SUCCESSFULL')"
      @click="close"
      autofocus
    /> -->
  </div>
</template>
