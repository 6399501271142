import { discordUser } from '@/stores';
import { botAPI } from './network';

export const connectDiscord = async (loginType = '') => {
  const state = encodeURIComponent(location.pathname) + loginType;
  if (import.meta.env.MODE == 'prod') {
    location.href =
      'https://discord.com/api/oauth2/authorize?client_id=1193017159950008351&response_type=code&redirect_uri=https%3A%2F%2Fapp.lepoker.io%2FdiscordAuth&scope=identify+guilds.join+guilds.members.read+guilds&state=' +
      state;
  } else if (import.meta.env.MODE == 'sit') {
    location.href =
      'https://discord.com/api/oauth2/authorize?client_id=1193017159950008351&response_type=code&redirect_uri=https%3A%2F%2Fsit-app.lepoker.io%2FdiscordAuth&scope=identify+guilds.join+guilds.members.read+guilds&state=' +
      state;
  } else if (import.meta.env.MODE == 'newdev') {
    location.href =
      'https://discord.com/api/oauth2/authorize?client_id=1190940105641308240&response_type=code&redirect_uri=https%3A%2F%2Fdev-app.lepoker.io%2FdiscordAuth&scope=identify+guilds.join+guilds.members.read+guilds&state=' +
      state;
  }
};

export const checkDiscordOfficialServer = async () => {
  let dataMap = { userId: discordUser.id, channelId: '1176027324316123196' };
  if (import.meta.env.MODE != 'prod') {
    dataMap = { userId: discordUser.id, channelId: '1191211019410866216' };
  }
  const status = await botAPI.get<boolean>('discord/checkJoinGuild', dataMap);
  return status;
};
