<script setup lang="ts">
import { useRouter } from 'vue-router';
import { botAPI, refreshToken } from '@/utils/network';
import { loginSocialV2, checkAccountStatus } from '@/utils/user';
import { tgUser, socialToken, showBindAccount, linkType, linkUserId, linkUserName, linkAvatar } from '@/stores';
import { onMounted, ref, watch } from 'vue';
import { useWebApp, useWebAppViewport, useWebAppClosingConfirmation } from 'vue-tg';
import { initAccount } from '@/stores/user';

const params = new URLSearchParams(window.location.search);
const rc = params.get('r') || '';
params.delete('r');
const roomId = ref(params.get('roomId'));
params.delete('roomId');
const gameType = ref(params.get('gameType'));
params.delete('gameType');
let tgPath = params.get('tgPath') || '';
params.delete('tgPath');

const dataMap = Object.fromEntries(params.entries());
const userId = params.get('id');
const first_name = params.get('first_name');
// const last_name = params.get('last_name');
let username = params.get('username');
const photo_url = params.get('photo_url');
let tgToken = params.get('tgToken');
const router = useRouter();
const inTg = ref(false);
const showWaiting = ref(true);

async function initAuth() {
  const tgApp = useWebApp();
  if (tgApp && tgApp.initData && tgApp.initData != 'user') {
    inTg.value = true;
    const view = useWebAppViewport();
    view.expand();
    view.disableVerticalSwipes();
    useWebAppClosingConfirmation().enableClosingConfirmation();
    const params = new URLSearchParams(window.location.search);
    const tgWebAppStartParam = params.get('tgWebAppStartParam');
    if (tgWebAppStartParam) {
      tgPath = tgWebAppStartParam.split('_').join('/');
    }
    const tgParams = new URLSearchParams(useWebApp().initData);
    const paramValue = tgParams.get('user');
    const user = JSON.parse(decodeURIComponent(paramValue!));

    const dataMap = Object.fromEntries(tgParams.entries());
    dataMap['authType'] = 'webapp';
    if (tgUser && tgUser.id) {
      gotoSite();
    } else {
      try {
        const data = await botAPI.post<{ token: string }>('tg/auth/', dataMap);
        tgUser.id = user['id'];
        tgUser.username = user['username'];
        socialToken.value = data.token;
        const result = await loginSocialV2(socialToken.value);
        showWaiting.value = false;
        if (result) {
          router.replace(tgPath);
        } else {
          router.replace('/home');
        }
      } catch (e: any) {
        console.error(e);
        if (!e || !e.msg) {
          alert('Connection Timeout!');
        }
      }
    }
  } else if (tgToken != null) {
    socialToken.value = tgToken;
    try {
      const data = await botAPI.get<{ userID: string; userName: string; avatarUrl: string }>('tg/user/' + tgToken);
      try {
        if (await checkAccountStatus(socialToken.value)) {
          await loginSocialV2(socialToken.value);
          gotoSite();
        } else {
          linkType.value = 'TG';
          linkUserId.value = data['userID'];
          linkUserName.value = data['userName'];
          linkAvatar.value = data['avatarUrl'];
          showBindAccount.value = 1;
        }
      } catch (e) {
        console.error(e);
      }
    } catch (e: any) {
      if (!e || !e.msg) {
        alert('Connection Timeout!');
      }

      showWaiting.value = false;
      router.replace('/home?r=' + rc);
    }
  } else if (userId != null) {
    if (tgUser && tgUser.id + '' == userId) {
      gotoSite();
    } else {
      dataMap['authType'] = 'browser';
      try {
        const data = await botAPI.post<{ token: string }>('tg/auth/', dataMap);
        try {
          socialToken.value = data.token;
          if (await checkAccountStatus(data.token)) {
            initAccount.value = false;
            await loginSocialV2(socialToken.value);
          } else {
            if (!username) {
              username = first_name + userId;
            }
            linkType.value = 'TG';
            linkUserId.value = userId;
            linkUserName.value = username;
            linkAvatar.value = photo_url;
            showBindAccount.value = 1;
            gotoSite();
          }
        } catch (e) {
          console.error(e);
        }
      } catch (e: any) {
        if (!e || !e.msg) {
          alert('Connection Timeout!');
        }
        showWaiting.value = false;
        router.replace('/home?r=' + rc);
      }
    }
  }
}

async function gotoSite() {
  tgUser.useTgShare = 1;
  showWaiting.value = false;
  let url = '/';
  if (tgPath) {
    url = decodeURIComponent(tgPath);
  } else {
    if (gameType.value == 'mtt') {
      if (roomId.value != null) {
        url = '/m/' + roomId.value;
      } else {
        url = '/m';
      }
    } else if (gameType.value == 'sng') {
      url = '/s';
    } else {
      if (roomId.value != null) {
        url = '/g/p/' + roomId.value;
      } else {
        url = '/lobby';
      }
    }
  }
  // if (login) {
  //   setTimeout(() => history.replaceState(history.state, '', url), 0);
  // } else {
  router.replace(url);
  // }
}

watch(
  () => initAccount.value,
  (v) => {
    if (v) {
      initAuth();
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (!refreshToken.value) {
    initAccount.value = true;
  }
});
</script>
<template>
  <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
      <h5 class="mt-4">{{ $t('WELCOME') }}</h5>
    </div>
  </Dialog>
  <!-- <TgMttInfo v-if="gameType == 'mtt' && inTg" :id="roomId" />
  <TgPrivateInfo v-if="gameType == 'private' && inTg" :id="roomId" />
  <footer v-if="showJoinBtn" class="flex fixed space-x-3 bottom-0 p-3 bg-dark-800 w-full">
    <Button class="flex-1" :label="$t('game.START_GAME')" @click="join" />
  </footer> -->
</template>
