<template>
  <div class="text-lg py-4">
    <DPUserGameSetting @close="success" />
  </div>
</template>
<script lang="ts" setup>
import { toast } from '@/components/ui/AToast.vue';

const success = () => toast.success('success');
</script>
