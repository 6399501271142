<template>
  <canvas class="object-contain" :ref="load" />
</template>
<script lang="ts" setup>
import parseAPNG from 'apng-js';

const props = defineProps<{ src: string; loop?: boolean }>();
let frameLength = -1;
const pm = fetch(props.src)
  .then((res) => res.arrayBuffer())
  .then((buffer) => parseAPNG(buffer));
const load = (canvas: any) => {
  if (frameLength > 0) return;
  pm.then((png) => {
    if (png instanceof Error || !canvas) return;
    canvas.width = png.width;
    canvas.height = png.height;
    frameLength = png.frames.length;
    return png.getPlayer(canvas.getContext('2d')!);
  }).then((p) => {
    if (!p) return;
    p.on('end', () => {
      if (props.loop) setTimeout(() => p.play(), 100);
    });
    p.on('frame', (v) => {
      if (!props.loop && frameLength - 1 === v) {
        p.pause();
      }
    });
    p.play();
  });
};
</script>
