<template>
  <div class="flex items-center mb-4">
    <h5 class="mr-a">{{ $t('PAY') }}</h5>
    <template v-for="(v, i) in cfg" :key="i">
      <img class="w-5" :src="iconMap[v.regType].img" alt="" />
      <span class="c-green ml-1">x{{ v.regFee }}</span>
      <span v-if="i < cfg.length - 1" class="mx-2">/</span>
    </template>
  </div>
  <h5 v-if="chips" class="flex mb-4">
    <span class="mr-a">{{ $t('GET') }}</span>
    {{ formatNumber(chips) }} {{ $t('CHIPS') }}
  </h5>
  <h5 v-if="remain" class="flex mb-4">
    <span class="mr-a">{{ $t('REMAINING') }}</span>
    {{ remain }}
  </h5>
  <AButton
    class="w-full mb-4"
    v-for="(v, i) in cfg"
    :key="i"
    :icon="iconMap[v.regType].icon"
    @click="emits('resolve', v.regType)"
    >x{{ v.regFee }}</AButton
  >
  <AButton class="w-full" type="secondary" @click="emits('reject')">{{ $t('CANCEL') }}</AButton>
</template>
<script setup lang="ts">
import { formatNumber } from '@/utils/strings';

const iconMap = {
  2: {
    img: new URL('@/assets/img/ticket.png', import.meta.url).href,
    icon: 'i-local-ticket',
  },
  3: {
    img: new URL('@/assets/img/diamond.png', import.meta.url).href,
    icon: 'i-local-diamond-fill',
  },
  4: {
    img: new URL('@/assets/img/coin.png', import.meta.url).href,
    icon: 'i-local-coin',
  },
};

defineProps<{ cfg: { regType: 2 | 3 | 4; regFee: number }[]; chips?: number; remain?: number }>();
const emits = defineEmits<{ reject: []; resolve: [2 | 3 | 4] }>();
</script>
