<template>
  <header class="bg-cover bg-right p-4 rd-lg bg-no-repeat" :style="{ backgroundImage: `url(${banner})` }">
    <h2 class="flex items-center my-1">
      {{ seasons?.[cur]?.seasonName || '-' }}
      <APopButton v-model="showPad" align="end">
        <AButton class="text-xs ml-2 !p-1" type="secondary" icon="i-local-arrow-d" />
        <template #pad>
          <div class="bg-gray-700 rd p-4 w-max space-y-2 fw-normal" un-children="cursor-pointer">
            <p v-for="(s, i) in seasons" :key="i" :class="{ 'c-green': cur === i }" @click="setSeason(i)">
              {{ s.dropName }}
            </p>
          </div>
        </template>
      </APopButton>
    </h2>
    <p class="my-2">{{ seasonRange }}</p>
    <ATitle>
      {{ $t('TOTAL_PARICIPANTS') }}: <b>{{ total }}</b>
    </ATitle>
  </header>

  <div class="space-y-2 flex-1 of-auto mt-4">
    <div
      v-for="(item, i) of rank"
      :key="i"
      class="bg-contain bg-no-repeat rd-lg bg-gray-900 flex items-center px-4 py-3"
      :class="{
        'bgi-ranking-1-bg': i === 0,
        'bgi-ranking-2-bg': i === 1,
        'bgi-ranking-3-bg': i === 2,
        'bgi-ranking-general-bg': i > 2,
      }"
    >
      <h2 class="w-16 pl-4 italic c-gray-200">{{ item.rank }}</h2>
      <div class="flex flex-row flex-1 mx-3 items-center of-hidden">
        <DPAvatar class="text-1.7rem flex items-center of-hidden" :name="item.userName" :avatar="item.avatarUrl">
          <template #default="{ user }">
            <h3 class="ml-2 truncate">{{ user.name }}</h3>
          </template>
        </DPAvatar>
      </div>
      <div class="text-right shrink-0">
        <h5 class="c-green">{{ item.totalPoints }}</h5>
        <div class="c-gray-400">
          {{ $t('X_TOURNAMENTS', { v: item.seq }) }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="mine" class="flex items-center px-4 py-3 b-t-solid b-green bg-gradient-to-b from-green-900">
    <h2 class="w-16 pl-4 italic c-gray-200">{{ mine.rank || '-' }}</h2>
    <div class="flex flex-row flex-1 mx-3 items-center of-hidden">
      <DPAvatar class="text-1.7rem flex items-center of-hidden" :name="mine.userName" :avatar="mine.avatarUrl">
        <template #default="{ user }">
          <h3 class="ml-2 truncate">{{ user.name }}</h3>
        </template>
      </DPAvatar>
    </div>
    <div class="text-right shrink-0">
      <h5 class="c-green">{{ mine.totalPoints }}</h5>
      <div class="c-gray-400">
        {{ $t('X_TOURNAMENTS', { v: mine.seq }) }}
      </div>
    </div>
  </div>
  <!-- <DPBanner
    v-if="showSponsor"
    class="mt-6"
    title="Play 30 game to win"
    desc="4000 vdbx"
    bg="bg-green"
    :src="sponsor"
    @close="showSponsor = false"
    @click="$router.push('/poker-to-mint-s4')"
  >
  </DPBanner> -->
</template>
<script lang="ts" setup>
import { authAPI } from '@/utils/network';
import { useExpiredStorage } from '@/utils/value';
import { computed, ref, watch } from 'vue';
import sponsor from '@/assets/img/debox-banner.png?url';

// const [showSponsor] = useExpiredStorage('sng:rank-m:banner', Date.now() + 24 * 36e5, true);

const rank = ref<{ userName: string; avatarUrl: string; rank: number; seq: number; totalPoints: number }[]>([]);
const seasons =
  ref<{ seasonName: string; dropName: string; seasonFlag: number; startDate: string; endDate: string }[]>();
const mine = ref();
const cur = ref(0);
const showPad = ref(false);

authAPI.get('v1/sng/getLeaderBoardList').then((v) => (seasons.value = v));
const setSeason = (i: number) => {
  cur.value = i;
  showPad.value = false;
};
const total = ref('-');
const banner = ref();

const seasonRange = computed(() => {
  if (!seasons.value) return '-';
  return `${seasons.value[cur.value].startDate} ~ ${seasons.value[cur.value].endDate}`;
});

const loadRank = () => {
  if (!seasons.value && cur.value) return;
  authAPI.get('v1/sng/get_power_leaderBoard', { seasonFlag: seasons.value?.[cur.value].seasonFlag }).then((r) => {
    rank.value = r.seasonPowerLeaderBoards;
    mine.value = r.userLeaderBoard;
    total.value = r.total;
    banner.value = r.bannerUrl;
  });
};

watch(cur, () => loadRank(), { immediate: true });
</script>
