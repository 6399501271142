<template>
  <div class="flex flex-col">
    <div
      v-if="showClan"
      class="flex justify-around mb-4 of-hidden"
      un-children="text-center flex-1 p-2 b-2 b-b-solid cursor-pointer "
    >
      <h5 :class="tab === 0 ? 'b-green bg-green/20' : 'b-transparent'" @click="tab = 0">{{ $t('PLAYER_RANKINGS') }}</h5>
      <h5 :class="tab === 1 ? 'b-green bg-green/20' : 'b-transparent'" @click="tab = 1">{{ $t('CLAN_RANKINGS') }}</h5>
    </div>
    <template v-if="tab === 0">
      <div v-if="showExport" class="flex text-sm justify-end mb-2">
        <AButton @click="exportData">{{ $t('mtt.EXPORT_DATA') }}</AButton>
      </div>
      <div class="rd bg-dark-600 flex-1 flex flex-col of-hidden">
        <div class="flex py-3 bg-gray-700 c-gray-400 text-center">
          <h6 class="w-16">{{ $t('mtt.RANK') }}</h6>
          <h6 class="w-12 text-left">{{ $t('CLAN') }}</h6>
          <h6 class="mr-auto">{{ $t('mtt.PLAYERS') }}</h6>
          <h6 class="w-20">{{ $t('mtt.CHIPS') }}</h6>
          <h6 class="w-18">{{ $t('mtt.TABLES') }}</h6>
        </div>
        <div v-bind="containerProps" class="flex-1 c-gray-300">
          <div v-bind="wrapperProps">
            <div
              v-for="{ data: u, index } in list"
              :key="index"
              class="flex text-center items-center h-12 of-hidden children:shrink-0"
              :class="{ 'bg-dark-900': index % 2 }"
            >
              <p class="w-16">{{ u.rank || '-' }}</p>
              <div class="w-12 pl-1 text-left">
                <img v-if="u.clan" class="w-5 h-5 rd block" :src="u.clan.logoUrl" alt="" />
              </div>
              <h6 class="flex shrink-1 justify-start items-center mr-auto of-hidden">
                <div class="truncate">{{ u.playerName }}</div>
                <span class="c-green">{{ u.reEntryNumber ? `(+${u.reEntryNumber})` : '' }}</span>
              </h6>
              <h6 v-if="u.chip === -1" class="w-20">{{ $t('ABSENT') }}</h6>
              <h6 v-else class="w-20">{{ u.diedTime ? $t('FINISHED') : u.chip }}</h6>
              <h6 class="w-18 truncate">{{ u.tableNo || '-' }}</h6>
            </div>
          </div>
        </div>
        <div
          v-if="mineRank"
          class="b-t-solid b-2 b-green flex items-center py-3 bg-gradient-to-b from-green-900 text-center"
        >
          <p class="w-12">{{ mineRank.rank || '-' }}</p>
          <h5 class="w-18">
            <DPAvatar class="text-2xl" :name="user!.name" :avatar="user!.avatar" />
          </h5>
          <img v-if="mineRank.clan" class="w-5 h-5 mr-2 rd" :src="mineRank.clan.logoUrl" alt="" />
          <h5 class="truncate mr-auto">
            {{ mineRank.playerName }}
            <span class="c-green">{{ mineRank.reEntryNumber ? `(+${mineRank.reEntryNumber})` : '' }}</span>
          </h5>
          <h5 v-if="mineRank.chip === -1" class="w-20">{{ $t('ABSENT') }}</h5>
          <h5 v-else class="w-20">{{ mineRank.diedTime ? $t('FINISHED') : mineRank.chip }}</h5>
          <h5 class="w-20 truncate">{{ mineRank.tableNo || '-' }}</h5>
        </div>
      </div>
    </template>
    <div v-else class="rd bg-dark-600 flex-1 flex flex-col of-hidden">
      <div class="flex py-3 bg-gray-700 c-gray-400 text-center justify-between">
        <h6 class="w-16">{{ $t('mtt.RANK') }}</h6>
        <h6 class="w-12">{{ $t('CLAN') }}</h6>
        <h6 class="w-20">{{ $t('SCORE') }}</h6>
        <h6 class="w-20">{{ $t('mtt.PLAYERS') }}</h6>
        <h6 class="w-40">{{ $t('MVP') }}</h6>
      </div>
      <div class="of-auto">
        <div
          v-for="(clan, i) in clanRank"
          :key="i"
          class="flex text-center items-center h-12 of-hidden justify-between children:shrink-0"
          :class="{ 'bg-dark-900': i % 2 }"
        >
          <p class="w-16">{{ list[0]?.data.rank ? i + 1 : '-' }}</p>
          <div class="w-12">
            <img class="size-7 rd block m-auto" :src="clan.logoUrl" alt="" />
          </div>
          <h6 class="w-20">{{ clan.clanPoint ?? '-' }}</h6>
          <h6 class="w-20">{{ clan.clanPlayers }}</h6>
          <h6 class="w-40 flex justify-center -space-x-2">
            <span v-if="clan.topPlayers.length === 0">-</span>
            <DPAvatar v-for="(u, k) in clan.topPlayers" :key="k" :name="u.userName" :avatar="u.userAvatarUrl" />
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import DPAvatar from '@/components/DPAvatar.vue';
import { user } from '@/stores';
import { authAPI } from '@/utils/network';
import { useIntervalFn, useVirtualList } from '@vueuse/core';
import saveAs from 'file-saver';
import { computed, ref } from 'vue';

const props = defineProps<{ id: string; showExport?: boolean; showClan: boolean }>();

const tab = ref(0);
const rank = ref<any[]>([]);
const clanRank = ref<any[]>([]);

const loadRank = async () => {
  rank.value = await authAPI.get('v1/mtt/getMTTRanking', { mttID: props.id });
  if (props.showClan) clanRank.value = await authAPI.get('v1/mtt/getMTTClanRanking', { mttID: props.id });
};

const mineRank = computed(() => rank.value.find((v) => v.userID === user.value?.id));

const { list, containerProps, wrapperProps } = useVirtualList(rank, {
  itemHeight: +parseFloat(getComputedStyle(document.body).fontSize) * 3,
});

useIntervalFn(loadRank, 3e4, { immediateCallback: true });

const exportData = async () => {
  const { data, suffix } = await authAPI.blob('v1/mtt/exportUserPriceInfo', { mttID: props.id });
  saveAs(data, `data-${props.id}${suffix ? `.${suffix}` : '.csv'}`);
};
</script>
