export type Rank = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'T' | 'J' | 'Q' | 'K';
export type Suit = 's' | 'h' | 'd' | 'c';
export type Card = `${Rank}${Suit}`;

export type Config = {
  blindLevels: {
    smallBlind: number;
    bigBlind: number;
    ante: number;
    anteTimes: string;
  }[];
  initBlind: number;
  minBuyBlind: number;
  maxBuyBlind: number;
  maxPlayerNumber: number;
  gameDuration: number;
  clientActTimeOut: number;
  straddle: boolean;
  runTwice: boolean;
  antes: boolean;
  enableRabbitHunting: boolean;
  allInInsurance: boolean;
  gameFee?: 0 | 1 | 2;
  ratio?: number;
  cap?: number;
  timeBank: {
    duration: number;
    number: number;
    addPolicy: number;
  };
  vdfShuffle?: boolean;
};

type GameStatus = {
  runTwicePlayers?: { position: number; runTwiceDecisionTimeRemainInSeconds: number }[];
  runTwiceFormerCards?: string[];
  startGameIcon: boolean;
  pause: boolean;
  gameOver: boolean;
  gameIsOver: boolean;
  askPlayers?: { timeRemainInSeconds: number; type?: string; position?: number }[];
  lastActionPositionInGame: number;
  break?: boolean;
  remainingBreakTime?: number;
  remainingPreTableTime: number;
  remainingEndTime?: number;
  insuranceAskInfo?: {
    outs: string[];
    odds: number[];
    minPremium: number[];
    maxPremium: number[];
    defaultPremium: number[];
    forceInsurance: boolean;
  };
};

export type Status = {
  id: string;
  type: string;
  chatRoomGroupID?: string;
  chatRoomUserName?: string;
  chatRoomSign?: string;
  cards: string[];
  totalPot: number;
  pot: { Amt: number }[];
  round: string;
  anchor: number;
  players: (Player | undefined)[];
  nextPlayer?: { position: number; timeRemainInSeconds: number[]; showTimeExpandIcon: boolean } | null;
  supportedActions?: { action: string; chips?: number[]; currentCallChips?: number }[];
  winningRate?: Record<string, number>;
  eachRoundMainPotWinningPlayer?: { position: number }[][];
  currentPosition: number;
  role: { owner: boolean };
  pingTimeout: number;
  game: GameStatus;
  remainPlayers?: [];
  showRabbitHuntingIcon?: boolean;
  rabbitHuntingCards?: string[];
  animation?: string;
  showOuts?: { uid: string; outs: string[]; leading: boolean }[];
  roomset: {
    config: Config;
    curCfg: Config;
    nextBlind: string;
    nextBlindTime?: number;
    sb: number;
    bb: number;
    ante: number;
    owner: string;
    mtt: string;
    tableBoardImg: string;
    verticalTableBoardImg?: string;
    code?: string;
  };
  modules: {
    buyIn: boolean;
    leave: boolean;
    share: boolean;
    ledger: boolean;
    rtc: boolean;
  };
  user: { auditBuyInList?: { name: string; stack: number; buyStack: number; messageID: string }[] };
  voiceSetting: { [key: string]: number };
  uniqueID: string;
};

export type User = {
  name: string;
  stack: number;
};

export type Player = {
  userID?: string;
  playerName: string;
  cards?: string[];
  handName?: string;
  stack: number;
  chips?: number;
  realAction?: string;
  realActionLastRound?: string;
  position: number;
  winChips?: { winChips: number; potID: number }[];
  showWinIcon: boolean;
  status?: string;
  waitingTime?: number;
  tickOutCountDown?: number;
  active?: boolean;
  back?: boolean;
  showHandsIcon?: boolean;
  showHandsType?: 'showAll' | 'showFirst' | 'showSecond';
  timeBank: [number, number];
  photoUrl?: string;
  chatRoomUserName?: string;
  uniqueID: string;
  animatedEmojisTimes?: number;
  seed?: boolean;
};

export type RoundRecord = {
  gameType: string;
  smallBlind: number;
  bigBlind: number;
  blindHands?: number;
  playerNums: number;
  roomName: string;
  dealer: number;
  seq: number;
  normalCards?: string[];
  runTwiceCards?: string[];
  currentPosition: number;
  players: RecordPlayer[];
  rounds: RecordRound[];
  playerWinChips: { position: number; winAmount: number }[];
  rabbitHuntingCards?: string[];
  vdf?: string;
  roomID: string;
};

export type RecordPlayer = {
  userID?: string;
  playerName: string;
  position: number;
  startStack: number;
  endStack: number;
  cards?: string[];
  win?: number;
  pos?: number;
  positionName?: string;
};
export type RecordRound = {
  cards: string[];
  street: string;
  roundPot: number;
  actions?: RecordAction[];
};

export type RecordAction = {
  actionNumber: number;
  action: string;
  position: number;
  positionName?: string;
  winningRate?: number;
  cards?: string[];
  latestAmount: number;
  actionAmount: number;
  isWinningRate?: boolean;
  runTwicePostions: number[];
  runTwiceIndex: 0 | 1;
  confirmBuyInsurance?: boolean;
};

export type Mtt = {
  verticalTableBackgroundUrl?: string;
  mttID?: string;
  mttName: string;
  mttType: number;
  status: number;
  startTime: number;
  registrationStartTime: number;
  minPlayers: number;
  maxPlayers: number;
  prizePoolSize: number;
  prizePool?: {
    prizePoolType?: number;
    prizePoolRate?: number;
    prizePoolValue?: number;
  };
  prizeClaimDeadline: number;
  lateRegistrationTime: number;
  reEntryLimits: number;
  maxPlayerNumber: number;
  clientActTimeOut: number;
  minBuyBlind: number;
  maxBuyBlind: number;
  initBlind: number;
  autoReEntry: number;
  autoRegApproval: number;
  allowQuit: boolean;
  isHost?: boolean;
  hostID?: string;
  hostName?: string;
  description?: string;
  breakTime?: number;
  observeMode?: boolean;
  blindDuration: number;
  totalEntries?: number;
  timeBank?: { duration: number; number: number; addPolicy: number };
  tableLogoUrl?: string;
  tableBackgroundUrl?: string;
  // leftTicket?: number; 因为缓存问题，该字段废弃
  isRegistered?: boolean;
  gameType?: string;
  waiting?: boolean;
  reEntries?: number;
  preTable?: boolean;
  addtionalTimeStamp?: number;
  mttTask?: { id: number; mttID: string; taskType: string; taskConfig: any[] };
  prizeDetail: string;
  prizeDescription: string;
  prizeHighLight: string;
  countryRestrict?: { restrictType: string; countryList: string[] };
  clanRestrict?: { restrictType: string; clanIdList: string[] };
  announcement?: string;
  enableAnnouncement?: boolean;
  announcementContentChanged?: number;
  enableShowAdvancedSettings?: boolean;
  themeRoomId?: number;
  themeType?: number;
  themeRoomConfig?: any;
  preHostId?: string;
  preHostName?: string;
  socialAccountList?: { socialType: string; socialId: string }[];
  showClanRanking: 0 | 1;
  videoChat: boolean;
  enableRabbitHunting: boolean;
  prizePoolType: 1 | 2 | 3;
  rebuyBlindTermination: number;
  clanConfig?: { type: number; clanId: string };
  reEntryConfig?: { regType: 2 | 3 | 4; regFee: number }[];
};

export type Ledger = {
  playerNames: string[];
  userSessionList: string[];
  buyIn: number;
  buyOut: number;
  stack: number;
  net: number;
  showFee: number;
  details: {
    buyIn: number;
    buyOut: number;
    handSeq: string;
    net: number;
    stack: number;
    starting: number;
    showFee: number;
  }[];
};

export const MttStatus: Record<number, string> = {
  1: 'ANNOUNCED',
  2: 'REGISTERING',
  3: 'LATE_REG',
  4: 'RUNNING',
  5: 'CANCELED',
  6: 'FINISHED',
  7: 'CANCELED',
  8: 'mtt.PRIZE_POOL',
};

export const MttUserStatus: Record<number, string> = {
  0: 'UNREGISTER',
  1: 'PENDING',
  2: 'APPROVED',
  3: 'REJECTED',
  4: 'LEAVE',
  5: 'OBSOLETE',
};

export type TaskInfo = {
  taskId: number;
  taskType: string;
  taskKey: string;
  title: string;
  desc: string;
  taskUrl?: string;
  checkUrl: string;
  btnText: string;
  text?: string;
  userId?: string;
  textId?: string;
  mttId?: string;
  chain?: string;
  balance?: number;
  duration?: number;
};

export type SNG = {
  blindDuration: number;
  blindStructure?: BlindStructure[];
  clientActTimeOut?: number;
  costPoints?: number;
  createNext?: boolean;
  createTime?: string;
  currentTime: number;
  description?: string;
  gameType?: string;
  initBlind: number;
  leftPlayers?: number;
  maxPlayerNumber: number;
  maxPlayers: number;
  minPlayers: number;
  preTable?: boolean;
  preTableTime?: number;
  sngID?: string;
  sngName?: string;
  sngNumber?: number;
  startTime: number;
  status: number;
  timeBank: TimeBank;
  totalEntries: number;
  waiting?: boolean;
  userAccountVOList: SNGUser[];
  userStatus: number;
};

export type BlindStructure = {
  ante: number;
  bigBlind: number;
  smallBlind: number;
  duration: number;
};

export type TimeBank = {
  addPolicy: number;
  duration: number;
  number: number;
};

export type SNGUser = {
  userAvatarUrl?: string;
  userName: string;
};

export type TicketInfo = {
  userId?: string;
  code: string;
  userName?: string;
  ticketStatus: number;
  exchangeStatus: number;
  avaliable: boolean;
  redeemed: boolean;
};

export type Clan = {
  clanId: string;
  name: string;
  memberCount: number;
  logoUrl: string;
  description: string;
  registrationGuidance: string;
  approveMode: 0 | 1 | 2;
  creator?: string;
  users?: any[];
  isStar?: boolean;
  clanRole?: number;
  rules?: string;
  isPublic?: boolean;
  maxMembers?: boolean;
};

export type PlatformTask = {
  id: number;
  contentKey: string;
  taskKey: string;
  taskGroup: string;
  taskLadder: number;
  taskUrl: string;
  gameType: string;
  prizeDiamond: number;
  prizePoint: number;
  prizeGold: number;
  prizeExperience: number;
  activePoint: number;
  isClaim: number;
  isFinish: number;
  times: number;
  finishId: number;
};

export type PlatformTaskGroup = {
  groupName: string;
  list: PlatformTask[];
};

export type UserExperience = {
  lastExperience: number;
  currentExperience: number;
  nextExperience: number;
  canClaim: number;
  prizeDiamond: number;
  level: number;
  title: string;
  finishId: number;
};

export type TaskClaimPrize = {
  point: number;
  diamond: number;
  gold: number;
  experience: number;
  activePoint: number;
  title: string;
  level: number;
};

export type GoldenRoom = {
  index: number;
  name: string;
  level: 0 | 1 | 2 | 3;
  minBuy: number;
  maxBuy: number;
  bigBlind: number;
  smallBlind: number;
  type: string;
  playingCount?: number;
  maxPlayerNumber?: number;
};

export type TaskRedDot = {
  signTask: number;
  dailyTask: number;
  experienceTask: number;
  diamondTask: number;
  goldTask: number;
  levelTask: number;
};
