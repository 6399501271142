<template>
  <div v-if="user" class="py-4">
    <header class="flex flex-col items-center mb-10">
      <DPAvatar class="text-7xl" :avatar="data.avatar" :name="data.name" />
      <div class="flex mt-8">
        <AUpload v-model="data.avatar" />
        <AButton class="ml-5" type="secondary" icon="i-local-delete" @click="data.avatar = ''" />
      </div>
      <p class="mt-2 c-gray">{{ $t('IMAGE_SIZE') }}</p>
    </header>
    <AInput v-model="data.name" :limit="40">{{ $t('USERNAME') }}</AInput>
    <div class="mb-1 mt-4">{{ $t('COUNTRY_REGION') }}</div>
    <Dropdown
      class="w-full"
      v-model="data.country"
      filter
      :options="countryData"
      option-label="name"
      option-value="code"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex items-center">
          <img
            class="h-4 mr-2"
            alt=""
            :src="`/flags/${countryData.find((v) => v.code === slotProps.value)!.code}.png`"
          />
          <div>{{ countryData.find((v) => v.code === slotProps.value)!.name }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="flex items-center">
          <img class="h-4 mr-2" alt="" :src="`/flags/${slotProps.option.code}.png`" />
          <div>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Dropdown>
    <AButton class="mt-8" portrait="mt-a" @click="updateUserInfo()">{{ $t('SAVE') }}</AButton>
  </div>
</template>
<script setup lang="ts">
import { toast } from '@/components/ui/AToast.vue';
import { countryData, user } from '@/stores';
import { dt } from '@/utils/datetime';
import { updateUser } from '@/utils/user';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();
const data = ref({ name: user.value!.name, avatar: user.value!.avatar, country: user.value!.country });

const updateUserInfo = async () => {
  try {
    await updateUser(data.value.name, data.value.avatar, data.value.country);
    toast.success(t('PROFILE_UPDATED'));
    router.back();
  } catch (e: any) {
    if (e.code == 410) toast.error(t('NEED_TO_WAIT_30_DAYS_LIMIT', { v: dt(e.msg * 1e3).strDatetime }));
    else toast.error(t('FAIL'));
  }
};
</script>
