<template>
  <ATitle class="text-xl mb-4">{{ $t('PLEASE_CHOOSE_YOUR_FEEDBACK_TYPE') }}</ATitle>
  <div class="bg-gray-700 rd-md p-5 items-start grid grid-cols-4 gap-x-20 gap-y-5" portrait="grid-cols-2">
    <ARadioButton v-model="type" value="bugReport">{{ $t('BUG_REPORT') }}</ARadioButton>
    <ARadioButton v-model="type" value="suggestion">{{ $t('SUGGESTIONS') }}</ARadioButton>
    <ARadioButton v-model="type" value="accuse">{{ $t('ACCUSE') }}</ARadioButton>
    <ARadioButton v-model="type" value="others">{{ $t('OTHERS') }}</ARadioButton>
  </div>
  <div class="c-white/50 text-sm mt-2">{{ $t('DIAMOND_REWARD_FOR_FEEDBACK') }}</div>

  <AInput v-model="email" name="email" autocomplete="email" :placeholder="$t('PLEASE_ENTER_YOUR_EMAIL')">
    <div class="mt-10 fw-medium">
      {{ $t('EMAIL') }} <span class="c-red">({{ $t('REQUIRED') }})</span>:
    </div>
  </AInput>
  <small class="p-error">{{ isEmail || !email ? '&nbsp;' : $t('PLEASE_ENTER_A_VALID_EMAIL') }}</small>

  <AInput :rows="6" :limit="300" v-model="content" :placeholder="$t('PLEASE_PROVIDE_FEEDBACK_CONTENT')">
    <div class="mt-10 fw-medium">
      {{ $t('CONTENT') }} <span class="c-red">({{ $t('REQUIRED') }})</span>:
    </div>
  </AInput>

  <div class="mt-10 flex justify-between">
    <div class="fw-medium">
      {{ $t('UPLOAD_IMAGE') }} <span class="c-green">({{ $t('OPTIONAL') }})</span>:
    </div>
    <div class="c-white/60 text-sm">{{ $t('MAXIMUM_NUM', [3]) }}</div>
  </div>
  <AUpload class="mt-1" :maxSize="2e6" v-model="image" v-if="images.length < 3">
    <div class="text-center bg-gray-700 b-1 b-solid b-white/20 rd-md p-2 text-sm cursor-pointer">
      <div class="c-green flex items-center justify-center">
        <div class="i-local-upload mr-2" />
        {{ $t('SELECT_IMAGE') }}
      </div>
      <div class="c-white/60">{{ $t('IMAGE_SIZE_LIMIT', ['2M']) }}</div>
    </div>
  </AUpload>
  <div
    class="flex p-2 items-center justify-between bg-dark-700 b-1 b-solid b-white/20 rd-md mt-2"
    v-for="(img, index) in images"
    :key="index"
  >
    <img
      :src="img"
      class="w-auto h-12 mr-2 cursor-pointer"
      @click="
        current = img;
        preview = true;
      "
    />
    <div class="i-local-delete text-xl c-dark-950 cursor-pointer" @click="removeImage(index)" />
  </div>

  <div class="mt-10 flex space-x-5 children:flex-1">
    <AButton type="secondary" @click="show = true">{{ $t('CANCEL') }}</AButton>
    <AButton @click="submit" :disabled="loading">{{ $t('mtt.SUBMIT') }}</AButton>
  </div>

  <ADialog class="w-100" v-model="show" :title="$t('NOTICE')">
    <div class="text-center text-lg">{{ $t('EXITING_PAGE_NOTICE') }}</div>
    <div class="mt-5 flex space-x-2 children:flex-1">
      <AButton type="secondary" @click="show = false">{{ $t('PROCEED') }}</AButton>
      <AButton @click="router.back()">{{ $t('CANCEL') }}</AButton>
    </div>
  </ADialog>

  <ADialog transparent v-model="preview">
    <img :src="current" class="w-full" alt="" />
  </ADialog>
</template>
<script setup lang="ts">
import router from '@/router';
import { authAPI } from '@/utils/network';
import { ref, watch, computed } from 'vue';
import { toast } from './ui/AToast.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const type = ref<string>('bugReport');
const email = ref<string>('');
const content = ref<string>('');
const current = ref<string>('');
const image = ref<string>('');
const images = ref<string[]>([]);
const loading = ref(false);
const show = ref(false);
const preview = ref(false);

watch(image, (val) => {
  if (val && !images.value.includes(val)) images.value.push(val);
});

const removeImage = (index: number) => {
  images.value.splice(index, 1);
};

const isEmail = computed(() => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.value,
  );
});

const submit = () => {
  if (!email.value || !isEmail.value || !content.value) {
    toast.error(t('PLEASE_FILL_IN_THE_REQUIRED_FIELDS'));
    return;
  }
  const source = router.options.history?.state?.back || '';

  loading.value = true;
  authAPI
    .post('feedback/submit', {
      type: type.value,
      email: email.value,
      content: content.value,
      images: images.value,
      source,
    })
    .then(() => {
      toast.success(t('SUBMISSION_SUCCESSFUL'));
      router.back();
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
