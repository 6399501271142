<script setup lang="ts">
import { useRouter } from 'vue-router';
import { botAPI, request_token, request_secret, xRedirect } from '@/utils/network';
import {
  user,
  xUser,
  socialToken,
  userTask,
  showBindAccount,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
} from '@/stores';
import { onMounted, ref } from 'vue';
import { loginSocialV2, socialLink, checkAccountStatus } from '@/utils/user';
import { convertToLangeLabel } from '@/utils/strings';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const params = new URLSearchParams(window.location.search);
const dataMap = Object.fromEntries(params.entries());
const showWaiting = ref(true);
const router = useRouter();

if (xRedirect.value && xRedirect.value.indexOf('---showQuest') > 0) {
  userTask.showQuest = true;
  xRedirect.value = xRedirect.value.replace('---showQuest', '');
}

async function initAuth() {
  dataMap['request_token'] = request_token.value;
  dataMap['request_secret'] = request_secret.value;
  if (user.value) dataMap['userId'] = user.value.id;
  try {
    const data = await botAPI.post<{
      token: string;
      userId: string;
      username: string;
      avatar: string;
    }>('x/auth', dataMap);
    xUser.id = data.userId;
    xUser.username = data.username;
    xUser.avatar = data.avatar;
    socialToken.value = data.token;
    request_token.value = '';
    request_secret.value = '';
  } catch (e: any) {
    userTask.showQuest = false;
    alert(e.msg);
    router.replace(decodeURIComponent(xRedirect.value));
    return;
  }
  try {
    if (xRedirect.value != null && xRedirect.value.indexOf('---linkX') >= 0) {
      xRedirect.value = xRedirect.value.replace('---linkX', '');
      showWaiting.value = false;
      try {
        await socialLink(xUser.id, xUser.username, xUser.avatar, 'X');
      } catch (e: any) {
        xUser.id = '';
        xUser.username = '';
        xUser.avatar = '';
        socialToken.value = '';
        userTask.showQuest = false;
        alert(i18n.t(convertToLangeLabel(e)));
      }
      router.replace(decodeURIComponent(xRedirect.value));
    } else {
      if (await checkAccountStatus(socialToken.value)) {
        await loginSocialV2(socialToken.value);
        xRedirect.value = xRedirect.value.replace('---bindX', '');
        await router.replace(decodeURIComponent(xRedirect.value));
        // setTimeout(() => history.replaceState(history.state, '', decodeURIComponent(xRedirect.value)), 0);
      } else {
        if (xRedirect.value.indexOf('---bindX') > 0) {
          xRedirect.value = xRedirect.value.replace('---bindX', '');
          showBindAccount.value = 3;
        } else {
          linkType.value = 'X';
          linkUserId.value = xUser.id;
          linkUserName.value = xUser.username;
          linkAvatar.value = xUser.avatar;
          showBindAccount.value = 1;
        }
        router.replace(decodeURIComponent(xRedirect.value));
      }
    }
  } catch (e: any) {
    userTask.showQuest = false;
    alert(e);
    router.replace(decodeURIComponent(xRedirect.value));
  }
}

onMounted(async () => {
  await initAuth();
});
</script>
<template>
  <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
      <h5 class="mt-4">{{ $t('discord.LOGIN') }}</h5>
    </div>
  </Dialog>
</template>
