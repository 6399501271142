<template>
  <header class="flex items-center px-4 bg-dark-900/50 backdrop-blur">
    <div v-if="$route.meta.title" class="i-local-arrow-l size-6 cursor-pointer" @click="back" />
    <div v-else class="i-local-menu2 size-6 cursor-pointer" @click="showMenu = true" />
    <div v-if="landscape" class="text-5rem ml-3 i-local-lepoker cursor-pointer" @click="$router.push('/home')" />
    <div v-if="landscape" class="flex flex-1 justify-center" un-children="min-w-30 text-center cursor-pointer">
      <h5 :class="{ 'c-green': $route.name === 'clan' }" @click="$router.push('/cl')">{{ $t('CLAN') }}</h5>
      <h5 :class="{ 'c-green': $route.name === 'private' }" @click="$router.push('/private')">
        {{ $t('PRIVATE_GAME') }}
      </h5>
      <h5 :class="{ 'c-green': $route.name === 'lobby' }" @click="$router.push('/lobby')">{{ $t('LOBBY') }}</h5>
      <h5 :class="{ 'c-green': $route.name === 'tournament' }" @click="$router.push('/m')">{{ $t('TOURNAMENT') }}</h5>
    </div>
    <h4 v-else-if="$route.meta.title" class="mx-2 flex-1" :class="{ 'text-center': !$route.meta.showAssets }">
      {{ $t($route.meta.title as string) }}
    </h4>
    <div class="flex ml-a items-center justify-end" :class="landscape ? 'w-34' : 'w-5'">
      <div id="m-nav-add" />
      <template v-if="landscape || $route.meta.showAssets">
        <div
          v-if="assets"
          class="bg-gray-700 h-6 rd-full pl-1 flex items-center mx-2 cursor-pointer"
          @click="$router.push('/mall/coin')"
        >
          <img class="w-5 mr-1" src="@/assets/img/coin.png" alt="" />
          <h6 v-if="assets.goldCoin != undefined">{{ formatNumber(assets.goldCoin, true) }}</h6>
          <h6 v-else class="c-green i-local-loading" />
          <div class="b-solid b-1 c-green size-5 rd-full mx-1">
            <div class="i-local-plus size-full" />
          </div>
        </div>
        <div
          v-if="assets"
          class="bg-gray-700 h-6 rd-full pl-1 flex items-center cursor-pointer"
          @click="$router.push('/mall/diamond')"
        >
          <img class="w-5 mr-1" src="@/assets/img/diamond.png" alt="" />
          <h6 v-if="assets.diamond != undefined">{{ formatNumber(assets.diamond, true) }}</h6>
          <h6 v-else class="c-green i-local-loading" />
          <div class="b-solid b-1 c-green size-5 rd-full mx-1">
            <div class="i-local-plus size-full" />
          </div>
        </div>
      </template>
      <div
        v-if="(landscape || !$route.meta.title) && user"
        class="relative ml-2 cursor-pointer"
        @click="showInbox = true"
      >
        <div class="size-7 i-local-mail-line" />
        <h6 v-if="inbox" class="absolute text-xs -top-.5 -right-1 text-center rd-full bg-red size-4">{{ inbox }}</h6>
      </div>
      <template v-if="landscape">
        <DPAvatar v-if="user" class="ml-2 text-xl cursor-pointer" :id="user.id" @click="$router.push('/profile')" />
        <div v-else class="i-local-user size-6 cursor-pointer ml-2" @click="showLogin = true" />
      </template>
    </div>
    <ADrawer class="pt-20 p-4 flex flex-col w-110" v-model="showMenu">
      <div class="flex-1 text-xl space-y-8" un-children="flex items-center cursor-pointer">
        <div @click="openTab('https://blog.lepoker.io')">
          <div class="i-local-blog mr-3 c-gray" />
          {{ $t('BLOG') }}
        </div>
        <div @click="$router.push('/poker-to-mint').then(() => (showMenu = false))">
          <div class="i-local-event mr-3 c-gray" />
          {{ $t('EVENT_TITLE_2') }}
          <div class="ml-3 text-base fw-bold bgi-hot bg-contain bg-no-repeat px-1 pr-5">HOT</div>
        </div>
        <div @click="$router.push('/mall/coin').then(() => (showMenu = false))">
          <div class="i-local-shop mr-3 c-gray" />
          {{ $t('MALL') }}
        </div>
        <div @click="$router.push('/change-log').then(() => (showMenu = false))">
          <div class="i-local-ledger mr-3 c-gray" />
          {{ $t('CHANGE_LOG') }}
        </div>
        <div @click="showLanguage = true">
          <div class="i-local-lang mr-3 c-gray" />
          Language
          <span class="ml-a">{{ lang }}</span>
          <div class="i-local-arrow-l rotate-180 ml-2 c-gray" />
        </div>
        <div @click="help = true">
          <div class="i-local-support mr-3 c-gray" />
          {{ $t('CUSTOMER_SUPPORT') }}
        </div>
        <div @click="handleFeedback">
          <div class="i-local-feedback mr-3 c-gray" />
          {{ $t('FEEDBACK') }}
        </div>
      </div>
      <div class="flex items-center c-gray mt-6">
        <span class="h-.3 bg-gray-700 flex-1 mr-2" />{{ $t('CONTACT_US') }}<span class="ml-2 h-.3 bg-gray-700 flex-1" />
      </div>
      <div class="flex my-4 p-4 justify-between items-center">
        <a href="https://www.facebook.com/lepokerpage" target="_blank">
          <div class="i-local-facebook size-9 c-gray" />
        </a>
        <a href="https://twitter.com/LePokerGame" class="rd-lg bg-gray p-1" target="_blank">
          <div class="i-local-twitter size-7 c-gray-900" />
        </a>
        <a href="https://discord.gg/fzhzcfz3Xp" class="rd-lg bg-gray p-1" target="_blank">
          <div class="i-local-discord size-7 c-gray-900" />
        </a>
        <a href="https://t.me/lepokergame" class="rd-lg bg-gray p-1" target="_blank">
          <div class="i-local-telegram size-7 c-gray-900" />
        </a>
        <a href="https://m.debox.pro/group?id=w8cgmhuv" class="rd-lg bg-gray p-1" target="_blank">
          <div class="i-local-debox size-7 c-gray-900" />
        </a>
      </div>
      <a href="mailto:team@lepoker.io" class="flex bg-gray-800 rd b-solid b-gray-600 p-2 c-gray text-lg">
        <span>team@lepoker.io</span>
        <span class="i-local-mail w-6 h-6 ml-a" />
      </a>
    </ADrawer>
    <ADrawer class="p-4 flex flex-col w-full" landscape="w-120" v-model="showInbox" :position="1">
      <DPInbox />
    </ADrawer>
    <DPCustomerHelp v-model="help" />
  </header>
</template>
<script setup lang="ts">
import { landscape, showLogin, user } from '@/stores';
import { computed, ref } from 'vue';
import { assets, inbox } from '@/stores/user';
import { LANGS } from '@/utils/language';
import { useI18n } from 'vue-i18n';
import { showLanguage } from '@/stores/lang';
import { openTab } from '@/utils/device';
import { formatNumber } from '@/utils/strings';
import { useRoute, useRouter } from 'vue-router';

const { locale } = useI18n();
const router = useRouter();
const route = useRoute();

const lang = computed(() => {
  return LANGS.find((v) => v.val === locale.value)!.label;
});

const showMenu = ref(false);
const showInbox = ref(false);
const help = ref(false);

const back = () => {
  if (history.state.back) router.back();
  else if (route.meta.back) router.replace(route.meta.back);
  else router.replace('/');
};

const handleFeedback = () => {
  if (user.value) {
    router.push('/profile/feedback').then(() => (showMenu.value = false));
  } else showLogin.value = true;
};
</script>
