<script setup lang="ts">
import { useRouter } from 'vue-router';
import { botAPI } from '@/utils/network';
import { loginSocialV2, checkAccountStatus } from '@/utils/user';
import { tgUser, socialToken, platform } from '@/stores';
import { lang } from '@/stores/lang';
import { onMounted, ref } from 'vue';
import { getUser, loading } from '@/tg/ton_index';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const confirm = useConfirm();

const params = new URLSearchParams(window.location.search);
let tgToken = params.get('tgToken');
const router = useRouter();
const play = ref(false);
const showLoading = ref(false);
let noAccount = false;

let userId = '';
let first_name = '';
let last_name = '';
let username = '';

window.addEventListener('message', async ({ data }) => {
  const pdData = data?.playdeck;
  if (!pdData) return;

  console.log('[game 🎾 :]', pdData.method, pdData.value);

  if (pdData.method === 'isOpen') {
    // window.playdeckIsOpen = pdData.value;
  }

  if (pdData.method === 'getUser') {
    pdData.value['authType'] = 'playdeck';
    try {
      const { token } = await botAPI.post<{ token: string }>('tg/auth/', pdData.value);
      socialToken.value = token;
      if (await checkAccountStatus(token)) {
        noAccount = false;
        loading(100);
      } else {
        noAccount = true;
        userId = pdData.value['id'];
        first_name = pdData.value['first_name'];
        last_name = pdData.value['last_name'];
        username = pdData.value['username'];
        if (!username) {
          username = first_name + userId;
        }
        loading(100);
      }

      console.log('success');
    } catch (e) {
      alert(e);
    }
    // window.playdeckUser = pdData.value;
  }

  if (pdData.method === 'getUserLocale') {
    // window.playdeckUserLocale = pdData.value;
  }

  if (pdData.method === 'getScore') {
    // window.playdeckScore = pdData.value;
  }
  if (pdData.method === 'getData') {
    // window.playdeckData = pdData.value;
  }

  if (pdData.method === 'play') {
    play.value = true;
  }

  if (pdData.method === 'pause') {
    // runner.stop();
  }
  if (pdData.method === 'loaded') {
    // loading(100);
  }
});

const telegramEnv = params.get('telegram');

if (telegramEnv) {
  console.log('telegramEnv=' + telegramEnv);
  tgUser.useTgShare = 2;
  platform.value = 'playdeck';
}

async function initAuth() {
  if (tgToken != null) {
    // const pars = window.TelegramGameProxy.initParams;
    // console.info('pars==' + pars);
    // console.info('pars==' + pars['tgShareScoreUrl']);
    // console.info('tgWebAppStartParam==' + tgWebAppStartParam);
    socialToken.value = tgToken;
    try {
      const data = await botAPI.get<{ userID: string; userName: string; avatarUrl: string }>('tg/user/' + tgToken);
      try {
        const result = await loginSocialV2(tgToken);
      } catch (e) {
        console.error(e);
      }
      tgUser.useTgShare = 2;
      play.value = true;
    } catch (e: any) {
      alert(e.msg);
      router.push('/');
    }
  }
}

async function gotoSite(gt: string) {
  // if (!noAccount) {
  showLoading.value = true;
  const tgLoginResult = await loginSocialV2(socialToken.value);
  if (tgLoginResult) {
    showLoading.value = false;
    if (gt == 'private') {
      router.push('/g');
    } else if (gt == 'mtt') {
      router.push('/m');
    }
  }
  // } else {
  //   confirm.require({
  //     message: t('BIND_ACCOUNT_DES'),
  //     header: t('NOTICE'),
  //     acceptLabel: t('BIND_ACCOUNT'),
  //     accept: () => {
  //       linkType.value = 'TG';
  //       linkUserId.value = userId;
  //       linkUserName.value = username;
  //       linkAvatar.value = '';
  //       showLogin.value = true;
  //       if (gt == 'private') {
  //         router.push('/game');
  //       } else if (gt == 'mtt') {
  //         router.push('/mtt');
  //       }
  //     },
  //     rejectLabel: t('CREATE_NEW_ACCOUNT'),
  //     reject: () => {
  //       linkType.value = '';
  //       linkUserId.value = '';
  //       linkUserName.value = '';
  //       linkAvatar.value = '';
  //       showLoading.value = true;
  //       loginSocialV2(socialToken.value);
  //       showLoading.value = false;
  //       if (gt == 'private') {
  //         router.push('/game');
  //       } else if (gt == 'mtt') {
  //         router.push('/mtt');
  //       }
  //     },
  //   });
  // }
}

const updateLang = (v: any) => {
  lang.value = v.value;
};

const langs = [
  { val: 'en', label: 'English' },
  { val: 'cn', label: '中文(简体)' },
  { val: 'tc', label: '中文(繁體)' },
  { val: 'ru', label: 'PyссKИЙ' },
  { val: 'jp', label: '日本語' },
  { val: 'kr', label: '한국어' },
  { val: 'vn', label: 'Tiếng Việt' },
];

onMounted(() => {
  initAuth();
  getUser();
});
</script>
<template>
  <Dialog class="w-100" modal :closable="false" :visible="showLoading" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
    </div>
  </Dialog>
  <header>
    <img src="/static/logo.png" alt="" />
    <h2>{{ $t('home.CREATE_GAME_H') }}</h2>
    <p>{{ $t('home.CREATE_GAME_DESC') }}</p>
  </header>
  <main>
    <!-- <a style="background: #019666" @click="gotoSite('private')">
      <img src="/static/deck-btn-game.png" alt="" />
      <h3>{{ $t('home.NAV_1') }}</h3>
      <p>{{ $t('PLAY_WITH_FRIENDS') }}</p>
    </a> -->
    <a style="background: #016dbb" @click="gotoSite('mtt')">
      <img src="/static/deck-btn-mtt.png" alt="" />
      <h3>{{ $t('JOIN_MTT') }}</h3>
      <p>{{ $t('WIN_BIG_PRIZE') }}</p>
    </a>
    <!-- <div class="flex place-content-center w-full">
      <Dropdown
        class="w-100px h-30px lang-select mr-2"
        v-model="$i18n.locale"
        @change="updateLang"
        option-label="label"
        option-value="val"
        :options="langs"
      />
    </div> -->
  </main>
</template>
<style scoped>
header {
  background: url(/static/table.png) no-repeat top center/cover;
  padding: 20vw 7vw 50vw;
  text-align: center;
}
header img {
  height: 9vw;
}
header h2 {
  font-size: 7vw;
  padding: 2vw;
  color: white;
}
header p {
  font-size: 4vw;
  color: rgb(211, 211, 211);
}
main {
  padding: 0 7vw;
}
main a {
  display: block;
  margin-bottom: 5vw;
  padding: 6vw;
  border-radius: 3vw;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
main h3 {
  font-size: 4vw;
  margin-bottom: 2vw;
}
main p {
  font-size: 3vw;
}
main img {
  float: right;
  height: 26vw;
  margin-top: -9vw;
  margin-right: -5vw;
}
</style>
