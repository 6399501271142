<script setup lang="ts">
import { useRouter } from 'vue-router';
import { socialLink, loginSocialV2, checkAccountStatus } from '@/utils/user';
import { botAPI } from '@/utils/network';
import {
  userTask,
  user,
  discordUser,
  socialToken,
  showBindAccount,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
} from '@/stores';
import { onMounted, ref } from 'vue';
import { convertToLangeLabel } from '@/utils/strings';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const params = new URLSearchParams(window.location.search);
let state = params.get('state');
const dataMap = Object.fromEntries(params.entries());
const showWaiting = ref(true);
const router = useRouter();

if (state != null && state.indexOf('---showQuest') > 0) {
  userTask.showQuest = true;
  state = state.replace('---showQuest', '');
}

async function initAuth() {
  if (user.value) dataMap['userId'] = user.value.id;
  if (!dataMap['code']) {
    return;
  }
  try {
    const data = await botAPI.post<{
      token: string;
      userId: string;
      username: string;
      avatar: string;
    }>('discord/auth', dataMap);
    discordUser.id = data.userId;
    discordUser.username = data.username;
    discordUser.avatar = data.avatar;
    socialToken.value = data.token;
    if (state != null && state.indexOf('---linkDiscord') > 0) {
      state = state.replace('---linkDiscord', '');
      showWaiting.value = false;
      try {
        await socialLink(discordUser.id, discordUser.username, discordUser.avatar, 'DISCORD');
      } catch (e: any) {
        discordUser.id = '';
        discordUser.username = '';
        discordUser.avatar = '';
        socialToken.value = '';
        userTask.showQuest = false;
        alert(i18n.t(convertToLangeLabel(e)));
      }
      await gotoSite(false);
    } else {
      if (await checkAccountStatus(data.token)) {
        await loginSocialV2(data.token);
        await gotoSite(true);
      } else {
        if (state!.indexOf('---bindDiscord') > 0) {
          showBindAccount.value = 3;
        } else {
          linkType.value = 'DISCORD';
          linkUserId.value = data.userId;
          linkUserName.value = data.username;
          linkAvatar.value = data.avatar;
          showBindAccount.value = 1;
        }
        await gotoSite(false);
      }
    }
  } catch (e: any) {
    userTask.showQuest = false;
    alert(e);
    await gotoSite(false);
  }
}

async function gotoSite(login: boolean) {
  if (state) {
    state = state.replace('---bindDiscord', '');
    showWaiting.value = false;
    // if (login) {
    //   history.replaceState(history.state, '', decodeURIComponent(state!));
    //   router.replace(decodeURIComponent(state));
    // } else {
    router.replace(decodeURIComponent(state));
    // }
  }
}

onMounted(async () => {
  await initAuth();
});
</script>
<template>
  <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
      <h5 class="mt-4">{{ $t('discord.LOGIN') }}</h5>
    </div>
  </Dialog>
</template>
