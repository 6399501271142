<template>
  <canvas v-if="path" :key="path" ref="el"></canvas>
</template>
<script setup lang="ts">
import { pag } from '@/utils/device';
import { onUnmounted, ref, watch } from 'vue';

const props = withDefaults(defineProps<{ path: string; loop?: boolean; autoplay?: boolean }>(), {
  loop: false,
  autoplay: true,
});
const emit = defineEmits<{
  loaded: [HTMLCanvasElement];
  complete: [];
  'loop-complete': [];
}>();
let view: any;

const play = () => view?.play();

const el = ref<HTMLCanvasElement>();

watch(el, (v) => {
  if (!v || view) return;
  load(v);
});

const load = (el: HTMLCanvasElement) => {
  if (!el) return;
  pag.then((PAG: any) => {
    fetch(props.path)
      .then((response) => response.blob())
      .then(async (blob) => {
        const file = new window.File([blob], props.path.replace(/(.*\/)*([^.]+)/i, '$2'));
        const pagFile = await PAG.PAGFile.load(file);
        el.width = pagFile.width();
        el.height = pagFile.height();
        view = await PAG.PAGView.init(pagFile, el);
        if (!view) return;
        emit('loaded', el);
        view.setRepeatCount(props.loop ? 0 : 1);
        view.addListener('onAnimationEnd', () => emit('complete'));
        view.addListener('onAnimationRepeat', () => emit('loop-complete'));
        if (props.autoplay) view.play();
      });
  });
};

onUnmounted(() => {
  view?.destroy();
});

defineExpose({ play });
</script>
