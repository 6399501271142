<template>
  <div class="flex justify-between c-gray-600 focus-within:c-green" @click="reset">
    <div class="absolute -z-10 op-0"><AInput :type="type" v-model="val" ref="input" :limit="len" /></div>
    <div
      v-for="i in len"
      :key="i"
      class="rd h-2.2em w-2.3em flex justify-center items-center bg-gray-700 b-solid"
      :class="val.length === i - 1 ? 'b-current' : 'b-gray-600'"
    >
      <span class="c-gray-50 fw-bold text-xl">
        {{ val[i - 1] || '' }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const input = ref();
const val = defineModel<string>({ default: '' });
defineProps<{ len: number; type?: string }>();

function reset() {
  val.value = '';
  input.value.input.focus();
}
</script>
