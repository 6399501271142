import { Client, type StompSubscription } from '@stomp/stompjs';
import { onUnmounted } from 'vue';
import { token, updateToken } from './network';
import EventEmitter from 'eventemitter3';
import { assets, inbox } from '@/stores/user';

let reslove: (c: Client) => void;
const promise = new Promise<Client>((r) => (reslove = r));

const stompEmitter = new EventEmitter();

const client = new Client({
  brokerURL: `wss://${import.meta.env.VITE_AUTH_HOST}/legame-socket/v1/ws`,
  reconnectDelay: 2000,
  heartbeatIncoming: 20000,
  heartbeatOutgoing: 20000,
  beforeConnect: () => {
    client.connectHeaders = token.value ? { Authorization: `Bearer ${token.value}` } : {};
  },
  onConnect: () => {
    reslove(client);
    stompEmitter.emit('connect', client);
    if (!token.value) return;
    assets.value = {};
    // client.subscribe('/user/diamondBalance', ({ body }) => Object.assign(assets.value!, JSON.parse(body)));
    client.subscribe('/user/balances', ({ body }) => Object.assign(assets.value!, JSON.parse(body)));
    client.subscribe('/user/inbox', ({ body }) => (inbox.value = JSON.parse(body).unreadCount));
  },
  onStompError: (frame) => {
    // console.error('stomp:', frame);
    if (frame.headers.code !== '400') updateToken();
  },
});

export const connectSocket = () => {
  if (client.active) client.forceDisconnect();
  else client.activate();
};

export const useTopic = (topic: string, callback: (data: any, c: Client) => void) => {
  let subscription: StompSubscription | undefined;
  let unmounted = false;
  const fn = (c: Client) => {
    subscription = c.subscribe(topic, ({ body }) => {
      callback(JSON.parse(body), c);
    });
  };
  promise.then((c) => {
    if (unmounted) return;
    fn(c);
    stompEmitter.on('connect', fn);
  });
  const close = () => {
    if (unmounted) return;
    subscription?.unsubscribe();
    stompEmitter.off('connect', fn);
    unmounted = true;
  };
  onUnmounted(close);
  return { close };
};
