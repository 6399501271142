<template>
  <Teleport to="body">
    <div
      v-if="imgs.length"
      class="fixed inset-0 flex space-x-2 items-center justify-center bg-black/50 z-500"
      @click.self="close"
    >
      <AButton type="secondary" :disabled="index < 1" icon="i-local-arrow-l" @click="index--" />
      <img :src="imgs[index]" class="w-200 max-h-80vh object-contain" portrait="w-96" @click="close" />
      <AButton
        type="secondary"
        :disabled="index >= imgs.length - 1"
        class="rotate-180"
        icon="i-local-arrow-l"
        @click="index++"
      />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const imgs = defineModel<string[]>({ default: [] });
const index = ref(0);

const close = () => {
  imgs.value = [];
  index.value === 0;
};
</script>
