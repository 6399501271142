<template>
  <div>{{ data }}</div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

const data = ref('');
await new Promise((resolve, rej) => {
  setTimeout(() => {
    data.value = 'hello';
    rej(0);
  }, 1000);
});
</script>
