<template>
  <div class="flex flex-col justify-center text-center items-center pb-8">
    <div class="w-70 h-70 relative">
      <template v-if="currentType === 0">
        <APAG class="w-70" :path="coinAnimate" :autoplay="true" @complete="next" />
      </template>
      <template v-if="currentType === 1">
        <APAG class="w-70" :path="diamondAnimate" :autoplay="true" @complete="next" />
      </template>
      <template v-if="currentType === 2">
        <APAG class="w-70" :path="taskBgAnimate" :autoplay="true" @complete="next" @loaded="start = true" />
        <img
          v-if="start"
          src="@/assets/img/task-experience.png"
          class="absolute animate-duration-1000 animate-fade-in w-20 top-20 left-25"
        />
      </template>
      <template v-if="currentType === 3">
        <APAG class="w-70" :path="taskBgAnimate" :autoplay="true" @complete="next" @loaded="start = true" />
        <img v-if="start" :src="titlePic" class="absolute animate-duration-1000 animate-fade-in w-40 top-8 left-15" />
      </template>
    </div>
    <div v-if="currentType === 0" class="text-8 fw-bold mt--16">{{ $t('COIN') }} x {{ props.coin }}</div>
    <div v-if="currentType === 1" class="text-8 fw-bold mt--16">{{ $t('DIAMOND') }} x {{ props.diamond }}</div>
    <div v-if="currentType === 2" class="text-8 fw-bold mt--16">{{ $t('EXP') }} x {{ props.experience }}</div>
    <div v-if="currentType === 3" class="text-8 fw-bold mt--16">{{ $t(props.title) }} {{ $t('AVATAR_FRAME') }}</div>
    <div class="text-xl mt-2">
      {{ $t('PLATFORM_TASK_CLAIM_TITLE') }} {{ props.coin ? props.coin + $t('COIN') : '' }}
      {{ props.diamond ? props.diamond + $t('DIAMOND') : '' }}
      {{ props.title ? $t('AVATAR_FRAME') : '' }}
      {{ props.experience ? props.experience + $t('EXP') : '' }}
    </div>
  </div>
</template>
<script setup lang="tsx">
import { computed, ref } from 'vue';
import coinAnimate from '@/assets/pag/coin.pag?url';
import diamondAnimate from '@/assets/pag/diamond.pag?url';
import taskBgAnimate from '@/assets/pag/task-bg.pag?url';

const start = ref(false);

const props = withDefaults(
  defineProps<{
    coin?: number;
    diamond?: number;
    experience?: number;
    title?: string;
  }>(),
  { coin: 0, diamond: 0, experience: 0, title: '' },
);

const types = computed(() => {
  const types = [];
  if (props.coin) types.push(0);
  if (props.diamond) types.push(1);
  if (props.experience) types.push(2);
  if (props.title) types.push(3);
  return types;
});

const currentTypeIndex = ref(0);

const currentType = computed(() => {
  if (types.value.length === 0) return -1;
  return types.value[currentTypeIndex.value];
});

const next = () => {
  if (types.value.length === currentTypeIndex.value + 1) return;
  start.value = false;
  currentTypeIndex.value += 1;
};

const titlePic = computed(() => {
  if (!props.title) return '/avatar-border/border1.png';
  return '/avatar-border/border' + props.title.replace('TITLE_', '') + '.png';
});
</script>
