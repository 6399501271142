// import router from '@/router';

let popZIndex = 100;
// router.beforeEach(() => {
//   popZIndex = 100;
// });

const getElementPos = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    right: rect.left + rect.width,
    bottom: rect.top + rect.height,
    width: rect.width,
    height: rect.height,
    wc: rect.width / 2 + rect.left,
    hc: rect.height / 2 + rect.top,
  };
};

export const setPopStyle = (el: HTMLElement, pop: HTMLElement, pos = 5, withZIndex = true) => {
  if (withZIndex) pop.style.zIndex = String(popZIndex++);
  const elPos = getElementPos(el);
  console.log(elPos);
  if ([0, 1, 2].includes(pos)) pop.style.top = elPos.top + 'px';
  else if ([3, 7].includes(pos)) pop.style.top = elPos.hc + 'px';
  else pop.style.top = elPos.bottom + 'px';
  if ([2, 3, 4].includes(pos)) pop.style.left = elPos.right + 'px';
  else if ([1, 5].includes(pos)) pop.style.left = elPos.wc + 'px';
  else pop.style.left = elPos.left + 'px';
};
