<template>
  <Dialog v-model:visible="show" :header="$t('CHOOSE_WALLET')" :draggable="false" modal>
    <div class="p-1 flex flex-col min-w-100 relative">
      <div class="flex flex-row justify-between mt-4">
        <div
          v-if="props.chain == 'bitlayer'"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="emits('loginWeb3', 'debox')"
        >
          <div class="mr-2 mb-2 p-2 rd-lg bg-green">
            <div class="i-local-debox size-8" />
          </div>
          DeBox
        </div>
        <div
          v-if="!['ton', 'bitlayer'].includes(props.chain)"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="emits('loginWeb3', 'okx')"
        >
          <div class="mr-2 mb-2 p-2 rd-lg bg-black">
            <div class="i-local-okx-wallet size-8" />
          </div>
          {{ $t('X_WALLET', { v: 'OKX' }) }}
        </div>
        <div
          v-if="!['ton', 'bitlayer', 'solana'].includes(props.chain)"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="emits('loginWeb3', 'metamask')"
        >
          <div class="i-local-metamask text-4xl mr-2 mb-2 h-12 w-12" />
          {{ $t('X_WALLET', { v: 'MetaMask' }) }}
        </div>
        <div
          v-if="props.chain == 'ton'"
          class="w-full cursor-pointer flex flex-1 rd-lg h-40 w-32 p-2 mr-2 flex-col justify-center items-center bg-gray-700 fw-semibold c-white"
          @click="emits('loginWeb3', 'ton-keeper')"
        >
          <div class="i-local-tonkeeper text-4xl mr-2 mb-2 h-12 w-12" />
          {{ $t('X_WALLET', { v: 'TON keeper' }) }}
        </div>
        <div
          v-if="['ton', 'bitlayer'].includes(props.chain)"
          class="w-full flex flex-1 rd-lg h-40 w-32 p-2 ml-2 flex-col justify-center items-center bg-gray-700 op-30"
        >
          <i class="mr-2 mb-2 i-local-wallet text-3.2rem" /> {{ $t('COMING_SOON') }}...
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
const props = defineProps<{ chain: string }>();
const show = defineModel<boolean>();
const emits = defineEmits<{ loginWeb3: [string]; chooseChain: [number, string] }>();
</script>
