<template>
  <div v-if="user" class="py-4">
    <ATitle class="mb-4 fw-bold">{{ $t('ACCOUNT_SECURITY') }}</ATitle>
    <div class="mb-8">
      <div class="w-116 flex items-center space-x-2">
        <div class="size-13 p-2 rd-full bg-green"><div class="i-local-mail size-full"></div></div>
        <div class="flex-1">
          <div class="flex items-center">
            <h5>{{ $t('EMAIL') }}</h5>
            <i v-if="user.registerChannel === 'EMAIL'" class="i-local-main-account size-4 ml-1.5" />
          </div>
          <p v-if="user.email" class="c-green">{{ user.email }}</p>
          <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
        </div>
        <AButton v-if="!user.email" class="text-sm" @click="showBindEmail = true">{{ $t('CONNECT') }}</AButton>
        <AButton v-else-if="user.registerChannel !== 'EMAIL'" type="secondary" class="text-sm" @click="unbindEmail">
          {{ $t('DISCONNECT') }}
        </AButton>
      </div>
    </div>
    <template v-if="platform !== 'playdeck'">
      <ATitle class="mb-4 fw-bold">{{ $t('CONNECTED_ACCOUNTS') }}</ATitle>
      <div class="grid gap-6 mb-8" landscape="grid-cols-2" un-children="w-116 flex items-center space-x-2">
        <div>
          <img class="size-13" src="@/assets/img/icon-discord.png" alt="" />
          <div class="flex-1">
            <div class="flex items-center">
              <h5>Discord</h5>
              <div v-if="user.registerChannel === 'DISCORD'" class="i-local-main-account size-4 ml-1.5" />
            </div>
            <p v-if="discordUser.id" class="c-green">{{ discordUser.username }}</p>
            <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
          </div>
          <AButton v-if="!discordUser.id" class="text-sm" @click="linkDiscord">{{ $t('CONNECT') }}</AButton>
          <AButton
            v-else-if="user.registerChannel !== 'DISCORD'"
            type="secondary"
            class="text-sm"
            @click="unlinkAccount(discordUser.id, 'DISCORD')"
          >
            {{ $t('DISCONNECT') }}
          </AButton>
        </div>
        <div v-if="platform !== 'tgMiniApp'">
          <img class="size-13" src="@/assets/img/icon-telegram.png" alt="" />
          <div class="flex-1">
            <div class="flex items-center">
              <h5>Telegram</h5>
              <div v-if="user.registerChannel === 'TG'" class="i-local-main-account size-4 ml-1.5" />
            </div>
            <p v-if="tgUser.id" class="c-green">{{ tgUser.username }}</p>
            <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
          </div>
          <ButtonLogin
            v-if="!tgUser.id"
            :botUsername="botName"
            :hideUserPic="true"
            mode="callback"
            requestAccess="write"
            @callback="loginWithTelegram"
          >
            <AButton class="text-sm">{{ $t('CONNECT') }}</AButton>
          </ButtonLogin>
          <AButton
            v-else-if="user.registerChannel !== 'TG'"
            type="secondary"
            class="text-sm"
            @click="unlinkAccount(tgUser.id + '', 'TG')"
          >
            {{ $t('DISCONNECT') }}
          </AButton>
        </div>
        <div>
          <div class="rd-full size-13 bg-white p-3 flex items-center justify-center">
            <div class="i-local-twitter size-full c-black" />
          </div>
          <div class="flex-1">
            <div class="flex items-center">
              <h5>X</h5>
              <div v-if="user.registerChannel === 'X'" class="i-local-main-account size-4 ml-1.5" />
            </div>
            <p v-if="xUser.id" class="c-green">{{ xUser.username }}</p>
            <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
          </div>
          <AButton v-if="!xUser.id" class="text-sm" @click="linkX">{{ $t('CONNECT') }}</AButton>
          <AButton
            v-else-if="user.registerChannel !== 'X'"
            type="secondary"
            class="text-sm"
            @click="unlinkAccount(xUser.id, 'X')"
          >
            {{ $t('DISCONNECT') }}
          </AButton>
        </div>
        <div>
          <div class="rd-full bg-green p-3 size-13 flex items-center justify-center">
            <div class="i-local-debox size-full c-white" />
          </div>
          <div class="flex-1">
            <div class="flex">
              <h5>DeBox</h5>
              <div v-if="user.registerChannel === 'DEBOX'" class="i-local-main-account size-4 ml-1.5" />
            </div>
            <p v-if="deboxUser.id" class="c-green">{{ deboxUser.username }}</p>
            <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
          </div>
          <AButton v-if="!deboxUser.id" class="text-sm" @click="linkDebox">{{ $t('CONNECT') }}</AButton>
          <AButton
            v-else-if="user.registerChannel !== 'DEBOX'"
            type="secondary"
            class="text-sm"
            @click="unlinkAccount(deboxUser.id, 'DEBOX')"
          >
            {{ $t('DISCONNECT') }}
          </AButton>
        </div>
      </div>
      <ATitle class="mb-4 fw-bold mb-8">{{ $t('CONNECT_WEB3_NETWORKS') }}</ATitle>
      <div class="grid gap-6" landscape="grid-cols-2" un-children="w-116 flex items-center space-x-2">
        <div>
          <img class="size-13" src="@/assets/img/ton.svg" />
          <div class="flex-1">
            <div class="flex items-center">
              <h5>TON</h5>
              <div v-if="user.registerChannel === 'WALLET_TON#TON'" class="i-local-main-account size-4 ml-1.5" />
            </div>
            <div
              v-if="tonUser.address"
              class="bg-gray-700 c-gray text-xs rd-full px-2 cursor-pointer"
              @click="copyAddress(tonUser.address)"
            >
              {{ getWeb3OmitAdress(tonUser.address) }}
              <i class="i-local-copy size-3 inline-block" />
            </div>
            <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
          </div>
          <AButton v-if="!tonUser.address" class="text-sm" @click="linkWeb3('ton')">{{ $t('CONNECT') }}</AButton>
          <AButton
            v-else-if="user.registerChannel !== 'WALLET_TON#TON'"
            type="secondary"
            class="text-sm"
            @click="unlinkAccount(tonUser.address, 'WALLET_TON#TON')"
          >
            {{ $t('DISCONNECT') }}
          </AButton>
        </div>
        <template v-if="platform != 'tgMiniApp'">
          <div>
            <img class="size-13" src="@/assets/img/icon-eth.png" />
            <div class="flex-1">
              <div class="flex items-center">
                <h5>ETH</h5>
                <div v-if="user.registerChannel === 'WALLET_EVM#ETH'" class="i-local-main-account size-4 ml-1.5" />
              </div>
              <div
                v-if="ethUser.address"
                class="bg-gray-700 c-gray text-xs rd-full px-2 cursor-pointer"
                @click="copyAddress(ethUser.address)"
              >
                {{ getWeb3OmitAdress(ethUser.address) }}
                <i class="i-local-copy size-3 inline-block" />
              </div>
              <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
            </div>
            <AButton v-if="!ethUser.address" class="text-sm" @click="linkWeb3('eth')">{{ $t('CONNECT') }}</AButton>
            <AButton
              v-else-if="user.registerChannel !== 'WALLET_EVM#ETH'"
              type="secondary"
              class="text-sm"
              @click="unlinkAccount(ethUser.address, 'WALLET_EVM#ETH')"
            >
              {{ $t('DISCONNECT') }}
            </AButton>
          </div>
          <div>
            <img class="size-13" src="@/assets/img/bnb.svg" />
            <div class="flex-1">
              <div class="flex items-center">
                <h5>BNB</h5>
                <div v-if="user.registerChannel === 'WALLET_EVM#BSC'" class="i-local-main-account size-4 ml-1.5" />
              </div>
              <div
                v-if="bnbUser.address"
                class="bg-gray-700 w-max c-gray text-xs rd-full px-2 cursor-pointer"
                @click="copyAddress(bnbUser.address)"
              >
                {{ getWeb3OmitAdress(bnbUser.address) }}
                <i class="i-local-copy size-3 inline-block" />
              </div>
              <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
            </div>
            <AButton v-if="!bnbUser.address" class="text-sm" @click="linkWeb3('bsc')">{{ $t('CONNECT') }}</AButton>
            <AButton
              v-else-if="user.registerChannel !== 'WALLET_EVM#BSC'"
              type="secondary"
              class="text-sm"
              @click="unlinkAccount(bnbUser.address, 'WALLET_EVM#BSC')"
            >
              {{ $t('DISCONNECT') }}
            </AButton>
          </div>
          <div>
            <img class="size-13" src="@/assets/img/icon-solana.png" />
            <div class="flex-1">
              <div class="flex items-center">
                <h5>Solana</h5>
                <div v-if="user.registerChannel === 'WALLET_SOL#SOL'" class="i-local-main-account size-4 ml-1.5" />
              </div>
              <div
                v-if="solanaUser.address"
                class="bg-gray-700 w-max c-gray text-xs rd-full px-2 cursor-pointer"
                @click="copyAddress(solanaUser.address)"
              >
                {{ getWeb3OmitAdress(solanaUser.address) }}
                <i class="i-local-copy size-3 inline-block" />
              </div>
              <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
            </div>
            <AButton v-if="!solanaUser.address" class="text-sm" @click="linkWeb3('sol')">{{ $t('CONNECT') }}</AButton>
            <AButton
              v-else-if="user.registerChannel !== 'WALLET_SOL#SOL'"
              type="secondary"
              class="text-sm"
              @click="unlinkAccount(solanaUser.address, 'WALLET_SOL#SOL')"
            >
              {{ $t('DISCONNECT') }}
            </AButton>
          </div>
          <div>
            <img class="size-13" src="@/assets/img/icon-zks.png" />
            <div class="flex-1">
              <div class="flex items-center">
                <h5>ZkSync</h5>
                <div v-if="user.registerChannel === 'WALLET_EVM#ZKS'" class="i-local-main-account size-4 ml-1.5" />
              </div>
              <div
                v-if="zksyncUser.address"
                class="bg-gray-700 w-max c-gray text-xs rd-full px-2 cursor-pointer"
                @click="copyAddress(zksyncUser.address)"
              >
                {{ getWeb3OmitAdress(zksyncUser.address) }}
                <i class="i-local-copy size-3 inline-block" />
              </div>
              <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
            </div>
            <AButton v-if="!zksyncUser.address" class="text-sm" @click="linkWeb3('zksync')">{{
              $t('CONNECT')
            }}</AButton>
            <AButton
              v-else-if="user.registerChannel !== 'WALLET_EVM#ZKS'"
              type="secondary"
              class="text-sm"
              @click="unlinkAccount(zksyncUser.address, 'WALLET_EVM#ZKS')"
            >
              {{ $t('DISCONNECT') }}
            </AButton>
          </div>
          <div>
            <img class="size-13" src="@/assets/img/icon-linea.png" />
            <div class="flex-1">
              <div class="flex items-center">
                <h5>Linea</h5>
                <div v-if="user.registerChannel === 'WALLET_EVM#LINEA'" class="i-local-main-account size-4 ml-1.5" />
              </div>
              <div
                v-if="lineaUser.address"
                class="bg-gray-700 w-max c-gray text-xs rd-full px-2 cursor-pointer"
                @click="copyAddress(lineaUser.address)"
              >
                {{ getWeb3OmitAdress(lineaUser.address) }}
                <i class="i-local-copy size-3 inline-block" />
              </div>
              <p v-else class="c-gray-600">{{ $t('NOT_CONNECTED') }}</p>
            </div>
            <AButton v-if="!lineaUser.address" class="text-sm" @click="linkWeb3('linea')">{{ $t('CONNECT') }}</AButton>
            <AButton
              v-else-if="user.registerChannel !== 'WALLET_EVM#LINEA'"
              type="secondary"
              class="text-sm"
              @click="unlinkAccount(lineaUser.address, 'WALLET_EVM#LINEA')"
            >
              {{ $t('DISCONNECT') }}
            </AButton>
          </div>
        </template>
      </div>
    </template>
    <DPWeb3Wallet v-model="showWeb3Wallet" :chain="chain" @loginWeb3="loginWeb3" />
    <ADialog class="w-100" v-model="showBindEmail" :title="$t('CONNECT_WITH_EMAIL')">
      <DPBindEmail connectType="connect" @callback="showBindEmail = false" />
    </ADialog>
  </div>
</template>
<script setup lang="ts">
import {
  deboxUser,
  discordUser,
  tgUser,
  user,
  xUser,
  bnbUser,
  solanaUser,
  zksyncUser,
  ethUser,
  lineaUser,
  tonUser,
  platform,
} from '@/stores';
import { botAPI } from '@/utils/network';
import { socialLink, socialUnlink, emailOperation } from '@/utils/user';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
import { okxLogin } from '@/utils/okx';
import { tonLogin } from '@/utils/ton';
import { useClipboard } from '@vueuse/core';
import { connectX } from '@/utils/twitter';
import { connectDiscord } from '@/utils/discord';
import { connectDebox } from '@/utils/debox';
import { metamaskLogin } from '@/utils/metamask';
import { toast } from '@/components/ui/AToast.vue';

const { copy } = useClipboard({ legacy: true });

const { t } = useI18n();
const showWeb3Wallet = ref(false);
const showBindEmail = ref(false);

const unbindEmail = async () => {
  try {
    await emailOperation(user.value!.email, 3);
    location.reload();
  } catch (e: any) {
    toast.error(t(convertToLangeLabel(e.msg ? e.msg : e)));
  }
};

const linkDiscord = async () => {
  await connectDiscord('---linkDiscord');
};

const linkDebox = async () => {
  await connectDebox('---linkDebox');
};

let clickLoginX = false;
const linkX = async () => {
  if (!clickLoginX) {
    clickLoginX = true;
    await connectX('---linkX');
  }
};

const botName = import.meta.env.VITE_TG_BOT;

const unlinkAccount = async (socialUserId: string, linkType: string) => {
  try {
    await socialUnlink(socialUserId, linkType);
    location.reload();
  } catch (e: any) {
    toast.error(t(convertToLangeLabel(e)));
  }
};

const loginWithTelegram = async (userLoginUser: any) => {
  let userName = '';
  userName = userLoginUser['username'];
  const firstName = userLoginUser['first_name'];
  const lastName = userLoginUser['last_name'];
  if (!userName && firstName) {
    userName = firstName + userLoginUser['id'];
  } else if (!userName && lastName) {
    userName = lastName + userLoginUser['id'];
  }

  try {
    const userID = await socialLink(userLoginUser['id'], userName, userLoginUser['photo_url'], 'TG');
    userLoginUser['authType'] = 'login';
    userLoginUser['lepokerUserId'] = userID;
    try {
      await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
      tgUser.id = userLoginUser['id'];
      tgUser.username = userName;
    } catch (e: any) {
      toast.error(t(convertToLangeLabel(e.message)));
    }
  } catch (error: any) {
    toast.error(t(convertToLangeLabel(error)));
  }
};

const chain = ref('');
const linkWeb3 = async (chainType: string) => {
  chain.value = chainType;
  showWeb3Wallet.value = true;
};

const loginWeb3 = async (wallet: string) => {
  try {
    if (wallet == 'okx') {
      await okxLogin(chain.value, 'link');
    } else if (wallet === 'ton-keeper') {
      await tonLogin('link');
    } else if (wallet === 'metamask') {
      await metamaskLogin(chain.value, 'link');
    }
  } catch (msg: any) {
    toast.error(t(convertToLangeLabel(msg)));
  }
  showWeb3Wallet.value = false;
};

const getWeb3OmitAdress = (address: string) => `${address.slice(0, 6)}...${address.slice(-5)}`;
const copyAddress = (address: string) => {
  copy(address);
  toast.success(t('game.COPIED'));
};
</script>
