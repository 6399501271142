<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    taskList: string[];
  }>(),
  { taskList: () => [] },
);

const taskTypes = ['X', 'TG', 'DISCORD', 'WEB3', 'YOUTUBE'];

const taskList = computed(() => {
  let result = [];
  let flag = false;
  props.taskList.forEach((item) => {
    if (~taskTypes.indexOf(item)) {
      result.push(item);
    } else if (item) {
      flag = true;
    }
  });

  if (flag) result.push('OTHER');

  return result;
});
</script>

<template>
  <div class="flex space-x-1">
    <div class="b-solid b-1 b-white b-op-20 p-0.3 rd-1.5" v-for="item in taskList" v-show="item" v-bind:key="item">
      <div
        :class="{
          'i-local-task-x': item === 'X',
          'i-local-task-tg': item === 'TG',
          'i-local-task-discord': item === 'DISCORD',
          'i-local-task-web3': item === 'WEB3',
          'i-local-task-youtube': item === 'YOUTUBE',
          'i-local-task-other': item === 'OTHER',
        }"
        class="w-4 h-4"
      />
    </div>
  </div>
</template>
