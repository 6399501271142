import type { Directive, Plugin } from 'vue';
import log from '@/utils/log';

const removeScrollBar = () => {
  const div = document.createElement('div');
  div.style.cssText = 'width:100px;height:100px;overflow:scroll;position:absolute;top:-9999px;';
  document.body.appendChild(div);
  const scrollbarWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  if (!scrollbarWidth) return;
  const style = document.createElement('style');
  style.innerHTML =
    '::-webkit-scrollbar {width: 5px;height:5px;} ::-webkit-scrollbar-thumb { background: #636363; border-radius: 3px; }';
  document.head.appendChild(style);
};

export default <Plugin>{
  install: (app) => {
    removeScrollBar();
    app.directive('btn', <Directive<HTMLElement>>{
      created: (el, binding) => {
        let ts = 0;
        el.addEventListener('click', (e) => {
          if (ts && Date.now() - ts < 500) return e.stopImmediatePropagation();
          if (binding.arg) log.click(binding.arg); // log by binding.arg
          ts = Date.now();
        });
      },
    });
    // app.directive('repeat', <Directive<HTMLElement>>{
    //   created: (el, binding) => {
    //     let timer: number | undefined;
    //     document.addEventListener(device.desktop ? 'mouseup' : 'touchend', () => timer && clearTimeout(timer));
    //     el.addEventListener(device.desktop ? 'mousedown' : 'touchstart', () => {
    //       (function fun(delay: number) {
    //         binding.value();
    //         timer = window.setTimeout(() => fun(Math.max(10, delay * 0.95)), delay);
    //       })(400);
    //     });
    //   },
    // });
    // app.directive('role', (el, binding) => {
    //   if (!el.parentNode) return;
    //   if (binding.value.includes(user.value?.role)) return;
    //   el.remove();
    // });
  },
};
