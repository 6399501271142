import { refCode, updateWalletAccount } from '@/stores/user';
import { refreshToken, token, authAPI, botAPI } from './network';
import { userTask, linkType, linkUserId, linkUserName, linkAvatar, user, showLogin, tgUser } from '@/stores';
import { upload } from '@/utils/general';

const userMap: Record<string, string> = {};

const url = new URL(location.href);
const urlRef = url.searchParams.get('r');
const urlLogin = url.searchParams.get('login');
if (urlLogin) showLogin.value = true;
if (urlRef) {
  localStorage.setItem('refCode', urlRef);
  url.searchParams.delete('r');
  history.replaceState(history.state, '', url.href);
}

export const sendCode = async (name: string) => {
  const data = await authAPI.post<{ challengeName: string; session: string }>('requestOtp?username=' + name);
  if (data.challengeName === 'CUSTOM_CHALLENGE') {
    userMap[name] = data.session;
  }
};

export const login = async (name: string, code: string) => {
  const url = userMap[name] !== undefined ? 'otpSignIn' : 'confirmSignUp';
  try {
    const data = await authAPI.post<any>(url, {
      username: name,
      otp: code,
      confirmationCode: code,
      session: userMap[name],
      invitationCode: localStorage.getItem('refCode'),
    });
    if (!data.isSuccessful) {
      userMap[name] = data.session;
      if (data.challengeParameters?.attemptsLeft) throw 'invalid OTP';
      throw 'invalid OTP. Try to get a new one';
    } else {
      token.value = data.authenticationResult.accessToken;
      refreshToken.value = data.authenticationResult.refreshToken;
    }
  } catch (e: any) {
    throw e.msg || e;
  }
};

export const loginSocialV2 = async (accessToken: string, email = '') => {
  try {
    const data = await authAPI.post<any>('social_account_auth', {
      accessToken: accessToken,
      invitationCode: localStorage.getItem('refCode'),
      email: email,
    });
    if (!data.isSuccessful) {
      return false;
    } else {
      token.value = data.authenticationResult.accessToken;
      refreshToken.value = data.authenticationResult.refreshToken;
      if (linkType.value) {
        await socialLink(linkUserId.value, linkUserName.value, linkAvatar.value, linkType.value);
        linkType.value = '';
        linkUserId.value = '';
        linkUserName.value = '';
        linkAvatar.value = '';
        location.reload();
      }
      return true;
    }
  } catch (e: any) {
    userTask.showQuest = false;
  }
};

export const checkAccountStatus = async (accessToken: string) => {
  try {
    const data = await authAPI.post<{ registerStatus: number; emailLinkStatus: number; email: string }>(
      'check_account_status',
      { accessToken: accessToken },
    );
    if (data.registerStatus) {
      return true;
    } else {
      return false;
    }
  } catch (e: any) {
    return false;
  }
};

export const loginWithTelegram = async (userLoginUser: any) => {
  let userName = '';
  userName = userLoginUser['username'];
  const firstName = userLoginUser['first_name'];
  const lastName = userLoginUser['last_name'];
  if (!userName && firstName) {
    userName = firstName + userLoginUser['id'];
  } else if (!userName && lastName) {
    userName = lastName + userLoginUser['id'];
  }
  const userID = await socialLink(userLoginUser['id'], userName, userLoginUser['photo_url'], 'TG');
  userLoginUser['authType'] = 'login';
  userLoginUser['lepokerUserId'] = userID;
  await botAPI.post<{ token: string }>('tg/auth/', userLoginUser);
  tgUser.id = userLoginUser['id'];
  tgUser.username = userName;
};

export const socialLink = async (userId: string, username: string, photo_url: string, socialAppType: string) => {
  try {
    const result = await authAPI.post<string>('v1/user/link_account', {
      userID: userId,
      userName: username,
      avatarUrl: photo_url,
      socialAppType: socialAppType,
    });
    if (socialAppType.indexOf('WALLET') >= 0) {
      updateWalletAccount(userId, socialAppType);
    }
    return result;
  } catch (e: any) {
    throw e.msg;
  }
};

export const socialUnlink = async (userId: string, socialAppType: string) => {
  try {
    return await authAPI.post<string>('v1/user/unlink_account', {
      userID: userId,
      userName: user.value?.name,
      avatarUrl: '',
      socialAppType: socialAppType,
    });
  } catch (e: any) {
    throw e.msg;
  }
};

export const emailBind = async (email: string, code: string, accessToken: string) => {
  try {
    const data = await authAPI.post<any>('email_link_account', {
      email: email,
      otp: code,
      accessToken: accessToken,
    });
    if (!data.isSuccessful) {
      if (data.challengeParameters?.attemptsLeft) throw 'invalid OTP';
      throw 'invalid OTP. Try to get a new one';
    } else {
      linkType.value = '';
      linkUserId.value = '';
      linkUserName.value = '';
      linkAvatar.value = '';
      token.value = data.authenticationResult.accessToken;
      refreshToken.value = data.authenticationResult.refreshToken;
      location.reload();
    }
  } catch (e: any) {
    throw e.msg;
  }
};

export const emailOPT = async (email: string, accessToken: string) => {
  try {
    const data = await authAPI.post<any>('request_otp_link_account', {
      email: email,
      accessToken: accessToken,
    });
    if (!data.challengeName) {
      console.error(data.msg);
    }
  } catch (e: any) {
    throw e.msg;
  }
};

export const emailOperation = async (email: string, type: number) => {
  try {
    await authAPI.post<any>('v1/user/email_operation', {
      email: email,
      type: type,
    });
  } catch (e: any) {
    throw e.msg;
  }
};

export const emailLink = async (email: string, otp: string, type: number) => {
  try {
    await authAPI.post<any>('v1/user/email_verify', {
      email: email,
      otp: otp,
      type: type,
    });
  } catch (e: any) {
    throw e.msg;
  }
};

export const genRefUrl = (path?: string, query: Record<string, string> = {}) => {
  if (!refCode.value) return location.href;
  const url = new URL(location.href);
  if (path) url.pathname = path;
  for (const k in query) url.searchParams.set(k, query[k]);
  url.searchParams.set('r', refCode.value);
  return url.href;
};

export const uploadUserAvatar = async (v: any) => {
  return await upload(v, 'lepoker-avatar');
};

// need refactoring
export const updateUser = async (name: string, userAvatarUrl: string, countryCode: string, init = false) => {
  await authAPI.post('v1/user/edit_profile', {
    userName: name,
    userAvatarUrl: userAvatarUrl,
    countryCode: countryCode,
    init: init,
  });
  user.value!.name = name;
  user.value!.avatar = userAvatarUrl;
  user.value!.country = countryCode;
  user.value!.profileUpdated = true;
};
