<script setup lang="ts">
import { authAPI } from '@/utils/network';
import { ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
  }>(),
  { modelValue: false },
);

const emit = defineEmits(['update:modelValue', 'refresh']);

const showTicketRedeem = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});

const ticketRedeemCode = ref('');
const ticketRedeemStatus = ref(0); // 0 -- normal, 1 -- success
const ticketRedeemErrorText = ref('');

const ticketRedeemRequest = () => {
  if (!ticketRedeemCode.value) return;
  authAPI
    .post('ticket/exchangeTournament', { code: ticketRedeemCode.value })
    .then(() => {
      ticketRedeemStatus.value = 1;
      ticketRedeemCode.value = '';
      emit('refresh');
    })
    .catch((result) => {
      ticketRedeemErrorText.value = result.msg;
      ticketRedeemErrorText.value = i18n.t(`ERROR_${result.code}`);
    });
};

watch(ticketRedeemCode, () => {
  ticketRedeemErrorText.value = '';
});

const ok = () => {
  showTicketRedeem.value = false;
  ticketRedeemStatus.value = 0;
  emit('refresh');
};
</script>

<template>
  <Dialog
    :draggable="false"
    class="w-100"
    modal
    v-model:visible="showTicketRedeem"
    :header="!ticketRedeemStatus ? $t('mtt.REDEEM_TICKET') : ' '"
  >
    <div class="px-4 pb-4 space-y-4" v-if="ticketRedeemStatus === 0">
      <div class="text-center op-70">
        {{ $t('mtt.REDEEM_TICKET_DESC') }}
      </div>
      <div class="c-white">
        {{ $t('mtt.REDEEM_CODE') }}
      </div>
      <InputText class="w-full" :placeholder="$t('mtt.REDEEM_CODE')" v-model="ticketRedeemCode" />
      <div class="c-red" v-show="!!ticketRedeemErrorText">
        {{ ticketRedeemErrorText }}
      </div>
      <Button
        class="w-full"
        @click="ticketRedeemRequest"
        :label="$t('mtt.REDEEM_THE_TICKET')"
        :disabled="!ticketRedeemCode"
      />
    </div>
    <div class="px-4 pb-4" v-if="ticketRedeemStatus === 1">
      <div class="flex flex-col items-center">
        <img src="@/assets/img/ticket.png" alt="" class="w-28 h-auto" />
        <p class="text-lg c-green fw-bold">
          {{ $t('mtt.TICKET_X_1') }}
        </p>
        <p class="text-lg c-white fw-bold">
          {{ $t('mtt.REDEEM_SUCCESS') }}
        </p>
      </div>
      <div class="flex justify-between mt-6 space-x-2">
        <Button class="flex-1" severity="secondary" @click="ticketRedeemStatus = 0" :label="$t('mtt.REDEEM_MORE')" />
        <Button class="flex-1" @click="ok" :label="$t('OK')" />
      </div>
    </div>
  </Dialog>
</template>
