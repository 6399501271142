<template>
  <ATitle class="text-xl mb-4">{{ $t('BASIC_SETTINGS') }}</ATitle>
  <AInput v-model="data.mttName" :limit="100" :placeholder="$t('mtt.TOURNAMENT_NAME')">
    {{ $t('mtt.TOURNAMENT_NAME') }}*
  </AInput>
  <div class="bg-gray-700 h-.3 my-3" />
  <AInput v-model="data.description" :rows="5" :limit="1000" :placeholder="$t('DESCRIPTION')">
    <div class="flex">
      {{ $t('DESCRIPTION') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('DESCRIPTION')"></span>
    </div>
  </AInput>
  <div class="bg-gray-700 h-.3 my-3" />
  <AInput v-model="data.prizeDescription" :rows="5" :limit="500" :placeholder="$t('DESCRIPTION')">
    <div class="flex">
      {{ $t('PRIZE_DESCRIPTION') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('PRIZE_DESCRIPTION')"></span>
    </div>
  </AInput>
  <div class="bg-gray-700 h-.3 my-3" />
  <div class="flex justify-between items-center">
    {{ $t('mtt.START_TIME') }}
    <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.START_TIME')"></span>
    <Calendar v-model="startTime" :minDate="new Date()" showTime :stepMinute="15" hourdataat="24" />
  </div>
  <div class="bg-gray-700 h-.3 my-3" />
  <div class="rd-lg p-4 flex justify-between bg-gray-800">
    <div>
      <div class="flex items-center">
        {{ $t('LOGO') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('LOGO')"></span>
      </div>
      <p class="mb-2 c-gray-400 !text-sm">{{ $t('LOGO_SIZE') }}</p>
      <div class="space-x-2 flex">
        <AUpload :maxSize="1e6" v-model="data.tableLogoUrl" />
        <AButton type="secondary" icon="i-local-delete" @click="data.tableLogoUrl = logo" />
      </div>
    </div>
    <img v-if="data.tableLogoUrl" class="w-24 h-24 object-cover rd-full" :src="data.tableLogoUrl" alt="" />
  </div>
  <AButton v-if="!showMore" outlined class="my-4 w-max" @click="showMore = true">
    {{ $t('MORE_SETTINGS') }} <span class="inline-block i-local-arrow-d size-4 align-middle" />
  </AButton>
  <template v-else>
    <ATitle class="text-xl mb-4 mt-8">{{ $t('ADVANCED_SETTINGS') }}</ATitle>
    <div class="flex justify-between items-center">
      {{ $t('mtt.SOCIAL_MEDIA_LINKS') }}
      <InputSwitch v-model="socialAccountSwitch" />
    </div>
    <template v-if="socialAccountSwitch">
      <template v-for="item in socialList" :key="item">
        <div class="flex relative w-full items-center my-2">
          <img :src="socialImgs[item]" class="size-8 mr-4" />
          <h4>{{ socialNames[item] }}</h4>
        </div>
        <AInput v-model="socialAccountList[item]" placeholder="https://" />
      </template>
    </template>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between items-center">
      {{ $t('mtt.REG_OPEN_TIME') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.REG_OPEN_TIME')"></span>
      <Calendar v-model="regStartTime" showTime :stepMinute="15" hourdataat="24" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ lateMin ? $t('mtt.LATE_REGISTRATION') : $t('mtt.LATE_REGISTRATION_OFF') }}
      <span v-if="lateMin" class="c-green ml-3">{{ `${lateMin} ${$t('MIN_')}` }}</span>
      <span class="i-local-tutorial c-gray-400 ml-1" @click="openToolTips('mtt.LATE_REGISTRATION')"></span>
    </div>
    <ASlider class="my-3" v-model="lateMin" :max="120" :step="10" />
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between items-center">
      {{ $t('mtt.INSTANT_REGISTRATION') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.INSTANT_REGISTRATION')"></span>
      <Dropdown
        class="w-46"
        v-model="data.autoRegApproval"
        optionLabel="label"
        optionValue="val"
        :options="entryOptions"
      />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.TABLE_SIZE') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.TABLE_SIZE')"></span>
      <Dropdown class="w-46" v-model="data.maxPlayerNumber" :options="[2, 6, 9]" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.ACTION_TIME') }}({{ $t('SEC') }})
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.ACTION_TIME')"></span>
      <Dropdown class="w-46" v-model="data.clientActTimeOut" :options="[10, 15, 20, 25, 30]" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.TIME_BREAK') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.TIME_BREAK')"></span>
      <InputSwitch v-model="breakTime" />
      <!-- <Dropdown class="w-46" v-model="breakTime" :options="['OFF', 'Time based', 'Blind based']" /> -->
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.BLIND_STRUCTURE') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.BLIND_STRUCTURE')"></span>
      <DPBlindPopup :duration="data.blindDuration / 60" v-model="data.blindStructureJson" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.STARTING_CHIPS') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.STARTING_CHIPS')"></span>
      <span class="c-green ml-3">
        {{ data.initBlind * (data.blindStructureJson[0]?.bigBlind || 20) }}({{ data.initBlind }}BB)
      </span>
    </div>
    <ASlider class="my-3" v-model="data.initBlind" :max="400" :min="60" :step="1" />
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between items-center mb-3" v-if="themeList.length">
      {{ $t('mtt.CUSTOMIZED_TABLE') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.CUSTOMIZED_TABLE')"></span>
      <InputSwitch id="ipt-mtt-observer" v-model="themeType" />
    </div>
    <div class="flex justify-between items-center mb-3" v-if="!themeType">
      {{ $t('mtt.THEME_ROOM') }}
      <Dropdown
        class="w-46"
        v-model="data.themeRoomId"
        :options="themeList"
        optionLabel="themeRoomName"
        optionValue="id"
      />
    </div>
    <div class="rd-lg of-hidden p-4 flex justify-between items-center bg-gray-800 mb-3">
      <div>
        <div class="flex items-center">
          {{ $t('TABLE_FELT_DESKTOP') }}
          <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('TABLE_FELT_DESKTOP')"></span>
        </div>
        <template v-if="themeType">
          <p class="mb-4 c-gray-400 !text-sm">{{ $t('LOGO_SIZE') }}</p>
          <div class="space-x-2 flex">
            <AUpload :maxSize="1e6" v-model="data.tableBackgroundUrl" />
            <AButton type="secondary" icon="i-local-delete" @click="data.tableBackgroundUrl = tableBG" />
          </div>
        </template>
      </div>
      <div class="flex flex-col items-center">
        <div class="table-landscape w-40 h-20 relative z-0 mb-2" v-if="themeType">
          <div
            class="custom-bg absolute inset-0"
            :style="{ backgroundImage: `url(${themeConfig.tableBackgroundUrl})` }"
          />
        </div>
        <div
          class="w-40 h-24 flex flex-col items-center justify-center canvas mb-1"
          :style="{ '--bg': `url(${themeConfig.carpetUrl})` }"
          v-else
        >
          <div class="table-landscape w-30 h-15 relative z-0">
            <div
              class="custom-bg absolute inset-0"
              :style="{ backgroundImage: `url(${themeConfig.tableBackgroundUrl})` }"
            />
          </div>
        </div>
        <DPTablePreview :themeConfig="themeConfig" :chooseLandscape="true" />
      </div>
    </div>
    <div class="rd-lg of-hidden p-4 flex justify-between items-center bg-gray-800">
      <div>
        <div class="flex items-center">
          {{ $t('TABLE_FELT_MOBILE') }}
          <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('TABLE_FELT_MOBILE')"></span>
        </div>
        <template v-if="themeType">
          <p class="mb-4 c-gray-400 !text-sm">{{ $t('LOGO_SIZE') }}</p>
          <div class="space-x-2 flex">
            <AUpload :maxSize="1e6" v-model="data.verticalTableBackgroundUrl" />
            <AButton type="secondary" icon="i-local-delete" @click="data.verticalTableBackgroundUrl = mTableBG" />
          </div>
        </template>
      </div>
      <div class="flex flex-col items-center">
        <div class="table-portrait w-19 h-27 relative z-0 mb-4" v-if="themeType">
          <div
            class="custom-bg absolute inset-0"
            :style="{ backgroundImage: `url(${themeConfig.verticalTableBackgroundUrl})` }"
          />
        </div>
        <div
          class="w-24 h-30 flex flex-col items-center justify-center canvas mb-1"
          :style="{ '--bg': `url(${themeConfig.carpetUrl})` }"
          v-else
        >
          <div class="table-portrait w-17 h-24 relative z-0">
            <div
              class="custom-bg absolute inset-0"
              :style="{ backgroundImage: `url(${themeConfig.verticalTableBackgroundUrl})` }"
            />
          </div>
        </div>
        <DPTablePreview :themeConfig="themeConfig" :chooseLandscape="false" />
      </div>
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between items-center">
      {{ $t('mtt.OBSERVER_MODE') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.OBSERVER_MODE')"></span>
      <InputSwitch v-model="data.observeMode" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between">
      <div class="flex items-center">{{ $t('mtt.RTC_SETTING_LABEL') }}</div>
      <InputSwitch v-model="data.videoChat" />
    </div>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex justify-between">
      <div class="flex items-center">{{ $t('RABBIT_HUNTING') }}</div>
      <InputSwitch v-model="data.enableRabbitHunting" />
    </div>
    <ATitle class="text-xl mb-4 mt-8">{{ $t('PROFESSIONAL_SETTINGS') }}</ATitle>
    <div class="flex items-center">
      {{ $t('mtt.REGISTERED_PLAYERS') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.REGISTERED_PLAYERS')"></span>
      <span class="c-green ml-3">{{ data.minPlayers }}-{{ data.maxPlayers }}</span>
    </div>
    <ASlider class="my-3" v-model="data.maxPlayers" :step="1" :max="maxPlayers" :min="2" />
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center mb-3">
      {{ $t('REWARD_CLAIM') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.REGISTERED_PLAYERS')"></span>
      <InputSwitch v-model="claimEnable" />
    </div>
    <template v-if="claimEnable">
      <div class="flex items-center">
        {{ $t('mtt.PRIZE_POOL_SIZE') }}
        <span class="c-green ml-3">{{ data.prizePoolSize }}{{ data.prizePoolType === 2 ? '%' : '' }}</span>
        <span class="i-local-tutorial c-gray ml-3 mr-auto" @click="openToolTips('mtt.PRIZE_POOL_SIZE')"></span>
        <Dropdown
          class="w-46"
          v-model="data.prizePoolType"
          optionLabel="label"
          optionValue="val"
          :options="prizePoolTypeOptions"
        />
      </div>
      <ASlider class="my-4" v-model="data.prizePoolSize" :step="1" :min="1" :max="prizePool" />
    </template>
    <div class="flex items-center mb-3">
      {{ $t('CLAIM_METHOD') }}
      <span class="i-local-tutorial c-gray ml-3 mr-auto" @click="openToolTips('mtt.PRIZE_POOL_SIZE')"></span>
      <!-- <Dropdown
        class="w-46"
        v-model="data.prizeClaimType"
        optionLabel="label"
        optionValue="val"
        :options="claimTypeOptions"
      /> -->
    </div>
    <AInput :rows="6" v-model="data.prizeDetail" :placeholder="$t('mtt.PRIZE_CLAIM_INSTRUCTIONS')" />
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('REBUY') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.REGISTERED_PLAYERS')"></span>
      <InputSwitch v-model="rebuyEnable" />
    </div>
    <template v-if="rebuyEnable">
      <div class="flex items-center my-3">
        {{ $t('MAX_REBUY_PER_PLAYER') }}
        <Dropdown class="w-46 ml-a" v-model="data.reEntryLimits" :options="range(10, 1)" />
      </div>
      <div class="flex items-center mb-3">
        {{ $t('REBUY_AUTHORIZATION') }}
        <Dropdown
          class="w-46 ml-a"
          v-model="data.autoReEntry"
          optionLabel="label"
          optionValue="val"
          :options="entryOptions"
        />
      </div>
      <!-- <div class="flex items-center mb-3">
        {{ $t('REBUY_TERMINATION_BLIND_LEVEL') }}
        <Dropdown class="w-46 ml-a" v-model="data.rebuyBlindTermination" :options="range(100, 1)" />
      </div> -->
    </template>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center">
      {{ $t('mtt.TIME_BANK') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.TIME_BANK')"></span>
      <InputSwitch v-model="timeBank" />
    </div>
    <template v-if="data.timeBank">
      <div class="flex items-center my-3">
        {{ $t('mtt.TIME_BANK_PERIOD') }}({{ $t('SEC') }})
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.TIME_BANK_PERIOD')"></span>
        <Dropdown class="w-24" v-model="data.timeBank.duration" :options="[15, 30, 45, 60]" />
      </div>
      <div class="flex items-center mb-3">
        {{ $t('mtt.START_TIME_BANK') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.START_TIME_BANK')"></span>
        <Dropdown class="w-24" v-model="data.timeBank.number" :options="[1, 2, 3]" />
      </div>
      <div class="flex items-center">
        {{ $t('mtt.ADDITIONAL_TIME_BANK') }}({{ $t('HANDS') }})
        <span class="i-local-tutorial c-gray-400 ml-1 mr-a" @click="openToolTips('mtt.ADDITIONAL_TIME_BANK')"></span>
        <Dropdown class="w-24" v-model="data.timeBank.addPolicy" :options="[20, 30, 40]" />
      </div>
    </template>
    <div class="bg-gray-700 h-.3 my-3" />
    <div class="flex items-center mb-3">
      {{ $t('CLAN') }}
      <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.TIME_BANK')"></span>
      <Dropdown
        class="w-46"
        v-model="data.clanConfig.type"
        optionLabel="label"
        optionValue="val"
        :options="clanOptions"
      />
    </div>
    <Dropdown
      v-if="data.clanConfig.type"
      class="w-full"
      v-model="data.clanConfig.clanId"
      optionLabel="name"
      optionValue="clanId"
      :options="ownClans"
      :emptyMessage="$t('NO_AVAILABLE_OPTIONS')"
    />
    <ATitle class="text-xl mt-8 mb-4">{{ $t('ADMIN_SETTINGS') }}</ATitle>
    <AAuth value="mtt_advanced_setting" :or="showAdvancedSetting">
      <div class="flex justify-between mb-4">
        <div class="flex items-center">{{ $t('mtt.MTT_TYPE') }}</div>
        <Dropdown class="w-46" v-model="data.mttType" :options="mttTypeOption" optionLabel="name" optionValue="value">
          <template #option="slotProps">
            <div class="flex">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div v-if="data.mttType == 1" class="flex justify-between mb-4">
        <div class="flex items-center">{{ $t('mtt.BIND_FINALS') }}</div>
        <Dropdown
          class="w-60"
          v-model="data.ticket.mttId"
          :options="mttFinalData"
          optionLabel="mttName"
          optionValue="mttId"
          :loading="showFinalsLoading"
          @change="selectMtt"
        >
          <template #option="slotProps">
            <div class="flex">
              <div>{{ slotProps.option.mttName }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="flex items-center mb-4">
        {{ $t('mtt.REGISTRATION_CONDITION') }}
        <span
          class="i-local-tutorial c-gray-400 ml-1 mr-auto"
          @click="openToolTips('mtt.REGISTRATION_CONDITION')"
        ></span>
        <Dropdown
          class="w-46"
          v-model="task.taskRequirement"
          :options="taskOption"
          optionLabel="name"
          optionValue="value"
        >
          <template #option="slotProps">
            <div class="flex" @click="slotProps.option.command">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div v-if="task.taskRequirement > 0" class="relative mb-4">
        <h5 class="flex items-center justify-between">{{ $t('task.SPONSOR_NAME') }}</h5>
        <AInput v-model="task.name" />
        <div v-for="t in task.tasksType" :key="t.id" class="bg-gray-800 p-4 rd-lg space-y-5 mt-4">
          <div v-if="hasTask('TG', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-telegram.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('TG_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('TG', t.id)" class="flex justify-between flex-col w-full">
            <h5 class="flex items-center mb-4">{{ $t('task.TASK_TYPE') }}</h5>
            <MultiSelect
              class="w-full"
              v-model="t.typeList"
              :options="tgTaskType"
              :placeholder="$t('SELECT_TASK', { v: 'Telegram' })"
              :maxSelectedLabels="3"
              :selectionLimit="10"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <template v-if="hasTask('TG', t.id) && t.typeList.includes('TG_JOIN_CHANNEL')">
            <h4 class="c-green">• {{ $t('TG_JOIN_CHANNEL') }}</h4>
            <h5>{{ $t('task.TG_CHANNEL_NAME') }}</h5>
            <AInput v-model="t.tgChannelName" />
            <h5>{{ $t('task.TG_CHANNEL_ID') }}</h5>
            <AInput v-model="t.tgChannelId" />
            <h5>{{ $t('task.TG_CHANNEL_URL') }}</h5>
            <AInput v-model="t.tgChannelUrl" />
          </template>
          <template v-if="hasTask('TG', t.id) && t.typeList.includes('TG_JOIN_GROUP')">
            <h4 class="c-green">• {{ $t('TG_JOIN_GROUP') }}</h4>
            <h5>{{ $t('task.TG_GROUP_NAME') }}</h5>
            <AInput v-model="t.tgGroupName" />
            <h5>{{ $t('task.TG_GROUP_ID') }}</h5>
            <AInput v-model="t.tgGroupId" />
            <h5>{{ $t('task.TG_GROUP_URL') }}</h5>
            <AInput v-model="t.tgGroupUrl" />
          </template>
          <div v-if="hasTask('DISCORD', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-discord.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('DISCORD_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('DISCORD', t.id)" class="flex justify-between flex-col w-full">
            <h5 class="flex items-center mb-4">{{ $t('task.TASK_TYPE') }}</h5>
            <MultiSelect
              class="w-full"
              v-model="t.typeList"
              :options="discordTaskType"
              :placeholder="$t('SELECT_TASK', { v: 'Discord' })"
              :selectionLimit="10"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <template v-if="hasTask('DISCORD', t.id) && t.typeList.includes('DISCORD_JOIN_SERVER')">
            <h4 class="c-green">• {{ $t('DISCORD_JOIN_SERVER') }}</h4>
            <h5>{{ $t('task.DISCORD_SERVER_NAME') }}</h5>
            <AInput v-model="t.discordServerName" />
            <h5>{{ $t('task.DISCORD_SERVER_ID') }}</h5>
            <AInput v-model="t.discordServerId" />
            <h5>{{ $t('task.DISCORD_SERVER_URL') }}</h5>
            <AInput v-model="t.discordServerUrl" />
          </template>
          <div v-if="hasTask('X', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-x-white.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('X_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('X', t.id)" class="flex justify-between flex-col w-full">
            <h5 class="flex items-center mb-4">{{ $t('task.TASK_TYPE') }}</h5>
            <MultiSelect
              class="w-full"
              v-model="t.typeList"
              :options="xTaskType"
              :placeholder="$t('SELECT_TASK', { v: 'X' })"
              :selectionLimit="10"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <template v-if="hasTask('X', t.id) && t.typeList.includes('X_FOLLOW')">
            <h4 class="c-green">• {{ $t('X_FOLLOW') }}</h4>
            <h5>{{ $t('task.X_USER_NAME') }}</h5>
            <AInput v-model="t.xTargetUserName" />
            <h5>{{ $t('task.X_USER_ID') }}</h5>
            <AInput v-model="t.xTargetUserId" />
          </template>
          <template v-if="hasTask('X', t.id) && t.typeList.includes('X_TWEET')">
            <h4 class="c-green">• {{ $t('X_TWEET') }}</h4>
            <AInput v-model="t.xTweetText" :rows="6" :limit="280">
              <h5>{{ $t('task.X_TWEET_TEXT') }}</h5>
            </AInput>
          </template>
          <template v-if="hasTask('X', t.id) && t.typeList.includes('X_RETWEET')">
            <h4 class="c-green">• {{ $t('X_RETWEET') }}</h4>
            <h5>{{ $t('task.X_RETWEET_ID') }}</h5>
            <AInput v-model="t.xRetweetId" />
          </template>
          <div v-if="hasTask('WEB3', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-wallet-task.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('WEB3_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('WEB3', t.id)" class="flex justify-between flex-col w-full">
            <h5 class="flex items-center mb-4">{{ $t('task.TASK_TYPE') }}</h5>
            <MultiSelect
              class="w-full"
              v-model="t.typeList"
              :options="web3TaskType"
              :placeholder="$t('SELECT_TASK', { v: 'Web3' })"
              :selectionLimit="10"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <template v-if="hasTask('WEB3', t.id) && t.typeList.includes('WEB3_HOLDING_TOKEN')">
            <h4 class="c-green">• {{ $t('WEB3_HOLDING_TOKEN') }}</h4>
            <h5>{{ $t('task.WEB3_BLOCK_CHAIN') }}</h5>
            <Dropdown
              class="w-full"
              v-model="t.web3TokenChain"
              optionLabel="label"
              optionValue="value"
              :options="tokenChainList"
            />
            <h5>{{ $t('task.WEB3_SYMBOL') }}</h5>
            <AInput v-model="t.web3TokenSymbol" />
            <h5>{{ $t('task.WEB3_THRESHOLD_BALANCE') }}</h5>
            <AInput v-model="t.web3TokenBalance" />
            <h5>{{ $t('task.WEB3_CONTRACT_ADDRESS') }}</h5>
            <AInput v-model="t.web3TokenContractAddress" />
          </template>
          <template v-if="hasTask('WEB3', t.id) && t.typeList.includes('WEB3_HOLDING_NFT')">
            <h4 class="c-green">• {{ $t('WEB3_HOLDING_NFT') }}</h4>
            <h5>{{ $t('task.WEB3_BLOCK_CHAIN') }}</h5>
            <Dropdown
              class="w-full"
              v-model="t.web3NFTChain"
              optionLabel="label"
              optionValue="value"
              :options="nftChainList"
            />
            <h5>{{ $t('task.WEB3_SYMBOL') }}</h5>
            <AInput v-model="t.web3NFTSymbol" />
            <h5>{{ $t('task.WEB3_THRESHOLD_BALANCE') }}</h5>
            <AInput type="num" v-model="t.web3NFTBalance" />
            <h5>{{ $t('task.WEB3_CONTRACT_ADDRESS') }}</h5>
            <AInput v-model="t.web3NFTContractAddress" />
          </template>
          <template v-if="hasTask('WEB3', t.id) && t.typeList.includes('WEB3_CHECK_TRANSACTION')">
            <ul class="marker:c-green list-disc c-green list-disc pl-5 space-y-3 w-full">
              <li>
                <h4>{{ $t('WEB3_CHECK_TRANSACTION') }}</h4>
              </li>
            </ul>
            <h5 class="flex items-center justify-between w-full">
              {{ $t('task.WEB3_BLOCK_CHAIN') }}
            </h5>
            <Dropdown
              class="w-full"
              v-model="t.web3TransactionChain"
              optionLabel="label"
              optionValue="value"
              :options="transactionChainList"
            />
            <h5 class="flex items-center justify-between w-full">
              {{ $t('task.WEB3_SYMBOL') }}
            </h5>
            <InputText class="w-full" v-model="t.web3TransactionSymbol" />
            <h5 class="flex items-center justify-between w-full">
              {{ $t('task.WEB3_TRANSACTION_TIMES') }}
            </h5>
            <InputNumber class="w-full" v-model="t.web3TransactionTimes" />
            <h5 class="flex items-center justify-between w-full">
              {{ $t('task.WEB3_TRANSACTION_DERATION') }}
            </h5>
            <InputNumber class="w-full" v-model="t.web3TransactionDuration" />
            <h5 class="flex items-center justify-between w-full">
              {{ $t('task.WEB3_CONTRACT_ADDRESS') }}
            </h5>
            <InputText class="w-full" v-model="t.web3TransactionContractAddress" />
          </template>
          <div v-if="hasTask('EMAIL', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-email.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('EMAIL_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('OTHER', t.id)" class="flex flex-row relative w-full items-center">
            <img src="@/assets/img/icon-other.png" class="h-8 w-8 mr-4" />
            <h4>{{ $t('OTHER_TASK') }}</h4>
            <div
              class="block i-local-remove text-3xl absolute right-0 c-red cursor-pointer"
              @click="removeTask(t)"
            ></div>
          </div>
          <div v-if="hasTask('OTHER', t.id)" class="flex justify-between flex-col w-full">
            <h5 class="flex items-center mb-4">{{ $t('task.TASK_TYPE') }}</h5>
            <MultiSelect
              class="w-full"
              v-model="t.typeList"
              :options="otherTaskType"
              :placeholder="$t('SELECT_TASK', { v: '' })"
              :selectionLimit="10"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <template v-if="hasTask('OTHER', t.id) && t.typeList.includes('OTHER_VISIT_WEBSITE')">
            <h4 class="c-green">• {{ $t('OTHER_VISIT_WEBSITE') }}</h4>
            <h5>{{ $t('task.OTHER_WEBSITE_NAME') }}</h5>
            <AInput v-model="t.otherWebsiteName" />
            <h5>{{ $t('task.OTHER_WEBSITE_URL') }}</h5>
            <InputText class="w-full" v-model="t.otherWebsiteUrl" />
          </template>
        </div>
        <AButton class="w-full mt-4" icon="i-local-plus" outlined @click="showAddTaskPopupTrigger">
          {{ $t('ADD_TASK') }}
        </AButton>
      </div>
      <h5>{{ $t('MIXED_BUY_IN') }}</h5>
      <div class="relative rd-lg bg-gray-900 p-4 my-4">
        <div class="flex items-center mb-4">{{ $t('mtt.REGISTRATION') }}</div>
        <MultiSelect
          class="w-full"
          v-model="regConfig"
          :options="regLimitOptions"
          :placeholder="$t('NO_SELECT')"
          :maxSelectedLabels="3"
          :selectionLimit="3"
          optionLabel="name"
          optionValue="value"
        />
        <template v-for="(v, i) in regAllLimit.filter((v) => regConfig.includes(v.regType))" :key="i">
          <h4 class="c-green my-2">{{ $t('MIX_REG_TYPE_' + v.regType) }}</h4>
          <AInput v-model="v.regFee" type="num" :min="1" />
        </template>
      </div>
      <div class="relative rd-lg bg-gray-900 p-4 mb-4">
        <div class="flex items-center mb-4">{{ $t('REBUY') }}</div>
        <MultiSelect
          class="w-full"
          v-model="reEntryConfig"
          :options="regLimitOptions"
          placeholder="no select"
          :maxSelectedLabels="3"
          :selectionLimit="3"
          optionLabel="name"
          optionValue="value"
        />
        <template v-for="(v, i) in reEntryAllLimit.filter((v) => reEntryConfig.includes(v.regType))" :key="i">
          <h4 class="c-green my-2">{{ $t('MIX_REG_TYPE_' + v.regType) }}</h4>
          <AInput v-model="v.regFee" type="num" :min="1" />
        </template>
      </div>
      <div class="flex items-center mb-4">
        {{ $t('mtt.VISIBLE') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.VISIBLE')"></span>
        <InputSwitch v-model="data.visible" />
      </div>
      <div class="flex items-center justify-between mb-4">
        {{ $t('OFFICIAL_0') }}
        <InputSwitch v-model="data.official" />
      </div>
      <div class="flex items-center mb-4">
        {{ $t('ANNOUNCEMENT') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('ANNOUNCEMENT')"></span>
        <InputSwitch v-model="data.enableAnnouncement" />
      </div>
      <AInput class="mb-4" v-if="data.enableAnnouncement" v-model="data.announcement" :rows="4" :limit="500" />
      <AInput class="mb-4" v-model="data.prizeHighLight" :limit="100" :placeholder="$t('PRIZE_HIGHLIGHT')">
        <div class="flex items-center">
          {{ $t('PRIZE_HIGHLIGHT') }}
          <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('PRIZE_HIGHLIGHT')"></span>
        </div>
      </AInput>
      <div class="flex justify-between items-center mb-4">
        {{ $t('CLAN_SETTINGS') }}
        <Dropdown
          class="w-46"
          v-model="data.clanRestrict.restrictType"
          :options="restrictOption"
          :autoOptionFocus="true"
          :selectOnFocus="true"
          optionLabel="name"
          optionValue="value"
        />
      </div>
      <div v-if="data.clanRestrict.restrictType != 'off'" class="flex items-center mb-3">
        {{ $t('mtt.SELECT_CLAN') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.SELECT_CLAN')"></span>
      </div>
      <MultiSelect
        v-if="data.clanRestrict.restrictType != 'off'"
        v-model="data.clanRestrict.clanIdList"
        :options="clanData"
        filter
        :filterFields="['name', 'clanId']"
        optionValue="clanId"
        :maxSelectedLabels="10"
        :selectionLimit="100"
        :placeholder="t('mtt.SELECT_CLAN')"
        display="chip"
        :loading="showClanLoading"
        @change="selectClan"
        class="relative w-full mb-3"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value.length" class="flex items-center">
            <template v-for="value in selectClanData" :key="value">
              <img class="size-4 rd-full mr-2" alt="" :src="`${value.logoUrl}`" />
              <div class="mr-2">{{ value.name }}</div>
            </template>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <img class="size-6 rd-full mr-2" alt="" :src="`${slotProps.option.logoUrl}`" />
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
      <div class="flex justify-between mb-3">
        <div class="flex items-center">{{ $t('mtt.IP_ACCESS_SETTING') }}</div>
        <Dropdown
          class="w-46"
          v-model="data.countryRestrict.restrictType"
          :options="restrictOption"
          :autoOptionFocus="true"
          :selectOnFocus="true"
          optionLabel="name"
          optionValue="value"
        />
      </div>
      <div v-if="data.countryRestrict.restrictType != 'off'" class="flex items-center mb-3">
        {{ $t('mtt.SELECT_COUNTRY_REGION') }}
        <span
          class="i-local-tutorial c-gray-400 ml-1 mr-auto"
          @click="openToolTips('mtt.SELECT_COUNTRY_REGION')"
        ></span>
      </div>
      <MultiSelect
        v-if="data.countryRestrict.restrictType != 'off'"
        v-model="data.countryRestrict.countryList"
        :options="countryData"
        filter
        :filterFields="['name', 'code']"
        optionValue="code"
        :maxSelectedLabels="10"
        :selectionLimit="10"
        :placeholder="t('mtt.SELECT_COUNTRY_REGION')"
        display="chip"
        class="relative w-full mb-3"
      >
        <template #value="slotProps">
          <div v-if="slotProps.value.length > 0" class="flex items-center">
            <template v-for="value in slotProps.value" :key="value">
              <img class="h-4 mr-2" alt="" :src="`/flags/${value}.png`" />
              <div class="mr-2">{{ value }}</div>
            </template>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <img class="h-4 mr-2" alt="" :src="`/flags/${slotProps.option.code}.png`" />
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
      <div class="flex items-center mb-4">
        {{ $t('SHOW_CLAN_RANKING') }}
        <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('SHOW_CLAN_RANKING')"></span>
        <InputSwitch v-model="showClanRanking" />
      </div>
      <template #fallback>
        <div class="bg-gray-800 p-4 mb-4">*{{ $t('ADVANCE_SETTING_TIPS') }}</div>
      </template>
    </AAuth>
  </template>

  <!-- <div class="flex justify-between items-center">
    {{ $t('mtt.PRIZE_CLAIM_DEADLINE') }}
    <span class="i-local-tutorial c-gray-400 ml-1 mr-auto" @click="openToolTips('mtt.PRIZE_CLAIM_DEADLINE')"></span>
    <Calendar
      v-model="prizeClaimDeadline"
      :minDate="new Date((data.startTime + oneDay) * 1e3)"
      showTime
      :stepMinute="15"
      hourdataat="24"
    />
  </div> -->

  <!-- <div class="flex justify-between">
    <div class="flex items-center">{{ $t('mtt.ALLOW_PLAYERS_QUIT') }}</div>
    <InputSwitch  v-model="data.allowQuit" />
  </div>
  <div class="w-full h-.5 bg-dark-600" />  -->

  <footer class="sticky bottom-4 flex space-x-4">
    <Button v-if="edit" class="flex-1" severity="secondary" :label="$t('BACK')" @click="$router.back()" />
    <Button v-if="edit" class="flex-1" severity="danger" :label="$t('CANCEL_TOURNAMENT')" @click="emits('cancel')" />
    <Button class="flex-1" :label="$t(edit ? 'SAVE' : 'CREATE')" @click="mttCreate" />
  </footer>
  <ADialog v-model="showAddTaskPopup" :title="`${$t('task.TASK_TYPE')}`">
    <p class="w-full text-center c-gray-400 mb-6">
      {{ $t('ADD_TASK_DESC') }}
    </p>
    <div class="space-y-4">
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('TG')">
        <img src="@/assets/img/icon-telegram.png" class="h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('TG_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div>
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('DISCORD')">
        <img src="@/assets/img/icon-discord.png" class="h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('DISCORD_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div>
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('X')">
        <img src="@/assets/img/icon-x-white.png" class="h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('X_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div>
      <!-- <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('YOUTUBE')">
        <div class="i-local-youtube h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('YOUTUBE_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div> -->
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('WEB3')">
        <div class="i-local-wallet-task h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('WEB3_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div>
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('EMAIL')">
        <div class="bg-green rd-full p-2 mr-4">
          <div class="i-local-mail size-4" />
        </div>
        <h4 class="flex items-center">{{ $t('EMAIL_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-white/60"></div>
      </div>
      <div class="flex flex-row relative w-full bg-gray-700 p-4 rd-lg cursor-pointer" @click="addTask('OTHER')">
        <div class="i-local-other-task h-8 w-8 mr-4" />
        <h4 class="flex items-center">{{ $t('OTHER_TASK') }}</h4>
        <div class="block i-local-plus text-3xl absolute right-4 c-gray-400"></div>
      </div>
    </div>
  </ADialog>
  <Dialog class="w-100" :visible="showDeleteTaskNotice" modal :closable="false" :header="$t('NOTICE')">
    <div class="text-center space-y-4">
      <p class="px-4 text-center c-gray-400">{{ task.taskRemoveAlertTxt }}</p>
    </div>
    <template #footer>
      <div class="flex flex-row justify-between w-full">
        <AButton class="flex-1" type="secondary" @click="showDeleteTaskNotice = false">{{ $t('CANCEL') }}</AButton>
        <AButton class="flex-1" @click="removeTaskConfirm">{{ $t('game.CONFIRM') }}</AButton>
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { dt } from '@/utils/datetime';
import { computed, ref, watch } from 'vue';
import { authAPI } from '@/utils/network';
import { useI18n } from 'vue-i18n';
import { countryData, tokenChainList, nftChainList, transactionChainList } from '@/stores';
import { user } from '@/stores';

const { t } = useI18n();
import { useDialog } from '@/components/ui/APopPad.vue';

const entryOptions = computed(() => [
  { val: 0, label: t('mtt.REVIEW_ORG') },
  { val: 1, label: t('mtt.REVIEW_AUTO') },
  { val: 2, label: t('mtt.REVIEW_TICKET') },
]);
const claimTypeOptions = computed(() => [
  { val: 0, label: 'Announcement' },
  { val: 1, label: 'Address collection' },
]);

const clanOptions = computed(() => [
  { val: 0, label: t('OFF') },
  { val: 1, label: t('JOIN_CLAN') },
  { val: 2, label: t('REGISTER_GAME_AND_JOIN_CLAN') },
]);

const ownClans = ref<{ clanId: string; name: string }[]>([]);
authAPI.get('v1/clan/user/managementList').then((v) => {
  ownClans.value = v;
});

const claimEnable = computed({
  get() {
    return data.value.prizePoolType !== 3;
  },
  set(v) {
    data.value.prizePoolType = v ? 1 : 3;
  },
});
const rebuyEnable = computed({
  get() {
    return data.value.reEntryLimits > 0;
  },
  set(v) {
    data.value.reEntryLimits = v ? 3 : 0;
  },
});
const prizePoolTypeOptions = computed(() => [
  { val: 1, label: t('FIXED_NUMBER') },
  { val: 2, label: t('FIXED_RATIO') },
]);

const props = defineProps<{ edit?: boolean; form?: any; mttID?: string; name?: string }>();

const regConfig = ref<number[]>([]);
const reEntryConfig = ref<number[]>([]);

import tableBG from '@/assets/img/table-bg.png';
import mTableBG from '@/assets/img/m-table-bg.png';
import logo from '@/assets/img/mtt-default-logo.png';

const oneDay = 24 * 3600;
const showMore = ref(false);
const maxPlayers = computed(() => {
  let res = 0;
  const permission = user.value?.permissions.find((v) => v.includes('mtt_max_players_'));
  if (permission) res = Number(permission.replace('mtt_max_players_', ''));
  return Math.max(props.form?.maxPlayers || 0, 500, res);
});

const prizePool = computed(() => {
  if (data.value.prizePoolType === 2) return 100;
  let res = 0;
  const permission = user.value?.permissions.find((v) => v.includes('mtt_prize_pool_'));
  if (permission) res = Number(permission.replace('mtt_prize_pool_', ''));
  return Math.max(props.form?.prizePoolSize || 0, 100, res);
});

const data = ref({
  mttName: props.name || '',
  tableLogoUrl: logo,
  tableBackgroundUrl: tableBG,
  verticalTableBackgroundUrl: mTableBG,
  startTime: Math.ceil((Date.now() + 3.6e6) / 3.6e6) * 3600,
  registrationStartTime: Math.ceil(Date.now() / 3.6e6) * 3600,
  minPlayers: 2,
  maxPlayers: 500,
  prizePoolSize: 1,
  prizePoolType: 3,
  prizeClaimType: 0,
  prizePool: {},
  rebuyBlindTermination: 3,
  lateRegistrationTime: 1800,
  reEntryLimits: 0,
  maxPlayerNumber: 6,
  clientActTimeOut: 15,
  enableRabbitHunting: true,
  minBuyBlind: 60,
  maxBuyBlind: 200,
  initBlind: 100,
  description: '',
  prizeDetail: '',
  prizeClaimDeadline: Math.ceil((Date.now() + 3.6e6) / 3.6e6) * 3600 + oneDay * 2,
  gameType: 'NLH',
  autoReEntry: 1,
  autoRegApproval: 1,
  allowQuit: false,
  observeMode: true,
  countryRestrict: {
    restrictType: 'off',
    countryList: [] as string[],
  },
  clanRestrict: {
    restrictType: 'off',
    clanIdList: [] as string[],
  },
  breakTime: 0,
  blindDuration: 300,
  blindStructureJson: [] as any[],
  timeBank: undefined as { duration: number; number: number; addPolicy: number } | undefined,
  ticket: { id: 0, mttId: '' },
  official: false,
  visible: false,
  clanConfig: { type: 0, clanId: '' },
  mttTask: {
    id: 0,
    mttID: '',
    taskType: '',
    taskConfig: [] as any[],
  },
  prizeDescription: '',
  prizeHighLight: '',
  videoChat: true,
  voiceChat: true,
  enableAnnouncement: false,
  announcement: '',
  themeRoomId: 0,
  themeType: 0,
  themeRoomConfig: null,
  mttType: 0,
  socialAccountList: [] as { socialType: string; socialId: string }[] | undefined,
  showClanRanking: 0,
  regConfig: [] as any[],
  reEntryConfig: [] as any[],
});

import { socialImgs, socialList, socialNames } from '@/views/mtt/socialIcons';
import { range } from '@/utils/value';
import { toast } from './ui/AToast.vue';

const socialAccountList = ref<any>({});
const socialAccountSwitch = ref<boolean>(false);

socialList.forEach((item) => (socialAccountList.value[item] = ''));

if (props.form === undefined) {
  authAPI
    .get<{ smallBlind: number; bigBlind: number; ante: number }[]>('v1/mtt/blinds_structure', {
      mttID: '',
    })
    .then((v) => (data.value.blindStructureJson = v!));
}
const dialog = useDialog();
const openToolTips = (name: string) => dialog.alert({ title: t(name), content: t(name + '_TOOL_TIPS') });
const showAdvancedSetting = ref(false);
const clanData = ref([] as { clanId: string; name: string; logoUrl: string }[]);
const selectClanData = ref([] as { clanId: string; name: string; logoUrl: string }[]);
const selectClan = (event: any) => {
  selectClanData.value = [] as { clanId: string; name: string; logoUrl: string }[];
  clanData.value.forEach((v: any) => {
    if (event.value.includes(v.clanId)) {
      selectClanData.value.push(v);
    }
  });
};
const showClanLoading = ref(false);
const advancedSettingKeys = [
  'mttType',
  'ticket',
  'visible',
  'official',
  'enableAnnouncement',
  'announcement',
  'prizeHighLight',
  'themeType',
  'clanRestrict',
  'countryRestrict',
  'mttTask',
];

const timeBank = computed({
  get() {
    return !!data.value.timeBank;
  },
  set(v) {
    data.value.timeBank = v ? { duration: 15, number: 3, addPolicy: 30 } : undefined;
  },
});

const emits = defineEmits<{ submit: any; cancel: any }>();

const showClanRanking = computed({
  get() {
    return !!data.value.showClanRanking;
  },
  set(v: boolean) {
    data.value.showClanRanking = +v;
  },
});

const breakTime = computed({
  get() {
    return !!data.value.breakTime;
  },
  set(v: boolean) {
    data.value.breakTime = v ? 5 * 60 : 0;
  },
});

const lateMin = computed({
  get() {
    return data.value.lateRegistrationTime / 60;
  },
  set(v: number) {
    data.value.lateRegistrationTime = ~~v * 60;
  },
});

const startTime = computed({
  get() {
    return dt(data.value.startTime * 1000).date;
  },
  set(v: Date) {
    data.value.startTime = ~~(v.getTime() / 6e4) * 60;
    if (v.getTime() / 1000 + oneDay > data.value.prizeClaimDeadline) {
      data.value.prizeClaimDeadline = ~~(v.getTime() / 6e4) * 60 + oneDay;
    }
  },
});
const regStartTime = computed({
  get() {
    return dt(data.value.registrationStartTime * 1000).date;
  },
  set(v: Date) {
    data.value.registrationStartTime = ~~(v.getTime() / 6e4) * 60;
  },
});
const prizeClaimDeadline = computed<Date | undefined>({
  get() {
    if (!data.value.prizeClaimDeadline) {
      return undefined;
    }
    return dt(data.value.prizeClaimDeadline * 1000).date;
  },
  set(v: Date | undefined) {
    if (!v) {
      data.value.prizeClaimDeadline = data.value.startTime + oneDay * 2;
    } else {
      data.value.prizeClaimDeadline = ~~(v.getTime() / 6e4) * 60;
    }
  },
});

const mttTypeOption = computed(() => [
  {
    name: t('mtt.MTT_TYPE_NORMAL'),
    value: 0,
  },
  {
    name: t('mtt.MTT_TYPE_PRELIMINARY'),
    value: 1,
  },
  {
    name: t('mtt.MTT_TYPE_FINALS'),
    value: 2,
  },
]);
const showFinalsLoading = ref(false);
const mttFinalData = ref([] as { id: number; mttId: string; mttName: string }[]);
watch(
  () => data.value.mttType,
  (v) => {
    if (v == 1) {
      showFinalsLoading.value = true;
      const permission = user.value?.permissions.find((v) => v.includes('mtt_advanced_setting'));
      if (permission) {
        authAPI.get<{ id: number; mttId: string; mttName: string }[]>('v1/mtt/get_mtt_list').then((res) => {
          mttFinalData.value = res;
          showFinalsLoading.value = false;
        });
      } else {
        authAPI.get('v1/mtt/show_details', { mttID: data.value.ticket.mttId }).then((res) => {
          mttFinalData.value.push({ id: res.id, mttId: res.mttID, mttName: res.mttName });
          showFinalsLoading.value = false;
        });
      }
    }
  },
  { immediate: true },
);
const selectMtt = (event: any) => {
  mttFinalData.value.forEach((v) => {
    if (event.value.includes(v.mttId)) {
      data.value.ticket = { id: v.id, mttId: v.mttId };
    }
  });
};

type TaskType = {
  id: number;
  type: string;
  typeList: string[];
  tgChannelId?: string;
  tgChannelUrl?: string;
  tgChannelName?: string;
  tgGroupId?: string;
  tgGroupUrl?: string;
  tgGroupName?: string;
  discordServerId?: string;
  discordServerName?: string;
  discordServerUrl?: string;
  xTargetUserId?: string;
  xTargetUserName?: string;
  xTweetText?: string;
  xRetweetId?: string;
  ytChannelId?: string;
  ytChannelName?: string;
  web3TokenChain?: string;
  web3TokenSymbol?: string;
  web3TokenBalance?: number;
  web3TokenContractAddress?: string;
  web3NFTChain?: string;
  web3NFTSymbol?: string;
  web3NFTBalance?: number;
  web3NFTContractAddress?: string;
  web3TransactionChain?: string;
  web3TransactionSymbol?: string;
  web3TransactionTimes?: number;
  web3TransactionDuration?: number;
  web3TransactionContractAddress?: string;
  otherWebsiteName?: string;
  otherWebsiteUrl?: string;
  otherClanId?: string;
};

const task = ref({
  taskRequirement: 0,
  name: '',
  tasksType: [] as TaskType[],
  taskToRemove: 0,
  taskRemoveAlertTxt: '',
});

const taskOption = computed(() => [
  {
    name: t('FREE_ROLL'),
    value: 0,
    command: () => {
      task.value.tasksType.length = 0;
    },
  },
  {
    name: t('TASK_REQUIREMENT'),
    value: 1,
    command: () => {
      task.value.tasksType.length = 0;
    },
  },
]);

const regAllLimit = ref([
  { regType: 2, regFee: 1 },
  { regType: 3, regFee: 10 },
  { regType: 4, regFee: 10 },
]);
const reEntryAllLimit = ref([
  { regType: 2, regFee: 1 },
  { regType: 3, regFee: 10 },
  { regType: 4, regFee: 10 },
]);

const regLimitOptions = computed(() => [
  { name: t('mtt.TICKET'), value: 2 },
  { name: t('DIAMOND'), value: 3 },
  { name: t('COIN'), value: 4 },
]);

const tgTaskType = computed(() => [
  {
    name: t('TG_JOIN_CHANNEL'),
    value: 'TG_JOIN_CHANNEL',
  },
  {
    name: t('TG_JOIN_GROUP'),
    value: 'TG_JOIN_GROUP',
  },
]);

const discordTaskType = computed(() => [
  {
    name: t('DISCORD_JOIN_SERVER'),
    value: 'DISCORD_JOIN_SERVER',
  },
]);

const xTaskType = computed(() => [
  {
    name: t('X_FOLLOW'),
    value: 'X_FOLLOW',
  },
  {
    name: t('X_TWEET'),
    value: 'X_TWEET',
  },
  {
    name: t('X_RETWEET'),
    value: 'X_RETWEET',
  },
]);

const web3TaskType = computed(() => [
  {
    name: t('WEB3_HOLDING_TOKEN'),
    value: 'WEB3_HOLDING_TOKEN',
  },
  {
    name: t('WEB3_HOLDING_NFT'),
    value: 'WEB3_HOLDING_NFT',
  },
  {
    name: t('WEB3_CHECK_TRANSACTION'),
    value: 'WEB3_CHECK_TRANSACTION',
  },
]);

const otherTaskType = computed(() => [
  {
    name: t('OTHER_VISIT_WEBSITE'),
    value: 'OTHER_VISIT_WEBSITE',
  },
]);

const showAddTaskPopup = ref(false);
const showDeleteTaskNotice = ref(false);

const showAddTaskPopupTrigger = () => (showAddTaskPopup.value = true);

const addTask = (type: string) => {
  let taskObj = {
    id: Date.now(),
    type,
    typeList: [],
  } as TaskType;
  switch (type) {
    case 'TG':
      taskObj = {
        ...taskObj,
        tgChannelId: '@',
        tgChannelUrl: '',
        tgGroupId: '@',
        tgGroupUrl: '',
      };
      break;
    case 'DISCORD':
      taskObj = {
        ...taskObj,
        discordServerId: '',
        discordServerUrl: '',
      };
      break;
    case 'X':
      taskObj = {
        ...taskObj,
        xTargetUserId: '',
        xTweetText: '',
        xRetweetId: '',
      };
      break;
    case 'YOUTUBE':
      taskObj = {
        ...taskObj,
        ytChannelId: '',
        ytChannelName: '',
      };
      break;
    case 'WEB3':
      taskObj = {
        ...taskObj,
        web3TokenChain: '',
        web3TokenSymbol: '',
        web3TokenBalance: 0,
        web3TokenContractAddress: '',
        web3NFTChain: '',
        web3NFTSymbol: '',
        web3NFTBalance: 0,
        web3NFTContractAddress: '',
        web3TransactionChain: '',
        web3TransactionSymbol: '',
        web3TransactionTimes: 0,
        web3TransactionDuration: 0,
        web3TransactionContractAddress: '',
      };
      break;
    case 'OTHER':
      taskObj = {
        ...taskObj,
        otherWebsiteName: '',
        otherWebsiteUrl: '',
        otherClanId: '',
      };
      break;
  }
  if (task.value.tasksType.filter((x: TaskType) => x.type === type).length >= 3)
    return toast.error(t('mtt.TASK_TYPE_SET_ERROR'));
  task.value.tasksType.push(taskObj);
  showAddTaskPopup.value = false;
};

const removeTask = (tt: TaskType) => {
  task.value.taskToRemove = tt.id;
  showDeleteTaskNotice.value = true;
  switch (tt.type) {
    case 'TG':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: 'Telegram' });
      break;
    case 'X':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: 'X' });
      break;
    case 'DISCORD':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: 'Discord' });
      break;
    case 'YOUTUBE':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: 'Youtube' });
      break;
    case 'WEB3':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: 'Web3' });
      break;
    case 'EMAIL':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: t('EMAIL') });
      break;
    case 'OTHER':
      task.value.taskRemoveAlertTxt = t('mtt.DELETE_TASK_CONFIRMATION', { v: t('OTHER') });
      break;
  }
};

const removeTaskConfirm = () => {
  const valIndex = task.value.tasksType.findIndex((i) => i.id === task.value.taskToRemove);
  if (valIndex >= 0) {
    task.value.tasksType.splice(valIndex, 1);
  }
  showDeleteTaskNotice.value = false;
};

const hasTask = (type: string, id: number) => {
  const valIndex = task.value.tasksType.findIndex((i) => i.type === type && i.id === id);
  return valIndex > -1;
};

const toastError = async (msg: string) => {
  toast.error(msg);
};

const mttCreate = async () => {
  let taskSet = true;
  data.value.voiceChat = data.value.videoChat;
  if (data.value.countryRestrict.restrictType == 'off') {
    data.value.countryRestrict.countryList = [] as string[];
  }
  data.value.regConfig = regAllLimit.value.filter((v) => regConfig.value.includes(v.regType));
  data.value.reEntryConfig = reEntryAllLimit.value.filter((v) => reEntryConfig.value.includes(v.regType));
  if (task.value.taskRequirement > 0) {
    data.value.mttTask.taskConfig.length = 0;
    if (props.mttID && task.value.taskRequirement > 0) {
      data.value.mttTask.mttID = props.mttID;
    }
    if (!task.value.name) {
      toastError('Please Set Sponser Name');
      taskSet = false;
      return;
    }
    let taskType = '';
    task.value.tasksType.forEach((tt: TaskType) => {
      if (tt.type == 'TG') {
        data.value.mttTask.taskConfig.push({ taskKey: 'TG_LOGIN', name: task.value.name });
        if (taskType.indexOf('TG') == -1) {
          taskType = taskType + 'TG,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'TG_JOIN_CHANNEL') {
            if (!tt.tgChannelId || !tt.tgChannelUrl || tt.tgChannelId == '@') {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Telegram Channel' }));
              taskSet = false;
              return;
            } else {
              let cid = tt.tgChannelId;
              if (cid.indexOf('@') == -1) {
                cid = '@' + tt.tgChannelId;
              }
              data.value.mttTask.taskConfig.push({
                taskKey: v,
                channelId: cid,
                taskUrl: tt.tgChannelUrl,
                name: tt.tgChannelName || task.value.name,
              });
            }
          } else if (v == 'TG_JOIN_GROUP') {
            if (!tt.tgGroupId || !tt.tgGroupUrl || tt.tgGroupId == '@') {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Telegram Group' }));
              taskSet = false;
              return;
            } else {
              let gid = tt.tgGroupId;
              if (gid.indexOf('@') == -1) {
                gid = '@' + tt.tgGroupId;
              }
              data.value.mttTask.taskConfig.push({
                taskKey: v,
                groupId: gid,
                taskUrl: tt.tgGroupUrl,
                name: tt.tgGroupName || task.value.name,
              });
            }
          }
        });
      } else if (tt.type == 'DISCORD') {
        data.value.mttTask.taskConfig.push({ taskKey: 'DISCORD_LOGIN', name: task.value.name });
        if (taskType.indexOf('DISCORD') == -1) {
          taskType = taskType + 'DISCORD,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'DISCORD_JOIN_SERVER') {
            if (!tt.discordServerId || !tt.discordServerUrl) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Discord Join Server' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              serverId: tt.discordServerId,
              taskUrl: tt.discordServerUrl,
              name: tt.discordServerName || task.value.name,
            });
          }
        });
      } else if (tt.type == 'X') {
        data.value.mttTask.taskConfig.push({ taskKey: 'X_LOGIN', name: task.value.name });
        if (taskType.indexOf('X') == -1) {
          taskType = taskType + 'X,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'X_FOLLOW') {
            if (!tt.xTargetUserId) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Twitter Follow' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              userId: tt.xTargetUserId,
              name: tt.xTargetUserName || task.value.name,
            });
          } else if (v == 'X_TWEET') {
            if (!tt.xTweetText) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Twitter Send Tweet' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              text: tt.xTweetText,
              name: task.value.name,
            });
          } else if (v == 'X_RETWEET') {
            if (!tt.xRetweetId) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Twitter Retweet' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              textId: tt.xRetweetId,
              name: task.value.name,
            });
          }
        });
      } else if (tt.type == 'YOUTUBE') {
        data.value.mttTask.taskConfig.push({ taskKey: 'YOUTUBE_LOGIN', name: task.value.name });
        if (taskType.indexOf('YOUTUBE') == -1) {
          taskType = taskType + 'YOUTUBE,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'YOUTUBE_SUBSCRIPT_CHANNEL') {
            if (!tt.ytChannelId) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Youtube ChannelId and ChannelName' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              channelId: tt.ytChannelId,
              taskUrl: 'https://www.youtube.com/channel/' + tt.ytChannelId,
              name: tt.ytChannelName || task.value.name,
            });
          }
        });
      } else if (tt.type == 'WEB3') {
        data.value.mttTask.taskConfig.push({ taskKey: 'WEB3_LOGIN', name: task.value.name });
        if (taskType.indexOf('WEB3') == -1) {
          taskType = taskType + 'WEB3,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'WEB3_HOLDING_TOKEN') {
            if (!tt.web3TokenChain || !tt.web3TokenSymbol || !tt.web3TokenBalance) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Web3 Holding Token' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              chain: tt.web3TokenChain,
              symbol: tt.web3TokenSymbol,
              balance: tt.web3TokenBalance,
              contractAddress: tt.web3TokenContractAddress,
              name: task.value.name,
            });
          } else if (v == 'WEB3_HOLDING_NFT') {
            if (!tt.web3NFTChain || !tt.web3NFTSymbol || !tt.web3NFTBalance) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Web3 Holding NFT' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              chain: tt.web3NFTChain,
              symbol: tt.web3NFTSymbol,
              balance: tt.web3NFTBalance,
              contractAddress: tt.web3NFTContractAddress,
              name: task.value.name,
            });
          } else if (v == 'WEB3_CHECK_TRANSACTION') {
            if (
              !tt.web3TransactionChain ||
              !tt.web3TransactionSymbol ||
              !tt.web3TransactionTimes ||
              !tt.web3TransactionDuration
            ) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Web3 Check Transaction' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              chain: tt.web3TransactionChain,
              symbol: tt.web3TransactionSymbol,
              balance: tt.web3TransactionTimes,
              duration: tt.web3TransactionDuration * 3600,
              contractAddress: tt.web3TransactionContractAddress,
              name: task.value.name,
            });
          }
        });
      } else if (tt.type == 'EMAIL') {
        data.value.mttTask.taskConfig.push({ taskKey: 'EMAIL_LOGIN', name: task.value.name });
        if (taskType.indexOf('EMAIL') == -1) {
          taskType = taskType + 'EMAIL,';
        }
      } else if (tt.type == 'OTHER') {
        data.value.mttTask.taskConfig.push({ taskKey: 'OTHER_LOGIN', name: task.value.name });
        if (taskType.indexOf('OTHER') == -1) {
          taskType = taskType + 'OTHER,';
        }
        tt.typeList.forEach((v) => {
          if (v == 'OTHER_VISIT_WEBSITE') {
            if (!tt.otherWebsiteName || !tt.otherWebsiteUrl) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Visit Website' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              taskUrl: tt.otherWebsiteUrl,
              name: tt.otherWebsiteName || task.value.name,
            });
          } else if (v == 'OTHER_CLAN_ALLOWED') {
            if (!tt.otherClanId) {
              toastError(t('mtt.TASK_SET_ERROR', { v: 'Clan Task' }));
              taskSet = false;
              return;
            }
            data.value.mttTask.taskConfig.push({
              taskKey: v,
              channelId: tt.otherClanId.replace(/\s+/g, ''),
              name: task.value.name,
            });
          }
        });
      }
    });
    data.value.mttTask.taskType = taskType;
    // 特殊情况需要清空
    if (data.value.mttTask.taskType == 'WEB3,' && data.value.mttTask.taskConfig.length == 1) {
      data.value.mttTask.taskType = '';
      data.value.mttTask.taskConfig.length = 0;
    }
  } else {
    data.value.mttTask.taskType = '';
    data.value.mttTask.taskConfig.length = 0;
  }
  if (socialAccountSwitch.value) {
    data.value.socialAccountList = socialList.map((item) => ({
      socialType: item,
      socialId: socialAccountList.value[item],
    }));
  } else {
    data.value.socialAccountList = undefined;
  }
  if (taskSet) {
    emits('submit', data.value);
  }
};
const initTaskInfo = async () => {
  // init task form infomation
  if (data.value.mttTask.taskConfig.length > 0) {
    task.value.taskRequirement = 1;
    let taskType = {
      id: Date.now(),
      type: '',
      typeList: [],
    } as TaskType;
    data.value.mttTask.taskConfig.forEach((v, index) => {
      if (!taskType.type) {
        if (v.taskKey == 'TG_LOGIN') {
          taskType.type = 'TG';
          task.value.name = v.name;
        } else if (v.taskKey == 'DISCORD_LOGIN') {
          taskType.type = 'DISCORD';
          task.value.name = v.name;
        } else if (v.taskKey == 'X_LOGIN') {
          taskType.type = 'X';
          task.value.name = v.name;
        } else if (v.taskKey == 'YOUTUBE_LOGIN') {
          taskType.type = 'YOUTUBE';
          task.value.name = v.name;
        } else if (v.taskKey == 'WEB3_LOGIN') {
          taskType.type = 'WEB3';
          task.value.name = v.name;
        } else if (v.taskKey == 'EMAIL_LOGIN') {
          taskType.type = 'EMAIL';
          task.value.name = v.name;
        } else if (v.taskKey == 'OTHER_LOGIN') {
          taskType.type = 'OTHER';
          task.value.name = v.name;
        }
      } else if (
        v.taskKey == 'TG_LOGIN' ||
        v.taskKey == 'DISCORD_LOGIN' ||
        v.taskKey == 'X_LOGIN' ||
        v.taskKey == 'YOUTUBE_LOGIN' ||
        v.taskKey == 'WEB3_LOGIN' ||
        v.taskKey == 'EMAIL_LOGIN' ||
        v.taskKey == 'OTHER_LOGIN'
      ) {
        // console.info(taskType);
        task.value.tasksType.push(taskType);
        taskType = {
          id: Date.now() + index,
          type: '',
          typeList: [],
        } as TaskType;
        if (v.taskKey == 'TG_LOGIN') {
          taskType.type = 'TG';
        } else if (v.taskKey == 'DISCORD_LOGIN') {
          taskType.type = 'DISCORD';
        } else if (v.taskKey == 'X_LOGIN') {
          taskType.type = 'X';
        } else if (v.taskKey == 'YOUTUBE_LOGIN') {
          taskType.type = 'YOUTUBE';
        } else if (v.taskKey == 'WEB3_LOGIN') {
          taskType.type = 'WEB3';
        } else if (v.taskKey == 'EMAIL_LOGIN') {
          taskType.type = 'EMAIL';
        } else if (v.taskKey == 'OTHER_LOGIN') {
          taskType.type = 'OTHER';
        }
      } else if (v.taskKey == 'TG_JOIN_CHANNEL') {
        taskType.typeList.push(v.taskKey);
        taskType.tgChannelId = v.channelId;
        taskType.tgChannelName = v.name;
        taskType.tgChannelUrl = v.taskUrl;
      } else if (v.taskKey == 'TG_JOIN_GROUP') {
        taskType.typeList.push(v.taskKey);
        taskType.tgGroupId = v.groupId;
        taskType.tgGroupName = v.name;
        taskType.tgGroupUrl = v.taskUrl;
      } else if (v.taskKey == 'DISCORD_JOIN_SERVER') {
        taskType.typeList.push(v.taskKey);
        taskType.discordServerId = v.serverId;
        taskType.discordServerUrl = v.taskUrl;
        taskType.discordServerName = v.name;
      } else if (v.taskKey == 'X_FOLLOW') {
        taskType.typeList.push(v.taskKey);
        taskType.xTargetUserId = v.userId;
        taskType.xTargetUserName = v.name;
      } else if (v.taskKey == 'X_TWEET') {
        taskType.typeList.push(v.taskKey);
        taskType.xTweetText = v.text;
      } else if (v.taskKey == 'X_RETWEET') {
        taskType.typeList.push(v.taskKey);
        taskType.xRetweetId = v.textId;
      } else if (v.taskKey == 'YOUTUBE_SUBSCRIPT_CHANNEL') {
        taskType.typeList.push(v.taskKey);
        taskType.ytChannelId = v.channelId;
        taskType.ytChannelName = v.name;
      } else if (v.taskKey == 'WEB3_HOLDING_TOKEN') {
        taskType.typeList.push(v.taskKey);
        taskType.web3TokenChain = v.chain;
        taskType.web3TokenSymbol = v.symbol;
        taskType.web3TokenBalance = v.balance;
        taskType.web3TokenContractAddress = v.contractAddress;
      } else if (v.taskKey == 'WEB3_HOLDING_NFT') {
        taskType.typeList.push(v.taskKey);
        taskType.web3NFTChain = v.chain;
        taskType.web3NFTSymbol = v.symbol;
        taskType.web3NFTBalance = v.balance;
        taskType.web3NFTContractAddress = v.contractAddress;
      } else if (v.taskKey == 'WEB3_CHECK_TRANSACTION') {
        taskType.typeList.push(v.taskKey);
        taskType.web3TransactionChain = v.chain;
        taskType.web3TransactionSymbol = v.symbol;
        taskType.web3TransactionTimes = v.balance;
        taskType.web3TransactionDuration = v.duration / 3600;
        taskType.web3TransactionContractAddress = v.contractAddress;
      } else if (v.taskKey == 'OTHER_VISIT_WEBSITE') {
        taskType.typeList.push(v.taskKey);
        taskType.otherWebsiteUrl = v.taskUrl;
        taskType.otherWebsiteName = v.name;
      } else if (v.taskKey == 'OTHER_CLAN_ALLOWED') {
        taskType.typeList.push(v.taskKey);
        taskType.otherClanId = v.channelId;
      }
    });
    if (taskType.type) {
      console.info(taskType);
      task.value.tasksType.push(taskType);
    }
  }
};

const restrictOption = computed(() => [
  {
    name: t('OFF'),
    value: 'off',
  },
  {
    name: t('DISALLOWED'),
    value: 'disallowed',
  },
  {
    name: t('ALLOWED'),
    value: 'allowed',
  },
]);

type RoomTheme = {
  id: number;
  tableBackgroundUrl: string;
  verticalTableBackgroundUrl: string;
  carpetUrl: string;
};

const themeList = ref<RoomTheme[]>([]);
const themeType = computed({
  get() {
    return !data.value.themeType;
  },
  set(v: boolean) {
    data.value.themeType = v ? 0 : 1;
    if (v) data.value.themeRoomId = 0;
  },
});

const themeConfig: any = computed(() => {
  if (themeType.value) {
    return {
      tableBackgroundUrl: data.value.tableBackgroundUrl,
      verticalTableBackgroundUrl: data.value.verticalTableBackgroundUrl,
    };
  }

  const findTheme = themeList.value.find((item) => item.id === data.value.themeRoomId);
  if (findTheme) {
    return findTheme;
  }

  if (data.value.themeRoomConfig) {
    return data.value.themeRoomConfig;
  }

  return {
    tableBackgroundUrl: data.value.tableBackgroundUrl,
    verticalTableBackgroundUrl: data.value.verticalTableBackgroundUrl,
  };
});

const getThemeList = async () => {
  themeList.value = await authAPI.get('v1/theme_room/enable_list');
};
getThemeList();

watch(
  () => props.form,
  (v) => {
    if (v) {
      const advanced = user.value?.permissions.includes('mtt_advanced_setting');
      if (advanced || (v.enableShowAdvancedSettings && props.mttID)) {
        showAdvancedSetting.value = true;
      } else {
        advancedSettingKeys.forEach((k) => {
          v[k] = (data.value as any)[k];
        });
      }
      Object.keys(v).map((k) => {
        if (!(k in data.value)) delete v[k];
      });
      data.value = { ...v };

      regConfig.value = [];
      reEntryConfig.value = [];
      v.regConfig?.forEach((c: any) => {
        regConfig.value.push(c.regType);
        regAllLimit.value.find((v) => v.regType === c.regType)!.regFee = c.regFee;
      });
      v.reEntryConfig?.forEach((c: any) => {
        reEntryConfig.value.push(c.regType);
        reEntryAllLimit.value.find((v) => v.regType === c.regType)!.regFee = c.regFee;
      });

      if (!data.value.clanConfig) data.value.clanConfig = { type: 0, clanId: '' };
      initTaskInfo();
      if (!data.value.countryRestrict) {
        data.value.countryRestrict = { restrictType: 'off', countryList: [] as string[] };
      }
      if (!data.value.clanRestrict) {
        data.value.clanRestrict = { restrictType: 'off', clanIdList: [] as string[] };
      }
      timeBank.value = !!data.value.timeBank;
      // if (!data.value.ticket) {
      //   data.value.ticket = { id: 0, mttId: '' };
      // }
      if (v.socialAccountList && v.socialAccountList.length) {
        v.socialAccountList.forEach((item: any) => {
          socialAccountList.value[item.socialType] = item.socialId;
        });
        socialAccountSwitch.value = true;
      } else {
        socialAccountSwitch.value = false;
      }
      if (v.prizePool?.prizePoolType) {
        data.value.prizePoolType = v.prizePool.prizePoolType;
        if (v.prizePool.prizePoolType === 2) {
          data.value.prizePoolSize = v.prizePool.prizePoolRate;
        } else {
          data.value.prizePoolSize = v.prizePool.prizePoolValue;
        }
      } else {
        data.value.prizePoolType = 1;
      }
    }
  },
  { immediate: true },
);

watch(
  () => data.value.prizePoolType,
  (v) => {
    if (data.value.prizePoolSize > prizePool.value) {
      data.value.prizePoolSize = prizePool.value;
    }
  },
);

watch(
  () => data.value.clanRestrict.restrictType,
  (v) => {
    if (v != 'off') {
      showClanLoading.value = true;
      const permission = user.value?.permissions.find((v) => v.includes('mtt_advanced_setting'));
      if (permission) {
        authAPI.get('v1/clan/listAll', { pageIndex: 0, pageSize: 100000 }).then((res) => {
          clanData.value = res.records;
          selectClanData.value = [] as { clanId: string; name: string; logoUrl: string }[];
          clanData.value.forEach((v: any) => {
            if (data.value.clanRestrict.clanIdList.includes(v.clanId)) {
              selectClanData.value.push(v);
            }
          });
          showClanLoading.value = false;
        });
      } else {
        data.value.clanRestrict.clanIdList.forEach((v) => {
          authAPI.get('v1/clan/getById', { clanId: v }).then((res) => {
            clanData.value.push(res.clanVO);
            selectClanData.value.push(res.clanVO);
            showClanLoading.value = false;
          });
        });
      }
    }
  },
  { immediate: true },
);
</script>
