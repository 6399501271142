<script setup lang="ts">
import { useRouter } from 'vue-router';
import { botAPI, xRedirect } from '@/utils/network';
import {
  user,
  deboxUser,
  socialToken,
  userTask,
  showBindAccount,
  linkType,
  linkUserId,
  linkUserName,
  linkAvatar,
} from '@/stores';
import { onMounted, ref } from 'vue';
import { loginSocialV2, socialLink, checkAccountStatus } from '@/utils/user';
import { convertToLangeLabel } from '@/utils/strings';
import { useI18n } from 'vue-i18n';
import { connectDebox, deboxScope } from '@/utils/debox';

const i18n = useI18n();

const params = new URLSearchParams(window.location.search);
const dataMap = Object.fromEntries(params.entries());
const deboxPath = params.get('deboxPath') || '/';
const showWaiting = ref(true);
const router = useRouter();

if (xRedirect.value && xRedirect.value.indexOf('---showQuest') > 0) {
  userTask.showQuest = true;
  xRedirect.value = xRedirect.value.replace('---showQuest', '');
}

async function initAuth() {
  if (!dataMap['code']) {
    await connectDebox('', decodeURIComponent(deboxPath));
    return;
  }
  if (user.value) dataMap['lepokerUserId'] = user.value.id;
  dataMap['scope'] = deboxScope;
  try {
    const data = await botAPI.post<{
      token: string;
      userId: string;
      username: string;
      avatar: string;
      walletAddress: string;
    }>('debox/auth', dataMap);
    deboxUser.id = data.userId;
    deboxUser.username = data.username;
    deboxUser.avatar = data.avatar;
    deboxUser.address = data.walletAddress;
    socialToken.value = data.token;
  } catch (e: any) {
    userTask.showQuest = false;
    alert(e.msg);
    router.replace(decodeURIComponent(xRedirect.value));
    return;
  }
  try {
    if (xRedirect.value != null && xRedirect.value.indexOf('---linkDebox') >= 0) {
      xRedirect.value = xRedirect.value.replace('---linkDebox', '');
      showWaiting.value = false;
      try {
        await socialLink(deboxUser.id, deboxUser.username, deboxUser.avatar, 'DEBOX');
      } catch (e: any) {
        deboxUser.id = '';
        deboxUser.username = '';
        deboxUser.avatar = '';
        deboxUser.address = '';
        socialToken.value = '';
        userTask.showQuest = false;
        alert(i18n.t(convertToLangeLabel(e)));
      }
      router.replace(decodeURIComponent(xRedirect.value));
    } else {
      if (await checkAccountStatus(socialToken.value)) {
        xRedirect.value = xRedirect.value.replace('---bindDebox', '');
        await loginSocialV2(socialToken.value);
        await router.replace(decodeURIComponent(xRedirect.value));
        // setTimeout(() => history.replaceState(history.state, '', decodeURIComponent(xRedirect.value)), 0);
      } else {
        if (xRedirect.value.indexOf('---bindDebox') > 0) {
          xRedirect.value = xRedirect.value.replace('---bindDebox', '');
          showBindAccount.value = 3;
        } else {
          linkType.value = 'DEBOX';
          linkUserId.value = deboxUser.id;
          linkUserName.value = deboxUser.username;
          linkAvatar.value = deboxUser.avatar;
          showBindAccount.value = 1;
        }
        router.replace(decodeURIComponent(xRedirect.value));
      }
    }
  } catch (e: any) {
    userTask.showQuest = false;
    alert(e);
    router.replace(decodeURIComponent(xRedirect.value));
  }
}

onMounted(async () => {
  await initAuth();
});
</script>
<template>
  <Dialog class="w-100" modal :closable="false" :visible="showWaiting" header="">
    <div class="text-center">
      <ProgressSpinner strokeWidth="3" />
      <h5 class="mt-4">{{ $t('discord.LOGIN') }}</h5>
    </div>
  </Dialog>
</template>
