<template>
  <Suspense @pending="fail = false">
    <slot v-if="fail" name="fail">error</slot>
    <slot v-else />
    <template #fallback>
      <slot name="fallback">
        <div class="h-full flex items-center justify-center">
          <div class="i-local-loading size-8 c-green" />
        </div>
      </slot>
    </template>
  </Suspense>
</template>
<script lang="ts" setup>
import { onErrorCaptured, ref } from 'vue';

const fail = ref(false);

onErrorCaptured(() => {
  fail.value = true;
});
</script>
