<template>
  <Teleport to="body">
    <div
      v-if="show"
      class="fixed inset-0 flex c-white bg-black/50 z-10"
      :class="{ 'justify-end': position === 1 }"
      @click.self="show = false"
    >
      <div
        v-bind="$attrs"
        class="relative bg-dark-900 py-4 animate-duration-100"
        :class="[
          position < 2 ? 'h-full' : 'w-full',
          {
            'self-start': position === 2,
            'self-end': position === 3,
            'animate-slide-in-left': position === 0,
            'animate-slide-in-right': position === 1,
            'animate-slide-in-down': position === 2,
            'animate-slide-in-up': position === 3,
          },
        ]"
      >
        <div
          class="absolute z-1 top-3 c-gray i-local-close size-6 cursor-pointer"
          :class="position < 2 ? 'left-3' : 'right-3'"
          @click="show = false"
        />
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
withDefaults(defineProps<{ position?: 0 | 1 | 2 | 3 }>(), { position: 0 });
const show = defineModel<boolean>();
</script>
