<template>
  <APopButton
    v-model="showOptions"
    class="rd cursor-pointer bg-gray-700 flex items-center p-.5em outline-gray-500 hover:outline-solid"
  >
    <slot :value="value" :label="label">
      {{ value }}
    </slot>
    <template #pad>
      <div class="rd bg-gray-700 min-w-full max-h-54 of-auto">
        <div
          v-for="{ val, label } in list"
          :key="val"
          class="rd hover:bg-gray-900 cursor-pointer p-.7em text-1em"
          @click="select(val, label)"
        >
          {{ label }}
        </div>
      </div>
    </template>
  </APopButton>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = defineProps<{ options: ({ val: any; label: string } | string | number)[] }>();
const value = defineModel<any>();
const showOptions = ref(false);

const list = computed(() => {
  return props.options.map((v) => (typeof v === 'object' ? v : { val: v, label: '' + v }));
});

const label = ref(list.value.find((v) => v.val === value.value)?.label || '-');

const select = (val: any, l: string) => {
  value.value = val;
  label.value = l;
  showOptions.value = false;
};
</script>
