<template>
  <div class="flex mb-8 space-x-4" un-children="flex-1 p-2 text-center rd-lg b-solid cursor-pointer">
    <h5 :class="tab === 0 ? 'b-green pointer-events-none' : 'b-gray-600 bg-gray-800'" @click="openTab(0)">
      {{ $t('TOP_WEALTH') }}
    </h5>
    <h5 :class="tab === 1 ? 'b-green pointer-events-none' : 'b-gray-600 bg-gray-800'" @click="openTab(1)">
      {{ $t('TOP_WINNERS') }}
    </h5>
  </div>
  <header v-if="tab === 0" class="text-center">
    <h2>{{ $t('TOP_WEALTH') }}</h2>
    <div class="c-gray mt-2">*{{ $t('RANKED_BY_COIN_BALANCE') }}</div>
  </header>
  <header v-else class="text-center">
    <h2>Nov. 2024 Rank</h2>
    <div class="c-gray mt-2">*{{ $t('RANKED_BY_NET_CHIPS_EARNED_FROM_RING_GAMES') }}</div>
  </header>
  <div class="flex-1 of-auto">
    <div
      class="flex items-end pt-15 justify-center"
      un-children="w-1/3 flex flex-col items-center rd-lg bg-gradient-to-b from-gray-800 to-transparent"
    >
      <div class="h-30">
        <DPAvatar
          class="w-full px-2 text-4xl flex flex-col pt-7.5 bgi-silver -mt-15 bg-[size:7.8rem] bg-top bg-no-repeat"
          :simple="true"
          :id="rank[1]?.id"
          :name="rank[1] ? undefined : '-'"
        >
          <template #default="{ user }">
            <h5 class="text-lg w-full text-center mt-4 truncate">{{ user.name }}</h5>
          </template>
        </DPAvatar>
        <h5 class="c-yellow flex items-center">
          <img class="w-4 mr-1" src="@/assets/img/coin.png" alt="" />{{ formatAmount(rank[1]?.val) || '-' }}
        </h5>
        <h4 class="c-[#b2bbe9]">No.2</h4>
      </div>
      <div class="h-40">
        <DPAvatar
          class="w-full px-2 text-5xl w-full flex flex-col pt-10 bgi-gold -mt-14 bg-[size:10.5rem] bg-top bg-no-repeat"
          :simple="true"
          :id="rank[0]?.id"
          :name="rank[0] ? undefined : '-'"
        >
          <template #default="{ user }">
            <h5 class="text-lg w-full text-center mt-5 truncate">{{ user.name }}</h5>
          </template>
        </DPAvatar>
        <h5 class="c-yellow flex items-center">
          <img class="w-4 mr-1" src="@/assets/img/coin.png" alt="" />{{ formatAmount(rank[0]?.val) }}
        </h5>
        <h4 class="c-yellow">No.1</h4>
      </div>
      <div class="h-30">
        <DPAvatar
          class="w-full px-2 text-4xl flex flex-col pt-7.5 bgi-bronze -mt-15 bg-[size:7.8rem] bg-top bg-no-repeat"
          :simple="true"
          :id="rank[2]?.id"
          :name="rank[2] ? undefined : '-'"
        >
          <template #default="{ user }">
            <h5 class="text-lg w-full text-center mt-4 truncate">{{ user.name }}</h5>
          </template>
        </DPAvatar>
        <h5 class="c-yellow flex items-center">
          <img class="w-4 mr-1" src="@/assets/img/coin.png" alt="" />{{ formatAmount(rank[2]?.val) }}
        </h5>
        <h4 class="c-[#ff6320]">No.3</h4>
      </div>
    </div>
    <div class="space-y-2 mt-8">
      <div
        v-for="(v, i) of rank.slice(3)"
        :key="i"
        class="bgi-ranking-general-bg bg-contain bg-no-repeat rd-lg bg-gray-900 flex items-center pr-4 py-2"
      >
        <h2 class="w-16 mr-8 text-center fw-extrabold italic c-gray shrink-0">{{ i + 4 }}</h2>
        <DPAvatar class="text-1.7rem flex items-center of-hidden" :id="v.id">
          <template #default="{ user }">
            <h5 class="ml-2 truncate">{{ user.name }}</h5>
          </template>
        </DPAvatar>
        <img class="w-4 mr-1 ml-a" src="@/assets/img/coin.png" alt="" />
        <h5 class="c-yellow">{{ formatAmount(v.val) }}</h5>
      </div>
    </div>
  </div>
  <div
    v-if="mine"
    class="flex items-center pr-4 py-3 b-t-solid b-green bg-gradient-to-b from-green-900/30 via-transparent"
  >
    <h2 class="w-16 mr-2 text-center fw-extrabold italic c-gray">{{ mine.seq }}</h2>
    <DPAvatar class="text-1.7rem flex items-center of-hidden" :id="mine.id">
      <template #default="{ user }">
        <h3 class="ml-2 truncate">{{ user.name }}</h3>
      </template>
    </DPAvatar>
    <img class="w-4 mr-1 ml-a" src="@/assets/img/coin.png" alt="" />
    <h5 class="c-yellow">{{ formatAmount(mine.val) }}</h5>
  </div>
</template>
<script setup lang="ts">
import { user } from '@/stores';
import { authAPI } from '@/utils/network';
import { formatAmount } from '@/utils/value';
import { computed, ref } from 'vue';

const tab = ref(0);

// const rank = ref([
//   [1, 2, 3, 4, 5],
//   [3, 4],
// ]);
const wealthRankData = ref<{ id: string; val: number }[]>([]);
const winRankData = ref<{ id: string; val: number }[]>([]);

const rank = computed(() => {
  return tab.value ? winRankData.value : wealthRankData.value;
});

const mine = computed(() => {
  const idx = rank.value.findIndex((v) => user.value?.id === v.id);
  if (idx < 0) return;
  return { seq: idx + 1, ...rank.value[idx] };
});

const openTab = async (i: 0 | 1) => {
  tab.value = i;
  if (i === 0)
    wealthRankData.value = (await authAPI.get('v1/goldCoinTable/getGoldenRank')).map((v: any) => ({
      id: v.userId,
      val: +v.balances,
    }));
  else
    winRankData.value = (await authAPI.get('v1/goldCoinTable/netIncomeRank', { type: 1 })).map((v: any) => ({
      id: v.userId,
      val: +v.netIncome,
    }));
};
openTab(0);
</script>
