<template>
  <APng :src="face" @click="showDrawer = true"></APng>
  <ASuspense>
    <DPAsyncRecord />
  </ASuspense>
  <ADrawer v-model="showDrawer" :position="3">123</ADrawer>
  <AButton outlined icon="i-local-close" @click="openClaim">Open Dialog</AButton>
  <AButton type="secondary" rounded icon="i-local-close">Open Dialog</AButton>
  <AButton text rounded icon="i-local-close" />
  <AButton rounded icon="i-local-close" @click="openAlert">Open Alert</AButton>
  <AInput type="num" v-model="inputValue" :limit="12">123</AInput>
  <AInput placeholder="123" :rows="2" />
  <ASplitInput :len="6" v-model="inputStr" />
  <ASlider class="p-8" :step="0.1" :max="200" v-model="inputValue" />
  <AOverlay>
    123
    <template #pop>
      <div>123</div>
    </template>
  </AOverlay>
  <AUpload />
  <ACheckBox v-model="checkValue" trust value="3">123</ACheckBox>
  <ACheckBox v-model="checkValue" value="2" />
  <APaginator :total="100" :size="11" />
  <APopButton v-model="pop" class="w-min" title="12">
    <template #pad>
      <p @click="pop = false">1233124123</p>
    </template>
  </APopButton>
  <ASelect :options="[1, 2, 3]"> </ASelect>
  <ADialog v-model="show" title="123">
    <p @click="openAlert">1233124123</p>
  </ADialog>
  <APAG :path="bomb" />
  <ADialog v-model="showReport" title="Report this user" class="w-120">
    <!-- <DPReportUser @close="showReport = false" /> -->
  </ADialog>
  <ADialog
    class="w-100 shine-bg item-center justify-center"
    :unclosable="true"
    :transparent="true"
    v-model="showClaimNotify"
  >
    <p class="text-center pt-4 text-xl">{{ $t('PLATFORM_TASK_CLAIM_TITLE') }}</p>
    <div class="flex flex-col rd-lg p-3 my-4 space-y-2 text-center item-center justify-center">
      <div class="flex justify-center pt-10" un-children="flex flex-col justify-center item-center p-4 rd-lg">
        <div>
          <div>
            <img src="@/assets/img/task-diamond.png" class="w-25" />
          </div>
          <span class="c-green fw-bold">2500 钻石</span>
        </div>
        <div>
          <div>
            <img src="/avatar-border/border1.png" class="w-28 -mt-8" />
          </div>
          <span class="c-green -mt-8 fw-bold pt-1">男爵</span>
        </div>
        <div>
          <div>
            <img src="@/assets/img/task-experience.png" class="w-16" />
          </div>
          <span class="c-green fw-bold">20 EXP</span>
        </div>
      </div>
      <div class="flex justify-center item-center pt-10">
        <AButton class="w-30" @click="showClaimNotify = false">{{ $t('game.CONFIRM') }}</AButton>
      </div>
    </div>
  </ADialog>
</template>
<style>
.task-reward {
  vertical-align: middle;
  background-size: contain;
  background: url('../assets/img/task-reward-bg.png') no-repeat top center/cover;
}
</style>
<script setup lang="tsx">
import { ref } from 'vue';
import bomb from '@/assets/pag/gift/LIKE-s.pag?url';
import face from '@/assets/img/a-win.png?url';
import { useDialog } from '@/components/ui/APopPad.vue';
import { useDlgCtrl } from '@/components/ui/ADialog.vue';

const pop = ref(false);
const checkValue = ref();
const inputStr = ref('');
const inputValue = ref(123);
const popup = useDialog();
const show = useDlgCtrl();
const showReport = ref(false);
const showClaimNotify = ref(false);
const openAlert = () => {
  popup.alert({ title: Date.now() + '', content: <div onClick={openAlert}>123</div> });
};

const showDrawer = ref(false);

const test = async () => {
  await Promise.reject();
};

import RewardClaimPage from '@/views/mtt/RewardClaimPage.vue';
import { formatAmount } from '@/utils/value';
import { formatNumber } from '@/utils/strings';

const openClaim = () => {
  showClaimNotify.value = true;
};
</script>
