<template>
  <div class="of-hidden rd bg-dark-400">
    <header class="flex p-2 bg-gray-700 c-gray-400 text-center">
      <h6 class="flex justify-center items-center flex-[.5]">{{ $t('mtt.LEVEL') }}</h6>
      <h6 class="flex justify-center items-center flex-[2]">{{ $t('mtt.BLINDS') }}</h6>
      <h6 class="flex justify-center items-center flex-1">{{ $t('ANTE') }}</h6>
      <h6 class="flex justify-center items-center flex-1">{{ $t('mtt.MINUTES') }}</h6>
      <h6 v-if="!staticData" class="flex justify-center items-center flex-[.5]">{{ $t('mtt.ACTIONS') }}</h6>
    </header>
    <div
      v-for="(item, i) in staticData || data"
      :key="i"
      class="flex p-2 c-gray-300 text-center items-center odd:bg-dark-600"
    >
      <p class="flex-[.5] flex justify-center items-center">{{ i + 1 }}</p>
      <p v-if="staticData" class="flex-[2]">{{ item.smallBlind }}/{{ item.bigBlind }}</p>
      <div v-else class="flex flex-[2] rd py-1 text-xs b-0 bg-gray-700 justify-center">
        <DPBlindInput class="text-right" v-model="item.smallBlind" />
        /
        <DPBlindInput v-model="item.bigBlind" />
      </div>
      <p v-if="staticData" class="flex-1">{{ item.ante }}</p>
      <div v-else class="flex-1 ml-1">
        <div class="w-21 m-auto rd py-1 b-0 bg-gray-700">
          <DPBlindInput class="text-center" v-model="item.ante" />
        </div>
      </div>
      <p v-if="staticData" class="flex-1">{{ item.duration }}</p>
      <div v-else class="flex-1 ml-1">
        <div class="w-21 m-auto rd py-1 b-0 bg-gray-700">
          <DPBlindInput class="text-center" v-model="item.duration" />
        </div>
      </div>
      <h6 v-if="!staticData" class="flex-[.5] text-lg justify-center c-gray-300 space-x-1 flex">
        <div class="block i-local-add" @click="add(i)"></div>
        <div class="block i-local-delete" @click="remove(i)"></div>
      </h6>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { BlindStructure } from '@/types';
import { authAPI } from '@/utils/network';
import { onMounted, ref } from 'vue';

const props = defineProps<{ duration: number; id?: string }>();
const data = defineModel<BlindStructure[]>();
const staticData = ref<BlindStructure[]>();

onMounted(async () => {
  if (data.value?.length) return;
  const v = await authAPI.get<BlindStructure[]>('v1/mtt/blinds_structure', {
    mttID: props.id || '',
  });
  const blinds = v.map((item) => (item.duration ? item : { ...item, duration: props.duration }));
  if (data.value) data.value = blinds;
  else staticData.value = blinds;
});
const add = (idx: number) => {
  data.value?.splice(idx, 0, { ...data.value[idx] });
};
const remove = (idx: number) => {
  data.value?.splice(idx, 1);
};
</script>
