<script setup lang="ts">
import { native } from '@/utils/device';
import { useRouteParams } from '@vueuse/router';
import { defineAsyncComponent, onUnmounted, ref } from 'vue';

const cmp = import('@/components/TheGame.vue');
const TheGame = defineAsyncComponent(() => cmp);

const id = useRouteParams<string>('room');
const type = useRouteParams<string>('type');
const typeMap: Record<string, string> = {
  p: 'private',
  m: 'mtt',
  s: 'sng',
  g: 'golden',
  'm-l': 'mtt_onlooker',
};

const inited = ref(false);
const version = ref('');
const initRoom = (v: string, ver: string) => {
  version.value = ver;
  id.value = v;
  inited.value = true;
};

onUnmounted(() => native?.quitGame());
</script>

<template>
  <TheGame v-if="inited" :id="id" :version="version" :type="typeMap[type]" />
  <DPCreater v-else :id="id" :type="typeMap[type]" @open-room="initRoom" />
</template>
<style>
.p-sidebar-header {
  display: none !important;
}
</style>
