<template>
  <div class="p-3 grid grid-cols-2 gap-3 justify-items-stretch items-center">
    <div>
      <h6 class="mb-1">{{ $t('game.SMALL_BLIND') }}</h6>
      <InputNumber
        :input-props="{ type: 'tel' }"
        input-class="w-full"
        :disabled="disable"
        v-model="sb"
        @update:modelValue="clipBB"
      />
    </div>
    <div>
      <h6 class="mb-1">{{ $t('game.BIG_BLIND') }}</h6>
      <InputNumber :input-props="{ type: 'tel' }" input-class="w-full" :min="sb" :disabled="disable" v-model="bb" />
    </div>
    <template v-if="showAdvance || type === 1">
      <div class="col-span-2">
        <h6>{{ $t('game.MIN_MAX_RANGE') }} ({{ buyin[0] + ' ~ ' + buyin[1] }}BB)</h6>
        <Slider
          class="my-4 mx-2"
          range
          :disabled="disable"
          :min="50"
          :max="1000"
          :modelValue="buyin"
          @update:modelValue="updateRange"
          :step="50"
        />
      </div>
      <div class="col-span-2">
        <h6 class="mb-1">{{ $t('ANTE') }}</h6>
        <Dropdown
          class="w-full"
          :disabled="disable"
          v-model="ante"
          optionLabel="label"
          optionValue="val"
          :options="antes"
        />
      </div>
      <h6>{{ $t('game.STRADDLE') }}</h6>
      <InputSwitch class="justify-self-end" :disabled="disable" v-model="straddle" />
      <h6>{{ $t('game.RUN_IT_TWICE') }}</h6>
      <InputSwitch class="justify-self-end" :disabled="disable" v-model="twice" />
      <h6>{{ $t('RABBIT_HUNTING') }}</h6>
      <InputSwitch class="justify-self-end" :disabled="disable" v-model="rabbit" />
      <h6>{{ $t('INSURANCE') }}</h6>
      <InputSwitch class="justify-self-end" :disabled="disable" v-model="insurance" />
      <template v-if="type === 0">
        <h6>{{ $t('BLOCKCHAIN_SHUFFLE') }}</h6>
        <InputSwitch class="justify-self-end" :disabled="disable" v-model="vdfShuffle" />
      </template>
      <h6 class="mb-1">{{ $t('FEE_STRCUTURE') }}</h6>
      <Dropdown
        class="w-full"
        :disabled="disable"
        v-model="fee"
        optionLabel="label"
        optionValue="val"
        :options="feeOptions"
      />
      <template v-if="fee !== 0">
        <div>
          <h6 class="mb-1">{{ $t('FEE_RATIO') }}</h6>
          <Dropdown
            class="w-full"
            :disabled="disable"
            v-model="ratio"
            optionLabel="label"
            optionValue="val"
            :options="feeRatioOptions"
          />
        </div>
        <div>
          <h6 class="mb-1">{{ $t('FEE_CAP') }}</h6>
          <Dropdown
            class="w-full"
            :disabled="disable || fee === 2"
            v-model="cap"
            optionLabel="label"
            optionValue="val"
            :options="feeCapOptions"
          />
        </div>
      </template>
      <div>
        <h6 class="mb-1">{{ $t('game.DECISION_TIME') }}({{ $t('SEC') }})</h6>
        <InputNumber :input-props="{ type: 'tel' }" input-class="w-full" :disabled="disable" v-model="timerNorm" />
      </div>
      <div>
        <h6 class="mb-1">{{ $t('mtt.TIME_BANK') }}({{ $t('SEC') }})</h6>
        <Dropdown class="w-full" :disabled="disable" v-model="timeBank.duration" :options="[15, 30, 45, 60]" />
      </div>
      <div>
        <h6 class="mb-1">{{ $t('mtt.START_TIME_BANK') }}</h6>
        <Dropdown class="w-full" :disabled="disable" v-model="timeBank.number" :options="[0, 1, 2, 3]" />
      </div>
      <div>
        <h6 class="mb-1">{{ $t('mtt.ADDITIONAL_TIME_BANK') }}({{ $t('HANDS') }})</h6>
        <Dropdown class="w-full" :disabled="disable" v-model="timeBank.addPolicy" :options="[20, 30, 40]" />
      </div>
      <div class="col-span-2">
        <p class="mb-1">{{ $t('game.ROOM_PLAYERS') }}</p>
        <Dropdown class="w-full" :disabled="disable || type === 1" v-model="mode" :options="modes" />
      </div>
      <div class="col-span-2">
        <h6 class="mb-1">{{ $t('game.GAME_DURATION') }}({{ $t('HOUR') }})</h6>
        <Dropdown
          class="w-full"
          :disabled="disable || type === 1"
          optionLabel="label"
          optionValue="val"
          v-model="durantion"
          :options="durantionOptions"
        />
      </div>
    </template>
  </div>
  <div v-if="!disable" class="p-3 sticky bottom-0 bg-gray-900">
    <AButton class="w-full" @click="submit">
      {{ $t(type === 1 ? 'game.USER_SETTINGS' : 'game.CREATE_GAME') }}
    </AButton>
    <h5
      v-if="type === 0"
      class="mt-4 flex items-center justify-center cursor-pointer c-green"
      @click="showAdvance = !showAdvance"
    >
      {{ $t('game.ADVANCED_SETTINGS') }}
      <i class="i-local-arrow-d" :class="{ 'rotate-180': showAdvance }" />
    </h5>
  </div>
</template>
<script setup lang="ts">
import type { Status } from '@/types';
import { computed } from 'vue';
import { inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
defineProps<{ type?: 0 | 1; disable?: boolean }>();

const emits = defineEmits<{ submit: [any] }>();

const game = inject('game', {}) as Status;

const sb = ref(1);
const bb = ref(2);
const buyin = ref([50, 1000]);
const timerNorm = ref(20);
const timeBank = ref({ duration: 15, number: 3, addPolicy: 30 });
const mode = ref('9');
const modes = ['2', '6', '9'];
const durantion = ref(3600 * 4);
const twice = ref(false);
const straddle = ref(false);
const rabbit = ref(true);
const insurance = ref(false);
const vdfShuffle = ref(false);
const fee = ref(0);
const ratio = ref(30);
const cap = ref(100);
const ante = ref('0');
const antes = computed(() => [
  { val: '0', label: t('OFF') },
  { val: '0.25', label: '0.25BB' },
  { val: '0.5', label: '0.5BB' },
  { val: '0.75', label: '0.75BB' },
  { val: '1', label: '1BB' },
]);

watch(
  () => game.roomset,
  (v) => {
    if (!v) return;
    sb.value = v.config.blindLevels[0].smallBlind;
    bb.value = v.config.blindLevels[0].bigBlind;
    ante.value = v.config.blindLevels[0].anteTimes;
    buyin.value[0] = v.config.minBuyBlind;
    buyin.value[1] = v.config.maxBuyBlind;
    timerNorm.value = v.config.clientActTimeOut;
    mode.value = '' + v.config.maxPlayerNumber;
    durantion.value = v.config.gameDuration;
    twice.value = v.config.runTwice;
    straddle.value = v.config.straddle;
    rabbit.value = v.config.enableRabbitHunting;
    timeBank.value = v.config.timeBank;
    fee.value = v.config.gameFee || 0;
    cap.value = v.config.cap ?? 10;
    ratio.value = v.config.ratio ?? 3;
    insurance.value = v.config.allInInsurance || false;
    vdfShuffle.value = v.config.vdfShuffle || false;
  },
  { immediate: true },
);

const showAdvance = ref(false);
function submit() {
  emits('submit', {
    blindLevels: [
      {
        smallBlind: sb.value,
        bigBlind: bb.value,
        ante: Math.ceil(+ante.value * bb.value),
        anteTimes: ante.value,
      },
    ],
    initBlind: buyin.value[0],
    minBuyBlind: buyin.value[0],
    maxBuyBlind: buyin.value[1],
    maxPlayerNumber: +mode.value,
    gameDuration: durantion.value,
    clientActTimeOut: timerNorm.value,
    straddle: straddle.value,
    runTwice: twice.value,
    antes: ante.value !== '0',
    videoChat: true,
    type: 'private',
    enableRabbitHunting: rabbit.value,
    timeBank: timeBank.value,
    gameFee: fee.value,
    ratio: ratio.value,
    allInInsurance: insurance.value,
    cap: cap.value,
    vdfShuffle: vdfShuffle.value,
  });
}

function clipBB(v: number) {
  if (bb.value < v) bb.value = v;
}

function updateRange(v: number | number[]) {
  if (!Array.isArray(v)) return;
  if (v[0] > v[1]) v[0] = v[1];
  buyin.value = v;
}

const durantionOptions = Array(16)
  .fill(0)
  .map((_, i) => ({ val: (3600 * (i + 1)) / 2, label: (i + 1) / 2 }));

const feeOptions = [
  { val: 0, label: t('OFF') },
  { val: 1, label: t('FEE_PER_HAND') },
];
const feeRatioOptions = Array(20)
  .fill(0)
  .map((_v, i) => ({ val: (i + 1) * 5, label: `${(i + 1) * 0.5}%` }));

const feeCapOptions = [
  { val: 0, label: t('NO_CAP') },
  ...Array(100)
    .fill(0)
    .map((_v, i) => ({ val: i + 1, label: `${(i + 1) / 10}BB` })),
];
</script>
