<template>
  <div class="py-4">
    <article v-for="(log, i) in logs" :key="i" class="p-4 rd-xl bg-gray-700 mb-4">
      <h1 class="c-green">{{ log.version }}</h1>
      <div class="c-gray mb-4">Updated time {{ dt(log.updateTime).strDatetime }}</div>
      <div class="ws-pre-wrap">{{ log.content }}</div>
    </article>
    <ALoader :loader="loadData" />
  </div>
</template>
<script lang="ts" setup>
import { dt } from '@/utils/datetime';
import { cmsLabelMapping } from '@/utils/language';
import { blogFetch } from '@/utils/network';
import qs from 'qs';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const logs = ref<any[]>([]);

const loadData = async () => {
  const query = qs.stringify({
    fields: ['version', 'updateTime', 'content'],
    sort: 'id:desc',
    locale: cmsLabelMapping[locale.value] || 'en',
    pagination: { limit: 3, start: logs.value.length },
  });
  const { data } = await blogFetch.get(`change-logs?${query}`).json<{ data: any[] }>();
  logs.value.push(...data.map((v) => v.attributes));
  if (data.length < 3) return 'end';
  return '';
};
</script>
