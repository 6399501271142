<template>
  <div ref="mainRef" v-bind="$attrs" @click="show = true">
    <slot />
  </div>
  <teleport to="body">
    <div v-if="show" ref="popRef" class="flex fixed w-0 h-0">
      <slot name="pop" />
    </div>
  </teleport>
</template>
<script setup lang="ts">
import { setPopStyle } from '@/utils/dom';
import { ref, watch } from 'vue';

defineOptions({ inheritAttrs: false });

const popRef = ref();
const mainRef = ref();

const show = defineModel<boolean>();

watch(popRef, (v) => {
  if (v) {
    setPopStyle(mainRef.value, v);
  }
});
</script>
