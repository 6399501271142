<script setup lang="ts">
import { botAPI, gameAPI, session } from '@/utils/network';
import { showLogin, tgUser, user } from '@/stores';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { convertToLangeLabel } from '@/utils/strings';
import { userPromise } from '@/stores/user';
import { toast } from './ui/AToast.vue';

const i18n = useI18n();

const name = ref('');
const router = useRouter();

const props = defineProps<{ id: string; type: string }>();
const emits = defineEmits<{ 'open-room': [string, string] }>();

const showCreate = ref(false);
const showJoin = ref(false);
const showSetting = ref(false);
const showNoGame = ref(false);
const showLoading = ref(true);
const error = ref('');
const prefix = ['mtt', 'mtt_onlooker', 'sng'].includes(props.type) ? 'mtt' : props.type;

function initRoom(v = props.id, ver = '') {
  emits('open-room', v, ver);
}

async function createRoom(config: any) {
  showCreate.value = false;
  showSetting.value = false;
  const v = await gameAPI.post<{ roomID: string; sessionID: string }>(prefix + '/v1/new_game', {
    playerName: name.value.trim(),
    roomConfig: config,
    traceID: URL.createObjectURL(new Blob()).split('/').at(-1),
    time: Date.now(),
  });
  session.value = v.sessionID;
  if (tgUser.id > 0) {
    botAPI.get('tg/sendPokerInvite', {
      userId: tgUser.id,
      gameType: 'private',
      roomId: v.roomID,
      chatId: tgUser.fromChatId,
      topicId: tgUser.fromTopicId,
    });
  }
  initRoom(v.roomID);
}

async function joinGame() {
  showJoin.value = false;
  error.value = '';
  try {
    const v = await gameAPI.post<{ sessionID: string }>(
      `${prefix}/v1/join_game`,
      { playerName: name.value, traceID: URL.createObjectURL(new Blob()).split('/').at(-1), time: Date.now() },
      { id: props.id, type: props.type },
    );
    session.value = v.sessionID;
    initRoom();
  } catch (err: any) {
    error.value = err.msg;
    showJoin.value = true;
  }
}

onMounted(async () => {
  await userPromise;
  name.value = user.value?.name || tgUser['username'] || '';
  if (!props.id) return (showCreate.value = true);
  try {
    const v = await gameAPI.post<{ playerName: string; sessionID: string; 'game-version'?: string }>(
      `${prefix}/v1/join_game`,
      { traceID: URL.createObjectURL(new Blob()).split('/').at(-1), time: Date.now() },
      { id: props.id, type: props.type },
    );
    session.value = v.sessionID;
    initRoom(props.id, v['game-version']);
  } catch (err: any) {
    if (err.code === 401) showJoin.value = true;
    else if (err.code === 505) showNoGame.value = true;
    else if (err.code === 536) showLogin.value = true;
    else if (err.msg) {
      toast.error(i18n.t(convertToLangeLabel(err.msg)));
    } else {
      if (props.type === 'mtt') router.replace(`/m/${props.id}`);
      else if (history.state.back) router.back();
      else router.replace('/');
    }
  } finally {
    showLoading.value = false;
  }
});

const goBack = () => {
  if (history.state.back) router.back();
  else router.replace('/');
};

const openSetting = () => {
  showCreate.value = false;
  showSetting.value = true;
};
</script>

<template>
  <ADialog v-model="showCreate" unclosable :title="$t('NEW_GAME')">
    <p class="c-gray-300 text-center mb-4">{{ $t('START_NEW_GAME') }}</p>
    <form class="flex flex-col w-90" @submit.prevent="openSetting">
      <AInput class="mb-6 text-lg" v-model="name" :limit="40" :placeholder="$t('YOUR_NICKNAME')">
        <p>{{ $t('NICK_NAME') }}</p>
      </AInput>
      <AButton class="mb-4" :disabled="!name?.trim()" @click="openSetting">
        {{ $t('game.CREATE_GAME') }}
      </AButton>
    </form>
  </ADialog>
  <ADialog class="w-120" v-model="showSetting" unclosable :title="$t('GAME_SETTINGS')">
    <p class="c-gray-300 text-center mb-2">{{ $t('CHANGE_GAME_SETTINGS') }}</p>
    <DPSetting @submit="createRoom" :type="0" />
  </ADialog>
  <ADialog v-model="showJoin" :title="$t('CREATE_YOUR_NAME')" unclosable>
    <form class="flex flex-col w-90" @submit.prevent="joinGame">
      <AInput class="text-lg" v-model="name" :limit="40" :placeholder="$t('YOUR_NICKNAME')">
        <p>{{ $t('JOIN_GAME') }}</p>
      </AInput>
      <p class="c-red h-6">{{ error }}</p>
      <AButton class="mb-4" :disabled="!name?.trim()" @click="joinGame">{{ $t('START') }}</AButton>
    </form>
  </ADialog>
  <ADialog class="w-100" unclosable :modelValue="!showJoin && !showCreate && !showSetting && showLoading">
    <div class="text-center py-10">
      <ProgressSpinner strokeWidth="3" />
    </div>
  </ADialog>
  <ADialog class="w-100" :modelValue="showNoGame" unclosable :title="$t('NOTICE')">
    <p class="text-center mb-8 c-gray-400">{{ $t('ROOM_CLOSED') }}</p>
    <AButton class="w-full" @click="goBack">{{ $t('EXIT_ROOM') }}</AButton>
  </ADialog>
</template>
