import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { ref } from 'vue';

import GamePage from '@/views/GamePage.vue';

import MTTCreatePage from '@/views/mtt/CreatePage.vue';

import TestPage from '@/views/TestPage.vue';

// import TermsPage from '@/views/TermsPage.vue';
// import PrivacyPage from '@/views/PrivacyPage.vue';

import HomePage from '@/views/HomePage.vue';
import MTTDetailPage from '@/views/mtt/DetailPage.vue';
import MTTTicketPage from '@/views/mtt/TicketPage.vue';

// import WalletPage from '@/views/wallet/index.vue';
// import WalletWithDrawPage from '@/views/wallet/WithDraw.vue';
// import WalletHistoryPage from '@/views/wallet/History.vue';

import TgAuth from '@/views/landing/TgAuthPage.vue';
import TgGame from '@/views/landing/TgGamePage.vue';
import DiscordAuth from '@/views/landing/DiscordAuthPage.vue';
import XAuth from '@/views/landing/XAuthPage.vue';
import GoogleAuth from '@/views/landing/GoogleAuthPage.vue';
import DeboxAuth from '@/views/landing/DeboxAuthPage.vue';
import VpnLanding from '@/views/landing/VpnLandingPage.vue';

import Layout from '@/views/GlobalLayout.vue';
import HomePages from '@/views/home';

import profilePages from '@/views/profile';

import SNG from '@/views/sng';
import Clan from '@/views/clan';
import Golden from '@/views/golden';
import activities from '@/views/activities';

import Sponsor from '@/views/sponsor/index.vue';
import SponsorDetail from '@/views/sponsor/Detail.vue';
import Validation from '@/views/validation/index.vue';
import FAQPage from '@/views/FAQPage.vue';
import ChangeLogPage from '@/views/ChangeLogPage.vue';
import { emitter } from '@/stores/user';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'base',
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      HomePages,
      {
        path: 'home',
        name: 'home',
        component: HomePage,
      },
      {
        path: 'faq/:section?',
        name: 'faq',
        component: FAQPage,
        props: true,
        meta: { title: 'FAQ' },
      },
      {
        path: 'change-log',
        name: 'change-log',
        component: ChangeLogPage,
        meta: { title: 'CHANGE_LOG' },
      },
      {
        path: 'm/:id([^/]{5,})/:label?',
        name: 'mtt-detail',
        component: MTTDetailPage,
        props: true,
        meta: { title: 'TOURNAMENT', back: '/m' },
      },
      {
        path: 'm/t/:id([^/]{5,})',
        name: 'mtt-ticket',
        component: MTTTicketPage,
        props: true,
        meta: { title: 'mtt.TICKET' },
      },
      { path: 'sponsor', component: Sponsor },
      { path: 'sponsor/:id', component: SponsorDetail, props: true, meta: { title: 'Sponsor' } },
      { path: 'validation/:room/:seq', component: Validation, props: true },
      SNG,
      ...Golden,
      ...Clan,
      ...activities,
    ],
  },
  { name: 'game', path: '/g/:type/:room?', component: GamePage },
  { name: 'tgAuth', path: '/auth', component: TgAuth },
  { name: 'tgGame', path: '/tgGame', component: TgGame },
  { name: 'discordAuth', path: '/discordAuth', component: DiscordAuth },
  { name: 'xAuth', path: '/xAuth', component: XAuth },
  { name: 'googleAuth', path: '/googleAuth', component: GoogleAuth },
  { name: 'deboxAuth', path: '/deboxAuth', component: DeboxAuth },
  { name: 'vpn', path: '/vpn', component: VpnLanding },
  // redirect
  { path: '/g', redirect: '/g/p' },
  { path: '/mtt', redirect: '/m' },
  { path: '/mtt/:id/:label?', redirect: { name: 'mtt-detail' } },
  { path: '/sng', redirect: '/s' },
  { path: '/game', redirect: '/g' },
  { name: 'default', path: '/:pathMatch(.*)*', redirect: '/lobby' },
];

if (import.meta.env.MODE !== 'prod') routes.push({ path: '/test', name: 'test', component: TestPage });

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

// const addLoginRouters = (permissions?: string[]) => {
//   if (!permissions) return;
//   router.addRoute({ name: 'game-guide', path: '/game/guide/:step', component: () => import('@/views/GuidePage.vue') });
//   router.addRoute('base', {
//     path: 'profile',
//     component: ProfilePage,
//     children: [
//       {
//         path: 'account',
//         name: 'account',
//         component: AccountPage,
//         meta: { title: 'ACCOUNT' },
//       },
//       {
//         path: 'records',
//         name: 'records',
//         component: RecordPage,
//         meta: { title: 'HAND_REVIEW' },
//       },
//       { name: 'wallet', path: 'wallet', component: WalletPage, meta: { title: 'WALLET' } },
//       { name: 'wallet-withdraw', path: 'wallet-withdraw', component: WalletWithDrawPage, meta: { title: 'WALLET' } },
//       { name: 'wallet-history', path: 'wallet-history', component: WalletHistoryPage, meta: { title: 'HISTORY' } },
//       { path: 'point', name: 'point', component: PointPage, meta: { title: 'POINT_SYSTEM' } },
//       { path: 'referral', name: 'referral', component: ReferralPage, meta: { title: 'REFERRAL' } },
//       { path: 'task', name: 'task', component: TaskPage, meta: { title: 'TASK_CENTER' } },
//       { path: 'clan', name: 'profile-clan', component: ClanPage, meta: { title: 'MY_CLAN' } },
//     ],
//   });
//   if (permissions.includes('create_mtt')) {
//     router.addRoute('base', {
//       path: 'm/new',
//       component: MTTCreatePage,
//       props: ({ query }) => ({ id: query.id }),
//       meta: { title: 'MTT' },
//     });
//   }
// };

// router.afterEach(() => {
//   logger.view();
// });
const userRouters: (() => void)[] = [];
let resolve: () => void;
const routerPromise = new Promise<void>((r) => (resolve = r));

export const routerIdx = ref(0);

const loadRouter = (permissions?: string[], blockRefresh = false) => {
  while (userRouters.length) userRouters.pop()!();
  if (permissions) {
    userRouters.push(
      router.addRoute({
        name: 'game-guide',
        path: '/game/guide/:step',
        component: () => import('@/views/GuidePage.vue'),
      }),
      ...profilePages.map((v) => router.addRoute('base', v)),
    );
    if (permissions.includes('create_mtt')) {
      userRouters.push(
        router.addRoute('base', {
          path: 'm/new',
          component: MTTCreatePage,
          props: ({ query }) => ({ id: query.id, name: query.name }),
          meta: { title: 'MTT' },
        }),
      );
    }
  }

  if (routerLoaded && !blockRefresh) {
    // console.log(router.currentRoute.value.fullPath);
    // router.replace('/home');
    routerIdx.value++;
    router.replace(router.currentRoute.value.fullPath);
  }
  routerLoaded = true;
  resolve();
};

emitter.on('permissions', loadRouter);

let routerLoaded = false;

router.beforeEach(async (to) => {
  if (!routerLoaded && to.redirectedFrom && to.redirectedFrom?.name !== 'base') {
    await routerPromise;
    return to.redirectedFrom?.fullPath;
  }
});

export default router;
