<script setup lang="ts">
import type { PropType } from 'vue';
import { onMounted, ref } from 'vue';

const props = defineProps({
  mode: {
    type: String as PropType<Mode>,
    required: true,
    validator: (prop: Mode) => ['callback', 'redirect'].includes(prop),
  },
  botUsername: {
    type: String,
    required: true,
    validator: (prop: String) => prop.endsWith('bot') || prop.endsWith('Bot'),
  },
  redirectUrl: {
    type: String,
    default: '',
  },
  requestAccess: {
    type: String as PropType<Access>,
    default: 'read' as Access,
    validator: (prop: Access) => ['read', 'write'].includes(prop),
  },
});

const emit = defineEmits(['callback']);

type Mode = 'callback' | 'redirect';
type Access = 'read' | 'write';

const button = ref<HTMLElement | undefined>();
const loading = ref(true);

const onTelegramAuth = (user: any) => {
  emit('callback', user);
};

onMounted(() => {
  const script = document.createElement('script');
  script.async = false;
  script.src = 'https://telegram.org/js/telegram-widget.js?22';
  script.setAttribute('data-size', 'medium');
  script.setAttribute('data-userpic', 'true');
  script.setAttribute('data-telegram-login', props.botUsername);
  script.setAttribute('data-request-access', props.requestAccess);
  script.setAttribute('data-radius', '0');
  script.onload = () => (loading.value = false);
  if (props.mode === ('callback' as Mode)) {
    (window as any).onTelegramAuth = onTelegramAuth;
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
  } else {
    script.setAttribute('data-auth-url', props.redirectUrl);
  }
  if (button.value) button.value.appendChild(script);
});
</script>
<template>
  <div class="relative of-hidden w-fit">
    <div class="absolute z-1 top-0 left-0 origin-top-left op-1 w-30px h-30px scale-1000" ref="button" />
    <div class="pointer-events-none">
      <slot />
    </div>
    <div v-if="loading" class="absolute inset-0 bg-black/40 flex items-center justify-center">
      <div class="text-lg i-local-loading" />
    </div>
  </div>
</template>
