<template>
  <section v-if="user" class="mb-4 flex items-center">
    <DPAvatar class="text-5xl" :name="user.name" :avatar="user.avatar" />
    <div class="mx-4 flex-1">
      <h2>{{ user.name }}</h2>
      <div class="flex items-center mt-1">
        <img class="h-4 mr-2" alt="" :src="`/flags/${countryData.find((v) => v.code === user!.country)?.code}.png`" />
        <div class="c-gray">{{ countryData.find((v) => v.code === user!.country)?.name }}</div>
      </div>
    </div>
    <AButton class="min-w-24" @click="$router.push('/profile/edit')">{{ $t('mtt.EDIT') }}</AButton>
  </section>
  <section v-else class="mb-4 flex">
    <div class="flex-1">
      <div class="pr-10 mb-4">{{ $t('LOG_IN_TO_DISCOVER_MORE_FUN_IN_LEPOKER') }}</div>
      <AButton @click="showLogin = true">{{ $t('LOGIN') }}</AButton>
    </div>
    <div class="i-local-logo size-24 c-gray-600"></div>
  </section>
  <template v-if="true">
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/account')">
      <div class="i-local-user mr-2 c-gray"></div>
      {{ $t('ACCOUNT') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray" />
    </div>
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/records')">
      <div class="i-local-history mr-2 c-gray"></div>
      {{ $t('HAND_REVIEW') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
    </div>
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/task/daily')">
      <div class="i-local-task-center mr-2 c-gray"></div>
      {{ $t('TASK_CENTER') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
    </div>
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/settings')">
      <div class="i-local-settings mr-2 c-gray"></div>
      {{ $t('GAME_SETTINGS') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
    </div>
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/clan')">
      <div class="i-local-clan mr-2 c-gray"></div>
      {{ $t('MY_CLAN') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
    </div>
    <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/referral')">
      <div class="i-local-gift mr-2 c-gray"></div>
      {{ $t('REFERRAL') }}
      <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
    </div>
  </template>
  <div class="text-xl h-16 flex items-center cursor-pointer" @click="showLanguage = true">
    <div class="i-local-lang mr-2 c-gray"></div>
    Language
    <span class="ml-a mr-4 c-gray">{{ LANGS.find((v) => v.val === locale)?.label }}</span>
    <div class="i-local-arrow-l rotate-180 c-gray" />
  </div>
  <div class="b-t-solid b-gray-700 text-xl h-16 flex items-center cursor-pointer" @click="openCustomer">
    <div class="i-local-support mr-2 c-gray"></div>
    {{ $t('CUSTOMER_SUPPORT') }}
    <div class="i-local-arrows-l rotate-180 ml-a c-gray"></div>
  </div>
  <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/faq')">
    <div class="i-local-tutorial mr-2 c-gray"></div>
    FAQ
    <div class="i-local-arrows-l rotate-180 ml-a c-gray"></div>
  </div>
  <div class="text-xl h-16 flex items-center cursor-pointer" @click="$router.push('/profile/feedback')">
    <div class="i-local-feedback mr-2 c-gray"></div>
    {{ $t('FEEDBACK') }}
    <div class="i-local-arrows-l rotate-180 ml-a c-gray"></div>
  </div>
  <!-- <div class="b-t-solid b-gray-700 text-xl h-20 flex items-center cursor-pointer">
    <div class="i-local-info mr-2 c-gray"></div>
    About
    <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
  </div> -->
  <div v-if="user" class="b-t-solid b-gray-700 text-xl h-20 flex items-center cursor-pointer" @click="logout">
    <div class="i-local-exit mr-2 c-gray" />
    {{ $t('LOGOUT_USER') }}
    <div class="i-local-arrow-l rotate-180 ml-a c-gray"></div>
  </div>
</template>
<script setup lang="ts">
import { useDialog } from '@/components/ui/APopPad.vue';
import { countryData, showLogin, user } from '@/stores';
import { showLanguage } from '@/stores/lang';
import { LANGS } from '@/utils/language';
import { refreshToken, token } from '@/utils/network';
import { useI18n } from 'vue-i18n';
import { emitter } from '@/stores/user';

const dialog = useDialog();
const { t, locale } = useI18n();

const logout = () =>
  dialog.confirm({
    title: t('LOGOUT_USER'),
    content: t('ARE_YOU_SURE_TO_LOG_OUT_FROM_LEPOKER'),
    action: () => {
      token.value = '';
      refreshToken.value = '';
    },
  });

const openCustomer = () => {
  emitter.emit('customerHelp', true);
};
</script>
